import Site from '../../entities/site';
import Typology from '../../entities/typology';
import UseCase from '../../../core/app/usecase';
import SiteRepository from '../../repositories/siteRepository';
import RequirementsGroup from '../../entities/requirementsGroup';
import Variant from '../../entities/variant';

interface Args {
	site: Site;
	companyId: string;
	typology?: Typology;
	requirementsGroup?: RequirementsGroup;
	companyVariant?: Variant;
	siteVariant?: Variant;
}

class CreateSite implements UseCase<Args, Site> {
	private siteRepository: SiteRepository;

	constructor(siteRepository: SiteRepository) {
		this.siteRepository = siteRepository;
	}

	async Call({ site, companyId, typology, requirementsGroup, companyVariant, siteVariant }: Args): Promise<Site> {
		return await this.siteRepository.createSite(companyId, site, typology, requirementsGroup, companyVariant, siteVariant);
	}
}

export default CreateSite;
