import Badge, { SiteData } from "../../../../domain/entities/badge";
import { View } from "@react-pdf/renderer";
import FrontBadgeMachine from "./NfcBadgeMachine";
import QrCodeBadgeBack from "./QrCodeBadgeBack";
import React from "react";

type QrCodeBadgeMachineProps = {
  badge: Badge;
};
const QrCodeBadgeMachine = ({ badge }: QrCodeBadgeMachineProps) => (
  <View style={{ flexDirection: "row" }}>
    <FrontBadgeMachine badge={badge} />
    <QrCodeBadgeBack site={badge.site as SiteData} serial={badge.serial} />
  </View>
);
export default QrCodeBadgeMachine;
