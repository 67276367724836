import { MdClose } from "react-icons/md";
import { useApp } from "../../../app";
import { useTranslation } from "react-i18next";
import LoadingView from "../Common/LoadingView";
import { COLORS } from "../../assets/theme/colors";
import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import SupplierTopBarName from "./SupplierTopBarName";
import ContentLayout from "../../layout/ContentLayout";
import ActionBar from "../../components/Common/ActionBar";
import { useNavigate, useParams } from "react-router-dom";
import { FC, useEffect } from "react";
import ActionBarItem from "../../components/Common/ActionBarItem";
import DetailsHeader from "../../components/Common/DetailsHeader";
import { Column } from "../../components/Views/Documents/GeneralDocumentsView";
import { useSupplierDetailModel } from "../../hooks/Suppliers/useSupplierDetailModel";
import SupplierDocumentsTable, {
  SupplierDocumentsProps,
} from "../../components/Views/common/SupplierDocumentsTable";
import { useSupplierOrSiteFilesDownloader } from "../../hooks/Document/useDocumentsFilesDownloader";
import ContactField from "../../components/Common/table/ContactField";

interface Props {
  permissions: SupplierDocumentsProps["permissions"];
}

const SupplierDetailView: FC<Props> = ({ permissions }) => {
  const { t } = useTranslation("suppliers");
  const navigate = useNavigate();
  const { updateContext } = useApp();
  const {
    supplier,
    documents,
    refetchDocument,
    documentInfoProps,
    updateFilterDocuments,
    updateSortDocuments,
    updateSortDocumentEvaluations,
    sortDocumentEvaluations,
    sortDocuments,
    setSortDocuments,
    filterDocuments,
    filterDocumentEvaluations,
    getDocumentEvaluations,
    updateFilterDocumentEvaluations,
    documentEvaluations,
    documentEvaluationsFetching,
    createDocument,
    updateDocument,
    tags,
    systemStaff,
    evaluateDocument
  } = useSupplierDetailModel();

  useEffect(() => {
    updateContext({
      topBarTitle: <SupplierTopBarName supplier={supplier} />,
    });
  }, [supplier]);

  const { supplierId } = useParams();
  const downloader = useSupplierOrSiteFilesDownloader(supplierId, "company");

  const getAddress = (address) => {
    if (!address) return "";
    const { street, cap, city } = address;

    // Build the address parts array, excluding empty values
    const parts = [
      street && street.trim(),
      city && city.trim(),
      cap && cap.trim(),
    ].filter(Boolean); // Remove empty values
    return parts.join(" - ");
  };

  const detailAttributes = [
    "name",
    "fiscalCode",
    "vat",
    "email",
    "pec",
    "address",
    "phoneNumber",
  ].map((column) => {
    const value =
      column === "address"
        ? getAddress(supplier?.company?.address)
        : supplier?.company?.[column];
    return {
      label: t(column, { ns: "companies" }),
      value: value || "-",
    };
  });

  const contactAttributes = [
    "doctor",
    "rspp",
    "contact",
    "employer",
    "rls",
  ].map((role) => ({
    label: t(role, { ns: "companies" }),
    value: (
      <ContactField staff={systemStaff?.find((staff) => staff.role === role)} />
    ),
  }));

  const columns: Column[] = [
    { field: "name", filterType: "text" as const, label: t("name") },
    { field: "tags", filterType: "tags" as const, label: t("tags") },
  ];

  columns.splice(1, 0, {
    field: "expiresAt",
    filterType: "date-range",
    label: t("expireDate", { ns: "documents" }),
  });

  if (!supplier) {
    // Company data not yet available.
    return <LoadingView />;
  }

  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            bgColor={COLORS.sikuroBlue}
            color="white"
            icon={MdClose}
            description={t("close", { ns: "common" })}
            onClick={() => navigate(`/suppliers`)}
          />
        </ActionBar>
      }
    >
      {supplier && (
        <Stack paddingLeft={5} paddingRight={5} paddingTop={5}>
          <Flex gap="20px" marginBottom={5}>
            <DetailsHeader
              isLoaded={!!supplier.company.name}
              title={t("details", { ns: "common" })}
              shouldShowImage={true}
              image={{ url: supplier.company.logo, disableFlag: true }}
              attributes={[...detailAttributes, ...contactAttributes]}
            />
          </Flex>
          {documents && (
            <Flex
              direction={"column"}
              padding={5}
              border="1px solid"
              borderColor="gray.300"
              borderRadius="10px"
            >
              <Text textStyle="h2" mt={3}>
                {t("documents", { ns: "documents" })}
              </Text>

              <SupplierDocumentsTable
                tableColumns={columns}
                downloader={downloader}
                documents={documents}
                refetchDocument={refetchDocument}
                documentsHasNextPage={null}
                documentsFetchNextPage={null}
                sortDocuments={sortDocuments}
                setSortDocuments={setSortDocuments}
                filterDocuments={filterDocuments}
                updateFilterDocuments={updateFilterDocuments}
                updateSortDocuments={updateSortDocuments}
                getDocumentEvaluations={getDocumentEvaluations}
                documentEvaluations={documentEvaluations}
                documentEvaluationsFetching={documentEvaluationsFetching}
                filterDocumentEvaluations={filterDocumentEvaluations}
                updateFilterDocumentEvaluations={
                  updateFilterDocumentEvaluations
                }
                sortDocumentEvaluations={sortDocumentEvaluations}
                updateSortDocumentEvaluations={updateSortDocumentEvaluations}
                {...documentInfoProps}
                isCompanySupplier
                createDocumentWithProperties={createDocument}
                updateDocument={updateDocument}
                tags={tags}
                permissions={permissions}
                evaluateDocument={evaluateDocument}
              />
            </Flex>
          )}
        </Stack>
      )}
    </ContentLayout>
  );
};

export default SupplierDetailView;
