import {
	makeAddFileToDocument,
	makeConfirmAiTask,
	makeCreateAiTask,
	makeDeleteAiTask,
	makeDeleteFileFromDocument,
	makeEvaluateSiteDocument,
	makeEvaluateSiteSupplierDocument,
	makeGetAiTaskDetail,
	makeGetAiTasks,
	makeGetAiTaskSites,
	makeGetDocumentEvaluations,
	makeGetDocumentFiles,
	makeGetDocumentFilesLog,
	makeGetDocumentLog,
	makeUpdateFile,
} from '../../../core/app/factory/documents/usecases';
import { makeGetDocumentTypesForAi } from '../../../core/app/factory/documentTypes/usecases';
import { makeGetTags } from '../../../core/app/factory/tags/usecases';
import AiTask from '../../../domain/entities/aiTask';
import AiTaskDocument from '../../../domain/entities/aiTaskDocument';
import { AiTaskSectionCode } from '../../../domain/entities/aiTaskSectionCode';
import { DocumentActionLogEntry, DocumentFileLogEntry } from '../../../domain/entities/documentLogEntry';
import DocumentType from '../../../domain/entities/documentType';
import { DocumentTypeCategory } from '../../../domain/entities/documentTypeCategory.enum';
import Evaluation from '../../../domain/entities/evaluation';
import { default as File, default as FileEntity } from '../../../domain/entities/file';
import { SortMeta } from '../../../domain/entities/interfaces/paginatedResults';
import { ResourceDocumentEvaluationState } from '../../../domain/entities/resourceDocumentEvaluationState.enum';
import { ResourceEvaluationState } from '../../../domain/entities/resourceEvaluationState.enum';
import Site, { DocumentSites } from '../../../domain/entities/site';
import { SupplierEvaluationState } from '../../../domain/entities/supplierEvaluationState.enum';
import Tag from '../../../domain/entities/tag';
import { GetDocumentEvaluationFilter, GetDocumentFilesFilter, GetDocumentLogFilter } from '../../../domain/repositories/documentRepository';
import { GetWorkingSiteDocumentsFilter } from '../../../domain/repositories/filters';
import { GetResourceEvaluationFilter, GetSitesFilter } from '../../../domain/repositories/siteRepository';
import { DocumentTypeResourceType } from '../../../mock/models/document-type';
import { AddDocumentTypeViewModel } from '../../hooks/Document/useAddDocumentType';
import { DocumentAIViewModel } from '../../hooks/Document/useDocumentAI';
import { DocumentInfoViewModel } from '../../hooks/Document/useDocumentInfo';

export abstract class BaseSiteEntityDetailViewModel implements DocumentInfoViewModel, AddDocumentTypeViewModel, DocumentAIViewModel {
	async evaluateDocument(
		companyId: string,
		siteId: string,
		documentId: string,
		result: ResourceDocumentEvaluationState,
		expiresAt?: Date,
		noEvaluationExpiration?: boolean,
		target?: string,
		resourceId?: string
	): Promise<void> {
		await makeEvaluateSiteDocument().Call({ companyId, siteId, documentId, result, expiresAt, noEvaluationExpiration, target, resourceId });
	}

	async evaluateSiteSupplierDocument(
		companyId: string,
		siteId: string,
		documentId: string,
		result: ResourceDocumentEvaluationState,
		expiresAt?: Date,
		noEvaluationExpiration?: boolean,
		target?: string,
		resourceId?: string
	): Promise<void> {
		await makeEvaluateSiteSupplierDocument().Call({ companyId, siteId, documentId, result, expiresAt, noEvaluationExpiration, target, resourceId });
	}

	async getDocumentEvaluations(
		companyId: string,
		siteId: string,
		resourceType: string,
		resourceId: string,
		documentId: string,
		filter: GetDocumentEvaluationFilter,
		sort: SortMeta
	): Promise<Evaluation<ResourceDocumentEvaluationState>[]> {
		return await makeGetDocumentEvaluations().Call({ companyId, siteId, resourceType, resourceId, documentId, filter, sort });
	}

	async getDocumentFiles(
		companyId: string,
		documentId: string,
		filter?: GetDocumentFilesFilter,
		sort?: SortMeta,
		siteId?: string,
		target?: string,
		resourceId?: string
	): Promise<File[]> {
		return await makeGetDocumentFiles().Call({ companyId, documentId, filter, sort, siteId, target, resourceId });
	}

	async deleteDocumentFile(
		companyId: string,
		documentId: string,
		fileId: string,
		siteId?: string,
		target?: string,
		resourceId?: string
	): Promise<void> {
		await makeDeleteFileFromDocument().Call({ companyId, documentId, fileId, siteId, target, resourceId });
	}

	async addFileToDocument(companyId: string, documentId: string, file: File, siteId?: string, target?: string, resourceId?: string): Promise<void> {
		await makeAddFileToDocument().Call({ companyId, documentId, file, siteId, target, resourceId });
	}

	async getDocumentLogs(
		companyId: string,
		documentId: string,
		filter?: GetDocumentLogFilter,
		sort?: SortMeta,
		resourceId?: string,
		siteId?: string,
		target?: string,
		pageParam?: number
	): Promise<DocumentFileLogEntry[]> {
		return await makeGetDocumentLog().Call({ companyId, documentId, filter, sort, resourceId, siteId, target, pageParam });
	}

	async getDocumentFilesLogs(
		companyId: string,
		documentId: string,
		filter?: GetDocumentLogFilter,
		sort?: SortMeta,
		pageParam?: number
	): Promise<DocumentActionLogEntry[]> {
		return await makeGetDocumentFilesLog().Call({ companyId, documentId, filter, sort, pageParam });
	}

	async updateFile(
		companyId: string,
		documentId: string,
		fileId: string,
		updatedFiles: Partial<FileEntity>[],
		siteIds?: string[],
		target?: string,
		resourceId?: string,
		selectAll?: boolean
	): Promise<void> {
		return await makeUpdateFile().Call({ companyId, documentId, updatedFiles, fileId, siteIds, target, resourceId, selectAll });
	}

	async getTags(companyId: string, search?: string): Promise<Tag[]> {
		return await makeGetTags().Call({ companyId, search });
	}

	getDocumentSites(companyId: string, documentId: string): Promise<DocumentSites> {
		throw new Error('Method not implemented.');
	}
	abstract getSiteResource(companyId: string, siteId: string, resourceId: string): Promise<any>;
	abstract getSiteResourceDocuments(
		companyId: string,
		siteId: string,
		resourceId: string,
		filterDocuments: GetWorkingSiteDocumentsFilter,
		sortDocuments: SortMeta,
		pageParam?: number
	): Promise<any>;
	abstract evaluateSiteResource(
		companyId: string,
		siteId: string,
		resourceId: string,
		value: ResourceEvaluationState | SupplierEvaluationState
	): Promise<void>;
	abstract getSiteResourceEvaluations(
		companyId: string,
		siteId: string,
		resourceId: string,
		pageParam: number,
		sort?: SortMeta,
		filter?: GetResourceEvaluationFilter
	): Promise<Evaluation<ResourceEvaluationState | SupplierEvaluationState>[]>;
	abstract getVariants(companyId: string, siteId: string): Promise<any[]>;
	abstract updateSiteResourceVariant(companyId: string, resourceId: string, siteId: string, variantId?: string): Promise<void>;
	abstract addRequirementToSiteResource(
		companyId: string,
		resourceId: string,
		siteId: string,
		documentTypeId: string,
		isOptional?: boolean,
		graceDays?: number
	): Promise<void>;
	abstract getDocumentTypes(
		companyId: string,
		search?: string,
		tags?: string[],
		sort?: SortMeta,
		pageParam?: number,
		resourceId?: string
	): Promise<DocumentType[]>;
	// AI section fuctions

	async createAiTask(
		companyId: string,
		files: FileEntity,
		sectionCode: AiTaskSectionCode,
		resourceId: string,
		type: DocumentTypeCategory,
		siteId?: string
	): Promise<string> {
		return await makeCreateAiTask().Call({
			companyId,
			files,
			sectionCode,
			resourceId,
			resourceType: type as unknown as DocumentTypeResourceType,
			siteId,
		});
	}

	async getAiTasks(
		companyId: string,
		sectionCode: AiTaskSectionCode,
		resourceType?: DocumentTypeCategory,
		resourceId?: string,
		siteId?: string,
		supplierId?: string
	): Promise<AiTask[]> {
		return await makeGetAiTasks().Call({ companyId, sectionCode, resourceType, resourceId, siteId, supplierId });
	}

	async getAiTaskDetail(companyId: string, taskId: string): Promise<AiTask> {
		return await makeGetAiTaskDetail().Call({ companyId, taskId });
	}

	async deleteAiTask(companyId: string, taskId: string): Promise<void> {
		return await makeDeleteAiTask().Call({ companyId, taskId });
	}

	async sendTaskConfirmation(companyId: string, taskId: string, aiTaskDocuments: AiTaskDocument[], siteIds: string[]): Promise<void> {
		return await makeConfirmAiTask().Call({ companyId, taskId, aiTaskDocuments, siteIds });
	}

	async getAiTaskSites(
		companyId: string,
		sectionCode: AiTaskSectionCode,
		taskId: string,
		sortSites: SortMeta,
		filterSites: GetSitesFilter,
		pageParam?: number
	) {
		return await makeGetAiTaskSites().Call({ companyId, sectionCode, taskId, sortSites, filterSites, pageParam });
	}

	async getDocumentTypesForAi(companyId: string, aiTaskId: string, resourceType: DocumentTypeCategory): Promise<DocumentType[]> {
		return await makeGetDocumentTypesForAi().Call({
			companyId,
			aiTaskId,
			resourceType,
		});
	}
}

abstract class BaseSiteResourceDetailViewModel extends BaseSiteEntityDetailViewModel implements DocumentAIViewModel {
	abstract getSpecializations(companyId: string, siteId: string): Promise<any[]>;
	abstract updateSiteResourceSpecializations(companyId: string, resourceId: string, siteId: string, specializationIds?: string[]): Promise<void>;
}

export default BaseSiteResourceDetailViewModel;
