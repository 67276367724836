import { useParams } from "react-router-dom";
import { Permission } from "../../components/Permissions/Permissions";
import { SiteWorkerParams } from "../../../domain/entities/siteWorker";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import SiteResourceDetailView from "../../components/Views/Sites/SiteResourceDetailView";
import useSiteWorkerDetailViewModel from "../../hooks/Site/useSiteWorkerDetailViewModel";
import { useResourceFilesDownloader } from "../../hooks/Document/useDocumentsFilesDownloader";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";

const SiteWorkerDetailView = () => {
  const { siteId, workerId } = useParams();
  const resourceColumns = [
    "lastName",
    "firstName",
    "dateOfBirth",
    "companyName",
    "dateOfEmployment",
    "email",
    "fiscalCode",
    "placeOfBirth",
    "jobTitle",
    "phone",
    "badge",
  ];

  const downloader = useResourceFilesDownloader(siteId, workerId, "workers");

  return (
    <SiteResourceDetailView<SiteWorkerParams>
      downloader={downloader}
      resourceId={workerId}
      type={DocumentTypeResourceType.WORKER}
      useViewModel={useSiteWorkerDetailViewModel(
        siteId,
        workerId,
        resourceColumns,
        DocumentTypeResourceType.WORKER,
        AiTaskSectionCode.SITE_RESOURCES_WORKERS
      )}
      resourceColumns={resourceColumns}
      isReadOnly={false}
      permissions={{
        downloadDocuments: Permission.Sites_DownloadWorkerDocuments,
        showGlobalEvaluation: Permission.Sites_Manage,
        setGlobalEvaluation: Permission.Sites_SetGlobalWorkerEval,
        addRequirement: Permission.Sites_NewWorkerRequirement,
        viewRequirements: Permission.Sites_Manage,
        editTags: Permission.Sites_EditWorkerRequirements,
        manageDocumentWithAi: Permission.Company_AIManageFile,
        documents: {
          delete: Permission.Sites_DeleteWorkerRequirement,
          edit: Permission.Sites_EditWorkerRequirements,
          evaluate: Permission.Sites_EditWorkerRequirements,
          viewEvaluations: Permission.Sites_EditWorkerRequirements,
          downloadAll: Permission.Sites_EditWorkerRequirements,
          addFile: Permission.Sites_EditWorkerRequirements,
          chat: Permission.Sites_AccessWorkerChat,
          setExpiration: Permission.Sites_EditWorkerRequirements,
          report: Permission.Sites_Reports,
        },
      }}
    />
  );
};

export default SiteWorkerDetailView;
