import UseCase from '../../../core/app/usecase';
import Supplier from '../../entities/supplier';
import SupplierRepository from '../../repositories/supplierRepository';
import GetSubcontractorsParameters from './getActiveSubcontractorsParameter';

class GetActiveSubcontractors implements UseCase<GetSubcontractorsParameters, Supplier[]> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, siteId, filter, sort, pageParam }: GetSubcontractorsParameters): Promise<Supplier[]> {
		return await this.supplierRepository.getActiveSubcontractors(companyId, siteId, filter, sort, pageParam);
	}
}

export default GetActiveSubcontractors;
