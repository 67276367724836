import { useParams } from "react-router-dom";
import { SiteToolParams } from "../../../domain/entities/siteTool";
import { Permission } from "../../components/Permissions/Permissions";
import useSiteToolsViewModel from "../../hooks/Site/useSiteToolsViewModel";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import SiteResourcesListView from "../../components/Views/Sites/SiteResourcesListView";

const SiteToolsView = () => {
  const { siteId } = useParams();
  const resourceColumns = ["name", "serial"];
  return (
    <SiteResourcesListView<SiteToolParams>
      type={DocumentTypeResourceType.TOOL}
      useViewModel={useSiteToolsViewModel(
        siteId,
        resourceColumns,
        DocumentTypeResourceType.TOOL,
      )}
      resourceColumns={resourceColumns}
      addToSitePermissions={[Permission.Sites_Manage, Permission.Sites_AddTool]}
      removeFromSitePermissions={[
        Permission.Sites_Manage,
        Permission.Sites_RemoveTool,
      ]}
    />
  );
};

export default SiteToolsView;
