import { FC } from "react";
import { Alert } from "../Common/Alert";
import { useTranslation } from "react-i18next";
import OnboardingHeader from "./OnboardingHeader";
import OnboardingFooter from "./OnboardingFooter";
import useOnboardingViewModel from "../../hooks/Onboarding/useOnboardingViewModel";
import { useNavigate } from "react-router-dom";
import {
  CardHeader,
  CardBody,
  Button,
  CardFooter,
  VStack,
  Text,
  Flex,
} from "@chakra-ui/react";
import OnboardingCheckboxes from "./OnboardingCheckboxes";
import FormInput from "../../components/Common/FormInput";
import PasswordFields from "./PasswordFields";

interface SignUpUserProps {
  onCancel: () => void;
}

const SignUpUser: FC<SignUpUserProps> = ({ onCancel }) => {
  const { t } = useTranslation("onboarding");
  const {
    methods,
    isLoading,
    successCreateAccount,
    createPersonalAccount,
    error,
    setError,
  } = useOnboardingViewModel();
  const navigate = useNavigate();
  const routeChange = () => {
    navigate("/?screen=sign-in");
  };

  return (
    <>
      {!successCreateAccount && (
        <>
          <CardHeader>
            <OnboardingHeader
              title={t("ui.sign up as a user")}
              onBack={onCancel}
            />
          </CardHeader>

          <CardBody>
            <FormInput
              inputProps={methods.register("email", {
                required: t("errors.email is required"),
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: t("errors.insert a valid email"),
                },
              })}
              label={t("ui.email")}
              isRequired
              error={methods.formState.errors.email?.message ?? false}
              type="email"
            />
            <FormInput
              inputProps={methods.register("firstName", {
                required: t("errors.user.first name is required"),
              })}
              label={t("ui.first name")}
              isRequired
              error={methods.formState.errors.firstName?.message ?? false}
              type="text"
            />
            <FormInput
              inputProps={methods.register("lastName", {
                required: t("errors.user.last name is required"),
              })}
              label={t("ui.last name")}
              isRequired
              error={methods.formState.errors.lastName?.message ?? false}
              type="text"
            />
            <PasswordFields
              errors={methods.formState.errors}
              register={methods.register}
              watch={methods.watch}
            />
            <OnboardingCheckboxes
              errors={methods.formState.errors}
              register={methods.register}
            />
          </CardBody>

          <CardFooter>
            <VStack width="100%" spacing={8}>
              <Button
                isLoading={isLoading}
                variant="ghost"
                color="white"
                _hover={{ bg: "#32A2DB", color: "#FFF" }}
                textColor="#32A2DB"
                onClick={() => createPersonalAccount()}
                isDisabled={!methods.formState.isValid}
              >
                {t("confirm", { ns: "common" })}
              </Button>

              <OnboardingFooter />
            </VStack>
          </CardFooter>
        </>
      )}

      {error && (
        <Alert
          title={t("apiErrors.cannotCreateAccount")}
          message={t(`apiErrors.${error}`)}
          onClose={() => setError("")}
          variant="warning"
        />
      )}

      {successCreateAccount && (
        <CardHeader
          padding={8}
          display={"flex"}
          flexDirection={"column"}
          gap={4}
          alignItems={"center"}
        >
          <Flex flexDirection={"column"} alignItems={"center"} gap={2}>
            <Text textAlign={"center"}>{t("ui.accountCreated")}</Text>
            <Text textAlign={"center"}>{t("ui.verifyEmail")}</Text>
          </Flex>
          <Button
            isLoading={isLoading}
            variant="ghost"
            color="white"
            _hover={{ bg: "#32A2DB", color: "#FFF" }}
            textColor="#32A2DB"
            onClick={routeChange}
          >
            {t("ui.sign in")}
          </Button>
        </CardHeader>
      )}
    </>
  );
};

export default SignUpUser;
