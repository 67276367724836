import {
  Button,
  Flex,
  IconButton,
  ModalBody,
  ModalContent,
  Text,
  ModalHeader,
  Modal,
  ModalOverlay,
} from "@chakra-ui/react";
import { FC, useState } from "react";
import RequirementsGroup from "../../../domain/entities/requirementsGroup";
import InputAnimatedLabel from "../../components/Common/InputAnimatedLabel";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";

// Props.
interface EditReqGroupNameProps {
  isOpen: boolean;
  requirementGroup: RequirementsGroup;
  onCancel: () => void;
  onConfirm: () => void;
  handleFieldChange: (fieldName: string, value: string) => void;
  isLoading: boolean;
}

/**
 * Renders a modal view that asks user to confirm an action.
 *
 * @param isOpen the flag indicating if the view is visible
 * @param onCancel the handler that cancels the user action, usually closing the view
 * @param onConfirm the handler that confirms the user action, usually closing the view
 * @returns
 */
const EditRequirementGroupNameView: FC<EditReqGroupNameProps> = ({
  isOpen,
  requirementGroup,
  onCancel,
  onConfirm,
  handleFieldChange,
  isLoading,
}) => {
  const { t } = useTranslation();

  const handleNewInput = (id: string, newValue: string) => {
    handleFieldChange("name", newValue);
  };

  const handleOnConfirm = () => {
    onConfirm();
  };

  return (
    <Modal isOpen={isOpen} onClose={onCancel} size="xl">
      <ModalOverlay />
      <ModalContent backgroundColor="white">
        <ModalHeader>
          <Flex
            flex={1}
            h="100%"
            w="100%"
            alignItems="center"
            textAlign="center"
            flexDirection="row"
          >
            <Text textColor={"black"} fontSize={14}>
              {requirementGroup?.id
                ? t("modifyRequirementGroup", { ns: "requirements" })
                : t("createRequirementGroup", { ns: "requirements" })}
            </Text>
            <Flex flex={1} />
            <IconButton
              marginLeft={3}
              fontSize="3xl"
              size="2xl"
              backgroundColor="transparent"
              onClick={onCancel}
              aria-label="Edit"
            >
              <MdClose />
            </IconButton>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Flex
            flex={1}
            h="100%"
            w="100%"
            justifyContent="space-between"
            flexDirection="row"
            alignItems="baseline"
            marginBottom={5}
          >
            <InputAnimatedLabel
              id={requirementGroup?.name}
              label={t("requirements-group-name", { ns: "requirements" })}
              defaultValue={requirementGroup?.name}
              handleOnChange={handleNewInput}
              width="70%"
            />
            <Button
              colorScheme="blue"
              onClick={handleOnConfirm}
              isLoading={isLoading}
              marginBottom={0}
            >
              {t("continue", { ns: "common" })}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export { EditRequirementGroupNameView };
