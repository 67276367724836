import { useParams } from "react-router-dom";
import { Permission } from "../../components/Permissions/Permissions";
import { SiteChemicalParams } from "../../../domain/entities/siteChemical";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import useSiteChemicalsViewModel from "../../hooks/Site/useSiteChemicalsViewModel";
import SiteResourcesListView from "../../components/Views/Sites/SiteResourcesListView";

const SiteChemicalsView = () => {
  const { siteId } = useParams();
  const resourceColumns = ["name"];
  return (
    <SiteResourcesListView<SiteChemicalParams>
      type={DocumentTypeResourceType.CHEMICAL}
      useViewModel={useSiteChemicalsViewModel(
        siteId,
        resourceColumns,
        DocumentTypeResourceType.CHEMICAL,
      )}
      resourceColumns={resourceColumns}
      addToSitePermissions={[
        Permission.Sites_Manage,
        Permission.Sites_AddChemical,
      ]}
      removeFromSitePermissions={[
        Permission.Sites_Manage,
        Permission.Sites_RemoveChemical,
      ]}
    />
  );
};

export default SiteChemicalsView;
