import Badge, { SiteData } from "../../../../domain/entities/badge";
import { View } from "@react-pdf/renderer";
import FrontBadgeWorker from "./NfcBadgeWorker";
import React from "react";
import QrCodeBadgeBack from "./QrCodeBadgeBack";

type QrCodeBadgeWorkerProps = {
  badge: Badge;
};
const QrCodeBadgeWorker = ({ badge }: QrCodeBadgeWorkerProps) => (
  <View style={{ flexDirection: "row" }}>
    <FrontBadgeWorker badge={badge} />
    <QrCodeBadgeBack site={badge.site as SiteData} serial={badge.serial} />
  </View>
);
export default QrCodeBadgeWorker;
