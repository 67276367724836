import { useState } from "react";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { SiteResourceDocument } from "../../../domain/entities/document";
import { useAuth } from "../../providers/Auth0JWTProvider";
import SupplierDocumentsViewModel from "../../viewmodels/documents/SupplierDocumentsViewModel";
import useDocumentInfo from "./useDocumentInfo";
import {
  SortDirection,
  SortMeta,
} from "../../../domain/entities/interfaces/paginatedResults";
import useAddDocumentType from "./useAddDocumentType";
import { RequirementSubject } from "../../../domain/entities/requirementSubject.enum";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { updateFilterWithDelete } from "../../../utils";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";
import useDocumentAI from "./useDocumentAI";

const updateSort = (setter) => (column: string, value: SortDirection) =>
  setter({ [column]: value });

const useSupplierDocumentsViewModel = (
  siteId: string,
  target: "company" | "site",
  aiTaskSectionCode: AiTaskSectionCode,
  documentsDidChange?: (documents: SiteResourceDocument[]) => void,
) => {
  const { companyId } = useAuth();

  // FIXME: in this context, the supplier must be the current user, but the final route
  //        also requires parameter companyId; this may lead to confusion of meaning of REST routes.
  const viewModel = new SupplierDocumentsViewModel(
    companyId,
    siteId,
    companyId,
    target,
  );

  const [documentId, setDocumentId] = useState<string>(null);
  const [filterDocumentEvaluations, setFilterDocumentEvaluations] = useState(
    {},
  );
  const [sortDocumentEvaluations, setSortDocumentEvaluations] = useState({});
  const [filterDocuments, setFilterDocuments] = useState({});
  const [sortDocuments, setSortDocuments] = useState<SortMeta>();

  const {
    data: documents,
    isLoading: fetchingDocuments,
    refetch: refetchDocuments,
  } = useInfiniteQuery<SiteResourceDocument[], Error>(
    ["get-supplier-documents", companyId, siteId, target],
    async ({ pageParam = 1 }) => {
      const documents = await viewModel.get(
        target,
        pageParam,
        filterDocuments,
        sortDocuments,
      );
      documentsDidChange?.(documents);
      return documents;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    },
  );

  const updateFilter =
    (setter) => (column: string, value: string | string[]) => {
      updateFilterWithDelete(setter, column, value);
      refetchDocuments();
    };

  const documentAIProps = useDocumentAI(viewModel, siteId, "", undefined, aiTaskSectionCode);
  const documentInfoProps = useDocumentInfo(viewModel, siteId, target, null);
  const documentTypesProps = useAddDocumentType(
    viewModel,
    target as unknown as DocumentTypeCategory,
  );

  const getDocumentEvaluationsQuery = useInfiniteQuery(
    [
      "document-evaluations",
      companyId,
      siteId,
      documentId,
      filterDocumentEvaluations,
      sortDocumentEvaluations,
    ],

    async ({ pageParam = 1 }) =>
      documentId
        ? await viewModel.getDocumentEvaluations(
          companyId,
          siteId,
          target,
          companyId,
          documentId,
          pageParam,
          filterDocumentEvaluations,
          Object.keys(sortDocumentEvaluations).length > 0
            ? {
              field: Object.keys(sortDocumentEvaluations)[0],
              direction: Object.values(
                sortDocumentEvaluations,
              )[0] as SortDirection,
            }
            : undefined,
        )
        : [],
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    },
  );

  const createRequirementMutation = useMutation(
    ["create-requirement", companyId, siteId, target],
    (params: {
      documentTypeId: string;
      isOptional: boolean;
      graceDays: number;
    }) => {
      return viewModel.addRequirementToTarget(
        params.documentTypeId,
        params.isOptional,
        params.graceDays,
        target === "company"
          ? RequirementSubject.COMPANY
          : RequirementSubject.SITE,
      );
    },
    {
      onSuccess: () => {
        refetchDocuments();
      },
    },
  );

  const addRequirementsToTarget = async (
    requirements: {
      documentTypeId: string;
      isOptional: boolean;
      graceDays: number;
    }[],
  ) => {
    await Promise.all(
      requirements.map(async (inputRequirement) => {
        await createRequirementMutation.mutateAsync({
          documentTypeId: inputRequirement["id"],
          isOptional: inputRequirement.isOptional,
          graceDays: inputRequirement.graceDays,
        });
      }),
    );
  };

  const deleteMutation = useMutation(
    (params: { requirementId: string; subject: RequirementSubject }) =>
      viewModel.deleteSiteRequirement(
        companyId,
        siteId,
        params.requirementId,
        params.subject,
      ),
    {
      onSuccess: () => refetchDocuments(),
      onError: (err) => console.error(err),
    },
  );

  const deleteRequirement = async (
    requirementId: string,
    subject: RequirementSubject,
  ) => {
    try {
      await deleteMutation.mutateAsync({ requirementId, subject });
    } catch (err) {
      console.error(err);
    }
  };

  const docs = documents?.pages?.flat() ?? [];
  const documentEvaluations =
    getDocumentEvaluationsQuery.data?.pages?.flat() ?? [];

  return {
    docs,
    refetchDocuments,
    fetchingDocuments,
    filterDocuments,
    sortDocuments,
    updateFilterDocuments: updateFilter(setFilterDocuments),
    updateSortDocuments: setSortDocuments,
    documentAIProps,
    documentInfoProps,
    documentTypesProps,
    documentEvaluationsProps: {
      setSupplierHookDocumentId: setDocumentId,
      documentEvaluations: documentEvaluations,
      documentEvaluationsFetching: getDocumentEvaluationsQuery.isLoading,
      filterDocumentEvaluations,
      sortDocumentEvaluations,
      updateFilterDocumentEvaluations: updateFilter(
        setFilterDocumentEvaluations,
      ),
      updateSortDocumentEvaluations: updateSort(setSortDocumentEvaluations),
    },
    addRequirementsToTarget,
    deleteRequirement,
  };
};

export default useSupplierDocumentsViewModel;
