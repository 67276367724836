import UseCase from '../../../core/app/usecase';
import Requirement from '../../entities/requirement';
import SupplierRepository from '../../repositories/supplierRepository';

type ARGS = {
	companyId: string;
	siteId: string;
	supplierId: string;
	documentTypeId: string;
	isOptional: boolean;
	graceDays: number;
};

class AddRequirementToSupplier implements UseCase<ARGS, Requirement> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call(parameters: ARGS): Promise<Requirement> {
		return await this.supplierRepository.addRequirementToSupplier(
			parameters.companyId,
			parameters.siteId,
			parameters.supplierId,
			parameters.documentTypeId,
			parameters.isOptional,
			parameters.graceDays,
		);
	}
}

export default AddRequirementToSupplier;
