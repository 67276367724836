import Worker, { WorkerStatus } from "../../../domain/entities/worker";
import {
  makeAddResourceToSites,
  makeGetAvailableBadgesUseCase,
  makeGetResourceBadge,
  makeGetWorker,
  makeLinkBadgesResource,
  makeUpdateWorker,
  makeUpdateWorkerStatus,
} from "../../../core/app/factory/workers/usecases";
import {
  makeGetResourceLinkableSites,
  makeGetWorkerSites,
} from "../../../core/app/factory/sites/usecases";
import Site, { ResourceSites } from "../../../domain/entities/site";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";
import {
  PaginatedResults,
  SortMeta,
} from "../../../domain/entities/interfaces/paginatedResults";
import Badge from "../../../domain/entities/badge";
import {
  GetAvailableBadgesFilters,
  GetBadgesFilters,
} from "../../../domain/repositories/badgeRepository";
import { BadgeStatusSite } from "../../hooks/Badge/useBadgeDetailViewModel";
import {
  makeGetBadgeSites,
  makeLinkBadgeResource,
  makeUnlinkBadgeResource,
} from "../../../core/app/factory/badges/usecases";
import { BadgeResourceType } from "../../../domain/entities/badgeResourceType.enum";

class WorkerViewModel {
  private getWorkerUseCase = makeGetWorker();
  private updateWorkerUseCase = makeUpdateWorker();
  private updateWorkerStatusUseCase = makeUpdateWorkerStatus();
  private getWorkerSitesUseCase = makeGetWorkerSites();
  private getResourceLinkableSitesUseCase = makeGetResourceLinkableSites();
  private linkResourceToSitesUseCase = makeAddResourceToSites();
  private getResourceBadgeUseCase = makeGetResourceBadge();
  private getBadgeSitesUseCase = makeGetBadgeSites();
  private getAvailableBadgesUseCase = makeGetAvailableBadgesUseCase();
  private linkBadgesToResourceUseCase = makeLinkBadgesResource();
  private unlinkBadgeResourceUseCase = makeUnlinkBadgeResource();

  async get(companyId: string, workerId: string): Promise<Worker> {
    return await this.getWorkerUseCase.Call({ companyId, workerId });
  }

  async getWorkerSites(
    companyId: string,
    workerId: string,
    sort: SortMeta,
    filter: GetSitesFilter,
    pageParam: number,
    includeWorkingSites?: boolean,
  ): Promise<ResourceSites> {
    return await this.getWorkerSitesUseCase.Call({
      companyId,
      workerId,
      sort,
      filter,
      pageParam,
      includeWorkingSites,
    });
  }
  async getResourceBadge(
    companyId: string,
    resource: string,
    resourceId: string,
    sort: SortMeta,
    filter: GetBadgesFilters,
    pageParam: number,
    supplierId?: string,
  ): Promise<PaginatedResults<Badge>> {
    return await this.getResourceBadgeUseCase.Call({
      companyId,
      resource,
      resourceId,
      sort,
      filter,
      pageParam,
      supplierId,
    });
  }
  async getBadgeSites(
    companyId: string,
    badgeId: string,
  ): Promise<BadgeStatusSite[]> {
    return await this.getBadgeSitesUseCase.Call({ companyId, badgeId });
  }

  async getAvailableBadges(
    companyId: string,
    resource: string,
    resourceId: string,
    sort: SortMeta,
    filter: GetAvailableBadgesFilters,
  ): Promise<Badge[]> {
    return await this.getAvailableBadgesUseCase.Call({
      companyId,
      resource,
      resourceId,
      sort,
      filter,
    });
  }

  async getResourceLinkableSites(
    companyId: string,
    resourceType: string,
    resourceId: string,
    sort: SortMeta,
    filters: GetSitesFilter,
    pageParam: number,
  ): Promise<Site[]> {
    return await this.getResourceLinkableSitesUseCase.Call({
      companyId,
      resourceType,
      resourceId,
      sort,
      filters,
      pageParam,
    });
  }

  async linkBadgesToResource(
    companyId: string,
    badgeIds: string[],
    resourceType: BadgeResourceType,
    resourceId: string,
  ): Promise<void> {
    return await this.linkBadgesToResourceUseCase.Call({
      companyId,
      resourceType,
      resourceId,
      badgeIds,
    });
  }

  async unlinkBadgeResource(companyId: string, badgeId: string): Promise<void> {
    return await this.unlinkBadgeResourceUseCase.Call({ companyId, badgeId });
  }

  async update(
    companyId: string,
    worker: Worker,
    imageFile?: File,
  ): Promise<Worker> {
    return await this.updateWorkerUseCase.Call({
      companyId,
      worker,
      imageFile,
    });
  }

  async setStatus(
    companyId: string,
    workerId: string,
    status: WorkerStatus,
  ): Promise<void> {
    return await this.updateWorkerStatusUseCase.Call({
      companyId,
      workerId,
      status,
    });
  }
  async linkResourceToSites(
    companyId: string,
    workerId: string,
    siteIds: string[],
  ): Promise<void> {
    return await this.linkResourceToSitesUseCase.Call({
      companyId,
      resourceType: "worker",
      resourceId: workerId,
      siteIds,
    });
  }
}

export { WorkerViewModel };
