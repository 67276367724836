import { injectable } from 'tsyringe';
import Chemical from '../../domain/entities/chemical';
import Document from '../../domain/entities/document';
import ChemicalRepository, { GetChemicalsFilter } from '../../domain/repositories/chemicalRepository';
import { GetDocumentsFilter } from '../../domain/repositories/documentRepository';
import { PaginatedResults, SortMeta } from '../../domain/entities/interfaces/paginatedResults';
import { ApiService } from '../utilities/apiService';
import { dateIntervals } from '../utilities/filters';
import ImportInfo from '../../domain/entities/importInfo';
import FileEntity from '../../domain/entities/file';
import DocumentTypeWithPublic from '../../domain/entities/documentTypeWithPublic';
import { ResourceDocumentEvaluationState } from '../../domain/entities/resourceDocumentEvaluationState.enum';
import { dateToRFC3339 } from '../../utils';
import { mapApiResponseToDocument } from '../adapters/getResourceDocuments';

@injectable()
class ServerChemicalRepository implements ChemicalRepository {
	constructor(private apiService: ApiService) {}

	async getChemicalById(companyId: string, chemicalId: string): Promise<Chemical | undefined> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/chemicals/${chemicalId}`,
		);
		if (!response.ok) {
			return undefined;
		}
		return await response.json();
	}

	async getChemicals(
		companyId: string,
		filter?: GetChemicalsFilter,
		archived?: boolean,
		sort?: SortMeta,
		pageParam?: number,
	): Promise<PaginatedResults<Chemical>> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...filter,
			...sort,
		});

		if (archived) {
			params.append('archived', 'true');
		}

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/chemicals?${params.toString()}`,
		);
		const data = await response.json();
		return data;
	}

	async restoreChemical(companyId: string, chemicalId: string): Promise<void> {
		await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/chemicals/${chemicalId}/restore`, {
			method: 'PUT',
		});
	}

	async getAvailableChemicalsCount(companyId: string, filter?: GetChemicalsFilter, sort?: SortMeta): Promise<number> {
		const params = new URLSearchParams({
			...filter,
			...sort,
		});
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/chemicals-count?${params.toString()}`,
		);
		const data = await response.json();
		return data.results.length;
	}

	async createChemical(companyId: string, chemical: Chemical, photo?: File): Promise<Chemical> {
		const formData = new FormData();
		const appendedParameters = new Set();

		if (photo) {
			formData.append('photo', photo);
			appendedParameters.add('photo');
		}

		Object.keys(chemical).map((parameter) => {
			if (!appendedParameters.has(parameter) && parameter !== 'photo') {
				formData.append(parameter, chemical[parameter]);
				appendedParameters.add(parameter);
			}
		});

		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/chemicals`, {
			method: 'POST',
			body: formData,
		});
		return await response.json();
	}

	async uploadChemicals(companyId: string, file: FileEntity): Promise<ImportInfo> {
		const formData = new FormData();
		formData.append('file', file.binaries[0]);
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/chemicals/import`, {
			method: 'POST',
			body: formData,
		});
		if (!response.ok) {
			const { message } = await response.json();
			throw new Error(message);
		}
		return response.json();
	}

	async updateChemical(companyId: string, parameters: Chemical, imageFile?: File): Promise<Chemical> {
		const formData = new FormData();
		const appendedParameters = new Set();

		if (imageFile) {
			formData.append('photo', imageFile);
			appendedParameters.add('photo');
		}

		Object.keys(parameters).map((parameter) => {
			parameters[parameter] = parameters[parameter] ?? '';
			if (!appendedParameters.has(parameter)) {
				formData.append(parameter, parameters[parameter]);
				appendedParameters.add(parameter);
			}
		});

		if (!imageFile) {
			formData.delete('photo');
		}

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/chemicals/${parameters.id}`,
			{
				method: 'POST',
				body: formData,
			},
		);

		return await response.json();
	}

	async evaluateChemicalDocument(
		companyId: string,
		chemicalId: string,
		documentId: string,
		evaluationState?: ResourceDocumentEvaluationState,
		expirationDate?: Date,
		noEvaluationExpiration?: boolean,
		siteIds?: string[],
		selectAll?: boolean,
	): Promise<void> {
		const requestBody = {
			sitesIds: siteIds,
			selectAll,
			...(evaluationState !== null && { result: evaluationState }),
			...(expirationDate && (expirationDate !== null ? { expiresAt: dateToRFC3339(expirationDate, true) } : { expiresAt: '' })),
			noEvaluationExpiration,
		};

		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/chemicals/${chemicalId}/documents/${documentId}/evaluations`,
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(requestBody),
			},
		);
	}

	async deleteChemical(companyId: string, chemicalId: string): Promise<void> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/chemicals/${chemicalId}`,
			{ method: 'DELETE' },
		);
		if (!response.ok) {
			throw new Error('Failed to delete chemical');
		}
	}

	async getChemicalDocuments(
		companyId: string,
		chemicalId: string,
		filter?: GetDocumentsFilter,
		sort?: SortMeta,
		pageParam?: number,
	): Promise<Document[]> {
		const { tags, expiresAt, ...restFilter } = filter || {};
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...restFilter,
			...dateIntervals(expiresAt),
			...sort,
		});
		tags?.length > 0 && tags.forEach((tag) => params.append('tagIds[]', tag));

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/chemicals/${chemicalId}/documents?${params.toString()}`,
		);
		const documents = await response.json();
		return documents.results ? Object.values(documents.results).map(mapApiResponseToDocument) : [];
	}

	async createChemicalDocument(companyId: string, chemicalId: string, documents: string[]): Promise<void> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/chemicals/${chemicalId}/documents`,
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ documentTypesIds: documents }),
			},
		);
		return await response.json();
	}

	async createPropagableChemicalDocument(companyId: string, chemicalId: string, documents: DocumentTypeWithPublic[]): Promise<void> {
		const transformedDocuments = documents.map((doc) => ({
			documentTypeId: doc.id,
			isPublic: doc.isPublic,
		}));

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/chemicals/${chemicalId}/documents`,
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ documents: transformedDocuments }),
			},
		);
		return await response.json();
	}

	async addTypologyToChemical(companyId: string, chemicalId: string, typologyId: string): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/chemicals/${chemicalId}/typologies/${typologyId}`,
			{
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
			},
		);
	}

	async removeTypologyFromChemical(companyId: string, chemicalId: string, typologyId: string): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/chemicals/${chemicalId}/typologies/${typologyId}`,
			{
				method: 'DELETE',
			},
		);
	}
}

export default ServerChemicalRepository;
