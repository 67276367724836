import UseCase from '../../../core/app/usecase';
import SupplierRepository from '../../repositories/supplierRepository';
import Evaluation from '../../entities/evaluation';
import { RequirementSubject } from '../../entities/requirementSubject.enum';
import { ResourceEvaluationState } from '../../entities/resourceEvaluationState.enum';
import { PaginatedResults, SortMeta } from '../../entities/interfaces/paginatedResults';
import { GetResourceEvaluationFilter } from '../../repositories/siteRepository';
import { GetBadgesFilters } from '../../repositories/badgeRepository';
import Badge from '../../entities/badge';

type ARGS = {
	companyId: string;
	resource: string;
	resourceId: string;
	sort?: SortMeta;
	filter?: GetBadgesFilters;
	pageParam?: number;
	supplierId?: string;
};

class GetSupplierResourceBadge implements UseCase<ARGS, PaginatedResults<Badge>> {
	constructor(private repository: SupplierRepository) {}

	async Call({ companyId, resource, resourceId, sort, filter, pageParam, supplierId }: ARGS): Promise<PaginatedResults<Badge>> {
		return this.repository.getResourceBadge(companyId, resource, resourceId, sort, filter, pageParam, supplierId);
	}
}

export default GetSupplierResourceBadge;
