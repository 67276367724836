import SignIn from "./SignIn";
import SignUpUser from "./SignUpUser";
import SignUpCompany from "./SignUpCompany";
import ZStack from "../../components/Common/ZStack";
import OnboardingSelector from "./OnboardingSelector";
import OnboardingBackground from "./OnboardingBackground";
import OnboardingBackgroundText from "./OnboardingBackgroundText";
import { AbsoluteCenter, Card, Collapse, Box } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import OnboardingRequestPasswordReset from "./OnboardingRequestPasswordReset";
import { useMediaQuery } from "@chakra-ui/react";
import useOnboardingViewModel from "../../hooks/Onboarding/useOnboardingViewModel";
import LoadingView from "../Common/LoadingView";
import { useEffect } from "react";
import { Alert } from "../Common/Alert";
import { useTranslation } from "react-i18next";

enum Screen {
  Selector = "selector",
  SignUpCompany = "sign-up-company",
  SignUpUser = "sign-up-user",
  SignIn = "sign-in",
  RequestPasswordReset = "request-password-reset",
}

const OnboardingView = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    screen: Screen.SignIn,
  });
  const selectedScreen = searchParams.get("screen") as Screen;
  const token = searchParams.get("token"); // invite token
  const company = searchParams.get("company");
  const { t } = useTranslation("errors");

  const onCancel = () => setSearchParams({ screen: Screen.Selector });
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const {
    userAlreadyRegistered,
    userAlreadyRegisteredLoading,
    activationError,
    setActivationError,
  } = useOnboardingViewModel();
  useEffect(() => {
    if (!userAlreadyRegisteredLoading && userAlreadyRegistered) {
      userAlreadyRegistered.already_registered
        ? setSearchParams({
            screen: Screen.SignIn,
            token: token,
            company: company,
          })
        : setSearchParams({
            screen: Screen.SignUpUser,
            token: token,
            company: company,
          });
    }
  }, [userAlreadyRegistered]);
  return (
    <ZStack>
      <OnboardingBackgroundText />
      <OnboardingBackground />

      <div style={{ width: "100vw", height: "100vh" }}>
        <AbsoluteCenter>
          <Card borderRadius={16} width={isMobile ? "100%" : 512}>
            {token && company && userAlreadyRegisteredLoading ? (
              <Box p={4}>
                {" "}
                <LoadingView />{" "}
              </Box>
            ) : (
              <>
                <Collapse
                  in={selectedScreen === Screen.Selector}
                  animateOpacity
                >
                  <OnboardingSelector
                    onSelectSignUpCompany={() =>
                      setSearchParams({ screen: Screen.SignUpCompany })
                    }
                    onSelectSignUpUser={() =>
                      setSearchParams({ screen: Screen.SignUpUser })
                    }
                    onSelectSignIn={() =>
                      setSearchParams({ screen: Screen.SignIn })
                    }
                  />
                </Collapse>
                <Collapse
                  in={selectedScreen === Screen.SignUpCompany}
                  animateOpacity
                >
                  {selectedScreen === Screen.SignUpCompany && (
                    <SignUpCompany onCancel={onCancel} />
                  )}
                </Collapse>
                <Collapse
                  in={selectedScreen === Screen.SignUpUser}
                  animateOpacity
                >
                  {selectedScreen === Screen.SignUpUser && (
                    <SignUpUser onCancel={onCancel} />
                  )}
                </Collapse>
                <Collapse in={selectedScreen === Screen.SignIn} animateOpacity>
                  {selectedScreen === Screen.SignIn && (
                    <SignIn
                      onCancel={onCancel}
                      onSelectRequestPasswordReset={() =>
                        setSearchParams({ screen: Screen.RequestPasswordReset })
                      }
                    />
                  )}
                </Collapse>
                <Collapse
                  in={selectedScreen === Screen.RequestPasswordReset}
                  animateOpacity
                >
                  {selectedScreen === Screen.RequestPasswordReset && (
                    <OnboardingRequestPasswordReset
                      onCancel={() =>
                        setSearchParams({ screen: Screen.SignIn })
                      }
                    />
                  )}
                </Collapse>
              </>
            )}
          </Card>
        </AbsoluteCenter>
      </div>
      {activationError && (
        <Alert
          title={t(`errorUserInvitation`)}
          message={t(activationError)}
          onClose={() => setActivationError("")}
          variant="warning"
        />
      )}
    </ZStack>
  );
};

export default OnboardingView;
