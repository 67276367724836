import { Document, Page, Text } from "@react-pdf/renderer";
import React from "react";
import Badge from "../../../../domain/entities/badge";
import QrCodeBadgeWorker from "./QrCodeBadgeWorker";
import QrCodeBadgeVehicle from "./QrCodeBadgeVehicle";
import QrCodeBadgeMachine from "./QrCodeBadgeMachine";
import NfcBadgeWorker from "./NfcBadgeWorker";
import NfcBadgeVehicle from "./NfcBadgeVehicle";
import NfcBadgeMachine from "./NfcBadgeMachine";

type BadgeTemplateProps = {
  badge: Badge;
};

const badgeTypeComponentMap = {
  "QR Code": {
    worker: QrCodeBadgeWorker,
    vehicle: QrCodeBadgeVehicle,
    machine: QrCodeBadgeMachine,
  },
  NFC: {
    worker: NfcBadgeWorker,
    vehicle: NfcBadgeVehicle,
    machine: NfcBadgeMachine,
  },
};

const BadgeTemplate = ({ badge }: BadgeTemplateProps) => {
  const renderBadgeComponent = () => {
    const { type, resource } = badge;
    if (
      Object.prototype.hasOwnProperty.call(badgeTypeComponentMap, type) &&
      Object.prototype.hasOwnProperty.call(
        badgeTypeComponentMap[type],
        resource?.type,
      )
    ) {
      const BadgeComponent = badgeTypeComponentMap[type][resource.type];
      return <BadgeComponent badge={badge} />;
    }

    // Default case: Render a default component if the mapping doesn't exist
    return <Text>No matching badge component found</Text>;
  };

  return renderBadgeComponent();
};

export default BadgeTemplate;
