import { Dispatch, SetStateAction } from "react";
import { ResourceDocumentEvaluationState } from "../../../../domain/entities/resourceDocumentEvaluationState.enum";
import { useTranslation } from "react-i18next";
import { Box, Select, Skeleton, Text } from "@chakra-ui/react";

interface EvalutationComponentProps {
  documentsFetching: boolean;
  readOnly: boolean;
  selectedState: ResourceDocumentEvaluationState;
  handleEvaluation: (value: ResourceDocumentEvaluationState) => void;
  setSelectedState: Dispatch<SetStateAction<ResourceDocumentEvaluationState>>;
  action: "add" | "delete" | "evaluate" | "edit" | "editFile" | string | null;
  isPublic: boolean;
  disabledFunctions: boolean;
  hasSites?: (action: string) => Promise<boolean>;
}

export const DocumentEvaluationComponent = ({
  documentsFetching,
  readOnly,
  selectedState,
  handleEvaluation,
  setSelectedState,
  isPublic,
  disabledFunctions,
  action,
  hasSites,
}: EvalutationComponentProps) => {
  const { t } = useTranslation("documents");
  return (
    <Box>
      <Text fontWeight={600} pb={1}>
        {t("evaluationDetails")}
      </Text>
      {!documentsFetching ? (
        <Select
          placeholder={t("selectEvaluationState")}
          _placeholder={{ fontSize: "12px" }}
          size={"sm"}
          value={selectedState || ""}
          onChange={async (e) => {
            setSelectedState(e.target.value as ResourceDocumentEvaluationState);
            if (
              !e.target.value ||
              (e.target.value as ResourceDocumentEvaluationState) ===
                ResourceDocumentEvaluationState.UNDEFINED
            ) {
              return;
            }
            handleEvaluation(e.target.value as ResourceDocumentEvaluationState);
          }}
          isDisabled={
            !isPublic || disabledFunctions || action !== null || readOnly
          }
        >
          <option value={ResourceDocumentEvaluationState.AVAILABLE}>
            {t(
              "combinedEvaluation." + ResourceDocumentEvaluationState.AVAILABLE,
              { ns: "enum" },
            )}
          </option>
          <option value={ResourceDocumentEvaluationState.NOT_AVAILABLE}>
            {t(
              "combinedEvaluation." +
                ResourceDocumentEvaluationState.NOT_AVAILABLE,
              { ns: "enum" },
            )}
          </option>
          <option value={ResourceDocumentEvaluationState.NOT_APPLICABLE}>
            {t(
              "combinedEvaluation." +
                ResourceDocumentEvaluationState.NOT_APPLICABLE,
              { ns: "enum" },
            )}
          </option>
        </Select>
      ) : (
        <Skeleton
          height={"30px"}
          w={"100%"}
          startColor={"gray.300"}
          endColor={"gray.300"}
        />
      )}
    </Box>
  );
};
