import { injectable } from 'tsyringe';
import { BadgeLogEntry, BadgeLogEntryAction } from '../../domain/entities/badgeLogEntry';
import BadgeReader from '../../domain/entities/badgeReader';
import { PaginatedResults, SortMeta } from '../../domain/entities/interfaces/paginatedResults';
import Site from '../../domain/entities/site';
import { UserMini } from '../../domain/entities/user';
import BadgeReaderRepository, {
	BadgeReadersFilters,
	GetBadgeReaderHistoryFilters,
	VirtualBadgeReaderUsersFilters,
} from '../../domain/repositories/badgeReaderRepository';
import { GetSitesFilter } from '../../domain/repositories/siteRepository';
import { BadgeReadersType } from '../../presentation/hooks/Site/useSiteBadgeReadersViewModel';
import { ApiService } from '../utilities/apiService';
import { dateIntervals, formatISODate } from '../utilities/filters';

@injectable()
class ServerBadgeReaderRepository implements BadgeReaderRepository {
	constructor(private apiService: ApiService) {}

	async getBadgeReaders(
		companyId: string,
		siteId: string,
		type: BadgeReadersType,
		page: number,
		perPage: number,
		filter?: BadgeReadersFilters,
		sort?: SortMeta
	): Promise<PaginatedResults<BadgeReader>> {
		const params = new URLSearchParams({
			type: BadgeReadersType[type],
			page: page.toString(),
			perPage: perPage.toString(),
			...{
				...filter,
			},
			...sort,
		});

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/badgeReaders?${params.toString()}`
		);
		return await response.json();
	}

	async getBadgeReadersWithoutPaging(companyId: string, siteId: string): Promise<BadgeReader[]> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/badgeReaders`
		);
		const badgeReaders = await response.json();

		return badgeReaders['results'] ?? [];
	}

	async getBadgeReadersSites(
		companyId: string,
		badgeReaderId: string,
		page: number,
		perPage: number,
		filter?: GetSitesFilter,
		sort?: SortMeta
	): Promise<PaginatedResults<Site>> {
		const params = new URLSearchParams({
			page: page.toString(),
			perPage: perPage.toString(),
			...{
				...filter,
			},
			...sort,
		});

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/badgeReaders/${badgeReaderId}/sites?${params.toString()}`
		);
		return await response.json();
	}

	async getBadgeReaderDetail(companyId: string, siteId: string, badgeReaderId: string): Promise<BadgeReader> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/badgeReaders/${badgeReaderId}`
		);
		return await response.json();
	}

	async getVirtualBadgeReaderAvailableUsers(
		companyId: string,
		siteId: string,
		page: number,
		perPage: number,
		filter?: VirtualBadgeReaderUsersFilters,
		sort?: SortMeta
	): Promise<PaginatedResults<UserMini>> {
		const params = new URLSearchParams({
			page: page.toString(),
			perPage: perPage.toString(),
			...{
				...filter,
			},
			...sort,
		});

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/badgeReaders/virtual/users?${params.toString()}`
		);
		return await response.json();
	}

	async createBadgeReader(companyId: string, siteId: string, badgeReader: Partial<BadgeReader>, type: BadgeReadersType): Promise<BadgeReader> {
		let creationBody = JSON.stringify(badgeReader);
		if (type == BadgeReadersType.virtual) {
			creationBody = JSON.stringify({
				name: badgeReader.name,
				direction: badgeReader.direction,
				userId: badgeReader.userId,
				timezone: badgeReader.timeZone,
			});
		}
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/badgeReaders/${BadgeReadersType[type]}`,
			{
				method: 'POST',
				body: creationBody,
				headers: { 'Content-Type': 'application/json' },
			}
		);
		return await response.json();
	}

	async deleteBadgeReader(companyId: string, siteId: string, badgeReaderId: string): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/badgeReaders/${badgeReaderId}`,
			{
				method: 'DELETE',
			}
		);
	}

	async unlinkBadgeReader(companyId: string, siteId: string, badgeReaderId: string): Promise<void> {
		try {
			const response = await this.apiService.fetchWithToken(
				`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/badgeReaders/${badgeReaderId}/remove`,
				{
					method: 'DELETE',
				}
			);

			if (!response.ok && response.status === 422) {
				const error = 'impossibleUnlinkBadgeReader';
				return Promise.reject(new Error(error));
			}
		} catch (error) {
			return Promise.reject(new Error(error));
		}
	}

	async updateBadgeReaderStatus(companyId: string, siteId: string, badgeReader: Partial<BadgeReader>): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/badgeReaders/${badgeReader.id}`,
			{
				method: 'PUT',
				body: JSON.stringify({ status: badgeReader.status }),
				headers: { 'Content-Type': 'application/json' },
			}
		);
	}

	async updateBadgeReader(companyId: string, siteId: string, badgeReader: Partial<BadgeReader>): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/badgeReaders/${badgeReader.id}`,
			{
				method: 'PUT',
				body: JSON.stringify({
					name: badgeReader.name,
					serial: badgeReader.serial,
					direction: badgeReader.direction,
					timeZone: badgeReader.timeZone,
					releConfiguration: badgeReader.releConfiguration,
					delay: badgeReader.delay,
				}),
				headers: { 'Content-Type': 'application/json' },
			}
		);
	}

	async getBadgeReaderHistory(
		companyId: string,
		siteId: string,
		badgeReaderId: string,
		pageParam: number,
		filter?: GetBadgeReaderHistoryFilters,
		sort?: SortMeta
	): Promise<BadgeLogEntry[]> {
		const { actionDate, actionTime, ...restFilter } = filter || {}; //FIXME: is actionTime required?
		const params = new URLSearchParams({
			...restFilter,
			perPage: (25).toString(),
			page: pageParam.toString(),
			...sort,
			...dateIntervals(actionDate),
			...(actionTime && actionTime[0] && actionTime[1] ? { actionTime: actionTime?.map((date) => date.toISOString()).join(',') } : {}),
		});

		let query = params.toString();
		query = query === '' ? '' : `?${query}`;

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/badgeReaders/${badgeReaderId}/records${query}`
		);
		const { results = [] } = await response.json();

		return results.map((entry: any) => ({
			id: entry.id,
			actionDate: new Date(entry.date),
			actionType: entry.type as BadgeLogEntryAction,
			actionResult: entry.result,
			badge: {
				id: entry.badge.id,
				code: entry.badge.code,
				serial: entry.badge.serial,
			},
			anomaly: entry.anomaly,
			isForced: entry.isForced,
			company: entry.name,
			resource: entry.resource,
			forcing: entry.forcing,
		}));
	}

	async createBadgeReaderLogEntry(companyId: string, siteId: string, badgeLogEntry: BadgeLogEntry, badgeReaderId: string): Promise<void> {
		const payload = {
			badge: {
				id: badgeLogEntry.badge.id,
			},
			record: {
				date: formatISODate(badgeLogEntry.actionDate),
				type: badgeLogEntry.actionType,
			},
		};

		const badgeLogbaseUrl = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/badgeReaders/${badgeReaderId}/forced-records`;

		try {
			const response = await this.apiService.fetchWithToken(`${badgeLogbaseUrl}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
			});
			if (!response.ok && response.status === 403) {
				const { error } = await response.json();
				return Promise.reject(new Error(error));
			}
		} catch (error) {
			return Promise.reject(new Error(error));
		}
	}

	async updateBadgeReaderLogEntry(companyId: string, siteId: string, badgeLogEntry: BadgeLogEntry, badgeReaderId: string): Promise<void> {
		const payload = {
			badge: {
				id: badgeLogEntry.badge.id,
			},
			record: {
				date: formatISODate(badgeLogEntry.actionDate),
				type: badgeLogEntry.actionType,
				result: badgeLogEntry.actionResult,
			},
		};

		const badgeLogbaseUrl = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/badgeReaders/${badgeReaderId}/records`;

		const response = await this.apiService.fetchWithToken(`${badgeLogbaseUrl}/${badgeLogEntry.id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload),
		});
	}

	async deleteBadgeReaderLogEntry(companyId: string, siteId: string, badgeLogEntry: BadgeLogEntry, badgeReaderId: string): Promise<void> {
		const badgeLogbaseUrl = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/badgeReaders/${badgeReaderId}/records/${badgeLogEntry.id}`;

		await this.apiService.fetchWithToken(badgeLogbaseUrl, {
			method: 'DELETE',
		});
	}

	async linkBadgeReaderToSites(companyId: string, siteId: string, badgeReaderId: string, siteIds: string[]): Promise<void> {
		const badgeLogbaseUrl = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/badgeReaders/${badgeReaderId}/assignToSites`;

		await this.apiService.fetchWithToken(badgeLogbaseUrl, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ siteIds }),
		});
	}
}

export default ServerBadgeReaderRepository;
