import { useParams } from "react-router-dom";
import { SiteToolParams } from "../../../domain/entities/siteTool";
import { Permission } from "../../components/Permissions/Permissions";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import useSiteToolDetailViewModel from "../../hooks/Site/useSiteToolDetailViewModel";
import SiteResourceDetailView from "../../components/Views/Sites/SiteResourceDetailView";
import { useResourceFilesDownloader } from "../../hooks/Document/useDocumentsFilesDownloader";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";

const SiteToolDetailView = () => {
  const { siteId, toolId } = useParams();
  const resourceColumns = ["name", "serial", "companyName"];

  const downloader = useResourceFilesDownloader(siteId, toolId, "tools");

  return (
    <SiteResourceDetailView<SiteToolParams>
      downloader={downloader}
      resourceId={toolId}
      type={DocumentTypeResourceType.TOOL}
      useViewModel={useSiteToolDetailViewModel(
        siteId,
        toolId,
        resourceColumns,
        DocumentTypeResourceType.TOOL,
        AiTaskSectionCode.SITE_RESOURCES_TOOLS
      )}
      resourceColumns={resourceColumns}
      isReadOnly={false}
      permissions={{
        downloadDocuments: Permission.Sites_DownloadToolDocuments,
        showGlobalEvaluation: Permission.Sites_Manage,
        setGlobalEvaluation: Permission.Sites_SetGlobalToolEval,
        addRequirement: Permission.Sites_NewToolRequirement,
        viewRequirements: Permission.Sites_Manage,
        editTags: Permission.Sites_EditToolRequirements,
        manageDocumentWithAi: Permission.Company_AIManageFile,
        documents: {
          delete: Permission.Sites_DeleteToolRequirement,
          edit: Permission.Sites_EditToolRequirements,
          evaluate: Permission.Sites_EditToolRequirements,
          viewEvaluations: Permission.Sites_EditToolRequirements,
          downloadAll: Permission.Sites_EditToolRequirements,
          addFile: Permission.Sites_EditToolRequirements,
          chat: Permission.Sites_AccessToolChat,
          setExpiration: Permission.Sites_EditToolRequirements,
        },
      }}
    />
  );
};

export default SiteToolDetailView;
