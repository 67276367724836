import { useParams } from "react-router-dom";
import { Permission } from "../../components/Permissions/Permissions";
import { SiteMachineParams } from "../../../domain/entities/siteMachine";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import useSiteMachinesViewModel from "../../hooks/Site/useSiteMachinesViewModel";
import SiteResourcesListView from "../../components/Views/Sites/SiteResourcesListView";

const SiteMachinesView = () => {
  const { siteId } = useParams();
  const resourceColumns = ["name", "plate", "model"];
  return (
    <SiteResourcesListView<SiteMachineParams>
      type={DocumentTypeResourceType.MACHINE}
      useViewModel={useSiteMachinesViewModel(
        siteId,
        resourceColumns,
        DocumentTypeResourceType.MACHINE,
      )}
      resourceColumns={resourceColumns}
      addToSitePermissions={[
        Permission.Sites_Manage,
        Permission.Sites_AddMachine,
      ]}
      removeFromSitePermissions={[
        Permission.Sites_Manage,
        Permission.Sites_RemoveMachine,
      ]}
    />
  );
};

export default SiteMachinesView;
