import UseCase from '../../../core/app/usecase';
import Typology from '../../entities/typology';
import TypologyRepository from '../../repositories/typologyRepository';
import { DocumentTypeResourceType } from '../../../mock/models/document-type';

type ARGS = {
	companyId: string;
	type: DocumentTypeResourceType;
	search?: string;
};

class GetTypologies implements UseCase<ARGS, Typology[]> {
	private typologyRepository: TypologyRepository;

	constructor(typologyRepository: TypologyRepository) {
		this.typologyRepository = typologyRepository;
	}

	async Call(args: ARGS): Promise<Typology[]> {
		return await this.typologyRepository.getTypologies(args.companyId, args.type, args.search);
	}
}

export default GetTypologies;
