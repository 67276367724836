import { SiteResource } from './siteResource';
import { SupplierResource } from './supplierResource';

export type SiteChemicalParams = {
	name: string;
};

export type SiteChemical = SiteResource<SiteChemicalParams>;

export type SupplierChemical = SupplierResource<SiteChemicalParams>;

export const mapSiteChemical =
	(siteId: string = null) =>
	(siteChemical): SiteChemical => ({
		name: siteChemical.name,
		variants: siteChemical.variants,
		specializations: siteChemical.specializations,
		company: siteChemical.companyName,
		id: siteChemical.id,
		siteId: siteId,
		companyId: siteChemical.companyId,
		state: siteChemical.state,
		isGlobal: siteChemical.isGlobal,
		isForcedEvaluation: siteChemical.isForcedEvaluation,
	});
