import Form from "./forms/Form";
import ImageBox from "./ImageBox";
import { Box, Flex, Text, useMediaQuery } from "@chakra-ui/react";
import { FC, ReactElement, ReactNode, useEffect, useState } from "react";
import { EditableFormFieldProps } from "./forms/FormField";
import { useAuth } from "../../providers/Auth0JWTProvider";

interface DataBoxProps {
  title: string;
  isEditing?: boolean;
  isLoading?: boolean;
  children?: ReactNode;
  fields: Array<ReactElement<Omit<EditableFormFieldProps, "isEditing">>>;

  image?: {
    url?: string;
    onUpdate?: (file: File) => void;
  };
}

const DataBox: FC<DataBoxProps> = ({
  title,
  image,
  isLoading,
  isEditing,
  fields,
  children,
}) => {
  const { token } = useAuth();
  const [imageUrl, setImageUrl] = useState<string>();

  const [isMobile] = useMediaQuery("(max-width: 767px)");

  useEffect(() => {
    const fetchImage = async (imageUrl: string) => {
      const response = await fetch(imageUrl, {
        headers: { Authorization: `Bearer ${token.access_token}` },
      });

      const blob = await response.blob();
      setImageUrl(URL.createObjectURL(blob));
    };

    if (image.url && token.access_token) {
      fetchImage(image.url);
    }
  }, [image.url, token.access_token]);

  return (
    <Flex width={"100%"} direction="column">
      <Text textStyle="h2" px={8} marginTop={5} marginBottom={3}>
        {title}
      </Text>
      <Flex direction="row" align="start">
        {image && (
          <Box width={"220px"} mx={8} pt={1}>
            <ImageBox
              disableFlag
              onChange={(imageParam) => {
                setImageUrl(undefined);
                image.onUpdate(imageParam);
              }}
              isLoaded={!isLoading}
              isEditing={isEditing}
              imageURL={imageUrl}
            />
          </Box>
        )}

        <Box
          pb={isEditing ? 6 : 8}
          flexGrow={isMobile ? undefined : 1} // Set flexGrow to 1 if testValue is true, otherwise, leave it undefined
          width={isMobile ? "800" : undefined}
          pr={8}
          pl={image ? -4 : 4}
          pt={isEditing ? 4 : 2}
        >
          <Form fields={fields} isEditing={isEditing} />
        </Box>
      </Flex>

      {children}
    </Flex>
  );
};

export default DataBox;
