import React, { FC, useEffect, useState } from "react";
import {
  FiAlertTriangle,
  FiCheckSquare,
  FiCopy,
  FiInfo,
  FiPlus,
} from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../assets/theme/colors";
import { ConfirmAlert } from "../Common/ConfirmAlert";
import ContentLayout from "../../layout/ContentLayout";
import { formatDateBasedOnLanguage, getBadgeReaderPath } from "../../../utils";
import ActionBar from "../../components/Common/ActionBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StatusToggle from "../../components/Common/StatusToggle";
import { DeleteActionAlert } from "../Common/DeleteActionAlert";
import ActionBarItem from "../../components/Common/ActionBarItem";
import EditButton from "../../components/Common/table/EditButton";
import DetailsHeader from "../../components/Common/DetailsHeader";
import DeleteButton from "../../components/Common/table/DeleteButton";
import { Permission } from "../../components/Permissions/Permissions";
import TableColumnHeader from "../../components/Common/table/TableColumnHeader";
import {
  Box,
  Flex,
  IconButton,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useClipboard,
  useMediaQuery,
} from "@chakra-ui/react";
import { BadgeReaderState } from "../../../domain/entities/badgeReaderState.enum";
import RenderIf, {
  useHasPermissions,
} from "../../components/Permissions/RenderIf";
import { BadgeReaderDirection } from "../../../domain/entities/badgeReaderDirection.enum";
import CreateUpdateStampingModal from "../../components/Views/Badge/CreateUpdateStampingModal";
import { GetBadgeReaderHistoryFilters } from "../../../domain/repositories/badgeReaderRepository";
import useSitesBadgeReaderDetailViewModel from "../../hooks/Site/useSiteBadgeReaderDetailViewModel";
import ColumnFilterComponent, {
  FilterComponentProps,
} from "../../components/Common/table/ColumnFilterComponent";
import {
  BadgeLogEntry,
  BadgeLogEntryAction,
  BadgeLogEntryActionResult,
} from "../../../domain/entities/badgeLogEntry";
import { Alert } from "../Common/Alert";
import { AllowedTimezones } from "../../../domain/entities/AllowedTimezones.enum";
import { BadgeReaderGate } from "../../../domain/entities/badgeReaderGate.enum";
import { useAuth } from "../../providers/Auth0JWTProvider";
import InfiniteTable from "../../components/Common/table/InfiniteTable";
import { BiLink } from "react-icons/bi";
import BaseModal from "../../components/Common/alerts/BaseModal";
import SelectSitesView from "../../components/Views/common/SelectSitesView";
import { ForcingDetailsModal } from "../Common/ForcingDetailModal";
import ActionButton from "../../components/Common/table/ActionButton";

interface Props {
  permissions: {
    setStatus: Permission;
    addRecord: Permission;
    edit: Permission;
  };
}

const SiteBadgeReaderDetail: FC<Props> = ({ permissions }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("badgeReaders");
  const [isTablet] = useMediaQuery("(max-width: 1300px)");
  const { badgeReaderId, siteId } = useParams();
  const [askConfirmDelete, setAskConfirmDelete] = useState<boolean>(false);
  const [showCreateUpdateStamp, setShowCreateUpdateStamp] =
    useState<boolean>(false);
  const [badgeLogEntryToDelete, setBadgeLogEntryToDelete] =
    useState<BadgeLogEntry>(undefined);
  const [selectedBadgeLogEntry, setSelectedBadgeLogEntry] =
    useState<BadgeLogEntry>(undefined);
  const [showModalLink, setShowModalLink] = useState(false);
  const [showForcedDetails, setShowForcedDetails] = useState(false);
  const [confirmChangeStateModal, setConfirmChangeStateModal] = useState<
    BadgeReaderState | false
  >(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { companyId } = useAuth();
  const {
    badgeReader,
    badgesAvailable,
    fetchingBadgeReader,
    filters,
    sort,
    history,
    historyIsLoading,
    linkBadgeReaderToSites,
    linkBadgeReaderToSitesIsLoading,
    selectedSiteIds,
    setSelectedSiteIds,
    sites,
    updateFilter,
    createBadgeLogEntry,
    updateBadgeLogEntry,
    deleteBadgeLogEntry,
    deleteBadgeLogEntryIsLoading,
    setSort,
    setStampingMgmtActive,
    updateBadgeReaderStatus,
    updateBadgeReader,
    error,
    setError,
    hasNextPage,
    fetchNextPage,
  } = useSitesBadgeReaderDetailViewModel(siteId, badgeReaderId);
  const location = useLocation();
  const badgeReaderType = location.state?.badgeReaderType;

  const navigateToBadgeReadersList = () => {
    navigate(`/sites/${siteId}/access/badge-readers`);
  };

  const columns: {
    field: keyof GetBadgeReaderHistoryFilters;
    filterType: FilterComponentProps<null>["type"];
    options?: Record<string, string>;
  }[] = [
    { field: "actionDate", filterType: "dateTime-range" },
    { field: "actionType", filterType: "select", options: BadgeLogEntryAction },
    {
      field: "actionResult",
      filterType: "select",
      options: BadgeLogEntryActionResult,
    },
    { field: "serial", filterType: "text" },
    { field: "badge", filterType: "text" },
    { field: "resource", filterType: "text" },
    { field: "company", filterType: "text" },
  ];

  const canEdit = useHasPermissions(permissions.edit);
  const canSetStatus = useHasPermissions(permissions.setStatus);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            bgColor={COLORS.sikuroBlue}
            color="white"
            icon={MdClose}
            description={t("close", { ns: "common" })}
            onClick={() => navigateToBadgeReadersList()}
          />
          <RenderIf permissions={permissions.addRecord}>
            <ActionBarItem
              icon={FiPlus}
              description={t("addStamping", { ns: "badges" })}
              onClick={() => {
                setStampingMgmtActive(true);
                setShowCreateUpdateStamp(true);
              }}
            />
          </RenderIf>
          <ActionBarItem
            icon={BiLink}
            description={t("linkBadgeReaderToSites")}
            onClick={() => {
              setShowModalLink(true);
            }}
          />
        </ActionBar>
      }
    >
      <Flex
        flex={1}
        h="100%"
        w="100%"
        alignItems="start"
        justifyContent="start"
        textAlign="center"
        flexDirection="column"
        padding={10}
      >
        {badgeReader && (
          <Flex pb={5} gap="20px" width={"100%"} flexWrap="wrap">
            {badgeReaderType === 0 && (
              <DetailsHeader
                title={t("details", { ns: "common" })}
                attributes={[
                  {
                    label: t("configurationLink"),
                    value: (
                      <BadgeReaderLink
                        valueReader={getBadgeReaderPath(
                          companyId,
                          siteId,
                          badgeReader.id,
                        )}
                      />
                    ),
                  },
                  {
                    label: t("columns.name"),
                    value: badgeReader.name ?? "-",
                    edit: canEdit && {
                      value: badgeReader?.name,
                      onChange: (value: string) => {
                        updateBadgeReader({
                          name: value,
                          serial: badgeReader.serial,
                          direction: badgeReader.direction,
                          timezone: badgeReader.timeZone.toString(),
                          delay: badgeReader.delay,
                        });
                      },
                      type: "text",
                    },
                  },
                  {
                    label: t("columns.serial"),
                    value: badgeReader.serial ?? "-",
                    edit: canEdit && {
                      value: badgeReader.serial,
                      onChange: (value: string) => {
                        updateBadgeReader({
                          name: badgeReader.name,
                          serial: value,
                          direction: badgeReader.direction,
                          timezone: badgeReader.timeZone.toString(),
                          delay: badgeReader.delay,
                        });
                      },
                      type: "text",
                    },
                  },
                  {
                    label: t("columns.readerDirection"),
                    value: t(badgeReader.direction) ?? "-",
                    edit: canEdit && {
                      value: badgeReader?.direction,
                      onChange: (value: BadgeReaderDirection) => {
                        updateBadgeReader({
                          name: badgeReader.name,
                          serial: badgeReader.serial,
                          direction: value,
                          timezone: badgeReader.timeZone.toString(),
                          delay: badgeReader.delay,
                        });
                      },
                      type: "select",
                      options: [
                        {
                          id: BadgeReaderDirection.BIDIRECTIONAL,
                          name: t(BadgeReaderDirection.BIDIRECTIONAL, {
                            ns: "badgeReaders",
                          }),
                        },
                        {
                          id: BadgeReaderDirection.ENTRY,
                          name: t(BadgeReaderDirection.ENTRY, {
                            ns: "badgeReaders",
                          }),
                        },
                        {
                          id: BadgeReaderDirection.EXIT,
                          name: t(BadgeReaderDirection.EXIT, {
                            ns: "badgeReaders",
                          }),
                        },
                      ],
                    },
                  },
                  ...(badgeReader.direction ===
                  BadgeReaderDirection.BIDIRECTIONAL
                    ? [
                        {
                          label: t("columns.entranceGate"),
                          value:
                            (badgeReader?.releConfiguration &&
                              t(badgeReader?.releConfiguration)) ??
                            "-",
                          edit: canEdit && {
                            value: badgeReader?.releConfiguration,
                            onChange: (value: BadgeReaderGate) => {
                              updateBadgeReader({
                                name: badgeReader.name,
                                serial: badgeReader.serial,
                                direction: badgeReader.direction,
                                timezone: badgeReader.timeZone.toString(),
                                releConfiguration: value,
                                delay: badgeReader.delay,
                              });
                            },
                            type: "select" as const,
                            options: [
                              { id: "", name: t("selectEntranceGate") },
                              {
                                id: BadgeReaderGate.R1ER2U,
                                name: t(BadgeReaderGate.R1ER2U, {
                                  ns: "badgeReaders",
                                }),
                              },
                              {
                                id: BadgeReaderGate.R1UR2E,
                                name: t(BadgeReaderGate.R1UR2E, {
                                  ns: "badgeReaders",
                                }),
                              },
                            ],
                          },
                        },
                      ]
                    : []),
                  {
                    label: t("columns.timezone"),
                    value: badgeReader.timeZone ?? "-",
                    edit: canEdit && {
                      value: badgeReader?.timeZone?.toString(),
                      onChange: (value: AllowedTimezones) => {
                        updateBadgeReader({
                          name: badgeReader.name,
                          serial: badgeReader.serial,
                          direction: badgeReader.direction,
                          timezone: value,
                          delay: badgeReader.delay,
                        });
                      },
                      type: "select",
                      options: Object.keys(AllowedTimezones).map(
                        (timezone) => ({
                          id: timezone,
                          name: AllowedTimezones[timezone],
                        }),
                      ),
                    },
                  },
                  {
                    label: t("columns.lastSync"),
                    value: badgeReader.lastPing
                      ? formatDateBasedOnLanguage(
                          badgeReader.lastPing as unknown as string,
                          true,
                        )
                      : "-",
                  },
                  {
                    label: t("columns.delay"),
                    value: badgeReader.delay ?? "-",
                    edit: canEdit && {
                      value: badgeReader?.delay?.toString(),
                      onChange: (value: string) => {
                        updateBadgeReader({
                          name: badgeReader.name,
                          serial: badgeReader.serial,
                          direction: badgeReader.direction,
                          timezone: badgeReader.timeZone.toString(),
                          delay: Number(value),
                        });
                      },
                      type: "number",
                    },
                  },
                ]}
                isLoaded={!fetchingBadgeReader}
              />
            )}

            {badgeReaderType === 1 && (
              <DetailsHeader
                title={t("details", { ns: "common" })}
                attributes={[
                  {
                    label: t("columns.name"),
                    value: badgeReader.name ?? "-",
                    edit: canEdit && {
                      value: badgeReader?.name,
                      onChange: (value: string) => {
                        updateBadgeReader({
                          name: value,
                          serial: badgeReader.serial,
                          direction: badgeReader.direction,
                          timezone: badgeReader.timeZone.toString(),
                        });
                      },
                      type: "text",
                    },
                  },
                  {
                    label: t("columns.userName"),
                    value: badgeReader.userName ?? "-",
                  },
                  {
                    label: t("columns.readerDirection"),
                    value: t(badgeReader.direction) ?? "-",
                    edit: canEdit && {
                      value: badgeReader?.direction,
                      onChange: (value: BadgeReaderDirection) => {
                        updateBadgeReader({
                          name: badgeReader.name,
                          serial: badgeReader.serial,
                          direction: value,
                          timezone: badgeReader.timeZone.toString(),
                        });
                      },
                      type: "select",
                      options: [
                        {
                          id: BadgeReaderDirection.BIDIRECTIONAL,
                          name: t(BadgeReaderDirection.BIDIRECTIONAL, {
                            ns: "badgeReaders",
                          }),
                        },
                        {
                          id: BadgeReaderDirection.ENTRY,
                          name: t(BadgeReaderDirection.ENTRY, {
                            ns: "badgeReaders",
                          }),
                        },
                        {
                          id: BadgeReaderDirection.EXIT,
                          name: t(BadgeReaderDirection.EXIT, {
                            ns: "badgeReaders",
                          }),
                        },
                      ],
                    },
                  },
                  {
                    label: t("columns.timezone"),
                    value: badgeReader.timeZone ?? "-",
                    edit: canEdit && {
                      value: badgeReader?.timeZone?.toString(),
                      onChange: (value: AllowedTimezones) => {
                        updateBadgeReader({
                          name: badgeReader.name,
                          serial: badgeReader.serial,
                          direction: badgeReader.direction,
                          timezone: value,
                        });
                      },
                      type: "select",
                      options: Object.keys(AllowedTimezones).map(
                        (timezone) => ({
                          id: timezone,
                          name: AllowedTimezones[timezone],
                        }),
                      ),
                    },
                  },
                ]}
                isLoaded={!fetchingBadgeReader}
              />
            )}

            <Flex
              p="10px 20px"
              flexDirection="column"
              gap="10px"
              width="340px"
              border="1px solid"
              borderColor="gray.300"
              flex="1 1 300px"
              borderRadius="10px"
            >
              <Box textStyle="h2">{t("columns.status")}</Box>
              <StatusToggle
                disabled={!canSetStatus}
                status={badgeReader.status}
                type="badgeReader"
                onChange={(status) =>
                  setConfirmChangeStateModal(status as BadgeReaderState)
                }
                isTablet={isTablet}
              />
            </Flex>
          </Flex>
        )}

        <Box
          px={3}
          py={5}
          border="1px solid"
          borderColor="gray.300"
          borderRadius="10px"
          width={"calc(100vw - 270px)"}
          position="relative"
        >
          <Flex
            flexDirection={"column"}
            alignItems={"start"}
            border="1px solid"
            borderColor="gray.300"
            borderRadius="10px"
            width={"calc(100vw -300px)"}
            position="relative"
            overflow={"auto"}
            marginTop={3}
          >
            <InfiniteTable
              autosize
              maxHeight="calc(100vh - 470px)"
              tableId="site-badge-reader-history-table"
              infiniteScroll={{
                dataLength: history.length,
                hasNextPage: hasNextPage,
                fetchNextPage: fetchNextPage,
              }}
              showEmptyText={history?.length === 0}
              emptyText={t("noHistoryBadgeReader", { ns: "badges" })}
              isLoading={historyIsLoading}
            >
              <Thead>
                <Tr>
                  {columns.map((column) => (
                    <Th
                      key={column.field}
                      width={
                        isTablet && history?.length > 0
                          ? "200px"
                          : column.field === "serial"
                            ? "230px"
                            : "100%"
                      }
                    >
                      <TableColumnHeader
                        text={t(`historyColumns.${column.field}`, {
                          ns: "badgeReaders",
                        })}
                        filter={{
                          isActive:
                            !!filters[column.field] &&
                            (!Array.isArray(filters[column.field]) ||
                              !!filters[column.field][0]),
                          component: (
                            <ColumnFilterComponent
                              selectOptions={column.options}
                              value={filters[column.field]}
                              namespace="badges"
                              type={column.filterType}
                              updateFilter={(value) =>
                                updateFilter(column.field, value)
                              }
                            />
                          ),
                        }}
                        sort={{
                          handler: (direction) =>
                            setSort({ field: column.field, direction }),
                          direction:
                            sort?.field === column.field
                              ? sort.direction
                              : null,
                        }}
                      />
                    </Th>
                  ))}
                  <Th w="100px" />
                </Tr>
              </Thead>
              <Tbody borderRadius="xl">
                {history?.map((log) => (
                  <Tr key={log.id}>
                    <Td width={isTablet ? "200px" : "100%"}>
                      {formatDateBasedOnLanguage(log?.actionDate, true)}
                    </Td>
                    <Td width={isTablet ? "200px" : "100%"}>
                      {t(`actionTypes.${log?.actionType}`, { ns: "badges" })}
                    </Td>
                    <Td
                      width={isTablet ? "200px" : "100%"}
                      sx={{ alignItems: "center" }}
                    >
                      <Flex flexDirection={"row"}>
                        {t(`actionResults.${log?.actionResult}`, {
                          ns: "badges",
                        })}
                        {log?.anomaly && (
                          <Tooltip label={t("anomaly", { ns: "badges" })}>
                            <span>
                              <FiAlertTriangle
                                style={{ marginLeft: "5px" }}
                                color={COLORS.darkYellow}
                              />
                            </span>
                          </Tooltip>
                        )}
                      </Flex>
                    </Td>
                    <Td width={isTablet ? "200px" : "230px"}>
                      {log?.badge?.serial}
                    </Td>
                    <Td width={isTablet ? "200px" : "100%"}>
                      {log?.badge?.code}
                    </Td>
                    <Td width={isTablet ? "200px" : "100%"}>{log?.resource}</Td>
                    <Td width={isTablet ? "200px" : "100%"}>{log?.company}</Td>
                    <Td w={"100px"} p={0} paddingLeft={3}>
                      {!log.isForced && (
                        <EditButton
                          onClick={() => {
                            setStampingMgmtActive(true);
                            setSelectedBadgeLogEntry(log);
                            setShowCreateUpdateStamp(true);
                          }}
                        />
                      )}
                      <DeleteButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setBadgeLogEntryToDelete(log);
                          setAskConfirmDelete(true);
                        }}
                      />
                      {log.isForced && (
                        <Tooltip
                          label={t("showDetails", { ns: "common" })}
                          shouldWrapChildren={true}
                        >
                          <ActionButton
                            aria-label="infoForced"
                            icon={<FiInfo color="red" />}
                            onClick={() => {
                              setShowForcedDetails(true);
                              setSelectedBadgeLogEntry(log);
                            }}
                          />
                        </Tooltip>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </InfiniteTable>
          </Flex>
        </Box>
      </Flex>
      <ForcingDetailsModal
        forcedDetails={{
          expiresAt: selectedBadgeLogEntry?.forcing?.expiresAt,
          note: selectedBadgeLogEntry?.forcing?.note,
          userName: selectedBadgeLogEntry?.forcing?.userName,
          codeBadge: selectedBadgeLogEntry?.badge?.code,
          resource: selectedBadgeLogEntry?.resource,
        }}
        setShowForcedDetails={setShowForcedDetails}
        showForcedDetails={showForcedDetails}
        title={t("forcedAccessInfoTitle")}
      />
      {confirmChangeStateModal && (
        <ConfirmAlert
          onCancel={() => setConfirmChangeStateModal(false)}
          onConfirm={async () => {
            await updateBadgeReaderStatus(confirmChangeStateModal);
            setConfirmChangeStateModal(false);
          }}
          title={t("updateMessage")}
          variant="question"
        />
      )}

      {showCreateUpdateStamp && (
        <CreateUpdateStampingModal
          onCancel={() => {
            setStampingMgmtActive(false);
            setShowCreateUpdateStamp(false);
            setSelectedBadgeLogEntry(undefined);
          }}
          onConfirm={(badgeLog) => {
            badgeLog.id
              ? updateBadgeLogEntry(badgeLog)
              : createBadgeLogEntry(badgeLog);
            setStampingMgmtActive(false);
            setShowCreateUpdateStamp(false);
            setSelectedBadgeLogEntry(undefined);
          }}
          badges={badgesAvailable}
          stampFromBadge={false}
          currentBadgeReader={badgeReader}
          badgeLogEntryToUpdate={selectedBadgeLogEntry}
        />
      )}

      {askConfirmDelete && (
        <DeleteActionAlert
          isLoading={deleteBadgeLogEntryIsLoading}
          onConfirm={async () => {
            await deleteBadgeLogEntry(badgeLogEntryToDelete);
            setAskConfirmDelete(false);
            setBadgeLogEntryToDelete(undefined);
          }}
          onCancel={() => {
            setAskConfirmDelete(false);
            setBadgeLogEntryToDelete(undefined);
          }}
          mainTitle={t("warning", { ns: "common" })}
          title={t("confirmLogEntryDelete", { ns: "badges" })}
          leftButtonText={t("confirm", { ns: "common" })}
          rightButtonText={t("cancel", { ns: "common" })}
          isOpen={!!askConfirmDelete}
        />
      )}
      {error && (
        <Alert
          title="Warning"
          message={t("logEntryErrors." + error, { ns: "badgeReaders" })}
          onClose={() => setError(null)}
          variant="warning"
        />
      )}
      {showModalLink && (
        <BaseModal
          onClose={() => setShowModalLink(false)}
          onConfirm={() => linkBadgeReaderToSites()}
          onConfirmDisabled={selectedSiteIds.length <= 0}
          isLoading={linkBadgeReaderToSitesIsLoading}
          onCancel={() => setShowModalLink(false)}
        >
          <SelectSitesView
            autosize
            title={t("selectSites", { ns: "common" })}
            reminderText={t("noSiteSelected", { ns: "common" })}
            alertText={t("siteSelectionAlert", { ns: "common" })}
            siteSelectedAction={setSelectedSiteIds}
            siteList={sites}
            includeFooterButton={false}
            showSelectAll={false}
            hideSubtitles={true}
          ></SelectSitesView>
        </BaseModal>
      )}
    </ContentLayout>
  );
};

export default SiteBadgeReaderDetail;

const BadgeReaderLink = ({ valueReader }: { valueReader: string }) => {
  const { onCopy, hasCopied } = useClipboard("");
  return (
    <>
      {valueReader}
      <IconButton
        variant="link"
        colorScheme="blue"
        aria-label="copy link"
        fontSize="15px"
        onClick={onCopy}
        icon={hasCopied ? <FiCheckSquare /> : <FiCopy />}
      />
    </>
  );
};
