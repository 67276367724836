import { useAuth } from "../../../providers/Auth0JWTProvider";
import Tool from "../../../../domain/entities/tool";
import { ToolViewModel } from "../../../viewmodels/tools/ToolViewModel";
import { useMemo, useState } from "react";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import { GetSitesFilter } from "../../../../domain/repositories/siteRepository";
import { updateFilterWithDelete } from "../../../../utils";
import { ResourceType } from "../../../screens/Site/ResourceSelectableTable";

const useToolViewModel = (id: string) => {
  const { companyId } = useAuth();
  const [sortResourceSites, setSortResourceSites] = useState<SortMeta>();
  const [filterResourceSites, setFilterResourceSites] =
    useState<GetSitesFilter>();
  const [sortSiteCollection, setSortSiteCollection] = useState<SortMeta>();
  const [filterSiteCollection, setFilterSiteCollection] =
    useState<GetSitesFilter>();
  const [selectAll, setSelectAll] = useState(false);
  const [enableGetResourceSites, setEnableGetResourceSites] = useState<{
    getSites: boolean;
    action: string;
  }>({ getSites: false, action: "" });
  const [act, setAct] = useState<string>();
  const viewModel = useMemo(() => new ToolViewModel(), []);
  const queryClient = useQueryClient();

  const {
    data: tool,
    isFetching,
    refetch: getTool,
  } = useQuery<Tool, Error>(
    ["tool", companyId, id],
    async () => await viewModel.get(companyId, id),
    {
      retry: false,
      initialData: undefined,
    },
  );

  const updateMutation = useMutation(
    ({ tool, imageFile }: { tool: Tool; imageFile?: File }) =>
      viewModel.update(companyId, tool, imageFile),
    {
      onError: (err) => {
        console.error(err);
      },
      onSuccess: () => getTool(),
    },
  );

  const updateTool = (tool: Tool) => {
    return updateMutation.mutateAsync({ tool });
  };

  const updateImage = (tool: Tool, imageFile?: File) => {
    updateMutation.mutate({
      tool,
      imageFile,
    });
  };
  const getToolSites = useInfiniteQuery(
    ["resource-sites", companyId, id, sortResourceSites, filterResourceSites],
    async ({ pageParam = 1 }) => {
      const includeWorkingSites = act === "add";
      return await viewModel.getToolSites(
        companyId,
        id,
        sortResourceSites,
        filterResourceSites,
        pageParam,
        includeWorkingSites,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.results.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: !!sortResourceSites || !!filterResourceSites,
    },
  );

  const hasSites = async (action) => {
    setAct(action);
    await queryClient.invalidateQueries([
      "resource-sites",
      companyId,
      id,
      sortResourceSites,
      filterResourceSites,
      action,
    ]);
    const data = await getToolSites.refetch();
    const sites = data.data?.pages.flatMap((page) => page.results) ?? [];
    return sites.length > 0;
  };

  const getResourceLinkableSites = useInfiniteQuery(
    [
      "site-collection",
      companyId,
      id,
      sortSiteCollection,
      filterSiteCollection,
    ],
    async ({ pageParam = 1 }) => {
      return await viewModel.getResourceLinkableSites(
        companyId,
        "tool" as ResourceType,
        id,
        sortSiteCollection,
        filterSiteCollection,
        pageParam,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    },
  );
  const linkToolToSites = (siteIds: string[]) => {
    linkToolToSitesMutation.mutateAsync(siteIds);
  };
  const linkToolToSitesMutation = useMutation(
    async (siteIds: string[]) => {
      return await viewModel.linkResourceToSites(companyId, id, siteIds);
    },
    {
      onError: (e) => console.error(e),
      onSuccess: () => getTool(),
    },
  );

  // const resourceSites = getToolSites.data?.pages?.flat() ?? [];

  const resourceSites =
    getToolSites.data?.pages?.flatMap((page) => page.results) ?? [];
  const totalSiteCount = getToolSites.data?.pages?.[0]?.count ?? 0;

  return {
    tool,
    getTool,
    isFetching,
    hasSites,
    updateImage,
    updateTool,
    resourceSites,
    setSortResourceSites,
    sortResourceSites,
    setFilterResourceSites,
    filterResourceSites,
    isFetchingSites: getToolSites.isFetching,
    hasNextPage: getToolSites.hasNextPage,
    fetchNextPage: getToolSites.fetchNextPage,
    setEnableGetResourceSites,
    updateFilterResourceSites: (field, value) => {
      updateFilterWithDelete(setFilterResourceSites, field, value);
    },
    siteCollection: getResourceLinkableSites.data?.pages?.flat() ?? [],
    sortSiteCollection,
    setSortSiteCollection,
    filterSiteCollection,
    updateFilterSiteCollection: (field, value) => {
      updateFilterWithDelete(setFilterSiteCollection, field, value);
    },
    siteCollectionHasNextPage: getResourceLinkableSites.hasNextPage,
    siteCollectionFetchNextPage: getResourceLinkableSites.fetchNextPage,
    refetchSiteCollection: getResourceLinkableSites.refetch,
    linkToolToSites,
    isLinking: linkToolToSitesMutation.isLoading,
    selectAll,
    setSelectAll,
    totalSiteCount,
  };
};

export default useToolViewModel;
