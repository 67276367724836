import { injectable } from 'tsyringe';
import Company from '../../domain/entities/company';
import { parseCompany } from '../responses/company';
import Document from '../../domain/entities/document';
import CompanyRepository from '../../domain/repositories/companyRepository';
import { SortMeta } from '../../domain/entities/interfaces/paginatedResults';
import { GetDocumentsFilter } from '../../domain/repositories/documentRepository';
import UpdateCompanyParameter from '../../presentation/hooks/Company/updateCompanyParameter';
import { ApiService } from '../utilities/apiService';
import { dateIntervals } from '../utilities/filters';
import { GetTenantsFilters } from '../../presentation/components/Views/common/ChangeTenantModal';
import { mapApiResponseToDocument } from '../adapters/getResourceDocuments';

@injectable()
class ServerCompanyRepository implements CompanyRepository {
	constructor(private apiService: ApiService) {}

	async getCompanyById(id: string): Promise<Company | undefined> {
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${id}`);
		if (!response.ok) {
			return undefined;
		}

		const payload = await response.json();
		const company = parseCompany(payload);
		return company;
	}

	async getCompanies(filter: GetTenantsFilters, sort: SortMeta, pageParam: number): Promise<Company[]> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...filter,
			...sort,
		});
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies?${params.toString()}`);
		const { results } = await response.json();
		return results;
	}

	async createCompany(company: Company): Promise<Company> {
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(company),
		});
		return await response.json();
	}

	async updateCompany(companyParameters: UpdateCompanyParameter): Promise<Company> {
		const formData = new FormData();
		const appendedParameters = new Set();
		if (typeof companyParameters.imageFile !== 'string' && companyParameters.imageFile) {
			formData.append('logo', companyParameters.imageFile);
			appendedParameters.add('logo');
		}

		Object.keys(companyParameters.company).map((parameter) => {
			companyParameters.company[parameter] = companyParameters.company[parameter] ?? '';
			if (!appendedParameters.has(parameter)) {
				if (parameter === 'address') {
					formData.append('street', companyParameters.company.address.street ?? '');
					formData.append('city', companyParameters.company.address.city ?? '');
					formData.append('cap', companyParameters.company.address.cap ?? '');
				} else if (parameter !== 'logo') {
					formData.append(parameter, companyParameters.company[parameter]);
				}
				appendedParameters.add(parameter);
			}
		});

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyParameters.company.id}`,
			{
				method: 'POST',
				body: formData,
			},
		);

		const payload = await response.json();
		const company = parseCompany(payload);
		return company;
	}

	async deleteCompany(id: string): Promise<Company> {
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${id}`, { method: 'DELETE' });
		if (!response.ok) {
			throw new Error('Failed to delete company');
		}
		return await response.json();
	}

	async getCompanyDocuments(companyId: string, filter?: GetDocumentsFilter, sort?: SortMeta, pageParam?: number): Promise<Document[]> {
		const { tags, expiresAt, ...restFilter } = filter || {};
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...restFilter,
			...dateIntervals(expiresAt),
			...sort,
		});
		tags?.length > 0 && tags.forEach((tag) => params.append('tagIds[]', tag));
		let query = params.toString();
		query = query !== '' ? `? ${query}` : '';
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/documents${query}`);
		const documents = await response.json();
		return documents.results ? Object.values(documents.results).map(mapApiResponseToDocument) : [];
	}
}

export default ServerCompanyRepository;
