import { SiteChemicalParams } from "../../../domain/entities/siteChemical";
import { SiteResourceDetailViewModel } from "../../hooks/Site/useSiteResourceDetailViewModel";
import SiteChemicalDetailViewModel from "../sites/SiteChemicalDetailViewModel";
import {
  makeAddRequirementToSiteChemical,
  makeDeleteSiteChemical,
} from "../../../core/app/factory/siteChemicals/usecases";
import { makeEvaluateSiteDocument } from "../../../core/app/factory/documents/usecases";
import { ResourceDocumentEvaluationState } from "../../../domain/entities/resourceDocumentEvaluationState.enum";

class WorkingSiteChemicalDetailViewModel
  extends SiteChemicalDetailViewModel
  implements SiteResourceDetailViewModel<SiteChemicalParams>
{
  evaluateSiteResource = undefined;
  async addRequirementToSiteResource(
    companyId: string,
    chemicalId: string,
    siteId: string,
    documentTypeId: string,
    isOptional: boolean,
    graceDays: number,
  ): Promise<void> {
    await makeAddRequirementToSiteChemical().Call({
      companyId,
      chemicalId,
      siteId,
      documentTypeId,
      isOptional,
      graceDays,
    });
  }
  async evaluateDocument(
    companyId: string,
    siteId: string,
    documentId: string,
    result: ResourceDocumentEvaluationState,
    expiresAt?: Date,
    noEvaluationExpiration?: boolean,
    target?: string,
    resourceId?: string,
  ): Promise<void> {
    await makeEvaluateSiteDocument().Call({
      companyId,
      siteId,
      documentId,
      result,
      expiresAt,
      noEvaluationExpiration,
      target,
      resourceId,
    });
  }

  async deleteSiteResource(
    companyId: string,
    chemicalId: string,
    siteId: string,
  ): Promise<void> {
    await makeDeleteSiteChemical().Call({ companyId, chemicalId, siteId });
  }
}

export default WorkingSiteChemicalDetailViewModel;
