import { injectable } from 'tsyringe';
import Audit, { AuditType } from '../../domain/entities/audit';
import DocumentComment from '../../domain/entities/documentComment';
import { DocumentTypeCategory } from '../../domain/entities/documentTypeCategory.enum';

import { PaginatedResults, SortMeta } from '../../domain/entities/interfaces/paginatedResults';
import Supplier from '../../domain/entities/supplier';
import AuditRepository, { GetAuditFilter } from '../../domain/repositories/auditRepository';
import { ApiService } from '../utilities/apiService';
import { dateIntervals } from '../utilities/filters';

@injectable()
class ServerAuditRepository implements AuditRepository {
	constructor(private apiService: ApiService) {}

	async getAudits(
		companyId: string,
		siteId?: string,
		page?: number,
		perPage?: number,
		filter?: GetAuditFilter,
		sort?: SortMeta,
	): Promise<PaginatedResults<Audit>> {
		const { date, ...restFilter } = filter || {};

		const params = new URLSearchParams({
			...restFilter,
			...sort,
			...dateIntervals(date),
		});

		const getAudits = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/reports?${params.toString()}`;

		const response = await this.apiService.fetchWithToken(getAudits, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		});

		const results = await response.json();

		return results ?? [];
	}

	async createAudit(
		companyId: string,
		audit: AuditType,
		siteId: string,
		supplierId: string,
		siteRequirementsToExclude?: string[],
		companyRequirementsToExclude?: string[],
		workersRequirementsToExclude?: string[],
		machinesRequirementsToExclude?: string[],
		vehiclesRequirementsToExclude?: string[],
		toolsRequirementsToExclude?: string[],
		chemicalsRequirementsToExclude?: string[],
		workerResourceIds?: string[],
		machineResourceIds?: string[],
		vehicleResourceIds?: string[],
		toolResourceIds?: string[],
		chemicalResourceIds?: string[],
	): Promise<boolean> {
		const reportType = audit === AuditType.RESOURCE ? 'reports-resources' : 'reports-itp';

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/suppliers/${supplierId}/${reportType}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					excludedSiteDocumentTypes: siteRequirementsToExclude,
					excludedCompanyDocumentTypes: companyRequirementsToExclude,
					excludedWorkerDocumentTypes: workersRequirementsToExclude,
					excludedMachineyDocumentTypes: machinesRequirementsToExclude,
					excludedVehicleDocumentTypes: vehiclesRequirementsToExclude,
					excludedToolDocumentTypes: toolsRequirementsToExclude,
					excludedChemicalDocumentTypes: chemicalsRequirementsToExclude,
					workerResourceIds,
					machineResourceIds,
					vehicleResourceIds,
					toolResourceIds,
					chemicalResourceIds,
				}),
			},
		);

		return Promise.resolve(response.ok);
	}

	async deleteAudit(companyId: string, auditId: string, siteId?: string): Promise<void> {
		const deleteUrl = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/reports/${auditId}`;
		await this.apiService.fetchWithToken(deleteUrl, {
			method: 'DELETE',
		});
	}

	async getAuditNote(
		companyId: string,
		requirementId: string,
		siteId: string,
		requirementSubject: DocumentTypeCategory,
		resourceId: string,
	): Promise<DocumentComment> {
		const response = await this.apiService.fetchWithToken(buildAuditNotePath(companyId, siteId, requirementId, requirementSubject, resourceId), {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		});

		return response.json();
	}

	async createAuditNote(
		companyId: string,
		siteId: string,
		requirementId: string,
		note: string,
		requirementSubject: DocumentTypeCategory,
		resourceId: string,
	): Promise<void> {
		await this.apiService.fetchWithToken(buildAuditNotePath(companyId, siteId, requirementId, requirementSubject, resourceId), {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ reportRemarks: note }),
		});
	}

	async updateAuditNote(
		companyId: string,
		siteId: string,
		requirementId: string,
		note: string,
		requirementSubject: DocumentTypeCategory,
		resourceId: string,
	): Promise<void> {
		await this.apiService.fetchWithToken(buildAuditNotePath(companyId, siteId, requirementId, requirementSubject, resourceId), {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ reportRemarks: note }),
		});
	}

	async getSelectableSuppliers(companyId: string, siteId: string): Promise<Supplier[]> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/suppliers?includeOwner=1`,
		);

		if (!response.ok) {
			throw new Error('Failed to get active supplier');
		}

		const { results } = await response.json();

		return results;
	}
}

function getAuditBasePath(companyId: string, siteId?: string) {
	const base = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/`;
	if (siteId) return `${base}sites/${siteId}/`;
	return base;
}

const buildAuditNotePath = (
	companyId: string,
	siteId: string,
	requirementId: string,
	requirementSubject: DocumentTypeCategory,
	resourceId: string,
) => {
	let apiPath = '';

	switch (requirementSubject) {
		case DocumentTypeCategory.COMPANY:
			apiPath = `${getAuditBasePath(companyId, siteId)}suppliers/${resourceId}/company-requirements/${requirementId}/report-remarks`;
			break;
		case DocumentTypeCategory.SITE:
			apiPath = `${getAuditBasePath(companyId, siteId)}suppliers/${resourceId}/site-requirements/${requirementId}/report-remarks`;
			break;
		default:
			apiPath = `${getAuditBasePath(companyId, siteId)}${requirementSubject}s/${resourceId}/requirements/${requirementId}/report-remarks`;
	}
	return apiPath;
};

export default ServerAuditRepository;
