import {
  Box,
  Button,
  Flex,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApp } from "../../../app";
import { Notification } from "../../../domain/entities/notification";
import { ListNotificationsStatusFilter } from "../../../domain/interactors/notifications/list";
import { COLORS } from "../../assets/theme/colors";
import ConfirmDeleteNotification from "../../components/Views/Notifications/ConfirmDeleteNotification";
import NotificationRow from "../../components/Views/Notifications/NotificationRow";
import ContentLayout from "../../layout/ContentLayout";
import InfiniteTable from "../../components/Common/table/InfiniteTable";
import { useMediaQuery } from "@chakra-ui/react";

interface Props {
  pageSize: number;
  status: ListNotificationsStatusFilter;
}

const NotificationsList: FC<Props> = ({ status, pageSize }) => {
  const { t } = useTranslation("notifications");
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const {
    notificationContext: {
      notifications,
      readNotification,
      deleteNotification,
      hasNextPage,
      fetchNextPage,
      setStatus,
      isFetchingNextPage,
    },
  } = useApp();

  const [deleteCandidate, setDeleteCandidate] = useState<Notification | null>(
    null
  );
  const confirmDeleteNotification = (notification: Notification) => {
    setDeleteCandidate(notification);
  };
  useEffect(() => {
    setStatus(status);
  }, [status]);

  return (
    <ContentLayout>
      <Flex p={10} direction="column">
        <Text
          fontSize="xl"
          textColor={COLORS.sikuroBlue}
          fontWeight="bold"
          pb={2}
        >
          {
            // Ternary operator used to make text translation explicit.
            t(status === "new" ? "newNotifications" : "archivedNotifications")
          }
        </Text>

        <Flex
          flexDirection={"column"}
          alignItems={"start"}
          width={isMobile ? "900px" : "100%"}
          position="relative"
          overflow={"hidden"}
          marginTop={3}
          sx={{
            "& > *": {
              width: "100%", // All direct children will take 100% width
            },
          }}
        >
          {notifications.length > 0 ? (
            notifications?.map((n) => (
              <div key={n}>
                <NotificationRow
                  notification={n}
                  onRead={status === "new" && readNotification}
                  onUnread={status === "archived" && readNotification}
                  onDelete={status === "archived" && confirmDeleteNotification}
                />
              </div>
            ))
          ) : (
            <Box
              border="1px solid"
              borderColor="gray.300"
              borderRadius="10px"
              p={4}
              textAlign="center"
              backgroundColor="#e5e5e5"
            >
              {t("noNewNotifications")}
            </Box>
          )}
          {hasNextPage && (
            <Button
              isLoading={isFetchingNextPage}
              onClick={() => fetchNextPage()}
            >
              {t("showOtherNotifications")}
            </Button>
          )}
        </Flex>
      </Flex>

      {deleteCandidate && (
        <ConfirmDeleteNotification
          notification={deleteCandidate}
          onConfirm={(id) => deleteNotification(id)}
          onCancel={() => setDeleteCandidate(null)}
        />
      )}
    </ContentLayout>
  );
};

export default NotificationsList;
