import Site from '../../entities/site';
import UseCase from '../../../core/app/usecase';
import { GetSitesFilter } from '../../repositories/siteRepository';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import RequirementsGroupRepository from '../../repositories/requirementsGroupRepository';

interface Args {
	companyId: string;
	requirementGroupId: string;
	filter?: GetSitesFilter;
	sort?: SortMeta;
	pageParam?: number;
}

class GetPropagableSites implements UseCase<Args, Site[]> {
	private requirementGroupRepository: RequirementsGroupRepository;

	constructor(requirementGroupRepository: RequirementsGroupRepository) {
		this.requirementGroupRepository = requirementGroupRepository;
	}

	async Call({ companyId, requirementGroupId, filter, sort, pageParam }: Args): Promise<Site[]> {
		return await this.requirementGroupRepository.getPropagableSites(companyId, requirementGroupId, filter, sort, pageParam);
	}
}

export default GetPropagableSites;
