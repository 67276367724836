import ActionButton, {ActionButtonProps} from "./ActionButton";
import {Icon, Tooltip} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaUndo } from "react-icons/fa";

const DiscardButton = ({ onClick, buttonColor, buttonWidth, iconSize }: ActionButtonProps) => {
  const { t } = useTranslation();

  return (
    <Tooltip label={t("discardChanges", { ns: "common" })} aria-label="discard">
      <span>
        <ActionButton aria-label="discard" onClick={onClick} buttonWidth={buttonWidth} 
        icon={<Icon as={FaUndo} fontSize={iconSize ? iconSize : "22px"} color={buttonColor}/>} />
      </span>
    </Tooltip>
  );
}


export default DiscardButton