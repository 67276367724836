import Badge, { SiteData } from "../../../../domain/entities/badge";
import { View } from "@react-pdf/renderer";
import FrontBadgeVehicle from "./NfcBadgeVehicle";
import QrCodeBadgeBack from "./QrCodeBadgeBack";
import React from "react";

type QrCodeBadgeVehicleProps = {
  badge: Badge;
};
const QrCodeBadgeVehicle = ({ badge }: QrCodeBadgeVehicleProps) => (
  <View style={{ flexDirection: "row" }}>
    <FrontBadgeVehicle badge={badge} />
    <QrCodeBadgeBack site={badge.site as SiteData} serial={badge.serial} />
  </View>
);
export default QrCodeBadgeVehicle;
