import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TopBar, { TopBarItem } from "../../Common/TopBar";
import {
  getSectionPermissions,
  Permission,
} from "../../Permissions/Permissions";

interface WorkingSitesDetailTopBarProps {
  emptyItems?: boolean;
}

const WorkingSitesDetailsTopBar = ({
  emptyItems = false,
}: WorkingSitesDetailTopBarProps) => {
  const { siteId } = useParams();
  const { t } = useTranslation("sites");

  const items: TopBarItem[] = [
    {
      title: t("summary"),
      path: `/workingsites/${siteId}/details`,
      permissions: getSectionPermissions("worksite", "records"),
    },
    {
      title: t("resources"),
      path: `/workingsites/${siteId}/resources`,
      permissions: getSectionPermissions("worksite", [
        "chemicals",
        "tools",
        "machines",
        "vehicles",
        "workers",
      ]),
    },
    {
      title: t("documents"),
      path: `/workingsites/${siteId}/documents`,
      permissions: getSectionPermissions("worksite", "company-documents"),
    },
    {
      title: t("subcontract", { ns: "supplier" }),
      path: `/workingsites/${siteId}/subcontracts`,
      permissions: getSectionPermissions("worksite", "subcontractors"),
    },
  ];

  return (
    <TopBar
      title={t("site")}
      items={emptyItems ? [] : items}
      backPath="/workingsites"
    />
  );
};

export default WorkingSitesDetailsTopBar;
