import SiteOptions, {
  ScheduleEmail,
} from "../../../domain/entities/siteOptions";
import {
  makeGetScheduleEmail,
  makeGetSiteOptions,
  makeUpdateScheduledEmail,
  makeUpdateSiteOptions,
} from "../../../core/app/factory/sites/usecases";

class SiteOptionsViewModel {
  constructor(
    private readonly companyId: string,
    private readonly siteId: string,
  ) {}
  async getSiteOptions(): Promise<SiteOptions> {
    return await makeGetSiteOptions().Call({
      companyId: this.companyId,
      siteId: this.siteId,
    });
  }

  async getScheduleEmail(): Promise<ScheduleEmail[]> {
    return await makeGetScheduleEmail().Call({
      companyId: this.companyId,
      siteId: this.siteId,
    });
  }

  async updateSiteOptions(options: SiteOptions): Promise<void> {
    return await makeUpdateSiteOptions().Call({
      companyId: this.companyId,
      siteId: this.siteId,
      options,
    });
  }

  async updateScheduledEmail(options: ScheduleEmail): Promise<void> {
    return await makeUpdateScheduledEmail().Call({
      companyId: this.companyId,
      siteId: this.siteId,
      options,
    });
  }
}

export default SiteOptionsViewModel;
