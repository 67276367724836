import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useAuth } from "../../providers/Auth0JWTProvider";
import SiteApproveEvaluationsViewModel from "../../viewmodels/sites/SiteApproveEvaluationsViewModel";
import { Evaluable } from "../../../domain/entities/evaluable.enum";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { updateFilterWithDelete } from "../../../utils";

export type PendingEvaluationsFilters = {
  company?: string;
  resource?: string;
  requirement?: string;
  result?: string;
  firstName?: string;
  lastName?: string;
};

const useSiteApproveEvaluationsViewModel = (
  siteId: string,
  type: Evaluable,
) => {
  const { companyId } = useAuth();
  const viewModel = new SiteApproveEvaluationsViewModel(
    companyId,
    siteId,
    type,
  );
  const [sort, setSort] = useState<SortMeta>();
  const [filterEvaluations, setFilterEvaluations] =
    useState<PendingEvaluationsFilters>();
  const [search, setSearch] = useState<string>();

  const getPendignEvaluationsQuery = useInfiniteQuery(
    ["company-suppliers", companyId, filterEvaluations, search, sort, type],
    async ({ pageParam = 1 }) => {
      const filters = search
        ? { ...filterEvaluations, search }
        : filterEvaluations;
      const result = await viewModel.get(filters, sort, pageParam);
      return result;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    },
  );

  const approvePendingEvaluationMutation = useMutation(
    ["approve-pending-evaluation", companyId, siteId, type],
    async ({
      resourceId,
      evaluationId,
      isApproved,
    }: {
      resourceId: string;
      evaluationId: string;
      isApproved: boolean;
    }) => viewModel.approve(resourceId, evaluationId, isApproved),
    {
      onSuccess: () => getPendignEvaluationsQuery.refetch(),
    },
  );

  const updateFilterEvaluations = (
    field: string,
    value: string | string[] | [Date, Date] | undefined,
  ) => {
    updateFilterWithDelete(setFilterEvaluations, field, value);
  };

  return {
    evaluations: getPendignEvaluationsQuery?.data?.pages?.flat() ?? [],
    evaluationsIsLoading: getPendignEvaluationsQuery.isLoading,
    pendingEvaluationsHasNextPage: getPendignEvaluationsQuery.hasNextPage,
    pendingEvaluationsFetchNextPage: getPendignEvaluationsQuery.fetchNextPage,
    filterEvaluations,
    setFilterEvaluations,
    updateFilterEvaluations,
    sort,
    setSort,
    approve: approvePendingEvaluationMutation.mutateAsync,
    approveIsLoading: approvePendingEvaluationMutation.isLoading,
    setSearch,
  };
};

export default useSiteApproveEvaluationsViewModel;
