import { Font, StyleSheet } from '@react-pdf/renderer';

Font.register({
	family: 'Roboto',
	fonts: [{ src: '/fonts/Roboto-Regular.ttf' }, { src: '/fonts/Roboto-Bold.ttf', fontWeight: 700 }],
});

export const styles = StyleSheet.create({
	badgeContainer: {
		border: '1px solid black',
		padding: '3mm',
		width: '85mm',
		height: '54mm',
		margin: '5mm',
		fontSize: 8,
		fontFamily: 'Roboto',
	},
	mainContainer: {
		flexDirection: 'row',
		marginBottom: '5mm',
	},
	photoContainer: {
		width: '25mm',
		height: '25mm',
		marginRight: '5mm',
	},
	qrCodeContainer: {
		width: '40mm',
		height: '40mm',
		margin: '0 auto',
	},
	photo: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
	workerDetailsContainer: {
		flexDirection: 'column',
	},
	workerDetailsRow: {
		flexDirection: 'row',
		marginBottom: '2mm',
	},
	headerContainer: {
		width: '100%',
		textAlign: 'center',
		marginBottom: '5mm',
	},
	footerContainer: {
		width: '100%',
		textAlign: 'right',
	},
	siteName: {
		fontSize: 10,
		fontWeight: 900,
		textTransform: 'uppercase',
		marginBottom: '3mm',
	},
});
