import { makeDocumentsPresetsRepository } from './repositories';
import AddTypologyToDocumentPreset from '../../../../domain/interactors/documentsPresets/addTypologyToDocumentPreset';
import GetDocumentPresets from '../../../../domain/interactors/documentsPresets/getDocumentPresets';
import CreateDocumentPreset from '../../../../domain/interactors/documentsPresets/createDocumentPreset';
import RemoveTypologyFromDocumentPreset from '../../../../domain/interactors/documentsPresets/removeTypologyFromDocumentPreset';
import DeleteDocumentPreset from '../../../../domain/interactors/documentsPresets/deleteDocumentPreset';
import UpdateDocumentPreset from '../../../../domain/interactors/documentsPresets/updateDocumentPreset';
import { makeTagRepository } from '../tags/repositories';
import UpdateTypologyToDocumentPreset from '../../../../domain/interactors/documentsPresets/updateTypologyToDocumentPreset';

export const makeGetDocumentsPresets = () => {
	return new GetDocumentPresets(makeDocumentsPresetsRepository());
};

export const makeCreateDocumentPreset = () => {
	return new CreateDocumentPreset(makeDocumentsPresetsRepository());
};

export const makeUpdateDocumentPreset = () => {
	return new UpdateDocumentPreset(makeDocumentsPresetsRepository());
};

export const makeDeleteDocumentPreset = () => {
	return new DeleteDocumentPreset(makeDocumentsPresetsRepository());
};

export const makeAddTypologyToDocumentPreset = () => {
	return new AddTypologyToDocumentPreset(makeDocumentsPresetsRepository());
};

export const makeUpdateTypologyToDocumentPreset = () => {
	return new UpdateTypologyToDocumentPreset(makeDocumentsPresetsRepository());
};

export const makeRemoveTypologyFromDocumentPreset = () => {
	return new RemoveTypologyFromDocumentPreset(makeDocumentsPresetsRepository());
};
