import { injectable } from 'tsyringe';
import TagRepository from '../../domain/repositories/tagRepository';
import { Tag } from '../../mock/models/tag';
import { ApiService } from '../utilities/apiService';

@injectable()
class ServerTagRepository implements TagRepository {
	constructor(private apiService: ApiService) {}

	async getTagById(companyId: string, id: string): Promise<Tag | undefined> {
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/tags/${id}`);
		return response.json();
	}

	async getTags(companyId: string, supplierId?: string, search?: string): Promise<Tag[]> {
		const id = supplierId ? `${supplierId}` : `${companyId}`;
		const filter = search ? `?search=${search}` : '';
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${id}/tags${filter}`);
		const payload = await response.json();
		return payload.results ?? [];
	}

	async createTag(companyId: string, tag: Tag): Promise<Tag> {
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/tags`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				name: tag.name,
			}),
		});
		return response.json();
	}

	async updateTag(companyId: string, tag: Tag): Promise<Tag> {
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/tags/${tag.id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(tag),
		});
		return response.json();
	}

	async renameTag(companyId: string, tag: Tag): Promise<void> {
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/tags/${tag.id}`, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ name: tag.name }),
		});
		if (response.status === 409) {
			return Promise.reject(new Error('cannot rename tag'));
		}
		return Promise.resolve();
	}

	async deleteTag(companyId: string, id: string): Promise<Tag> {
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/tags/${id}`, {
			method: 'DELETE',
		});
		return response.json();
	}
}

export default ServerTagRepository;
