import { useState } from 'react';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import Typology from '../../../domain/entities/typology';
import { useAuth } from '../../providers/Auth0JWTProvider';
import { CompanyResourceType } from '../../../domain/entities/companyResourceType.enum';
import { PaginatedResults, SortMeta } from '../../../domain/entities/interfaces/paginatedResults';
import ImportInfo from '../../../domain/entities/importInfo';
import FileEntity from '../../../domain/entities/file';
import { GetResourcesFilter } from '../../../domain/repositories/workerRepository';
import { updateFilterWithDelete } from '../../../utils';
import { GetSitesFilter, LinkResourcesToSiteWarning } from '../../../domain/repositories/siteRepository';
import Site from '../../../domain/entities/site';

export interface ResourceListViewModel<T> {
	get: (companyId: string, search?: GetResourcesFilter, archived?: boolean, sort?: SortMeta, pageParam?: number) => Promise<PaginatedResults<T>>;
	delete: (companyId: string, resourceId: string) => Promise<void>;
	restore: (companyId: string, resourceId: string) => Promise<void>;
	create: (companyId: string, resource: T, photo?: File) => Promise<T>;
	getTypologies: (companyId: string) => Promise<Typology[]>;
	uploadResources: (companyId: string, file: FileEntity) => Promise<ImportInfo>;
	getResourceLinkableSites: (companyId: string, type: string, filters?: GetSitesFilter, sort?: SortMeta, pageParam?: number) => Promise<Site[]>;
	addResourcesToSites: (
		companyId: string,
		resourceIds: string[],
		siteIds: string[],
		selectAllSites?: boolean,
		selectAllResources?: boolean,
	) => Promise<LinkResourcesToSiteWarning>;
}

export interface CreateResourceArgs<T> {
	resource: T;
	image?: File;
}

const useResourceListViewModel = <T>(viewModel: ResourceListViewModel<T>, type: CompanyResourceType) => {
	const { companyId } = useAuth();
	const [search, setSearch] = useState<string>('');
	const [filter, setFilter] = useState<GetResourcesFilter>({});
	const [sort, setSort] = useState<SortMeta>();
	const [error, setError] = useState<string>();
	const [showArchived, setShowArchived] = useState<boolean>(false);
	const [sortSiteCollection, setSortSiteCollection] = useState<SortMeta>();
	const [filterSiteCollection, setFilterSiteCollection] = useState<GetSitesFilter>();
	const [resourcesCount, setResourcesCount] = useState<number>(0);
	const [addResourcesToSiteWarning, setAddResourcesToSiteWarning] = useState<LinkResourcesToSiteWarning | null>(null);

	const updateFilterResources = (column: string, value: string) => {
		updateFilterWithDelete(setFilter, column, value);
	};
	const getResourcesQuery = useInfiniteQuery(
		['resources', type, companyId, search, filter, sort, showArchived],
		async ({ pageParam = 1 }) => {
			const { results, count } = await viewModel.get(
				companyId,
				search
					? {
							...filter,
							search: search,
						}
					: filter,
				showArchived,
				sort,
				pageParam,
			);
			setResourcesCount(count);
			return results;
		},
		{
			getNextPageParam: (lastPage, pages) => {
				if (lastPage?.length === 25) {
					return pages.length + 1;
				}
			},
		},
	);

	const getTypologiesQuery = useQuery(['typologies', companyId, type], async () => await viewModel.getTypologies(companyId), {
		initialData: [],
	});

	const deleteMutation = useMutation((resourceId: string) => viewModel.delete(companyId, resourceId), {
		onSuccess: () => getResourcesQuery.refetch(),
		onError: (e) => console.error(e),
	});

	const restoreMutation = useMutation((resourceId: string) => viewModel.restore(companyId, resourceId), {
		onSuccess: () => getResourcesQuery.refetch(),
		onError: (e) => console.error(e),
	});

	const createMutation = useMutation(
		async ({ resource, image }: CreateResourceArgs<T>) => {
			return await viewModel.create(companyId, resource, image);
		},
		{
			onSuccess: () => getResourcesQuery.refetch(),
			onError: (e) => console.error(e),
		},
	);
	const uploadResourcesMutation = useMutation(
		(file: FileEntity) => {
			return viewModel.uploadResources(companyId, file);
		},
		{
			onSuccess: () => {
				getResourcesQuery.refetch();
			},
			onError: (err: Error) => {
				console.log(err?.message);
			},
		},
	);

	const uploadResources = async (file: FileEntity) => {
		try {
			return await uploadResourcesMutation.mutateAsync(file);
		} catch (err) {
			setError(err.message);
		}
	};

	const getResourceLinkableSites = useInfiniteQuery(
		['site-collection', companyId, sortSiteCollection, filterSiteCollection],
		async ({ pageParam = 1 }) => {
			return await viewModel.getResourceLinkableSites(companyId, type, filterSiteCollection, sortSiteCollection, pageParam);
		},
		{
			getNextPageParam: (lastPage, pages) => {
				if (lastPage?.length === 25) {
					return pages.length + 1;
				}
			},
		},
	);
	const addResourcesToSitesMutation = useMutation(
		async ({
			resourceIds,
			siteIds,
			selectAllSites,
			selectAllResources,
		}: {
			resourceIds: string[];
			siteIds: string[];
			selectAllSites?: boolean;
			selectAllResources?: boolean;
		}) => {
			const response = await viewModel.addResourcesToSites(companyId, resourceIds, siteIds, selectAllSites, selectAllResources);
			setAddResourcesToSiteWarning(response);
			return response;
		},
		{
			onSuccess: () => {
				getResourcesQuery.refetch();
			},
		},
	);

	const resources = getResourcesQuery.data?.pages?.flat() ?? [];

	return {
		resources: resources,
		resourcesCount: resourcesCount,
		resourcesHasNextPage: getResourcesQuery.hasNextPage,
		resourcesFetchNextPage: getResourcesQuery.fetchNextPage,
		isLoading: getResourcesQuery.isLoading,
		isFetching: getResourcesQuery.isFetching,
		filter,
		setFilter,
		sort,
		setSort,
		search,
		setSearch,
		deleteResource: deleteMutation.mutateAsync,
		deleteResourcesLoading: deleteMutation.isLoading,
		createResource: createMutation.mutateAsync,
		createResourceLoading: createMutation.isLoading,
		restoreResource: restoreMutation.mutate,
		uploadResources,
		uploadResourcesError: error,
		typologies: getTypologiesQuery.data,
		typologiesIsFetching: getTypologiesQuery.isLoading,
		showArchived,
		setShowArchived,
		updateFilterResources,
		siteCollection: getResourceLinkableSites.data?.pages?.flat() ?? [],
		sortSiteCollection,
		setSortSiteCollection,
		filterSiteCollection,
		updateFilterSiteCollection: (field, value) => {
			updateFilterWithDelete(setFilterSiteCollection, field, value);
		},
		siteCollectionHasNextPage: getResourceLinkableSites.hasNextPage,
		siteCollectionFetchNextPage: getResourceLinkableSites.fetchNextPage,
		refetchSiteCollection: getResourceLinkableSites.refetch,
		addResorcesToSites: addResourcesToSitesMutation.mutateAsync,
		addResourcesToSiteWarning,
		setAddResourcesToSiteWarning,
	};
};

export { useResourceListViewModel };
