import {
  Badge,
  Box,
  Flex,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
} from "@chakra-ui/react";
import { FaRegCommentAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { MdSend } from "react-icons/md";
import { COLORS } from "../../assets/theme/colors";
import { darken } from "polished";
import DocumentComment from "../../../domain/entities/documentComment";
import CommentMessage from "./CommentMessage";
import { MentionsInput, Mention } from "react-mentions";
import Style from "../../../presentation/components/Views/common/mentionsComment.module.css";
import { UserMini } from "../../../domain/entities/user";

type Props = {
  onClick: (comment: string) => void;
  comments: DocumentComment[];
  isLoading: boolean;
  color?: string;
  fontSize?: string;
  isDisabled?: boolean;
  showShadow?: boolean;
  taggableUsers: UserMini[];
};

const CommentButton = ({
  onClick,
  comments,
  isLoading,
  color = "#767676",
  fontSize = "18px",
  isDisabled,
  showShadow,
  taggableUsers,
}: Props) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState("");
  const [focus, setFocus] = useState(false);
  const handleButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    onClick(comment);
    setComment("");
  };

  return (
    <Popover>
      <Tooltip
        label={t("addComment", { ns: "communication" })}
        aria-label="Preview"
      >
        <span>
          <PopoverTrigger>
            <IconButton
              aria-label="comment"
              variant="ghost"
              sx={{ color: "#767676" }}
              fill={color}
              minWidth="22px"
              paddingLeft={showShadow ? 2 : 0}
              paddingRight={showShadow ? 2 : 0}
              borderRadius={25}
              isDisabled={isDisabled}
              _hover={
                showShadow
                  ? {
                      paddingLeft: "2",
                      paddingRight: "2",
                      borderRadius: "25",
                      boxShadow: "0 0 12px 4px rgba(0, 0, 0, 0.2)",
                    }
                  : undefined
              }
            >
              <div style={{ position: "relative" }}>
                {comments.length > 0 && (
                  <Badge
                    bg="rgba(50, 162, 219, .8)"
                    color="white"
                    position="relative"
                    top="4px"
                    right="-5px"
                  >
                    {comments.length}
                  </Badge>
                )}
                <Icon
                  as={FaRegCommentAlt}
                  fontSize={fontSize}
                  marginTop={1.5}
                />
              </div>
            </IconButton>
          </PopoverTrigger>
        </span>
      </Tooltip>

      <PopoverContent>
        <PopoverArrow backgroundColor={"lightblue"} />
        <PopoverCloseButton />
        <PopoverBody backgroundColor={"lightblue"} borderRadius={6}>
          <Box
            sx={{ minHeight: "24px", maxHeight: "150px", overflowY: "auto" }}
          >
            {comments.map((comment, i) => (
              <CommentMessage
                key={comment.id}
                message={comment.comment}
                date={comment.createdAt}
                author={comment.author.name}
                fontSize={14}
                my={0}
                subTextProps={{
                  fontSize: 11,
                  lineHeight: 1,
                  my: 0,
                  mt: 0,
                  pb: 2,
                  mb: 2,
                  borderBottom:
                    i !== comments.length - 1 && "1px solid lightgray",
                }}
              />
            ))}
          </Box>
          <Flex
            sx={{
              alignItems: "start",
              border: "1px solid",
              borderRadius: "6",
              gap: "5px",
              padding: "10px",
              borderColor: focus ? "#3182ce" : "lightgray",
              boxShadow: focus ? "0 0 0 1px #3182ce" : "none",
              backgroundColor: "white",
            }}
          >
            <MentionsInput
              value={comment}
              onChange={(event) => {
                setComment(event.target.value);
              }}
              className="mentions"
              classNames={Style}
              placeholder={t("addComment", { ns: "communication" })}
              allowSuggestionsAboveCursor
              forceSuggestionsAboveCursor
            >
              <Mention
                data={
                  taggableUsers?.map(({ id, name }) => ({
                    id,
                    display: name,
                  })) ?? []
                }
                className={Style.mentions__mention}
              />
            </MentionsInput>
            <div>
              <IconButton
                isDisabled={comment === "" || isLoading}
                borderRadius="50%"
                aria-label="Circular Button"
                icon={<MdSend />}
                alignSelf={"center"}
                backgroundColor={COLORS.sikuroBlue}
                color="white"
                _hover={{ backgroundColor: darken(0.1, COLORS.sikuroBlue) }}
                onClick={handleButtonClick}
                sx={{ height: "24px", minWidth: "24px", fontSize: "12px" }}
              />
            </div>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default CommentButton;
