import { NewResource, SiteResourcesViewModel } from '../../hooks/Site/useSiteResourcesViewModel';
import { makeCreateSiteTool, makeGetSiteTools, makeDeleteSiteTool, makeRestoreSiteTool } from '../../../core/app/factory/siteTools/usecases';
import { makeGetAvailableToolsCount, makeGetTools } from '../../../core/app/factory/tools/usecases';
import { makeGetSiteVariants } from '../../../core/app/factory/variants/usecases';
import { DocumentTypeResourceType } from '../../../mock/models/document-type';
import { makeGetSiteSpecializations } from '../../../core/app/factory/specializations/usecases';
import { SiteTool, SiteToolParams } from '../../../domain/entities/siteTool';
import { GetSiteToolsFilters } from '../../../domain/repositories/siteToolRepository';
import { PaginatedResults, SortMeta } from '../../../domain/entities/interfaces/paginatedResults';
import { GetToolsFilter } from '../../../domain/repositories/toolRepository';

class SiteToolsViewModel implements SiteResourcesViewModel<SiteToolParams> {
	async getSiteResources(
		companyId: string,
		siteId: string,
		page: number,
		archived?: boolean,
		filter?: GetSiteToolsFilters,
		sort?: SortMeta,
		supplierId?: string,
	): Promise<PaginatedResults<SiteTool>> {
		return await makeGetSiteTools().Call({ companyId, siteId, page, perPage: 25, filter, sort, supplierId, archived });
	}

	async getOwnedResources(
		companyId: string,
		siteId: string,
		filter?: GetToolsFilter,
		sort?: SortMeta,
		pageParam?: number,
	): Promise<PaginatedResults<SiteToolParams>> {
		return (await makeGetTools().Call({ companyId, filter: { siteAvailable: siteId, ...filter }, sort, pageParam })) as unknown as Promise<
			PaginatedResults<SiteToolParams>
		>;
	}

	async getAvailableResourcesTotalCount(companyId: string, siteId: string, filter?: GetToolsFilter, sort?: SortMeta): Promise<number> {
		return (await makeGetAvailableToolsCount().Call({
			companyId,
			filter: { siteAvailable: siteId, ...filter },
			sort,
		})) as unknown as Promise<number>;
	}

	async getVariants(companyId: string, siteId: string): Promise<any[]> {
		return await makeGetSiteVariants().Call({ companyId, siteId, type: DocumentTypeResourceType.TOOL });
	}

	async getSpecializations(companyId: string, siteId: string): Promise<any[]> {
		return await makeGetSiteSpecializations().Call({ companyId, siteId, type: DocumentTypeResourceType.TOOL });
	}

	/*async addVariantToSiteResource(companyId: string, toolId: string, siteId: string, variantName: string): Promise<void> {
    await makeAddVariantToSiteTool().Call({companyId, toolId, siteId, variantName})
  }

  async removeVariantFromSiteResource(companyId: string, toolId: string, siteId: string, variantId: string): Promise<void> {
    await makeRemoveVariantFromSiteTool().Call({companyId, toolId, siteId, variantId})
  }

  async addSpecializationToSiteResource(companyId: string, toolId: string, siteId: string, specializationName: string): Promise<void> {
    await makeAddSpecializationToSiteTool().Call({companyId, toolId, siteId, specializationName})
  }

  async removeSpecializationFromSiteResource(companyId: string, toolId: string, siteId: string, specializationId: string): Promise<void> {
    await makeRemoveSpecializationFromSiteTool().Call({companyId, toolId, siteId, specializationId})
  }*/

	async createSiteResource(
		companyId: string,
		siteId: string,
		resources: NewResource[],
		selectAll: boolean,
		selectedResourceNumber: number,
		selectAllVariants: string,
		selectAllSpecializations: string[],
		filterSiteResources?: Record<string, string | string[]>,
	): Promise<boolean> {
		return await makeCreateSiteTool().Call({
			companyId,
			siteId,
			resources,
			selectAll,
			selectedResourceNumber,
			selectAllVariants,
			selectAllSpecializations,
			filterSiteResources,
		});
	}

	async deleteSiteResource(companyId: string, toolId: string, siteId: string): Promise<void> {
		await makeDeleteSiteTool().Call({ companyId, toolId, siteId });
	}

	async restoreSiteResource(companyId: string, toolId: string, siteId: string): Promise<void> {
		await makeRestoreSiteTool().Call({ companyId, toolId, siteId });
	}
}

export default SiteToolsViewModel;
