import TagList from "../../screens/Common/TagList";
import { Named } from "../../../domain/entities/interfaces/named";
import { Identifiable } from "../../../domain/entities/interfaces/identifiable";

type Props = {
  elements: (Identifiable & Named)[];
  onRemove: (element: Identifiable & Named) => void;
};
const SelectedTagList = ({ elements, onRemove }: Props) => {
  const selected = elements.map((c) => ({
    id: c.id,
    name: c.name,
    color: "#39b2ff",
  }));
  return (
    <TagList
      marginTop={8}
      // tags={selected.sort((a, b) => a.name.localeCompare(b.name))}
      tags={selected}
      onDelete={onRemove}
    />
  );
};

export default SelectedTagList;
