import UseCase from '../../../../core/app/usecase';
import Requirement from '../../../entities/requirement';
import SiteMachineRepository from '../../../repositories/siteMachineRepository';

type ARGS = {
	companyId: string;
	siteId: string;
	machineId: string;
	documentTypeId: string;
	isOptional: boolean;
	graceDays: number;
};

class AddRequirementToSiteMachine implements UseCase<ARGS, Requirement> {
	private siteRepository: SiteMachineRepository;

	constructor(siteRepository: SiteMachineRepository) {
		this.siteRepository = siteRepository;
	}

	async Call(parameters: ARGS): Promise<Requirement> {
		return await this.siteRepository.addRequirementToSiteMachine(
			parameters.companyId,
			parameters.siteId,
			parameters.machineId,
			parameters.documentTypeId,
			parameters.isOptional,
			parameters.graceDays,
		);
	}
}

export default AddRequirementToSiteMachine;
