import { useMemo, useState } from 'react';
import { useAuth } from '../../providers/Auth0JWTProvider';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import Variant from '../../../domain/entities/variant';
import Specialization from '../../../domain/entities/specialization';
import { SiteResource } from '../../../domain/entities/siteResource';
import { SiteResourceDocument } from '../../../domain/entities/document';
import Evaluation from '../../../domain/entities/evaluation';
import { ResourceEvaluationState } from '../../../domain/entities/resourceEvaluationState.enum';
import { ResourceDocumentEvaluationState } from '../../../domain/entities/resourceDocumentEvaluationState.enum';
import { SortDirection, SortMeta } from '../../../domain/entities/interfaces/paginatedResults';
import useDocumentInfo, { DocumentInfoViewModel, UseDocumentInfo } from '../Document/useDocumentInfo';
import useAddDocumentType, { AddDocumentTypeViewModel, UseAddDocumentType } from '../Document/useAddDocumentType';
import { DocumentTypeCategory } from '../../../domain/entities/documentTypeCategory.enum';
import { GetDocumentEvaluationFilter } from '../../../domain/repositories/documentRepository';
import { SiteWorkerMeta } from '../../../domain/repositories/siteWorkerRepository';
import { GetResourceEvaluationFilter } from '../../../domain/repositories/siteRepository';
import { formatISODate } from '../../../infrastructure/utilities/filters';
import FileEntity from '../../../domain/entities/file';
import { RequirementSubject } from '../../../domain/entities/requirementSubject.enum';
import useDocumentCommunicationViewModel, { DocumentCommunicationHook } from '../Document/useDocumentCommunicationVIewModel';
import { GetWorkingSiteDocumentsFilter } from '../../../domain/repositories/filters';
import { getDateFormat, updateFilterWithDelete } from '../../../utils';
import { parse } from 'date-fns';
import useDocumentAI, { DocumentAIViewModel, UseDocumentAI } from '../Document/useDocumentAI';
import { AiTaskSectionCode } from '../../../domain/entities/aiTaskSectionCode';

export type Filter = { [key: string]: string | string[] | [Date, Date] };
export type UpdateFilter = (column: string, value: string | string[] | [Date, Date]) => void;
export type Sort = { [key: string]: SortDirection };
export type UpdateSort = (column: string, value: SortDirection) => void;

export interface SiteResourceDetailViewModel<Resource> extends DocumentInfoViewModel, DocumentAIViewModel, AddDocumentTypeViewModel {
	getSiteResource: (companyId: string, siteId: string, resourceId: string) => Promise<SiteResource<Resource>>;
	getSiteResourceDocuments: (
		companyId: string,
		siteId: string,
		resourceId: string,
		filter: GetWorkingSiteDocumentsFilter,
		sortDocuments: SortMeta,
		pageParam: number,
	) => Promise<SiteResourceDocument[]>;
	getVariants: (companyId: string, siteId: string) => Promise<Variant[]>;
	getSpecializations: (companyId: string, siteId: string) => Promise<Specialization[]>;
	updateSiteResourceVariant: (companyId: string, siteId: string, resourceId: string, variantId?: string) => Promise<void>;
	updateSiteResourceSpecializations: (companyId: string, siteId: string, resourceId: string, specializationIds?: string[]) => Promise<void>;
	evaluateSiteResource?: (companyId: string, siteId: string, resourceId: string, value: ResourceEvaluationState) => Promise<void>;
	autoEvaluateSiteResource?: (companyId: string, siteId: string, resourceId: string) => Promise<void>;
	evaluateDocument?: (
		companyId: string,
		siteId: string,
		documentId: string,
		result: ResourceDocumentEvaluationState,
		expiresAt?: Date,
		noEvaluationExpiration?: boolean,
		target?: string,
		resourceId?: string,
	) => Promise<void>;
	getSiteResourceEvaluations: (
		companyId: string,
		siteId: string,
		resourceId: string,
		pageParam: number,
		sort?: SortMeta,
		filter?: GetResourceEvaluationFilter,
	) => Promise<Evaluation<ResourceEvaluationState>[]>;
	addRequirementToSiteResource?: (
		companyId: string,
		resourceId: string,
		siteId: string,
		documentTypeId: string,
		isOptional: boolean,
		graceDays: number,
	) => Promise<void>;
	removeRequirementFromSiteResource?: (
		companyId: string,
		resourceId: string,
		siteId: string,
		requirementId: string,
		subject: RequirementSubject,
	) => Promise<void>;
	getDocumentEvaluations: (
		companyId: string,
		siteId: string,
		resourceType: string,
		resourceId: string,
		documentId: string,
		filter?: GetDocumentEvaluationFilter,
		sort?: SortMeta,
	) => Promise<Evaluation<ResourceDocumentEvaluationState>[]>;
	deleteSiteResource?: (companyId: string, siteId: string, resourceId: string) => Promise<void>;
	updateSiteWorkerMeta?: (companyId: string, siteId: string, workerId: string, meta: SiteWorkerMeta) => Promise<void>;

	updateFile(
		companyId: string,
		documentId: string,
		fileId: string,
		updatedFiles: Partial<FileEntity>[],
		siteIds?: string[],
		type?: string,
		resourceId?: string,
	): Promise<void>;
}

export type EvaluateDocumentParams = {
	documentId: string;
	result: ResourceDocumentEvaluationState;
	validity?: string;
	noEvaluationExpiration?: boolean;
};

export type useSiteResourceDetailViewModel<Resource> = {
	siteResource: SiteResource<Resource>;
	isLoadingSiteResource: boolean;
	siteResourceDocuments: SiteResourceDocument[];
	siteResourceDocumentsRefetch;
	siteResourcesHasNextPage;
	siteResourcesFetchNextPage;
	siteResourceDocumentsIsLoading: boolean;
	siteResourceDocumentsIsFetching: boolean;
	siteResourceEvaluations: Evaluation<ResourceEvaluationState>[];
	documentEvaluations: Evaluation<ResourceDocumentEvaluationState>[];
	documentEvaluationsFetching: boolean;
	updateSiteResourceVariant: (variantId?: string) => void;
	updateSiteResourceSpecializations: (specializationsIds?: string[]) => void;
	sortDocuments: SortMeta;
	updateSortDocuments: (sort: SortMeta) => void;
	filterDocuments: Filter;
	updateFilterDocuments: UpdateFilter;
	variants: Variant[];
	specializations: Specialization[];

	getSiteResourceEvaluations: () => void;
	getSiteResourceEvaluationsIsLoading: boolean;
	filterResourceEvaluations: GetDocumentEvaluationFilter;
	updateFilterResourceEvaluations: UpdateFilter;
	sortResourceEvaluations: Sort;
	updateSortResourceEvaluations: UpdateSort;

	setSiteResourceHookDocumentId: (documentId: string) => void;
	addRequirementsToSiteResource: (requirements: { documentTypeId: string; isOptional: boolean }[]) => void;
	removeRequirementFromSiteResource: (requirementId: string, subject: RequirementSubject) => void;
	evaluateSiteResource: (result: ResourceEvaluationState) => void;
	autoEvaluateSiteResource: () => void;
	evaluateDocument: (props: EvaluateDocumentParams) => void;
	evaluateDocumentIsLoading: boolean;

	updateFilterDocumentEvaluations: UpdateFilter;
	filterDocumentEvaluations: Filter;
	sortDocumentEvaluations: Sort;
	updateSortDocumentEvaluations: UpdateSort;
	deleteSiteResource: () => Promise<void>;
	documentTypesProps: UseAddDocumentType;
	documentInfoProps: UseDocumentInfo;
	updateSiteWorkerHasSafetyInduction?: (hasSafetyInduction: boolean) => void;
	updateFile(documentId: string, fileId: string, updatedFiles: Partial<FileEntity>[], siteIds?: string[]): Promise<void>;
	communicationProps: DocumentCommunicationHook;
	documentAIProps: UseDocumentAI;
};

const updateFilter = (setter) => (column: string, value: string | string[]) => {
	updateFilterWithDelete(setter, column, value);
};

const updateSort = (setter) => (column: string, value: SortDirection) => setter({ [column]: value });

function createUseSiteResourceDetailViewModel<Resource>(viewModelFactory: () => SiteResourceDetailViewModel<Resource>) {
	return function useSiteResourceDetailViewModel(
		siteId: string,
		resourceId: string,
		resourceColumns: string[],
		type: string,
		aiSectionCode?: AiTaskSectionCode
	): useSiteResourceDetailViewModel<Resource> {
		const { companyId } = useAuth();
		const viewModel = viewModelFactory();
		const [documentId, setDocumentId] = useState<string>(null);
		const [filterDocuments, setFilterDocuments] = useState({});
		const [filterDocumentEvaluations, setFilterDocumentEvaluations] = useState({});
		const [sortDocuments, setSortDocuments] = useState<SortMeta>();
		const [sortDocumentEvaluations, setSortDocumentEvaluations] = useState({});
		const [filterResourceEvaluations, setFilterResourceEvaluations] = useState({});
		const [sortResourceEvaluations, setSortResourceEvaluations] = useState({});
		const communicationProps = useDocumentCommunicationViewModel(resourceId, type as DocumentTypeCategory, siteId);

		const documentTypesProps = useAddDocumentType(viewModel, type as DocumentTypeCategory);

		const siteResourceQuery = useQuery(['site-resource', companyId, siteId, resourceId], () => {
			return viewModel.getSiteResource(companyId, siteId, resourceId);
		});

		const siteResourceDocumentsQuery = useInfiniteQuery(
			['site-resource-documents', companyId, siteId, resourceId, filterDocuments, sortDocuments],
			async ({ pageParam = 1 }) => {
				return await viewModel.getSiteResourceDocuments(companyId, siteId, resourceId, filterDocuments, sortDocuments, pageParam);
			},
			{
				getNextPageParam: (lastPage, pages) => {
					if (lastPage?.length === 25) {
						return pages.length + 1;
					}
				}
			},
		);

		const documentInfoProps = useDocumentInfo(viewModel, siteId, type, resourceId);
		const documentAIProps = useDocumentAI(viewModel, siteId, resourceId, type as DocumentTypeCategory, aiSectionCode);

		const siteResourceEvaluationsQuery = useInfiniteQuery(
			[
				'site-resource-evaluations',
				companyId,
				siteId,
				resourceId,
				JSON.stringify(sortResourceEvaluations),
				JSON.stringify(filterResourceEvaluations),
			],
			async ({ pageParam = 1 }) => {
				let sort: SortMeta = { field: 'createdAt', direction: SortDirection.Descending };
				const filter: GetResourceEvaluationFilter = {};

				const sortKeys = Object.keys(sortResourceEvaluations);
				if (sortKeys.length > 0) {
					sort = {
						field: sortKeys[0],
						direction: sortResourceEvaluations[sortKeys[0]],
					};

					if (sort.field == 'createdAt') {
						sort.field = 'actionDate';
					}
				}
				const filterKeys = Object.keys(filterResourceEvaluations);
				filterKeys.forEach((k) => {
					if (k == 'createdAt') {
						const fromDate = formatISODate(filterResourceEvaluations[k][0]);
						if (fromDate) {
							filter.fromDate = fromDate;
						}

						const toDate = formatISODate(filterResourceEvaluations[k][1]);
						if (toDate) {
							filter.toDate = toDate;
						}
					}

					const v = filterResourceEvaluations[k];
					if (v && v !== '') {
						filter[k] = filterResourceEvaluations[k];
					}
				});

				delete filter['createdAt'];

				return viewModel.getSiteResourceEvaluations(companyId, siteId, resourceId, pageParam, sort, filter);
			},
			{
				getNextPageParam: (lastPage, pages) => {
					if (lastPage?.length === 25) {
						return pages.length + 1;
					}
				},
			},
		);

		const variantsQuery = useQuery(['variants', companyId, siteId, type], () => viewModel.getVariants(companyId, siteId), {
			initialData: [],
		});

		const specializationsQuery = useQuery(['specializations', companyId, siteId, type], () => viewModel.getSpecializations(companyId, siteId), {
			initialData: [],
		});

		const getDocumentEvaluationsQuery = useQuery(
			['document-evaluations', companyId, siteId, resourceId, documentId, filterDocumentEvaluations, sortDocumentEvaluations],
			() => {
				return documentId
					? viewModel.getDocumentEvaluations(
						companyId,
						siteId,
						type,
						resourceId,
						documentId,
						filterDocumentEvaluations,
						Object.keys(sortDocumentEvaluations).length > 0
							? {
								field: Object.keys(sortDocumentEvaluations)[0],
								direction: Object.values(sortDocumentEvaluations)[0] as SortDirection,
							}
							: undefined,
					)
					: [];
			},
		);

		const deleteSiteResourceMutation = useMutation(
			['delete-site-resource', companyId, siteId, resourceId],
			async () => await viewModel.deleteSiteResource(companyId, resourceId, siteId),
		);

		const updateSiteResourceVariantMutation = useMutation(
			async (tagId?: string) => await viewModel.updateSiteResourceVariant(companyId, resourceId, siteId, tagId),
			{
				onSuccess: async () => {
					await siteResourceQuery.refetch();
					await siteResourceDocumentsQuery.refetch();
					communicationProps.refetchTaggableDocuments();
				},
			},
		);

		const updateSiteResourceSpecializationsMutation = useMutation(
			async (tagIds?: string[]) => await viewModel.updateSiteResourceSpecializations(companyId, resourceId, siteId, tagIds),
			{
				onSuccess: async () => {
					await siteResourceQuery.refetch();
					await siteResourceDocumentsQuery.refetch();
					communicationProps.refetchTaggableDocuments();
				},
			},
		);

		const createRequirementMutation = useMutation(
			['create-requirement', companyId, siteId, resourceId],
			(params: { documentTypeId: string; isOptional: boolean; graceDays: number }) => {
				return viewModel.addRequirementToSiteResource(companyId, resourceId, siteId, params.documentTypeId, params.isOptional, params.graceDays);
			},
			{
				onSuccess: () => {
					siteResourceDocumentsQuery.refetch(), siteResourceQuery.refetch();
					communicationProps.refetchTaggableDocuments();
				},
			},
		);

		const deleteRequirementMutation = useMutation(
			['delete-requirement', companyId, siteId, resourceId],
			(params: { requirementId: string; subject: RequirementSubject }) => {
				return viewModel.removeRequirementFromSiteResource(companyId, resourceId, siteId, params.requirementId, params.subject);
			},
			{
				onSuccess: () => {
					siteResourceDocumentsQuery.refetch(), siteResourceQuery.refetch();
					communicationProps.refetchTaggableDocuments();
				},
			},
		);

		const evaluateSiteResourceMutation = useMutation(
			['evaluate-site-resource', companyId, siteId, resourceId],
			(result: string) => viewModel.evaluateSiteResource(companyId, siteId, resourceId, result as ResourceEvaluationState),
			{
				onSuccess: () => {
					siteResourceQuery.refetch();
				},
			},
		);

		const autoEvaluateSiteResourceMutation = useMutation(
			['auto-evaluate-site-resource', companyId, siteId, resourceId],
			() => viewModel.autoEvaluateSiteResource(companyId, siteId, resourceId),
			{
				onSuccess: () => {
					siteResourceQuery.refetch();
				},
			},
		);

		const evaluateDocumentMutation = useMutation(
			['evaluate-site-resource', companyId, siteId, resourceId],
			({ result, validity, documentId, noEvaluationExpiration }: EvaluateDocumentParams) => {
				const date = validity !== '' && validity !== null ? parse(validity, getDateFormat(), new Date()) : null;

				return viewModel.evaluateDocument(
					companyId,
					siteId,
					documentId,
					result,
					result === 'available' ? date : null,
					noEvaluationExpiration,
					type,
					resourceId,
				);
			},
			{
				onSuccess: () => {
					siteResourceDocumentsQuery.refetch();
					siteResourceQuery.refetch();
				},
			},
		);

		const updateSiteWorkerHasSafetyInduction = useMutation(
			['update-site-worker-has-safety-induction', companyId, siteId, resourceId],
			(hasSafetyInduction: boolean) =>
				viewModel.updateSiteWorkerMeta &&
				viewModel.updateSiteWorkerMeta(companyId, siteId, resourceId, {
					hasSafetyInduction,
				}),
			{
				onSuccess: () => siteResourceQuery.refetch(),
			},
		);

		const updateFileMutation = useMutation(
			(args: { documentId: string; fileId: string; updatedFiles: Partial<FileEntity>[]; siteIds?: string[] }) =>
				viewModel.updateFile(companyId, args.documentId, args.fileId, args.updatedFiles, args.siteIds, type, resourceId),
			{
				onSuccess: () => siteResourceDocumentsQuery.refetch(),
				onError: (err) => console.error(err),
			},
		);

		const variants = useMemo(() => variantsQuery.data, [variantsQuery.data]);

		const specializations = useMemo(() => specializationsQuery.data, [specializationsQuery.data]);

		const addRequirementsToSiteResource = async (
			requirements: {
				documentTypeId: string;
				isOptional: boolean;
				graceDays: number;
			}[],
		) => {
			await Promise.all(
				requirements.map(async (inputRequirement) => {
					await createRequirementMutation.mutateAsync({
						documentTypeId: inputRequirement['id'],
						isOptional: inputRequirement.isOptional,
						graceDays: inputRequirement.graceDays,
					});
				}),
			);
		};

		const removeRequirementFromSiteResource = async (requirementId: string, subject: RequirementSubject) => {
			await deleteRequirementMutation.mutateAsync({ requirementId: requirementId, subject: subject });
		};

		const updateFile = async (documentId: string, fileId: string, updatedFiles: Partial<FileEntity>[], siteIds?: string[]) => {
			await updateFileMutation.mutateAsync({ documentId, fileId, updatedFiles, siteIds });
		};

		const siteResourceDocuments = siteResourceDocumentsQuery.data?.pages?.flat() ?? [];
		const siteResourceEvaluations = siteResourceEvaluationsQuery.data?.pages?.flat() ?? [];

		return {
			siteResource: siteResourceQuery.data,
			isLoadingSiteResource: siteResourceQuery.isLoading,
			variants,
			specializations,
			updateSiteResourceVariant: updateSiteResourceVariantMutation.mutate,
			updateSiteResourceSpecializations: updateSiteResourceSpecializationsMutation.mutate,
			siteResourceDocuments,
			siteResourceDocumentsRefetch: siteResourceDocumentsQuery.refetch,
			siteResourcesHasNextPage: siteResourceDocumentsQuery.hasNextPage,
			siteResourcesFetchNextPage: siteResourceDocumentsQuery.fetchNextPage,
			siteResourceDocumentsIsLoading: siteResourceDocumentsQuery.isLoading,
			siteResourceDocumentsIsFetching: siteResourceDocumentsQuery.isFetching,
			siteResourceEvaluations,
			getSiteResourceEvaluations: siteResourceEvaluationsQuery.refetch,
			getSiteResourceEvaluationsIsLoading: siteResourceEvaluationsQuery.isLoading,
			setSiteResourceHookDocumentId: setDocumentId,
			addRequirementsToSiteResource,
			removeRequirementFromSiteResource,
			documentEvaluations: getDocumentEvaluationsQuery.data ?? [],
			documentEvaluationsFetching: getDocumentEvaluationsQuery.isLoading,
			sortDocuments,
			filterDocuments,
			updateFile,
			evaluateSiteResource: evaluateSiteResourceMutation.mutateAsync,
			autoEvaluateSiteResource: autoEvaluateSiteResourceMutation.mutate,
			evaluateDocument: evaluateDocumentMutation.mutateAsync,
			evaluateDocumentIsLoading: evaluateDocumentMutation.isLoading,
			filterResourceEvaluations,
			sortResourceEvaluations,
			updateFilterResourceEvaluations: updateFilter(setFilterResourceEvaluations),
			updateFilterDocuments: updateFilter(setFilterDocuments),
			updateFilterDocumentEvaluations: updateFilter(setFilterDocumentEvaluations),
			updateSortDocumentEvaluations: updateSort(setSortDocumentEvaluations),
			updateSortDocuments: setSortDocuments,
			updateSortResourceEvaluations: updateSort(setSortResourceEvaluations),
			filterDocumentEvaluations,
			sortDocumentEvaluations,
			deleteSiteResource: deleteSiteResourceMutation.mutateAsync,
			documentInfoProps,
			documentTypesProps,
			updateSiteWorkerHasSafetyInduction: updateSiteWorkerHasSafetyInduction.mutate,
			communicationProps,
			documentAIProps
		};
	};
}

export { createUseSiteResourceDetailViewModel };
