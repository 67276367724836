import { makeGetDocumentTypes, makeGetDocumentTypesForAi } from '../../../core/app/factory/documentTypes/usecases';
import {
	makeAddFileToDocument,
	makeDeleteFileFromDocument,
	makeDocumentSites,
	makeGetDocumentFiles,
	makeGetDocumentFilesLog,
	makeGetDocumentLog,
	makeUpdateFile,
} from '../../../core/app/factory/documents/usecases';
import {
	makeAddRequirementToSupplierCompanyResource,
	makeAutoEvaluateCompanySupplierResource,
	makeCreateSupplierResourceDocuments,
	makeEvaluateCompanySupplierResource,
	makeEvaluateCompanySupplierResourceRequirement,
	makeGetAvailableBadgesUseCase,
	makeGetCompanySupplierResourceEvaluations,
	makeGetResourceBadge,
	makeGetSpecializationsForCompanySupplierRequirements,
	makeGetSupplierDocumentEvaluations,
	makeGetSupplierResourceDocuments,
	makeGetVariantsForCompanySupplierRequirements,
	makeLinkBadgesResource,
	makeUpdateCompanySupplierResourceSpecializations,
	makeUpdateCompanySupplierResourceVariant,
	makeUpdateSupplierResourceDocument,
} from '../../../core/app/factory/suppliers/useCases';
import { makeGetTags } from '../../../core/app/factory/tags/usecases';
import Document from '../../../domain/entities/document';
import { DocumentActionLogEntry, DocumentFileLogEntry } from '../../../domain/entities/documentLogEntry';
import DocumentType from '../../../domain/entities/documentType';
import { DocumentTypeCategory } from '../../../domain/entities/documentTypeCategory.enum';
import Evaluation from '../../../domain/entities/evaluation';
import { default as File, default as FileEntity } from '../../../domain/entities/file';
import { PaginatedResults, SortMeta } from '../../../domain/entities/interfaces/paginatedResults';
import { RequirementSubject } from '../../../domain/entities/requirementSubject.enum';
import { ResourceDocumentEvaluationState } from '../../../domain/entities/resourceDocumentEvaluationState.enum';
import { ResourceEvaluationState } from '../../../domain/entities/resourceEvaluationState.enum';
import Site, { DocumentSites } from '../../../domain/entities/site';
import Specialization from '../../../domain/entities/specialization';
import { SupplierResource } from '../../../domain/entities/supplierResource';
import Tag from '../../../domain/entities/tag';
import Variant from '../../../domain/entities/variant';
import { GetDocumentEvaluationFilter, GetDocumentFilesFilter, GetDocumentLogFilter } from '../../../domain/repositories/documentRepository';
import { GetResourceEvaluationFilter, GetSitesFilter } from '../../../domain/repositories/siteRepository';
import { AddDocumentTypeViewModel } from '../../hooks/Document/useAddDocumentType';
import { DocumentInfoViewModel } from '../../hooks/Document/useDocumentInfo';
import { Filter } from '../../hooks/Site/useSiteResourceDetailViewModel';
import { GetBadgesFilters } from '../../../domain/repositories/badgeRepository';
import { BadgeStatusSite } from '../../hooks/Badge/useBadgeDetailViewModel';
import Badge from '../../../domain/entities/badge';
import { makeGetBadgeSites, makeUnlinkBadgeResource } from '../../../core/app/factory/badges/usecases';

abstract class SupplierResourceViewModel<T> implements DocumentInfoViewModel, AddDocumentTypeViewModel {
	protected constructor(
		public companyId: string,
		public supplierId: string,
		public resourceId: string,
		public type: RequirementSubject,
	) { }

	abstract getSupplierResource(): Promise<SupplierResource<T>>;

	async getSupplierResourceDocuments(pageParam: number, sortDocuments: SortMeta, filterDocuments: Filter): Promise<Document[]> {
		return await makeGetSupplierResourceDocuments().Call({
			companyId: this.companyId,
			supplierId: this.supplierId,
			resourceId: this.resourceId,
			type: this.type,
			pageParam: pageParam,
			sortDocuments: sortDocuments,
			filterDocuments: filterDocuments,
		});
	}

	async getVariants(): Promise<Variant[]> {
		return await makeGetVariantsForCompanySupplierRequirements().Call({
			companyId: this.companyId,
			supplierId: this.supplierId,
			subject: this.type,
		});
	}

	async getSpecializations(): Promise<Specialization[]> {
		return await makeGetSpecializationsForCompanySupplierRequirements().Call({
			companyId: this.companyId,
			supplierId: this.supplierId,
			subject: this.type,
		});
	}

	async updateVariant(variantId: string): Promise<void> {
		await makeUpdateCompanySupplierResourceVariant().Call({
			companyId: this.companyId,
			supplierId: this.supplierId,
			resourceId: this.resourceId,
			variantId,
			type: this.type,
		});
	}

	async updateSpecializations(specializationIds: string[]): Promise<void> {
		await makeUpdateCompanySupplierResourceSpecializations().Call({
			companyId: this.companyId,
			supplierId: this.supplierId,
			resourceId: this.resourceId,
			specializationIds,
			type: this.type,
		});
	}

	async evaluateResource(value: ResourceEvaluationState): Promise<void> {
		await makeEvaluateCompanySupplierResource().Call({
			companyId: this.companyId,
			supplierId: this.supplierId,
			resourceId: this.resourceId,
			value,
			type: this.type,
		});
	}

	async autoEvaluateResource(): Promise<void> {
		await makeAutoEvaluateCompanySupplierResource().Call({
			companyId: this.companyId,
			supplierId: this.supplierId,
			resourceId: this.resourceId,
			type: this.type,
		});
	}

	async evaluateRequirement(
		requirementId: string,
		result: ResourceDocumentEvaluationState,
		expiresAt: Date,
		siteIds?: string[],
		selectAll?: boolean,
	): Promise<void> {
		await makeEvaluateCompanySupplierResourceRequirement().Call({
			companyId: this.companyId,
			supplierId: this.supplierId,
			resourceId: this.resourceId,
			requirementId,
			result,
			type: this.type,
			expiresAt,
			siteIds,
			selectAll,
		});
	}

	async getEvaluations(pageParam: number, sort?: SortMeta, filter?: GetResourceEvaluationFilter): Promise<Evaluation<ResourceEvaluationState>[]> {
		return await makeGetCompanySupplierResourceEvaluations().Call({
			companyId: this.companyId,
			supplierId: this.supplierId,
			resourceId: this.resourceId,
			type: this.type,
			pageParam,
			sort,
			filter,
		});
	}

	async getResourceBadge(
		companyId: string,
		resource: string,
		resourceId: string,
		sort: SortMeta,
		filter: GetBadgesFilters,
		pageParam: number,
		supplierId?: string,
	): Promise<PaginatedResults<Badge>> {
		return await makeGetResourceBadge().Call({ companyId, resource: this.type, resourceId, sort, filter, pageParam, supplierId });
	}
	async getBadgeSites(companyId: string, badgeId: string): Promise<BadgeStatusSite[]> {
		return await makeGetBadgeSites().Call({ companyId, badgeId });
	}

	async getAvailableBadges(companyId: string, resourceId: string, sort: SortMeta, filter: GetBadgesFilters, supplierId?: string): Promise<Badge[]> {
		return await makeGetAvailableBadgesUseCase().Call({ companyId, resource: this.type, resourceId, sort, filter, supplierId });
	}

	async linkBadgesToResource(companyId: string, badgeIds: string[], resourceId: string, supplierId?: string): Promise<void> {
		return await makeLinkBadgesResource().Call({ companyId, resourceType: this.type, resourceId, badgeIds, supplierId });
	}

	async unlinkBadgeResource(companyId: string, badgeId: string): Promise<void> {
		return await makeUnlinkBadgeResource().Call({ companyId, badgeId });
	}

	async addRequirement(documentTypeId: string, isOptional: boolean, graceDays: number): Promise<void> {
		await makeAddRequirementToSupplierCompanyResource().Call({
			companyId: this.companyId,
			supplierId: this.supplierId,
			resourceId: this.resourceId,
			documentTypeId,
			isOptional,
			graceDays,
			subject: this.type,
		});
	}

	async getDocumentEvaluations(
		documentId: string,
		resourceType?: string,
		resourceId?: string,
		filter?: GetDocumentEvaluationFilter,
		sort?: SortMeta,
	): Promise<Evaluation<ResourceDocumentEvaluationState>[]> {
		return await makeGetSupplierDocumentEvaluations().Call({
			companyId: this.companyId,
			supplierId: this.supplierId,
			documentId,
			resourceType,
			resourceId,
			filter,
			sort,
		});
	}

	async updateFile(
		companyId: string,
		documentId: string,
		fileId: string,
		updatedFiles: Partial<FileEntity>[],
		siteIds?: string[],
		target?: string,
		resourceId?: string,
		selectAll?: boolean,
	): Promise<void> {
		return await makeUpdateFile().Call({
			companyId,
			documentId,
			updatedFiles,
			fileId,
			siteIds,
			target,
			resourceId,
			selectAll,
		});
	}

	async getTags(companyId: string, supplierId?: string, search?: string): Promise<Tag[]> {
		return await makeGetTags().Call({ companyId, supplierId, search });
	}

	async getDocumentFiles(
		companyId: string,
		documentId: string,
		filter?: GetDocumentFilesFilter,
		sort?: SortMeta,
		siteId?: string,
		target?: string,
		resourceId?: string,
	): Promise<File[]> {
		return await makeGetDocumentFiles().Call({ companyId, documentId, filter, sort, siteId, target, resourceId });
	}

	async getDocumentFilesLogs(
		companyId: string,
		documentId: string,
		filter?: GetDocumentLogFilter,
		sort?: SortMeta,
		pageParam?: number,
	): Promise<DocumentActionLogEntry[]> {
		return await makeGetDocumentFilesLog().Call({ companyId, documentId, filter, sort, pageParam });
	}

	async deleteDocumentFile(
		companyId: string,
		documentId: string,
		fileId: string,
		siteId?: string,
		target?: string,
		resourceId?: string,
		siteIds?: string[],
		selectAll?: boolean,
	): Promise<void> {
		await makeDeleteFileFromDocument().Call({ companyId, documentId, fileId, siteId, target, resourceId, siteIds, selectAll });
	}

	async addFileToDocument(
		companyId: string,
		documentId: string,
		file: File,
		siteId?: string,
		target?: string,
		resourceId?: string,
		siteIds?: string[],
		toSupplier?: boolean,
	): Promise<void> {
		await makeAddFileToDocument().Call({ companyId, documentId, file, siteId, target, resourceId, toSupplier, siteIds });
	}

	async getDocumentLogs(
		companyId: string,
		documentId: string,
		filter?: GetDocumentLogFilter,
		sort?: SortMeta,
		resourceId?: string,
		siteId?: string,
		target?: string,
		pageParam?: number,
	): Promise<DocumentFileLogEntry[]> {
		return await makeGetDocumentLog().Call({ companyId, documentId, filter, sort, resourceId, siteId, target, pageParam });
	}

	async getDocumentTypes(companyId: string, search?: string, tags?: string[], sort?: SortMeta, pageParam?: number): Promise<DocumentType[]> {
		return await makeGetDocumentTypes().Call({
			companyId,
			filter: this.type as unknown as DocumentTypeCategory,
			includePublic: true,
			search,
			tags,
			sort,
			pageParam,
		});
	}

	async getDocumentTypesForAi(companyId: string, aiTaskId: string, resourceType: DocumentTypeCategory): Promise<DocumentType[]> {
		return await makeGetDocumentTypesForAi().Call({
			companyId,
			aiTaskId,
			resourceType
		});
	}

	async getDocumentSites(
		companyId: string,
		documentId: string,
		sort?: SortMeta,
		filter?: GetSitesFilter,
		pageParam?: number,
		includeWorkingSites?: boolean,
	): Promise<DocumentSites> {
		return await makeDocumentSites().Call({
			companyId,
			documentId,
			sort,
			filter,
			pageParam,
			includeWorkingSites,
		});
	}

	async createSupplierResourceDocument(
		isPublic: boolean,
		documentTypeId: string,
		siteIds: string[],
		target: string,
		resourceId: string,
		result?: ResourceDocumentEvaluationState,
		expiresAt?: Date,
		files?: FileEntity,
	): Promise<void> {
		await makeCreateSupplierResourceDocuments().Call({
			companyId: this.companyId,
			supplierId: this.supplierId,
			isPublic,
			documentTypeId,
			siteIds,
			result,
			expiresAt,
			files,
			target,
			resourceId,
		});
	}

	async updateSupplierResourceDocument(document: Document, siteIds: string[], target: string, resourceId: string): Promise<void> {
		await makeUpdateSupplierResourceDocument().Call({
			companyId: this.companyId,
			supplierId: this.supplierId,
			document,
			siteIds,
			target,
			resourceId,
		});
	}
}

export default SupplierResourceViewModel;
