import FileEntity from './domain/entities/file';
import Tag from './domain/entities/tag';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import { format, isValid } from 'date-fns';
import { string } from 'zod';

export function formatDate(date: string | Date, separator = '/', fullYear = false, calendarFormat = false) {
	const parsedDate = date instanceof Date ? date : new Date(date);

	const day = String(parsedDate.getDate()).padStart(2, '0');
	const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
	const year = String(parsedDate.getFullYear()).slice(fullYear ? 0 : 2);
	if (!calendarFormat) {
		return date instanceof Date ? [day, month, year].join(separator) : '-';
	} else {
		return date instanceof Date ? [year, month, day].join(separator) : '-';
	}
}

export function formatTime(date: string | Date, includeSeconds = false) {
	const parsedDate = date instanceof Date ? date : new Date(date);
	const hours = String(parsedDate.getHours()).padStart(2, '0');
	const minutes = String(parsedDate.getMinutes()).padStart(2, '0');

	if (!includeSeconds) {
		return [hours, minutes].join(':');
	}

	const seconds = String(parsedDate.getSeconds()).padStart(2, '0');
	return [hours, minutes, seconds].join(':');
}

export function formatDateWithGraceDays(date?: string | Date, graceDays?: number): string | undefined {
	let base: Date = undefined;

	if (typeof date === 'string') {
		base = new Date(date);
	} else if (date instanceof Date) {
		base = date;
	}

	if (graceDays && graceDays > 0) {
		base?.setDate(base?.getDate() + graceDays);
	}

	return base ? base.toLocaleDateString() : undefined;
}

export function formatDateTime(date: string | Date, dateSeparator = '/', fullYear = false, includeSeconds = false) {
	if (!date) {
		return;
	}

	const formattedDate = formatDate(new Date(date), dateSeparator, fullYear);
	const formattedTime = formatTime(date, includeSeconds);

	const temp = `${formattedDate} ${formattedTime}`;

	return temp;
}

export function formatStringToDate(date: string, showTime?: boolean) {
	if (date) {
		return DateTime.fromISO(date)
			.toUTC()
			.toLocal()
			.toFormat(showTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy')
			.toString();
	}
	return '';
}

export function dateToRFC3339(d: Date, toMidnight?: boolean) {
	let date = d;

	function pad(number: number) {
		if (number < 10) {
			return '0' + number;
		}
		return number;
	}

	if (typeof date === 'string') {
		date = new Date(date);
	}
	if (toMidnight) {
		date.setHours(23, 59, 59, 59);
	}

	const year = date.getUTCFullYear();
	const month = pad(date.getUTCMonth() + 1);
	const day = pad(date.getUTCDate());
	const hours = pad(date.getUTCHours());
	const minutes = pad(date.getUTCMinutes());
	const seconds = pad(date.getUTCSeconds());

	return year + '-' + month + '-' + day + 'T' + hours + ':' + minutes + ':' + seconds + 'Z';
}

export function getUniqueTags<T extends { [key: string]: any }>(items: T[], key = 'tags'): Tag[] {
	return items && Array.isArray(items)
		? items
				?.map((item) => item?.[key])
				.flat()
				.filter((tag, index, self) => self.findIndex((t) => t?.id === tag?.id) === index)
		: [];
}

export function removeEmptyAttributes(obj: object) {
	for (const key in obj) {
		if (obj[key] === null || obj[key] === undefined || obj[key] === '' || (Array.isArray(obj[key]) && obj[key].length === 0)) {
			delete obj[key];
		}
	}
	return obj;
}

export const getBadgeReaderPath = (companyId, siteId, badgeReaderId) =>
	`${process.env.REACT_APP_SERVER_API_ENDPOINT}/badgeReaders/${badgeReaderId}/access`;

// Procces file
export const processUploadedFiles = (files: FileList) => {
	const newFile: FileEntity = {
		id: uuidv4(),
		uri: files[0]?.webkitRelativePath,
		name: files[0]?.name,
		uploadDate: new Date(),
		binaries: files,
	};
	return newFile;
};

export const formatDateForInputHTML = (date: Date | string) => {
	const d = typeof date === 'string' ? new Date(date) : date;
	const year = d?.getFullYear();
	const month = ('0' + (d?.getMonth() + 1)).slice(-2);
	const day = ('0' + d?.getDate()).slice(-2);
	return `${year}-${month}-${day}`;
};

export function removeEmptyProperties(obj: object) {
	const newObj = {};
	for (const key in obj) {
		if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
			continue;
		}
		if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
			const nestedObj = removeEmptyProperties(obj[key]);
			if (Object.keys(nestedObj).length !== 0) {
				newObj[key] = nestedObj;
			}
		} else {
			newObj[key] = obj[key];
		}
	}
	return newObj;
}

/**
 *
 * @param setFilterFunction is the function that will upate filter's state
 * @param field is the name of the property that will be filtered
 * @param value is the value of the property that will be filtered
 */
export function updateFilterWithDelete(setFilterFunction: (prev: any) => void, field: string, value: string | string[] | [Date, Date] | undefined) {
	setFilterFunction((prev) => {
		// Create a copy of the previous state
		const nextState = { ...prev };

		// If the value is undefined, empty, or an empty array, remove the field
		if (value === undefined || (Array.isArray(value) && value.length === 0) || value === '') {
			delete nextState[field];
		} else {
			// Otherwise, update the field with the new value
			nextState[field] = value;
		}
		return nextState;
	});
}

export const isValidDate = (dateString) => {
	if (!dateString) {
		return false;
	}
	return isValid(new Date(dateString));
};

export const getLocale = () => {
	switch (localStorage.getItem('i18nextLng')) {
		case 'it':
			return 'it';
		case 'en':
			return 'en';
		default:
			return 'en';
	}
};

export const getDateFormat = () => {
	switch (localStorage.getItem('i18nextLng')) {
		case 'it':
		case 'fr':
		case 'es':
			return 'dd/MM/yyyy';
		case 'en':
			return 'MM/dd/yyyy';
		default:
			return 'MM/dd/yyyy';
	}
};

export const getPlaceholder = () => {
	switch (localStorage.getItem('i18nextLng')) {
		case 'it':
			return 'gg/mm/aaaa';
		case 'en':
			return 'mm/gg/yyyy';
		default:
			return 'mm/dd/yyyy';
	}
};

export function formatDateBasedOnLanguage(dateStr: string | number | Date, includeTime = false) {
	if (dateStr) {
		const date = new Date(dateStr);
		if (!isValid(date)) {
			return '';
		}
		let dateFormat: string;
		let locale: Locale;

		if (localStorage.getItem('i18nextLng') === 'en') {
			dateFormat = includeTime ? 'MM/dd/yyyy HH:mm:ss' : 'MM/dd/yyyy';
		} else if (localStorage.getItem('i18nextLng') === 'it') {
			dateFormat = includeTime ? 'dd/MM/yyyy HH:mm:ss' : 'dd/MM/yyyy';
		} else {
			dateFormat = includeTime ? 'MM/dd/yyyy HH:mm:ss' : 'MM/dd/yyyy';
		}
		return format(date, dateFormat);
	}
	return '';
}

export const removeOverflowFromClassName = (className: string) => {
	const removeOverflow = (elements) => {
		elements.forEach((element) => {
			element.style.removeProperty('overflow');
		});
	};

	const delayRemoveOverflow = () => {
		setTimeout(() => {
			const elements = document.querySelectorAll(`.${className}`);
			if (elements.length > 0) {
				removeOverflow(elements);
			}
		}, 1000);
	};

	const observer = new MutationObserver((mutationsList) => {
		for (const mutation of mutationsList) {
			if (mutation.type === 'childList') {
				delayRemoveOverflow();
			}
		}
	});

	observer.observe(document.body, { childList: true, subtree: true });

	delayRemoveOverflow();

	return () => observer.disconnect();
};

export const transformToApiFormat = (selectedDays: string[]): { [key: string]: boolean } => {
	const result: { [key: string]: boolean } = {
		'0': false,
		'1': false,
		'2': false,
		'3': false,
		'4': false,
		'5': false,
		'6': false,
	};
	selectedDays.forEach((day) => {
		result[day] = true;
	});
	return result;
};
//This function remaps an array of requirements into an object that is used to print the requirements table based on the variant and any specializations
export const groupBySubject = (data) => {
	return data.reduce((acc, curr) => {
		if (!acc[curr.subject]) {
			acc[curr.subject] = {};
		}

		curr.variants.forEach((variant) => {
			if (!acc[curr.subject][variant.name]) {
				acc[curr.subject][variant.name] = {
					documents: [{}],
					specializations: [],
					color: variant.color,
				};
			}

			acc[curr.subject][variant.name].documents.push({ name: curr.documentType.name, isOptional: curr.isOptional });
			curr.specializations.forEach((spec) => {
				if (!acc[curr.subject][variant.name].specializations.includes(spec.name)) {
					acc[curr.subject][variant.name].specializations.push({
						name: spec.name,
						color: spec.color,
					});
				}
			});
		});
		return acc;
	}, {});
};

export const groupSpecializationBySubject = (data) => {
	return data.reduce((acc, curr) => {
		if (!acc[curr.subject]) {
			acc[curr.subject] = {};
		}

		curr.specializations.forEach((specialization) => {
			if (!acc[curr.subject][specialization.name]) {
				acc[curr.subject][specialization.name] = {
					documents: [{}],
					color: specialization.color,
				};
			}
			acc[curr.subject][specialization.name].documents.push({ name: curr.documentType.name, isOptional: curr.isOptional });
			curr.specializations.forEach((spec) => {
				if (!acc[curr.subject][specialization.name].specializations?.includes(spec.name)) {
					acc[curr.subject][specialization.name].specializations?.push({
						name: spec.name,
						color: spec.color,
					});
				}
			});
		});
		return acc;
	}, {});
};
