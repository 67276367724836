import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Notification } from "../../../../domain/entities/notification";

import {
  Button,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { COLORS } from "../../../assets/theme/colors";
import { formatDateBasedOnLanguage, formatDateTime } from "../../../../utils";

interface Props {
  notification: Notification;

  onConfirm: (id: string) => void;
  onCancel: () => void;
}

const ConfirmDeleteNotification: FC<Props> = ({
  notification,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation("notifications");

  const confirm = () => {
    onConfirm(notification.id);
    onCancel();
  };

  return (
    <Modal isOpen onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("confirmDeleteNotification")}</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Text fontWeight="semibold" overflowWrap="anywhere" mb={2}>
            {notification.title}
          </Text>
          <Text align={"right"}>
            {t("receivedOn")}:{" "}
            {formatDateBasedOnLanguage(notification.createdAt, true)}
          </Text>
        </ModalBody>

        <ModalFooter gap={2}>
          <Button onClick={confirm} bgColor={COLORS.sikuroBlue} color="white">
            {t("confirm", { ns: "common" })}
          </Button>
          <Button onClick={onCancel} bgColor={COLORS.yellow}>
            {t("cancel", { ns: "common" })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmDeleteNotification;
