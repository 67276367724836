import { useMemo, useState } from 'react';
import { useAuth } from '../../providers/Auth0JWTProvider';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { ResourceEvaluationState } from '../../../domain/entities/resourceEvaluationState.enum';
import { SortDirection, SortMeta } from '../../../domain/entities/interfaces/paginatedResults';
import useDocumentInfo from '../Document/useDocumentInfo';
import useAddDocumentType from '../Document/useAddDocumentType';
import { DocumentTypeCategory } from '../../../domain/entities/documentTypeCategory.enum';
import SupplierResourceViewModel from '../../viewmodels/suppliers/SupplierResourceViewModel';
import { SiteWorkerParams } from '../../../domain/entities/siteWorker';
import { SiteMachineParams } from '../../../domain/entities/siteMachine';
import { SiteToolParams } from '../../../domain/entities/siteTool';
import { SiteVehicleParams } from '../../../domain/entities/siteVehicle';
import { SiteChemicalParams } from '../../../domain/entities/siteChemical';
import FileEntity from '../../../domain/entities/file';
import CreateSupplierResourceDocumentParams from './createSupplierResourceDocumentParams';
import UpdateSupplierDocumentParams from './updateSupplierDocumentParam';
import { EvaluateSupplierDocumentParams } from './useSupplierDetailModel';
import { useParams } from 'react-router-dom';
import { GetAvailableBadgesFilters } from '../../../domain/repositories/badgeRepository';
import { updateFilterWithDelete } from '../../../utils';

export type UpdateFilter = (column: string, value: string | string[] | [Date, Date]) => void;
export type Sort = { [key: string]: SortDirection };

const updateFilter = (setter) => (column: string, value: string | string[]) =>
	setter((prev) => ({
		...prev,
		[column]: value,
	}));

const updateSort = (setter) => (column: string, value: SortDirection) => setter({ [column]: value });

function useSupplierResourceDetailViewModel(
	viewModel: SupplierResourceViewModel<SiteWorkerParams | SiteMachineParams | SiteToolParams | SiteVehicleParams | SiteChemicalParams>,
) {
	const { companyId } = useAuth();
	const { supplierId } = useParams();
	const [documentId, setDocumentId] = useState<string>(null);
	const [filterDocuments, setFilterDocuments] = useState({});
	const [filterDocumentEvaluations, setFilterDocumentEvaluations] = useState({});
	const [sortDocuments, setSortDocuments] = useState<SortMeta>();
	const [sortDocumentEvaluations, setSortDocumentEvaluations] = useState({});
	const [filterResourceEvaluations, setFilterResourceEvaluations] = useState({});
	const [sortResourceEvaluations, setSortResourceEvaluations] = useState({});
	const [enableGetEvaluationsHistory, setEnableGetEvaluationsHistory] = useState<boolean>(false);
	const [sortResourceBadge, setSortResourceBadge] = useState<SortMeta>();
	const [filterResourceBadge, setFilterResourceBadge] = useState<GetAvailableBadgesFilters>({});
	const [badgeIds, setBadgeIds] = useState<string[]>([]);
	const [badgeId, setBadgeId] = useState<string>();
	const [availableBadgesFilters, setAvailableBadgesFilters] = useState<GetAvailableBadgesFilters>({});
	const [availableBadgesSort, setAvailableBadgesSort] = useState<SortMeta>(null);
	const [showInfoSuccess, setShowInfoSuccess] = useState(false);

	const documentTypesProps = useAddDocumentType(viewModel, viewModel.type as unknown as DocumentTypeCategory);

	const supplierResourceQuery = useQuery(['supplier-resource', companyId, viewModel.supplierId, viewModel.resourceId], () =>
		viewModel.getSupplierResource(),
	);

	const supplierResourceDocumentsQuery = useInfiniteQuery(
		['site-resource-documents', companyId, viewModel.supplierId, viewModel.resourceId, sortDocuments, filterDocuments],
		async ({ pageParam = 1 }) => await viewModel.getSupplierResourceDocuments(pageParam, sortDocuments, filterDocuments),
		{
			getNextPageParam: (lastPage, pages) => {
				if (lastPage?.length === 25) {
					return pages.length + 1;
				}
			},
		},
	);
	const documentInfoProps = useDocumentInfo(viewModel, null, viewModel.type, viewModel.resourceId);

	const supplierResourceEvaluationsQuery = useInfiniteQuery(
		['supplier-resource-evaluations', companyId, viewModel.supplierId, viewModel.resourceId, filterResourceEvaluations, sortResourceEvaluations],
		async ({ pageParam = 1 }) => {
			const data = await viewModel.getEvaluations(
				pageParam,
				Object.keys(sortResourceEvaluations).length > 0
					? {
							field: Object.keys(sortResourceEvaluations)[0],
							direction: Object.values(sortResourceEvaluations)[0] as SortDirection,
						}
					: undefined,
				filterResourceEvaluations,
			);
			return data;
		},
		{
			getNextPageParam: (lastPage, pages) => {
				if (lastPage?.length === 25) {
					return pages.length + 1;
				}
			},
			enabled: enableGetEvaluationsHistory,
		},
	);

	const getResourceBadge = useInfiniteQuery(
		['resource-badge', companyId, viewModel.resourceId, sortResourceBadge, filterResourceBadge],
		async ({ pageParam = 1 }) => {
			const { results } = await viewModel.getResourceBadge(
				companyId,
				'',
				viewModel.resourceId,
				sortResourceBadge,
				filterResourceBadge,
				pageParam,
				viewModel.supplierId,
			);
			return results;
		},
		{
			getNextPageParam: (lastPage, pages) => {
				if (lastPage?.length === 25) {
					return pages.length + 1;
				}
			},
		},
	);

	const getBadgeSitesQuery = useQuery(
		['get-badge-sites', companyId, badgeId],
		async () => {
			return viewModel.getBadgeSites(companyId, badgeId);
		},
		{
			enabled: !!badgeId,
		},
	);

	const getAvailableBadgesQuery = useQuery(['available-badges', companyId, availableBadgesFilters, availableBadgesSort], async () => {
		return await viewModel.getAvailableBadges(companyId, viewModel.resourceId, availableBadgesSort, availableBadgesFilters, viewModel.supplierId);
	});

	const linkBadgesToResourceMutation = useMutation(
		async () => {
			return await viewModel.linkBadgesToResource(companyId, badgeIds, viewModel.resourceId, viewModel.supplierId);
		},
		{
			onError: (e) => console.error(e),
			onSuccess: () => {
				getResourceBadge.refetch(), setBadgeIds([]), setShowInfoSuccess(true);
			},
		},
	);

	const unlinkBadgeResourceMutation = useMutation(
		async () => {
			return await viewModel.unlinkBadgeResource(companyId, badgeId);
		},
		{
			onError: (e) => console.error(e),
			onSuccess: () => {
				getResourceBadge.refetch();
			},
		},
	);

	const getDocumentEvaluationsQuery = useQuery(
		['document-evaluations', companyId, viewModel.supplierId, viewModel.resourceId, documentId, filterDocumentEvaluations, sortDocumentEvaluations],
		() =>
			documentId
				? viewModel.getDocumentEvaluations(
						documentId,
						viewModel.type,
						viewModel.resourceId,
						filterDocumentEvaluations,
						Object.keys(sortDocumentEvaluations).length > 0
							? { field: Object.keys(sortDocumentEvaluations)[0], direction: Object.values(sortDocumentEvaluations)[0] as SortDirection }
							: undefined,
					)
				: [],
		{
			initialData: [],
		},
	);

	const createRequirementMutation = useMutation(
		['create-requirement', companyId, viewModel.supplierId, viewModel.resourceId],
		(params: { documentTypeId: string; isOptional: boolean; graceDays: number }) => {
			return viewModel.addRequirement(params.documentTypeId, params.isOptional, params.graceDays);
		},
		{
			onSuccess: () => {
				supplierResourceDocumentsQuery.refetch();
			},
		},
	);

	const evaluateSupplierResourceMutation = useMutation(
		['evaluate-supplier-resource', companyId, viewModel.resourceId, viewModel.resourceId],
		(result: string) => viewModel.evaluateResource(result as ResourceEvaluationState),
		{
			onSuccess: () => {
				supplierResourceQuery.refetch();
			},
		},
	);

	const autoEvaluateSupplierResourceMutation = useMutation(
		['evaluate-supplier-resource', companyId, viewModel.resourceId, viewModel.resourceId],
		() => viewModel.autoEvaluateResource(),
		{
			onSuccess: () => {
				supplierResourceQuery.refetch();
			},
		},
	);

	const evaluateDocumentMutation = useMutation(
		['evaluate-supplier-resource', companyId, viewModel.supplierId, viewModel.resourceId],
		({ evaluationState, expirationDate, documentId, siteIds, selectAll }: EvaluateSupplierDocumentParams) => {
			return viewModel.evaluateRequirement(
				documentId,
				evaluationState,
				evaluationState === 'available' ? expirationDate : null,
				siteIds,
				selectAll,
			);
		},
		{
			onSuccess: () => {
				supplierResourceDocumentsQuery.refetch();
				supplierResourceQuery.refetch();
				getDocumentEvaluationsQuery.refetch();
			},
		},
	);

	const updateFileMutation = useMutation(
		(args: { documentId: string; fileId: string; updatedFiles: Partial<FileEntity>[] }) =>
			viewModel.updateFile(companyId, args.documentId, args.fileId, args.updatedFiles),
		{
			onSuccess: () => supplierResourceDocumentsQuery.refetch(),
			onError: (err) => console.error(err),
		},
	);

	const addRequirementsToSupplierResource = async (requirements: { documentTypeId: string; isOptional: boolean; graceDays: number }[]) => {
		await Promise.all(
			requirements.map(async (inputRequirement) => {
				await createRequirementMutation.mutateAsync({
					documentTypeId: inputRequirement['id'],
					isOptional: inputRequirement.isOptional,
					graceDays: inputRequirement.graceDays,
				});
			}),
		);
	};

	const getDocumentDetails = (documentId: string) => setDocumentId(documentId);

	const updateFile = async (documentId: string, fileId: string, updatedFiles: Partial<FileEntity>[]) => {
		await updateFileMutation.mutateAsync({ documentId, fileId, updatedFiles });
	};

	const supplierResourceDocuments = supplierResourceDocumentsQuery.data?.pages?.flat() ?? [];

	const createSupplierResourceDocumentMutation = useMutation(
		['create-supplier-resource-document', companyId, viewModel.resourceId],
		({ isPublic, documentTypeId, siteIds, result, expiresAt, files }: CreateSupplierResourceDocumentParams) => {
			return viewModel.createSupplierResourceDocument(
				isPublic,
				documentTypeId,
				siteIds,
				viewModel.type,
				viewModel.resourceId,
				result,
				expiresAt,
				files,
			);
		},
		{
			onSuccess: () => {
				supplierResourceDocumentsQuery.refetch();
				getDocumentEvaluationsQuery.refetch();
			},
		},
	);

	const unlinkBadgeResource = () => {
		return unlinkBadgeResourceMutation.mutateAsync();
	};

	const linkBadgesToResource = () => {
		linkBadgesToResourceMutation.mutateAsync();
	};

	const updateSupplierResourceDocumentMutation = useMutation(
		['update-supplier-resource-document', companyId, viewModel.resourceId],
		({ document, siteIds }: UpdateSupplierDocumentParams) =>
			viewModel.updateSupplierResourceDocument(document, siteIds, viewModel.type, viewModel.resourceId),
		{
			onSuccess: () => {
				supplierResourceDocumentsQuery.refetch();
				getDocumentEvaluationsQuery.refetch();
			},
		},
	);
	const getSupplierTagsQuery = useQuery(
		['company-supplier-resource-tags', companyId, supplierId],
		() => {
			return viewModel.getTags(companyId, supplierId);
		},
		{
			enabled: !!(companyId && supplierId),
			retry: false,
		},
	);
	const tags = getSupplierTagsQuery.data;

	const supplierResourceEvaluations = supplierResourceEvaluationsQuery.data?.pages?.flat() ?? [];
	const resourceBadges = getResourceBadge.data?.pages?.flat() ?? [];
	const badgeSites = getBadgeSitesQuery.data;
	const availableBadgesResource = getAvailableBadgesQuery.data;

	return {
		supplierResource: supplierResourceQuery.data,
		isLoadingSupplierResource: supplierResourceQuery.isFetching,
		supplierResourceDocuments,
		tags,
		refetchSupplierResourceDocuments: supplierResourceQuery.refetch,
		supplierResourcesHasNextPage: supplierResourceDocumentsQuery.hasNextPage,
		supplierResourcesFetchNextPage: supplierResourceDocumentsQuery.fetchNextPage,
		supplierResourceDocumentsIsFetching: supplierResourceDocumentsQuery.isLoading,

		supplierResourceEvaluations,
		getSupplierResourceEvaluations: supplierResourceEvaluationsQuery.refetch,
		setEnableGetEvaluationsHistory,
		filterResourceEvaluations,
		updateFilterResourceEvaluations: updateFilter(setFilterResourceEvaluations),
		sortResourceEvaluations,
		updateSortResourceEvaluations: updateSort(setSortResourceEvaluations),

		getDocumentEvaluations: getDocumentDetails,
		addRequirementsToSupplierResource,
		documentEvaluations: getDocumentEvaluationsQuery.data,
		documentEvaluationsFetching: getDocumentEvaluationsQuery.isLoading,
		sortDocuments,
		filterDocuments,
		updateFile,
		evaluateSupplierResource: evaluateSupplierResourceMutation.mutate,
		autoEvaluateSupplierResource: autoEvaluateSupplierResourceMutation.mutate,
		evaluateDocument: evaluateDocumentMutation.mutate,

		updateFilterDocuments: updateFilter(setFilterDocuments),
		updateFilterDocumentEvaluations: updateFilter(setFilterDocumentEvaluations),
		updateSortDocumentEvaluations: updateSort(setSortDocumentEvaluations),
		updateSortDocuments: setSortDocuments,

		filterDocumentEvaluations,
		sortDocumentEvaluations,
		documentInfoProps,
		documentTypesProps,
		createSupplierResourceDocument: createSupplierResourceDocumentMutation.mutateAsync,
		updateSupplierResourceDocument: updateSupplierResourceDocumentMutation.mutate,
		resourceBadges,
		sortResourceBadge,
		filterResourceBadge,
		setFilterResourceBadge,
		setSortResourceBadge,
		hasBadgeNextPage: getResourceBadge.hasNextPage,
		fetchBadgeNextPage: getResourceBadge.fetchNextPage,
		isFetchingBadge: getResourceBadge.isFetching,
		setBadgeIds,
		badgeIds,
		badgeId,
		setBadgeId,
		badgeSites,
		updateFilterBadge: (field, value) => {
			updateFilterWithDelete(setFilterResourceBadge, field, value);
		},
		availableBadgesResource,
		availableBadgesFilters,
		availableBadgesSort,
		setAvailableBadgesSort,
		availableBadgesIsLoading: getAvailableBadgesQuery.isLoading,
		availableBadgesRefetch: getAvailableBadgesQuery.refetch,
		updateFilterAvailableBadge: (field, value) => {
			updateFilterWithDelete(setAvailableBadgesFilters, field, value);
		},
		linkBadgesToResource,
		linkBadgesToResourceIsLoading: linkBadgesToResourceMutation.isLoading,
		showInfoSuccess,
		setShowInfoSuccess,
		unlinkBadgeResource,
		unlinkBadgeResourceIsLoading: unlinkBadgeResourceMutation.isLoading,
	};
}

export default useSupplierResourceDetailViewModel;
