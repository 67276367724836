import {
  makeGetSite,
  makeUpdateSite,
  makeUpdateSiteState,
} from "../../../core/app/factory/sites/usecases";
import Site, { SiteState } from "../../../domain/entities/site";

class SiteDetailsViewModel {
  private getUC = makeGetSite();
  private updateUC = makeUpdateSite();
  private updateStateUC = makeUpdateSiteState();

  constructor(private companyId: string) {}

  // Public.
  async get(siteId: string): Promise<Site> {
    return await this.getUC.Call({ companyId: this.companyId, siteId });
  }

  async update(site: Site, image?: File): Promise<Site> {
    return await this.updateUC.Call({ companyId: this.companyId, site, image });
  }

  async updateState(siteId: string, state: SiteState): Promise<void> {
    await this.updateStateUC.Call({ companyId: this.companyId, siteId, state });
  }
}

export default SiteDetailsViewModel;
