import { useMemo, useState } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingView from "../../Common/LoadingView";
import ContentLayout from "../../../layout/ContentLayout";
import DocumentPresetsTable from "./DocumentPresetsTable";
import DocumentsPresetsActions from "./DocumentsPresetsActions";
import { ErrorBanner } from "../../../components/Common/alerts/ErrorBanner";
import { useDocumentsPresetsList } from "../../../hooks/DocumentsPresets/useDocumentsPresetsList";
import {
  DocumentPresetTarget,
  documentPresetTargetFromString,
} from "../../../../domain/entities/documentPresetTarget.enum";
import AddDocumentTypesModal from "../../../components/Views/common/AddDocumentTypesModal";
import { DeleteActionAlert } from "../../Common/DeleteActionAlert";
import DocumentPreset from "../../../../domain/entities/documentPreset";
import Variant from "../../../../domain/entities/variant";
import { COLORS } from "../../../assets/theme/colors";
import DOMPurify from "dompurify";
import { useMediaQuery } from "@chakra-ui/react";

const useResource = (): DocumentPresetTarget => {
  // React router V6 does NOT support regex in paths;
  // this hack is used to extract pluralized resource
  // name from path (routes are explicitly defined in
  // app router).
  const location = useLocation();
  const components = location.pathname.split("/");
  return documentPresetTargetFromString(components[components.length - 1]);
};

const DocumentsPresetsView = () => {
  const { t } = useTranslation("documentsPresets");
  const resource = useResource();
  const [isMobile] = useMediaQuery("(max-width: 767px)");

  const [selectTypeIsOpen, setSelectTypeIsOpen] = useState<boolean>(false);
  const [showDeleteDocumentTypologyAlert, setShowDeleteDocumentTypologyAlert] =
    useState<boolean>(false);
  const [askConfirmDelete, setAskConfirmDelete] = useState<boolean>(false);

  const [typologyToDelete, setTypologyToDelete] = useState<Variant>();
  const [presetToDelete, setPresetToDelete] = useState<string>();

  const [documentPresetToUnlink, setDocumentPresetToUnlink] =
    useState<DocumentPreset>();

  const {
    error,
    clearError,
    setTypologiesFilter,
    typologiesFilter,
    isLoading,
    typologies,
    presets,
    movePreset,
    linkTypologyToPreset,
    unlinkTypologyFromPreset,
    unlinkTypologyFromPresetIsLoading,
    setDocumentTypesAsPreset,
    creatingPresets,
    unsetDocumentTypeAsPreset,
    documentTypesProps,
    updateTypologyToPreset,
    renameTypologyError,
    isTagLoading,
    unsetDocumentTypeAsPresetIsLoading,
  } = useDocumentsPresetsList(resource);

  const availableDocumentTypes = useMemo(
    () =>
      documentTypesProps.documentTypes.filter((documentType) => {
        return !presets?.find(
          (preset) => preset?.documentType?.id === documentType.id,
        );
      }),
    [documentTypesProps.documentTypes, presets],
  );

  const sanitizedHtmlContent = DOMPurify.sanitize(
    t(`sectionDescriptions.${resource}`, { color: COLORS.sikuroBlue }),
  );

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <ContentLayout
      action={
        <DocumentsPresetsActions
          onAddDocumentType={() => setSelectTypeIsOpen(true)}
        />
      }
    >
      <Flex
        paddingLeft={10}
        paddingTop={10}
        paddingBottom={10}
        paddingRight={3}
        flex={1}
        h="100%"
        width={isMobile ? "900px" : "100%"}
        textAlign="center"
        flexDirection="column"
        alignItems="start"
        justifyContent="start"
      >
        <Text fontSize="larger" fontWeight="bold" textColor="#32A2DB">
          {t(`sectionHeaders.${resource}`)}
        </Text>
        <Text dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }} />
        {error && <ErrorBanner text={error} onClose={clearError} />}

        <DocumentPresetsTable
          typologies={typologies}
          onFilterByTypology={setTypologiesFilter}
          typologiesFilter={typologiesFilter}
          rows={presets}
          presetsAreLoading={isLoading}
          onReorder={movePreset}
          onAddTypology={(preset, typology) =>
            linkTypologyToPreset({ typology, preset })
          }
          onDeleteTypology={(preset, typology) =>
            unlinkTypologyFromPreset({ preset, typology })
          }
          onDelete={(preset) => setPresetToDelete(preset.id)}
          setShowDeleteAlert={() => setShowDeleteDocumentTypologyAlert(true)}
          setAskConfirmDelete={() => setAskConfirmDelete(true)}
          setTypologyToRemove={(typo) => setTypologyToDelete(typo)}
          setDocuPresetToUnlink={(preset) => setDocumentPresetToUnlink(preset)}
          onUpdate={updateTypologyToPreset}
          renameTypologyError={renameTypologyError}
          isTagLoading={isTagLoading}
        />

        {showDeleteDocumentTypologyAlert && (
          <DeleteActionAlert
            isLoading={unlinkTypologyFromPresetIsLoading}
            onConfirm={async () => {
              await unlinkTypologyFromPreset({
                typology: typologyToDelete,
                preset: documentPresetToUnlink,
              });
              setShowDeleteDocumentTypologyAlert(false);
            }}
            onCancel={() => setShowDeleteDocumentTypologyAlert(false)}
            mainTitle={t("warning", { ns: "common" })}
            title={t("deletePresetTypology", { ns: "documents" })}
            leftButtonText={t("confirm", { ns: "common" })}
            rightButtonText={t("cancel", { ns: "common" })}
            isOpen={!!showDeleteDocumentTypologyAlert}
          />
        )}

        {askConfirmDelete && (
          <DeleteActionAlert
            isLoading={unsetDocumentTypeAsPresetIsLoading}
            onConfirm={async () => {
              await unsetDocumentTypeAsPreset(presetToDelete);
              setAskConfirmDelete(false);
            }}
            onCancel={() => setAskConfirmDelete(false)}
            mainTitle={t("warning", { ns: "common" })}
            title={t("deletePreset", { ns: "documents" })}
            leftButtonText={t("confirm", { ns: "common" })}
            rightButtonText={t("cancel", { ns: "common" })}
            isOpen={!!askConfirmDelete}
          />
        )}

        {selectTypeIsOpen && (
          <AddDocumentTypesModal
            {...documentTypesProps}
            documentTypes={availableDocumentTypes}
            onClose={() => setSelectTypeIsOpen(false)}
            onConfirm={async (documentTypes) => {
              await setDocumentTypesAsPreset(documentTypes);
              setSelectTypeIsOpen(false);
            }}
            isFetching={documentTypesProps.documentTypesIsFetching}
            isAddingDocumentsApi={creatingPresets}
            enableVariantSpecSelection={false}
            showAvailableDocuments={false}
          />
        )}
      </Flex>
    </ContentLayout>
  );
};

export default DocumentsPresetsView;
