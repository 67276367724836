import UseCase from '../../../core/app/usecase';
import SiteRepository from '../../repositories/siteRepository';
import Requirement from '../../entities/requirement';
import { RequirementSubject } from '../../entities/requirementSubject.enum';

type ARGS = {
	companyId: string;
	siteId: string;
	documentTypeId: string;
	isOptional: boolean;
	graceDays: number;
	requirementSubject: RequirementSubject;
};

class AddRequirementToWorkingSite implements UseCase<ARGS, Requirement> {
	private siteRepository: SiteRepository;

	constructor(siteRepository: SiteRepository) {
		this.siteRepository = siteRepository;
	}

	async Call(parameters: ARGS): Promise<Requirement> {
		return await this.siteRepository.addRequirementToWorkingSite(
			parameters.companyId,
			parameters.siteId,
			parameters.documentTypeId,
			parameters.isOptional,
			parameters.graceDays,
			parameters.requirementSubject,
		);
	}
}

export default AddRequirementToWorkingSite;
