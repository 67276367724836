import { Checkbox, Flex, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import DocumentType from "../../../domain/entities/documentType";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { SiteChemical } from "../../../domain/entities/siteChemical";
import { SiteMachine } from "../../../domain/entities/siteMachine";
import { SiteTool } from "../../../domain/entities/siteTool";
import { SiteVehicle } from "../../../domain/entities/siteVehicle";
import { SiteWorker } from "../../../domain/entities/siteWorker";
import { updateFilterWithDelete } from "../../../utils";
import ColumnFilterComponent from "../../components/Common/table/ColumnFilterComponent";
import InfiniteTable from "../../components/Common/table/InfiniteTable";
import TableColumnHeader from "../../components/Common/table/TableColumnHeader";
import { GetSiteResourcesAuditFilters } from "../../hooks/Site/useSiteAuditsViewModel";

export enum ResourceType {
  WORKER = "worker",
  MACHINE = "machine",
  VEHICLE = "vehicle",
  TOOL = "tool",
  CHEMICAL = "chemical",
}
interface ResourceTableProps {
  resourceList:
    | SiteWorker[]
    | SiteChemical[]
    | SiteMachine[]
    | SiteVehicle[]
    | SiteTool[];
  hasNextPage: boolean;
  fetchNextPage: () => void;
  resourceIsLoading: boolean;
  includedResource: string[];
  setIncludedResource: (resource: string[]) => void;
  tableId: string;
  setSiteResourcesSort: Dispatch<SetStateAction<SortMeta>>;
  siteResourcesSort: SortMeta;
  type: ResourceType;
  setSiteResourceFilter?: Dispatch<
    SetStateAction<GetSiteResourcesAuditFilters>
  >;
  siteResourceFilter?: GetSiteResourcesAuditFilters;
  setFilterSiteWorkers?: Dispatch<SetStateAction<GetSiteResourcesAuditFilters>>;
  setFilterSiteChemicals?: Dispatch<
    SetStateAction<GetSiteResourcesAuditFilters>
  >;
  setFilterSiteMachines?: Dispatch<
    SetStateAction<GetSiteResourcesAuditFilters>
  >;
  setFilterSiteVehicles?: Dispatch<
    SetStateAction<GetSiteResourcesAuditFilters>
  >;
  setFilterTools?: Dispatch<SetStateAction<GetSiteResourcesAuditFilters>>;
  filterSiteChemicals?: GetSiteResourcesAuditFilters;
  filterSiteMachines?: GetSiteResourcesAuditFilters;
  filterSiteTools?: GetSiteResourcesAuditFilters;
  filterSiteVehicles?: GetSiteResourcesAuditFilters;
  filterSiteWorkers?: GetSiteResourcesAuditFilters;
}
const ResourceSelectableTable = ({
  resourceList,
  hasNextPage,
  fetchNextPage,
  type,
  resourceIsLoading,
  setIncludedResource,
  includedResource,
  tableId,
  setSiteResourceFilter,
  siteResourceFilter,
}: ResourceTableProps) => {
  const [parentSelectAllVisibile, setParentSelectAllVisibile] =
    useState<boolean>(false);

  const { t } = useTranslation("audit");

  const toggleItem = (id: string) => {
    if (includedResource.includes(id)) {
      setIncludedResource(includedResource.filter((i) => i !== id));
    } else {
      setIncludedResource([...includedResource, id]);
    }
  };

  const updateSiteResourcesFilter = (
    field: string,
    value: string | string[],
  ) => {
    updateFilterWithDelete(setSiteResourceFilter, field, value);
  };

  const filterValue = () => {
    if (siteResourceFilter) {
      switch (type) {
        case ResourceType.WORKER:
          return siteResourceFilter["firstName"];
        default:
          return siteResourceFilter["name"];
      }
    }
  };

  const isActiveFilter = () => {
    if (siteResourceFilter) {
      switch (type) {
        case ResourceType.WORKER:
          return !!siteResourceFilter["firstName"];
        default:
          return !!siteResourceFilter["name"];
      }
    }
  };

  const selectAllBadges = () => {
    setIncludedResource(resourceList.map((resource) => resource.id));
  };

  const toggleSelectAll = (value: boolean) => {
    setParentSelectAllVisibile(value);
    if (!value) {
      setParentSelectAllVisibile(false);
      setIncludedResource([]);
    } else {
      selectAllBadges();
    }
  };

  return (
    <Flex
      flexDirection={"column"}
      alignItems={"start"}
      border="1px solid"
      borderColor="gray.300"
      borderRadius="10px"
      h="100%"
      w="100%"
      position="relative"
      marginTop={2}
      marginBottom={3}
    >
      <InfiniteTable
        maxHeight="100vh"
        autosize={true}
        infiniteScroll={{
          dataLength: resourceList.length,
          hasNextPage: hasNextPage,
          fetchNextPage: fetchNextPage,
        }}
        tableId={tableId}
        showEmptyText={resourceList?.length === 0}
        isLoading={resourceIsLoading}
        emptyText={t("noResourcesAvailable", { ns: "siteResources" })}
      >
        <Thead>
          <Tr>
            <Th key={"selectAllCheckbox"} width={10}>
              <Checkbox
                borderColor={"gray.500"}
                isChecked={parentSelectAllVisibile}
                onChange={() => toggleSelectAll(!parentSelectAllVisibile)}
              ></Checkbox>
            </Th>
            <Th>
              <TableColumnHeader
                text={t("columns.resourceName")}
                filter={{
                  component: (
                    <ColumnFilterComponent
                      type="text"
                      value={filterValue()}
                      updateFilter={(value) =>
                        updateSiteResourcesFilter(
                          type === ResourceType.WORKER ? "firstName" : "name",
                          value as string | string[],
                        )
                      }
                      namespace="resources"
                    />
                  ),
                  isActive: isActiveFilter(),
                }}
              />
            </Th>
            {type === ResourceType.WORKER && (
              <Th>
                <TableColumnHeader
                  text={t("columns.resourceLastName")}
                  filter={{
                    component: (
                      <ColumnFilterComponent
                        type="text"
                        value={filterValue()}
                        updateFilter={(value) =>
                          updateSiteResourcesFilter(
                            "lastName",
                            value as string | string[],
                          )
                        }
                        namespace="resources"
                      />
                    ),
                    isActive: !!siteResourceFilter["lastName"],
                  }}
                />
              </Th>
            )}
          </Tr>
        </Thead>

        <Tbody borderRadius="xl">
          {resourceList &&
            resourceList?.map((resource) => {
              return (
                <Tr
                  key={resource?.id}
                  style={{
                    backgroundColor: "white",
                  }}
                >
                  <Td width={10}>
                    <Checkbox
                      borderColor={"gray.500"}
                      isChecked={
                        includedResource
                          ? includedResource.includes(resource?.id)
                          : false
                      }
                      onChange={() => toggleItem(resource?.id)}
                    ></Checkbox>
                  </Td>
                  <Td>{resource?.firstName ?? resource?.name}</Td>
                  {type === ResourceType.WORKER && (
                    <Td>{resource?.lastName}</Td>
                  )}
                </Tr>
              );
            })}
        </Tbody>
      </InfiniteTable>
    </Flex>
  );
};

export default ResourceSelectableTable;
