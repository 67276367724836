import { IconButton, IconButtonProps } from "@chakra-ui/react";
import React, { MouseEventHandler, ReactNode } from "react";

export type ActionButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  buttonColor?: string;
  buttonWidth?: string;
  iconSize?: string;
};

const ActionButton = ({
  onClick,
  buttonWidth,
  icon,
  ariaLabel,
  ...iconButtonProps
}: ActionButtonProps &
  IconButtonProps & { ariaLabel?: string; icon: ReactNode }) => (
  <IconButton
    aria-label={ariaLabel}
    variant="ghost"
    onClick={(e) => {
      e.stopPropagation();
      onClick(e);
    }}
    sx={{ color: "#767676" }}
    fill="#767676"
    fontSize="18px"
    minWidth={buttonWidth ? buttonWidth : "22px"}
    {...iconButtonProps}
  >
    {icon}
  </IconButton>
);

export default ActionButton;
