import { useParams } from "react-router-dom";
import { Permission } from "../../components/Permissions/Permissions";
import { SiteVehicleParams } from "../../../domain/entities/siteVehicle";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import SiteResourceDetailView from "../../components/Views/Sites/SiteResourceDetailView";
import useSiteVehicleDetailViewModel from "../../hooks/Site/useSiteVehicleDetailViewModel";
import { useResourceFilesDownloader } from "../../hooks/Document/useDocumentsFilesDownloader";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";

const SiteVehicleDetailView = () => {
  const { siteId, vehicleId } = useParams();
  const resourceColumns = ["name", "plate", "model", "badge", "companyName"];

  const downloader = useResourceFilesDownloader(siteId, vehicleId, "vehicles");

  return (
    <SiteResourceDetailView<SiteVehicleParams>
      downloader={downloader}
      resourceId={vehicleId}
      type={DocumentTypeResourceType.VEHICLE}
      useViewModel={useSiteVehicleDetailViewModel(
        siteId,
        vehicleId,
        resourceColumns,
        DocumentTypeResourceType.VEHICLE,
        AiTaskSectionCode.SITE_RESOURCES_VEHICLES
      )}
      resourceColumns={resourceColumns}
      isReadOnly={false}
      permissions={{
        downloadDocuments: Permission.Sites_DownloadVehicleDocuments,
        showGlobalEvaluation: Permission.Sites_Manage,
        setGlobalEvaluation: Permission.Sites_SetGlobalVehicleEval,
        addRequirement: Permission.Sites_NewVehicleRequirement,
        viewRequirements: Permission.Sites_Manage,
        editTags: Permission.Sites_EditVehicleRequirements,
        manageDocumentWithAi: Permission.Company_AIManageFile,
        documents: {
          delete: Permission.Sites_DeleteVehicleRequirement,
          edit: Permission.Sites_EditVehicleRequirements,
          evaluate: Permission.Sites_EditVehicleRequirements,
          viewEvaluations: Permission.Sites_EditVehicleRequirements,
          downloadAll: Permission.Sites_EditVehicleRequirements,
          addFile: Permission.Sites_EditVehicleRequirements,
          chat: Permission.Sites_AccessVehicleChat,
          setExpiration: Permission.Sites_EditVehicleRequirements,
        },
      }}
    />
  );
};

export default SiteVehicleDetailView;
