import UseCase from '../../../core/app/usecase';
import { BadgeLogEntry } from '../../entities/badgeLogEntry';
import BadgeRepository from '../../repositories/badgeRepository';

type ARGS = {
	companyId: string;
	siteId: string;
	badgeLogEntry: BadgeLogEntry;
	badgeId: string;
};

class UpdateBadgeLogEntry implements UseCase<ARGS, void> {
	private badgeRepository: BadgeRepository;

	constructor(badgeRepository: BadgeRepository) {
		this.badgeRepository = badgeRepository;
	}

	async Call({ companyId, siteId, badgeLogEntry, badgeId }: ARGS): Promise<void> {
		return await this.badgeRepository.updateBadgeLogEntry(companyId, siteId, badgeLogEntry, badgeId);
	}
}

export default UpdateBadgeLogEntry;
