import { FiSave } from "react-icons/fi";
import { useApp } from "../../../app";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingView from "../Common/LoadingView";
import { ConfirmAlert } from "../Common/ConfirmAlert";
import ContentLayout from "../../layout/ContentLayout";
import ActionBar from "../../components/Common/ActionBar";
import { FC, useEffect, useState } from "react";
import RenderIf from "../../components/Permissions/RenderIf";
import ActionBarItem from "../../components/Common/ActionBarItem";
import SiteOptionsType from "../../../domain/entities/siteOptions";
import {
  getSectionPermissions,
  Permission,
} from "../../components/Permissions/Permissions";
import { ErrorBanner } from "../../components/Common/alerts/ErrorBanner";
import useSiteOptionsViewModel from "../../hooks/Site/useSiteOptionsViewModel";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Select,
  SimpleGrid,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Switch,
  Text,
} from "@chakra-ui/react";
import { useMediaQuery } from "@chakra-ui/react";
import TagEmailInput from "../../components/Views/common/TagEmailInput";
import { WeekdaysCheckboxGroup } from "../../components/Views/common/WeekdaysCheckboxGroup";
import { Alert } from "../Common/Alert";
import { BadgeType } from "../../../domain/entities/badgeType.enum";

const SiteOptionsView = () => {
  const { siteId } = useParams();
  const { t } = useTranslation("sites");
  const { context, updateContext } = useApp();
  const {
    options,
    updateOption,
    reload,
    isLoading,
    updateScheduledEmail,
    setEmails,
    emails,
    setTime,
    time,
    setSelectedDays,
    selectedDays,
    scheduleActive,
    setScheduleActive,
    getScheduleEmailQueryRefetch,
    error,
    setError,
  } = useSiteOptionsViewModel(siteId);

  const [optionsDidChange, setOptionsDidChange] = useState(false);
  const [currentOptions, setCurrentOptions] =
    useState<SiteOptionsType>(options);
  const [showCseEnabledAlert, setShowCseEnabledAlert] =
    useState<boolean>(false);

  const handleTimeChange = (e) => {
    setOptionsDidChange(true);
    setTime(e.target.value);
  };

  const updateCseOption = (flag: boolean) => {
    if (flag) {
      setShowCseEnabledAlert(true);
      return;
    }

    updateCurrentOptions({ isEvaluationApprovalRequired: flag });
  };

  const updateCurrentOptions = (options: Partial<SiteOptionsType>) => {
    setOptionsDidChange(true);
    setCurrentOptions({ ...currentOptions, ...options });
  };

  const enableCse = () => {
    setShowCseEnabledAlert(false);
    updateCurrentOptions({ isEvaluationApprovalRequired: true });
  };

  const saveOptions = async () => {
    setOptionsDidChange(false);
    updateOption(currentOptions);
    updateContext({
      ...context,
      site: { ...context.site, options: currentOptions },
    });
    updateScheduledEmail();
  };

  useEffect(() => {
    reload(), getScheduleEmailQueryRefetch();
  }, []); // Forces reload of site options on component mount.

  if ((!options || isLoading) && !error) {
    return <LoadingView />;
  }

  const generateTimeOptions = () => {
    const timeOptions = [];
    for (let i = 0; i < 24; i++) {
      const hourString = i.toString().padStart(1, "0");
      timeOptions.push({
        value: hourString,
        name: `${hourString}:00`,
      });
    }
    return timeOptions;
  };

  const timeOptions = generateTimeOptions();

  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            icon={FiSave}
            onClick={saveOptions}
            description={t("saveSettings")}
          />
        </ActionBar>
      }
    >
      <SiteOptionsContainer title={t("options.title")}>
        {/** Pending evaluations (CSE), requires permissions */}
        <RenderIf permissions={[Permission.Sites_ConfirmPendingEvals]}>
          <SiteOptionComponent
            title={t("options.validate")}
            property="validateEvaluations"
            description={t("options.validateSubtitle")}
            error={
              options.canDisableEvaluationApproval === false &&
              t("options.cannotDisableValidate")
            }
          >
            <Switch
              id="validateEvaluations"
              isChecked={
                currentOptions?.isEvaluationApprovalRequired ??
                options?.isEvaluationApprovalRequired
              }
              isDisabled={options.canDisableEvaluationApproval == false}
              onChange={(e) => {
                localStorage.setItem(
                  "isReviewEnabled",
                  e.target.checked.toString()
                );
                updateCseOption(e.target.checked);
              }}
            />
          </SiteOptionComponent>
        </RenderIf>

        {/** Safety induction */}
        <SiteOptionComponent
          property="safetyInduction"
          title={t("options.safetyInduction")}
          description={t("options.safetyInductionSubtitle")}
        >
          <Switch
            id="safetyInduction"
            isChecked={currentOptions?.isSafetyInduction}
            onChange={(e) => {
              setOptionsDidChange(true);
              updateCurrentOptions({ isSafetyInduction: e.target.checked });
            }}
          />
        </SiteOptionComponent>

        {/** Badge record max interval expressed in hours */}
        <SiteOptionComponent
          property="badgeRecordMaxHours"
          title={t("options.badgeRecordMaxHours")}
          description={t("options.badgeRecordMaxHoursSubtitle")}
        >
          <Slider
            flex="1"
            min={1}
            max={24}
            focusThumbOnChange={false}
            value={currentOptions?.badgeRecordMaxHours}
            onChange={(value) => {
              setOptionsDidChange(true);
              updateCurrentOptions({ badgeRecordMaxHours: value });
            }}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb fontSize="sm" boxSize="32px">
              {currentOptions?.badgeRecordMaxHours}
            </SliderThumb>
          </Slider>
        </SiteOptionComponent>

        {/** Safety induction additional option (on site access) */}
        {currentOptions?.isSafetyInduction && (
          <SiteOptionComponent
            property="safetyInductionOnSiteAccess"
            title={t("options.safetyInductionOnSiteAccess")}
            description={t("options.safetyInductionOnSiteAccessSubtitle")}
          >
            <Switch
              id="safetyInductionOnSiteAccess"
              isChecked={currentOptions?.isSafetyInductionOnSiteAccess}
              onChange={(e) => {
                setOptionsDidChange(true);
                updateCurrentOptions({
                  isSafetyInductionOnSiteAccess: e.target.checked,
                });
              }}
            />
          </SiteOptionComponent>
        )}
        <SiteOptionComponent
          property="badgeAssociations"
          title={t("options.badgeAssociations")}
          description={t("options.badgeAssociationsSubtitle")}
        >
          <Switch
            id="badgeAssociations"
            isChecked={currentOptions?.automaticBadgeLinking}
            onChange={(e) => {
              setOptionsDidChange(true);
              updateCurrentOptions({
                automaticBadgeLinking: e.target.checked,
              });
            }}
          />
        </SiteOptionComponent>
        {currentOptions?.automaticBadgeLinking && (
          <SiteOptionComponent
            property="badgeTypeForAutomaticLinking"
            title={t("options.badgeTypeForAutomaticLinking")}
            description={t("options.badgeTypeForAutomaticLinkingSubtitle")}
          >
            <Select
              value={currentOptions.badgeTypeForAutomaticLinking}
              onChange={(e) => {
                setOptionsDidChange(true);
                updateCurrentOptions({
                  badgeTypeForAutomaticLinking: e.target.value as BadgeType,
                });
              }}
            >
              <option value="">{t("selectBadge", { ns: "badges" })}</option>
              <option value={BadgeType.QR}>{BadgeType.QR}</option>
              <option value={BadgeType.NFC}>{BadgeType.NFC}</option>
            </Select>
          </SiteOptionComponent>
        )}
      </SiteOptionsContainer>
      {optionsDidChange && (
        <Box m={3}>
          <ErrorBanner borderRadius="10px" text={t("usavedChanges")} />
        </Box>
      )}
      <Box
        border="1px solid"
        borderColor="gray.300"
        borderRadius="10px"
        m={3}
        p={4}
      >
        <Flex justify={"flex-start"} gap={4} justifyItems={"center"}>
          <Text fontWeight={"bold"} fontSize={18}>
            {t("options.scheduledEmail")}
          </Text>
          <Switch
            mt={1}
            isChecked={scheduleActive}
            onChange={(e) => {
              setOptionsDidChange(true), setScheduleActive(e.target.checked);
            }}
          />
        </Flex>
        {scheduleActive && (
          <>
            <TagEmailInput
              scheduleEmails={emails ?? []}
              onChange={(value) => {
                setOptionsDidChange(true), setEmails(value);
              }}
            />

            <Flex
              justifyContent={"space-between"}
              gap={8}
              mt={4}
              flexWrap="wrap"
            >
              <Box mt={4}>
                <WeekdaysCheckboxGroup
                  selectedDays={selectedDays ?? {}}
                  handleChange={(value) => {
                    setOptionsDidChange(true), setSelectedDays(value);
                  }}
                ></WeekdaysCheckboxGroup>
              </Box>
              <Select
                onChange={handleTimeChange}
                placeholder={t("selectTime", { ns: "common" })}
                value={`${time}`}
                width={"30%"}
              >
                {timeOptions?.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </Flex>
          </>
        )}
      </Box>

      {showCseEnabledAlert && (
        <ConfirmAlert
          title={t("confirmEnableCse", { context: "title" })}
          message={t("confirmEnableCse", { context: "description" })}
          onCancel={() => setShowCseEnabledAlert(false)}
          onConfirm={enableCse}
          variant="warning"
        />
      )}

      {error && (
        <Alert
          variant="warning"
          title={t("warning", { ns: "common" })}
          message={t(error, { ns: "errors" })}
          onClose={() => setError(undefined)}
        />
      )}
    </ContentLayout>
  );
};

interface SiteOptionComponentProps {
  title: string;
  error?: string;
  property: string;
  description: string;
  children: JSX.Element | JSX.Element[];
}

const SiteOptionComponent: FC<SiteOptionComponentProps> = ({
  title,
  error,
  property,
  description,
  children,
}) => {
  return (
    <Box>
      <FormControl display="flex" alignItems="center">
        <FormLabel htmlFor={property} mb={0}>
          {title}
        </FormLabel>
        {children}
      </FormControl>
      <Box mt={2} fontSize="small" fontStyle="italic">
        {description}
      </Box>
      {error && (
        <Box mt={2}>
          <ErrorBanner borderRadius="10px" text={error} />
        </Box>
      )}
    </Box>
  );
};

interface SiteOptionsContainerProps {
  title: string;
  children: JSX.Element | JSX.Element[];
}

const SiteOptionsContainer: FC<SiteOptionsContainerProps> = ({
  title,
  children,
}) => {
  const [isMobile] = useMediaQuery("(max-width: 767px)");

  return (
    <Box
      m={3}
      pt={3}
      pb={5}
      border="1px solid"
      borderColor="gray.300"
      borderRadius="10px"
      minHeight="600px"
      width={isMobile ? "500px" : undefined}
    >
      <Box textStyle="h2" width="100%" marginLeft={5}>
        {title}
      </Box>
      <SimpleGrid
        mt={3}
        gap={10}
        p="10px 20px"
        width="100%"
        height="210px"
        columns={2}
      >
        {children}
      </SimpleGrid>
    </Box>
  );
};

export default SiteOptionsView;
