import Worker from "../../../domain/entities/worker";
import {
  makeAddTypologyToWorker,
  makeCreateWorker,
  makeDeleteWorker,
  makeGetWorkers,
  makeRestoreWorker,
  makeUploadWorkers,
} from "../../../core/app/factory/workers/usecases";
import { ResourceListViewModel } from "../../hooks/Resources/useResourceListViewModel";
import { makeGetTypologies } from "../../../core/app/factory/typologies/usecases";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import Typology from "../../../domain/entities/typology";
import {
  PaginatedResults,
  SortMeta,
} from "../../../domain/entities/interfaces/paginatedResults";
import ImportInfo from "../../../domain/entities/importInfo";
import FileEntity from "../../../domain/entities/file";
import { GetResourcesFilter } from "../../../domain/repositories/workerRepository";
import {
  GetSitesFilter,
  LinkResourcesToSiteWarning,
} from "../../../domain/repositories/siteRepository";
import Site from "../../../domain/entities/site";
import {
  makeAddResourcesToSites,
  makeGetLinkableSites,
} from "../../../core/app/factory/sites/usecases";
import { ResourceType } from "../../screens/Site/ResourceSelectableTable";

class WorkersListViewModel implements ResourceListViewModel<Worker> {
  private getWorkersUseCase = makeGetWorkers();
  private restoreWorkerUseCase = makeRestoreWorker();
  private deleteWorkerUseCase = makeDeleteWorker();
  private crateWorkerUseCase = makeCreateWorker();
  private getTypologiesUseCase = makeGetTypologies();
  private linkTypologyUseCase = makeAddTypologyToWorker();
  private uploadWorkers = makeUploadWorkers();
  private getLinkableSitesUseCase = makeGetLinkableSites();
  private addResourcesToSitesUseCase = makeAddResourcesToSites();

  async get(
    companyId: string,
    filter?: GetResourcesFilter,
    archived?: boolean,
    sort?: SortMeta,
    pageParam?: number,
  ): Promise<PaginatedResults<Worker>> {
    return await this.getWorkersUseCase.Call({
      companyId,
      archived,
      filter,
      sort,
      pageParam,
    });
  }

  async delete(companyId: string, resourceId: string): Promise<void> {
    await this.deleteWorkerUseCase.Call({ companyId, workerId: resourceId });
  }

  async restore(companyId: string, resourceId: string): Promise<void> {
    await this.restoreWorkerUseCase.Call({ companyId, workerId: resourceId });
  }

  async create(
    companyId: string,
    resource: Worker,
    photo: File,
  ): Promise<Worker> {
    const newWorker = await this.crateWorkerUseCase.Call({
      companyId,
      worker: resource,
      photo,
    });
    return newWorker;
  }

  async uploadResources(
    companyId: string,
    file: FileEntity,
  ): Promise<ImportInfo> {
    return await this.uploadWorkers.Call({ companyId, file });
  }

  async getTypologies(companyId: string): Promise<Typology[]> {
    return await this.getTypologiesUseCase.Call({
      companyId,
      type: DocumentTypeResourceType.WORKER,
    });
  }
  async getResourceLinkableSites(
    companyId: string,
    resourceType: string,
    filters?: GetSitesFilter,
    sort?: SortMeta,
    pageParam?: number,
  ): Promise<Site[]> {
    return await this.getLinkableSitesUseCase.Call({
      companyId,
      resourceType,
      filters,
      sort,
      pageParam,
    });
  }
  async addResourcesToSites(
    companyId: string,
    resourceIds: string[],
    siteIds: string[],
    selectAllSites?: boolean,
    selectAllResources?: boolean,
  ): Promise<LinkResourcesToSiteWarning> {
    return await this.addResourcesToSitesUseCase.Call({
      companyId,
      resourceType: ResourceType.WORKER,
      resourceIds,
      siteIds,
      selectAllSites,
      selectAllResources,
    });
  }
}

export { WorkersListViewModel };
