import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TopBar, { TopBarItem } from "../../Common/TopBar";
import { getSectionPermissions } from "../../Permissions/Permissions";
import { useEffect } from "react";

interface SitesDetailTopBarProps {
  emptyItems?: boolean;
}

const SitesDetailsTopBar = ({ emptyItems = false }: SitesDetailTopBarProps) => {
  const { siteId } = useParams();
  const { t } = useTranslation("sites");
  const isReviewEnabled = localStorage.getItem("isReviewEnabled");

  const items: TopBarItem[] = [
    {
      title: t("summary"),
      path: `/sites/${siteId}/details`,
      permissions: getSectionPermissions("sites", "records"),
    },
    {
      title: t("documents"),
      path: `/sites/${siteId}/documents`,
      permissions: getSectionPermissions("sites", [
        "site-documents",
        "eval-owner",
        "overview",
      ]),
    },
    {
      title: t("resources"),
      path: `/sites/${siteId}/resources`,
      permissions: getSectionPermissions("sites", [
        "chemicals",
        "tools",
        "machines",
        "vehicles",
        "workers",
      ]),
    },
    {
      title: t("suppliers"),
      path: `/sites/${siteId}/suppliers`,
      permissions: getSectionPermissions("sites", "suppliers"),
    },
    {
      title: t("requirements"),
      path: `/sites/${siteId}/requirements`,
      permissions: getSectionPermissions("sites", "requirements"),
    },
    {
      title: t("access"),
      path: `/sites/${siteId}/access`,
      permissions: getSectionPermissions("sites", "access"),
    },
    isReviewEnabled === "true" && {
      title: t("approve"),
      path: `/sites/${siteId}/approve`,
      permissions: getSectionPermissions("sites", "review"),
    },
    {
      title: t("audit"),
      path: `/sites/${siteId}/audits`,
      permissions: getSectionPermissions("sites", "reports"),
    },
  ].filter(Boolean);

  return (
    <TopBar
      title={t("site")}
      items={emptyItems ? [] : items}
      backPath="/sites"
    />
  );
};

export default SitesDetailsTopBar;
