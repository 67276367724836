import { Evaluable } from "../../../domain/entities/evaluable.enum";
import {
  makeApprovePendingEvaluation,
  makeGetPendingEvaluations,
} from "../../../core/app/factory/sites/usecases";
import { PendingEvaluationsFilters } from "../../hooks/Site/useSiteApproveEvaluationsViewModel";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";

class SiteApproveEvaluationsViewModel {
  constructor(
    private readonly companyId: string,
    private readonly siteId: string,
    private readonly type: Evaluable,
  ) {}

  async get(
    filters: PendingEvaluationsFilters,
    sort: SortMeta,
    pageParam: number,
  ) {
    return await makeGetPendingEvaluations().Call({
      companyId: this.companyId,
      siteId: this.siteId,
      type: this.type,
      filters,
      sort,
      pageParam,
    });
  }

  async approve(resourceId: string, evaluationId: string, isApproved: boolean) {
    await makeApprovePendingEvaluation().Call({
      companyId: this.companyId,
      siteId: this.siteId,
      resourceId,
      evaluationId,
      isApproved,
      type: this.type,
    });
  }
}

export default SiteApproveEvaluationsViewModel;
