import UseCase from '../../../core/app/usecase';
import { DocumentTypeResourceType } from '../../../mock/models/document-type';
import Tag from '../../entities/tag';
import RequirementRepository from '../../repositories/requirementRepository';

interface Args {
	companyId: string;
	requirementId: string;
	specialization: Tag;
	requirementGroupId: string;
	resourceType: DocumentTypeResourceType;
}

class AddSpecializationToRequirement implements UseCase<Args, void> {
	constructor(private repository: RequirementRepository) {}

	async Call(args: Args): Promise<void> {
		return await this.repository.addSpecializationToRequirement(
			args.companyId,
			args.requirementId,
			args.specialization,
			args.requirementGroupId,
			args.resourceType,
		);
	}
}

export default AddSpecializationToRequirement;
