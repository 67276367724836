import Document from '../../domain/entities/document';
import { RenameModel } from '../../domain/entities/documentType';

export const mapApiResponseToDocument = (apiResponse: any): Document => {
  return {
    id: apiResponse.id,
    type: {
      id: apiResponse.type.id,
      name: apiResponse.type.name,
      description: apiResponse.type.description,
      duration: apiResponse.type.duration,
      tags: apiResponse.type.tags ?? [],
      renameModels: apiResponse.type.renameModels
        ? <RenameModel>{ upload: apiResponse.type.renameModels.upload, download: apiResponse.type.renameModels.download }
        : undefined,
    },
    isManual: apiResponse.isManual,
    expiresAt: apiResponse.expiresAt,
    expiresIn: apiResponse.expiresIn,
    isPublic: apiResponse.isPublic,
    filesCount: apiResponse.filesCount,
    evaluationResult: apiResponse.lastEvaluation ? apiResponse.lastEvaluation.result : undefined,
    evaluationExpiresAt: apiResponse.lastEvaluation ? apiResponse.lastEvaluation.expiresAt : undefined,
    noEvaluationExpiration: apiResponse.lastEvaluation?.noExpiration,
    noExpiration: apiResponse.noExpiration,
    lastUploadedFileUri: apiResponse.lastUploadedFileUri,
  };
};
