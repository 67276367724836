import { useApp } from "../../../../app";
import ActionBar from "../../Common/ActionBar";
import TagSelect from "../../Common/TagSelect";
import { useNavigate } from "react-router-dom";
import PlusButton from "../../Common/PlusButton";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import BaseModal from "../../Common/alerts/BaseModal";
import TagList from "../../../screens/Common/TagList";
import { COLORS } from "../../../assets/theme/colors";
import { Trans, useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import ActionBarItem from "../../Common/ActionBarItem";
import DetailsHeader from "../../Common/DetailsHeader";
import { formatDateBasedOnLanguage } from "../../../../utils";
import { FiAlertTriangle, FiInfo } from "react-icons/fi";
import ContentLayout from "../../../layout/ContentLayout";
import { Permission } from "../../Permissions/Permissions";
import { InfoBanner } from "../../Common/alerts/InfoBanner";
import LoadingView from "../../../screens/Common/LoadingView";
import DownloadAllButton from "../../Common/DownloadAllButton";
import AddDocumentTypesModal from "../common/AddDocumentTypesModal";
import SiteResourceDocuments, { ResourceDocumentsPermissions } from "../common/SiteResourceDocuments";
import { MdClose, MdDelete, MdOutlineHistory } from "react-icons/md";
import RenderIf, { useHasPermissions } from "../../Permissions/RenderIf";
import DocumentCommunicationModal from "../common/DocumentCommunicationModal";
import { DeleteActionAlert } from "../../../screens/Common/DeleteActionAlert";
import RejectionSummary, { mapRejectionGroups } from "../Documents/RejectionSummary";
import ResourceEvaluationHistoryModal from "../common/ResourceEvaluationHistoryModal";
import { RequirementSubject } from "../../../../domain/entities/requirementSubject.enum";
import { DownloadResourceFileScope } from "../../../../domain/repositories/documentsDownloader";
import ResourceEvaluationToggle, { EvaluationButton } from "../../Common/ResourceEvaluationToggle";
import { ResourceEvaluationState } from "../../../../domain/entities/resourceEvaluationState.enum";
import { useSiteResourceDetailViewModel } from "../../../hooks/Site/useSiteResourceDetailViewModel";
import { Alert, AlertIcon, Box, Flex, IconButton, Link, Switch, Text, useMediaQuery, useToast } from "@chakra-ui/react";
import { useDocumentsFilesDownloader, UseDownloader } from "../../../hooks/Document/useDocumentsFilesDownloader";
import DOMPurify from "dompurify";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import { PermissionCheck, useAuth } from "../../../providers/Auth0JWTProvider";
import { AiTaskSectionCode } from "../../../../domain/entities/aiTaskSectionCode";
import AiButton from "../../Common/AiButton";
import { AiTaskStatus } from "../../../../domain/entities/aiTask";
import ManageTaskWithAiModal from "../Documents/ManageTaskWithAiModal";

type SiteResourceDetailListViewProps<Resource> = {
  type: "worker" | "machine" | "vehicle" | "tool" | "chemical";
  resourceId: string;
  useViewModel: useSiteResourceDetailViewModel<Resource>;
  resourceColumns: string[];
  isWorkingSite?: boolean;
  isReadOnly: boolean;
  downloader: UseDownloader;
  permissions: SiteResourceDetailPermissions;
};

type SiteResourceDetailPermissions = {
  downloadDocuments: Permission;
  showGlobalEvaluation: Permission;
  setGlobalEvaluation: Permission;
  viewRequirements: Permission;
  addRequirement: Permission;
  editTags: Permission;
  setState?: Permission;
  manageDocumentWithAi: Permission;
  documents: ResourceDocumentsPermissions;
};

const SiteResourceDetailView = <Resource extends { [key: string]: any }>({
                                                                           type,
                                                                           useViewModel,
                                                                           resourceColumns,
                                                                           isWorkingSite,
                                                                           isReadOnly,
                                                                           downloader,
                                                                           resourceId,
                                                                           permissions
                                                                         }: SiteResourceDetailListViewProps<Resource>) => {

  const { t } = useTranslation("siteResources");
  const { companyId } = useAuth();
  const { context } = useApp();
  const [isTablet] = useMediaQuery("(max-width: 1300px)");
  const columns =
    type === "worker" && context.site?.options?.isSafetyInduction
      ? [...resourceColumns, "safetyInduction"]
      : resourceColumns;
  const {
    siteResource,
    isLoadingSiteResource,
    variants,
    specializations,
    evaluateSiteResource,
    updateSiteResourceVariant,
    updateSiteResourceSpecializations,
    siteResourceDocuments,
    siteResourceDocumentsRefetch,
    siteResourcesHasNextPage,
    siteResourcesFetchNextPage,
    siteResourceDocumentsIsLoading,
    siteResourceDocumentsIsFetching,
    siteResourceEvaluations,
    getSiteResourceEvaluations,
    getSiteResourceEvaluationsIsLoading,
    filterResourceEvaluations,
    updateFilterResourceEvaluations,
    sortResourceEvaluations,
    updateSortResourceEvaluations,
    evaluateDocument,
    evaluateDocumentIsLoading,
    sortDocuments,
    updateSortDocuments,
    updateFilterDocuments,
    filterDocuments,
    setSiteResourceHookDocumentId,
    documentEvaluations,
    documentEvaluationsFetching,
    addRequirementsToSiteResource,
    removeRequirementFromSiteResource,
    filterDocumentEvaluations,
    updateFilterDocumentEvaluations,
    updateSortDocumentEvaluations,
    sortDocumentEvaluations,
    deleteSiteResource,
    documentTypesProps,
    documentInfoProps,
    updateSiteWorkerHasSafetyInduction,
    autoEvaluateSiteResource,
    communicationProps,
    documentAIProps
  } = useViewModel;

  const documentDownloader = useDocumentsFilesDownloader(
    siteResource?.siteId,
    resourceId,
    (type + "s") as DownloadResourceFileScope
  );
  const [
    showConfirmEvaluateResourceModal,
    setShowConfirmEvaluateResourceModal
  ] = useState<false | ResourceEvaluationState>(false);
  const [
    showResourceEvaluationHistoryModal,
    setShowResourceEvaluationHistoryModal
  ] = useState(false);
  const [showUpdateVariantsModal, setShowUpdateVariantsModal] = useState<
    false | string
  >(false);
  const [showNeedsRequirementsModal, setShowNeedsRequirementsModal] =
    useState<boolean>(false);
  const [showUpdateSpecializationsModal, setShowUpdateSpecializationsModal] =
    useState<false | string[]>(false);
  const [showAddRequirementModal, setShowAddRequirementModal] = useState(false);
  const [showCommunicationModal, setShowCommunicationModal] = useState(false);
  const [showInfoBanner, setShowInfoBanner] = useState(true);
  const [showSystemDocTypes, setShowSystemDocTypes] = useState(false);
  const [showManageTaskWithAI, setShowManageTaskWithAI] =
    useState<boolean>(false);
  const [askConfirmDelete, setAskConfirmDelete] = useState<boolean | string>(
    false
  );
  const [
    deleteRequirementFromSiteResourceIsLoading,
    setDeleteRequirementFromSiteResourceIsLoading
  ] = useState<boolean>(false);
  const [addDocumentTypeIsLoading, setAddDocumentTypeIsLoading] =
    useState<boolean>(false);

  const variantTranslation = t("showUpdateTagsModal", { ns: "siteResources" });
  const canSetGlobalEvaluation = useHasPermissions(
    permissions.setGlobalEvaluation
  );
  const sanitizedHtmlContent = DOMPurify.sanitize(
    t("tagSelect", { tag: t("variant"), color: COLORS.sikuroBlue })
  );
  const sanitizedHtmlContentSpecialization = DOMPurify.sanitize(
    t("tagsSelect", { color: COLORS.sikuroBlue })
  );

  const aiSectionCode = (() => {
    if (!isWorkingSite) {
      switch (type as DocumentTypeCategory) {
        case DocumentTypeCategory.CHEMICAL:
          return AiTaskSectionCode.SITE_RESOURCES_CHEMICALS;
        case DocumentTypeCategory.MACHINE:
          return AiTaskSectionCode.SITE_RESOURCES_MACHINES;
        case DocumentTypeCategory.TOOL:
          return AiTaskSectionCode.SITE_RESOURCES_TOOLS;
        case DocumentTypeCategory.VEHICLE:
          return AiTaskSectionCode.SITE_RESOURCES_VEHICLES;
        case DocumentTypeCategory.WORKER:
          return AiTaskSectionCode.SITE_RESOURCES_WORKERS;
        default:
          return null; // or some default value
      }
    }

    if (isWorkingSite) {
      switch (type as DocumentTypeCategory) {
        case DocumentTypeCategory.CHEMICAL:
          return AiTaskSectionCode.WORKING_SITE_RESOURCES_CHEMICALS;
        case DocumentTypeCategory.MACHINE:
          return AiTaskSectionCode.WORKING_SITE_RESOURCES_MACHINES;
        case DocumentTypeCategory.TOOL:
          return AiTaskSectionCode.WORKING_SITE_RESOURCES_TOOLS;
        case DocumentTypeCategory.VEHICLE:
          return AiTaskSectionCode.WORKING_SITE_RESOURCES_VEHICLES;
        case DocumentTypeCategory.WORKER:
          return AiTaskSectionCode.WORKING_SITE_RESOURCES_WORKERS;
        default:
          return null; // or some default value
      }
    }
  })();

  const availableDocumentTypes = useMemo(
    () =>
      documentTypesProps.documentTypes.filter((documentType) => {
        return !siteResourceDocuments.find(
          (document) => document?.documentTypeId === documentType.id
        );
      }),
    [documentTypesProps.documentTypes, siteResourceDocuments]
  );

  useEffect(() => {
    documentTypesProps.setGetSystemDocTypes(isWorkingSite);
  }, []);

  const handleAddRequirements = async (documentTypes) => {
    setAddDocumentTypeIsLoading(true);
    await addRequirementsToSiteResource(documentTypes);
    setShowAddRequirementModal(false);
    setShowSystemDocTypes(false);
    setAddDocumentTypeIsLoading(false);
  };

  const canDownloadAllDocuments = useHasPermissions(
    permissions.downloadDocuments
  );


  const getDetailValue = (column: string) => {
    if (["dateOfBirth", "dateOfEmployment"].includes(column))
      return formatDateBasedOnLanguage(siteResource[column]);
    if (column === "badge")
      return siteResource.badge ? (
        <Link
          color={COLORS.sikuroBlue}
          href={`/company/badges/${siteResource.badge.id}`}
        >
          {siteResource.badge.code}
        </Link>
      ) : (
        "-"
      );
    if (column === "safetyInduction")
      return (
        <Switch
          isChecked={siteResource.hasSafetyInduction}
          onChange={(e) => updateSiteWorkerHasSafetyInduction(e.target.checked)}
        />
      );
    return siteResource[column];
  };

  const refetchCommentsAndNotes = () => {
    communicationProps.refetchResourceDocumentComments(),
      communicationProps.refetchResourceDocumentNotes();
  };

  const navigate = useNavigate();
  const toast = useToast();

  if (!siteResource) {
    return <LoadingView />;
  }
  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            bgColor={COLORS.sikuroBlue}
            color="white"
            icon={MdClose}
            description={t("close", { ns: "common" })}
            onClick={() =>
              navigate(
                `/${isWorkingSite ? "working" : ""}sites/${
                  siteResource?.siteId
                }/resources/${type}s`
              )
            }
          />
          <RenderIf permissions={permissions.documents.chat}>
            <ActionBarItem
              bgColor={COLORS.yellow}
              color="black"
              icon={HiOutlineChatAlt2}
              description={t("chat", { ns: "communication" })}
              onClick={() => {
                refetchCommentsAndNotes();
                setShowCommunicationModal(true);
              }}
            />
          </RenderIf>
          {isWorkingSite && companyId === siteResource.companyId && (
            <ActionBarItem
              bgColor="red"
              color="white"
              icon={MdDelete}
              description={t("removeResource", { ns: "siteResources" })}
              onClick={async () => {
                await deleteSiteResource();
                navigate(
                  `/${isWorkingSite ? "working" : ""}sites/${
                    siteResource?.siteId
                  }/resources/${type}s`
                );
              }}
            />
          )}
        </ActionBar>
      }
    >
      {siteResource && (
        <Box px={3} py={5}>
          <DetailsHeader
            title={t("details", { ns: "common" })}
            image={{ url: siteResource.photo }}
            attributes={columns.map((column) => ({
              label: t(column),
              value: getDetailValue(column)
            }))}
            isLoaded={!isLoadingSiteResource}
            shouldShowImage={true}
          />
          <Box
            mt={5}
            p={3}
            border="1px solid"
            borderColor="gray.300"
            borderRadius="10px"
            overflow="hidden"
          >
            {!(isWorkingSite || siteResource.isGlobal) &&
              siteResource.isForcedEvaluation && (
                <Alert my={2} mx={-3} py={1} status="error" width="auto">
                  <AlertIcon />
                  <div>
                    <Trans t={t} i18nKey="evaluationForced">
                      Warning: the evaluation is forced.{" "}
                      <Link
                        sx={{ fontWeight: 700, textDecoration: "underline" }}
                        onClick={() => autoEvaluateSiteResource()}
                      >
                        Click here
                      </Link>{" "}
                      to remove the force
                    </Trans>
                  </div>
                </Alert>
              )}
            <Flex
              flexDirection={"column"}
              justifyContent="center"
              gap={5}
              alignItems="center"
            >
              <RenderIf permissions={permissions.showGlobalEvaluation}>
                <Flex
                  justifyContent="center"
                  gap={5}
                  alignItems="center"
                  marginTop={2}
                >
                  {isWorkingSite || siteResource.isGlobal ? (
                    <>
                      {siteResource.state ? (
                        <EvaluationButton
                          value={siteResource.state}
                          evaluationType={"resource"}
                        />
                      ) : (
                        <Flex
                          borderWidth="1px"
                          borderRadius={"10px"}
                          backgroundColor={"white"}
                          padding={2}
                        >
                          <Flex
                            borderWidth="1px"
                            borderRadius={"10px"}
                            backgroundColor={"gray.200"}
                            color={"black"}
                            padding={3}
                            fontSize={"16px"}
                          >
                            {t("emptyEvaluation", { ns: "requirements" })}
                          </Flex>
                        </Flex>
                      )}
                    </>
                  ) : (
                    <ResourceEvaluationToggle
                      key={String(showConfirmEvaluateResourceModal)}
                      status={siteResource.state}
                      onChange={(status: ResourceEvaluationState) => {
                        setShowConfirmEvaluateResourceModal(status);
                      }}
                      type="resource"
                      showExpirationDate={false}
                      disabled={!canSetGlobalEvaluation}
                    />
                  )}

                  {siteResource.state && (
                    <IconButton
                      fontSize="3xl"
                      backgroundColor="transparent"
                      onClick={() =>
                        setShowResourceEvaluationHistoryModal(true)
                      }
                      aria-label="document-history"
                    >
                      <MdOutlineHistory />
                    </IconButton>
                  )}
                </Flex>
              </RenderIf>

              <Box mt={2}>
                <RejectionSummary
                  groups={mapRejectionGroups([
                    { resource: type, documents: siteResourceDocuments }
                  ])}
                />
              </Box>

              <Flex
                gap={15}
                mt={2}
                width={"full"}
                alignItems="start"
                justifyContent={"flex-start"}
              >
                <Box marginTop={2}>
                  {!isReadOnly && !siteResource.isGlobal && (
                    <Flex flexDirection={"column"}>
                      <TagSelect
                        isMulti={false}
                        style={{ zIndex: "2" }}
                        tags={variants}
                        selectedTags={siteResource.variants.map((v) => v.id)}
                        setSelectedTags={(variant) => {
                          setShowUpdateVariantsModal(
                            variant.filter(
                              (v) => v !== siteResource?.variants[0]?.id
                            )[0] ?? undefined
                          );
                        }}
                        defaultMenuIsOpen={false}
                        label={t("variant")}
                      />
                      <Text
                        mt={-4}
                        fontSize={10}
                        dangerouslySetInnerHTML={{
                          __html: sanitizedHtmlContent
                        }}
                      />
                    </Flex>
                  )}
                  {(isReadOnly || siteResource.isGlobal) && (
                    <div>
                      {siteResource?.variants &&
                        siteResource?.variants.length > 0 && (
                          <Flex>
                            <Text
                              fontSize={14}
                              textColor={COLORS.sikuroBlue}
                              marginRight={5}
                            >
                              {t("variants", { ns: "requirements" })}
                            </Text>
                            <TagList tags={siteResource?.variants ?? []} />
                          </Flex>
                        )}
                      {(!siteResource?.variants ||
                        siteResource?.variants.length == 0) && (
                        <Flex>
                          <Text
                            mt={-3}
                            fontSize={10}
                            border="1px"
                            borderColor={COLORS.lightRed}
                            borderStyle="solid"
                            borderRadius="5px"
                            padding="2"
                            bgColor={COLORS.lightRed}
                          >
                            {t("noVariantSelected", { ns: "workers" })}
                          </Text>
                        </Flex>
                      )}
                    </div>
                  )}
                </Box>
                <Flex alignItems={"center"} flexDirection="column" mt="2">
                  {!(isReadOnly || siteResource.isGlobal) ? (
                    <>
                      <TagSelect
                        style={{ zIndex: "2" }}
                        tags={specializations}
                        selectedTags={siteResource.specializations.map(
                          (v) => v.id
                        )}
                        setSelectedTags={setShowUpdateSpecializationsModal}
                        defaultMenuIsOpen={false}
                        label={t("specializations")}
                      />
                      <Text
                        mt={-4}
                        fontSize={10}
                        dangerouslySetInnerHTML={{
                          __html: sanitizedHtmlContentSpecialization
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {siteResource?.specializations &&
                      siteResource?.specializations.length > 0 ? (
                        <Flex>
                          <Text
                            fontSize={14}
                            textColor={COLORS.sikuroBlue}
                            marginRight={5}
                          >
                            {t("specializations", { ns: "requirements" })}
                          </Text>
                          <TagList tags={siteResource?.specializations ?? []} />
                        </Flex>
                      ) : (
                        <Text
                          fontSize={10}
                          border="1px"
                          borderColor={COLORS.lightYellow}
                          borderStyle="solid"
                          borderRadius="md"
                          padding="1"
                          bgColor={COLORS.lightYellow}
                        >
                          {t("noSpecializationsSelected", {
                            ns: "requirements"
                          })}
                        </Text>
                      )}
                    </>
                  )}
                </Flex>
                <Flex flexGrow={1} />
                {companyId === siteResource.companyId && (
                  <RenderIf
                    check={PermissionCheck.All}
                    permissions={[
                      permissions.documents.addFile,
                      permissions.manageDocumentWithAi
                    ]}
                  >
                    <Flex mt={7} gap={4}>
                      <AiButton
                        tooltipMessage={t("manageAi", { ns: "documents" })}
                        onClick={() => {
                          documentAIProps.aiTasksRefetch();
                          setShowManageTaskWithAI(true);
                        }}
                        isAnimated={documentAIProps.aiTasks.some(
                          (task) => task.status === AiTaskStatus.RUNNING
                        )}
                      />
                    </Flex>
                  </RenderIf>
                )}
                {companyId === siteResource.companyId && (
                  <Flex mt={7} gap={4}>
                    <RenderIf permissions={permissions.addRequirement}>
                      {
                        <PlusButton
                          tooltipMessage={t("createNewDocument", {
                            ns: "documents"
                          })}
                          onClick={() => {
                            setShowAddRequirementModal(true);
                          }}
                        />
                      }
                    </RenderIf>

                    {canDownloadAllDocuments &&
                      siteResourceDocuments.length > 0 &&
                      !isWorkingSite && (
                        <DownloadAllButton
                          onClick={() =>
                            documentDownloader.initiateDocumentsZipDownload(
                              (ok: boolean) => {
                                toast({
                                  duration: 5000,
                                  isClosable: true,
                                  colorScheme: ok ? "green" : "red",
                                  icon: ok ? <FiInfo /> : <FiAlertTriangle />,
                                  description: ok
                                    ? t("downloadAllFilesOk", {
                                      ns: "documents"
                                    })
                                    : t("downloadAllFilesError", {
                                      ns: "documents"
                                    })
                                });
                              }
                            )
                          }
                        />
                      )}
                  </Flex>
                )}
              </Flex>

              {showInfoBanner && (
                <RenderIf permissions={permissions.viewRequirements}>
                  <InfoBanner
                    text={t("dragAndDropInfo", { ns: "documents" })}
                    onClose={() => setShowInfoBanner(false)}
                  />
                </RenderIf>
              )}

              <RenderIf permissions={permissions.viewRequirements}>
                <SiteResourceDocuments
                  resourceId={resourceId}
                  resourceCategory={type as DocumentTypeCategory}
                  downloader={downloader}
                  documents={siteResourceDocuments}
                  documentsRefetch={siteResourceDocumentsRefetch}
                  documentsHasNextPage={siteResourcesHasNextPage}
                  documentsFetchNextPage={siteResourcesFetchNextPage}
                  evaluateDocument={evaluateDocument}
                  evaluateDocumentIsLoading={evaluateDocumentIsLoading}
                  sortDocuments={sortDocuments}
                  filterDocuments={filterDocuments}
                  updateFilterDocuments={updateFilterDocuments}
                  updateSortDocuments={updateSortDocuments}
                  setSiteResourceHookDocumentId={setSiteResourceHookDocumentId}
                  documentEvaluations={documentEvaluations}
                  documentEvaluationsFetching={documentEvaluationsFetching}
                  filterDocumentEvaluations={filterDocumentEvaluations}
                  updateFilterDocumentEvaluations={
                    updateFilterDocumentEvaluations
                  }
                  sortDocumentEvaluations={sortDocumentEvaluations}
                  updateSortDocumentEvaluations={updateSortDocumentEvaluations}
                  isWorkingSite={isWorkingSite || siteResource.isGlobal}
                  isFetchingDocuments={siteResourceDocumentsIsFetching}
                  isLoadingDocuments={siteResourceDocumentsIsLoading}
                  isReadOnly={siteResource.isGlobal || isReadOnly}
                  {...documentInfoProps}
                  onDelete={(requirementId) => {
                    setAskConfirmDelete(requirementId);
                  }}
                  showCommunications={
                    isWorkingSite ? companyId === siteResource.companyId : true
                  }
                  communicationProps={communicationProps}
                  requirementSubject={RequirementSubject.SITE}
                  permissions={permissions.documents}
                  isTablet={isTablet}
                  deltaWidth="175px"
                  aiTaskSectionCode={aiSectionCode}
                  canAddFile={
                    isWorkingSite ? companyId === siteResource.companyId : true
                  }
                />
              </RenderIf>
            </Flex>
          </Box>
        </Box>
      )}

      {showAddRequirementModal && (
        <RenderIf permissions={permissions.addRequirement}>
          <AddDocumentTypesModal
            {...documentTypesProps}
            isRequirement
            isAddingDocumentsApi={addDocumentTypeIsLoading}
            documentTypes={availableDocumentTypes}
            onClose={() => setShowAddRequirementModal(false)}
            onConfirm={handleAddRequirements}
            isFetching={documentTypesProps.documentTypesIsFetching}
          />
        </RenderIf>
      )}
      {showSystemDocTypes && (
        <RenderIf permissions={permissions.addRequirement}>
          <AddDocumentTypesModal
            {...documentTypesProps}
            isRequirement
            isWorkingSite
            isAddingDocumentsApi={addDocumentTypeIsLoading}
            documentTypes={availableDocumentTypes}
            onClose={() => setShowSystemDocTypes(false)}
            onConfirm={handleAddRequirements}
            isFetching={documentTypesProps.systemDocumentTypesFetching}
            enableVariantSpecSelection={false}
          />
        </RenderIf>
      )}

      {showConfirmEvaluateResourceModal && (
        <BaseModal
          bodyOverflow={"hidden"}
          title={t("confirmEvaluationForced")}
          onClose={() => setShowConfirmEvaluateResourceModal(false)}
          onConfirm={() => {
            evaluateSiteResource(
              showConfirmEvaluateResourceModal as ResourceEvaluationState
            );
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: t("showConfirmEvaluateResourceModal")
            }}
          />
        </BaseModal>
      )}

      {showUpdateVariantsModal && (
        <BaseModal
          title={t("warning")}
          onClose={() => setShowUpdateVariantsModal(false)}
          onConfirm={() => {
            updateSiteResourceVariant(showUpdateVariantsModal)
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: t(variantTranslation, { tag: t("variant") })
            }}
          />
        </BaseModal>
      )}

      {showNeedsRequirementsModal && (
        <BaseModal
          title={t("warning")}
          onClose={() => setShowNeedsRequirementsModal(false)}
        >
          <Text>{t("needRequirements")}</Text>
        </BaseModal>
      )}

      {showUpdateSpecializationsModal && (
        <BaseModal
          title={t("warning")}
          onClose={() => setShowUpdateSpecializationsModal(false)}
          onConfirm={() =>
            updateSiteResourceSpecializations(showUpdateSpecializationsModal)
          }
        >
          <div
            dangerouslySetInnerHTML={{
              __html: t(variantTranslation, { tag: t("specialization") })
            }}
          />
        </BaseModal>
      )}

      {showResourceEvaluationHistoryModal && (
        <ResourceEvaluationHistoryModal
          onClose={() => setShowResourceEvaluationHistoryModal(false)}
          evaluations={siteResourceEvaluations}
          getEvaluations={getSiteResourceEvaluations}
          getEvaluationsIsLoading={getSiteResourceEvaluationsIsLoading}
          filterResourceEvaluations={filterResourceEvaluations}
          updateFilterResourceEvaluations={updateFilterResourceEvaluations}
          sortResourceEvaluations={sortResourceEvaluations}
          updateSortResourceEvaluations={updateSortResourceEvaluations}
        />
      )}

      {showCommunicationModal && (
        <DocumentCommunicationModal
          onClose={() => setShowCommunicationModal(false)}
          hook={communicationProps}
          permissions={permissions.documents}
        />
      )}

      {showManageTaskWithAI && (
        <ManageTaskWithAiModal
          title={t("uploadWithAi", { ns: "documents" })}
          onClose={() => {
            siteResourceDocumentsRefetch();
            setShowManageTaskWithAI(false);
          }}
          isPropagable={false}
          aiTaskSectionCode={aiSectionCode}
          aiTasks={documentAIProps.aiTasks}
          createAiTask={documentAIProps.createAiTask}
          aiTaskDetail={documentAIProps.aiTaskDetail}
          aiTaskDetailFetching={documentAIProps.aiTaskDetailFetching}
          setSelectedAiTaskId={documentAIProps.setAiTaskId}
          aiTasksHasNextPage={documentAIProps.aiTasksHasNextPage}
          aiTasksFetchNextPage={documentAIProps.aiTasksFetchNextPage}
          aiTasksFetching={documentAIProps.aiTasksFetching}
          onDeleteTask={documentAIProps.deleteAiTask}
          onConfirmTask={documentAIProps.confirmAiTask}
          onConfirmTaskIsLoading={documentAIProps.confirmAitaskIsLoading}
          documentTypesForAi={documentAIProps.documentTypesForAi}
          setEnableDocumentTypesForAiQuery={
            documentAIProps.setEnableDocumentTypesForAiQuery
          }
          sitesWhereToPropagate={documentAIProps.sitesForAiTask}
        />
      )}

      {askConfirmDelete && (
        <DeleteActionAlert
          isLoading={deleteRequirementFromSiteResourceIsLoading}
          onConfirm={async () => {
            setDeleteRequirementFromSiteResourceIsLoading(true);
            await removeRequirementFromSiteResource(
              askConfirmDelete as string,
              type as RequirementSubject
            );
            setAskConfirmDelete(false);
            setDeleteRequirementFromSiteResourceIsLoading(false);
          }}
          onCancel={() => {
            setAskConfirmDelete(false);
          }}
          mainTitle={t("warning", { ns: "common" })}
          title={t("confirmDelete", { ns: "requirements" })}
          leftButtonText={t("confirm", { ns: "common" })}
          rightButtonText={t("cancel", { ns: "common" })}
          isOpen={!!askConfirmDelete}
        />
      )}
    </ContentLayout>
  );
};

export default SiteResourceDetailView;
