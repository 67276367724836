import {
  Flex,
  GridItem,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  ModalOverlay,
  Text,
  Tooltip,
  useToast
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FiAlertTriangle, FiInfo } from "react-icons/fi";
import { IoMdDownload } from "react-icons/io";
import { SiteResourceDocument } from "../../../../domain/entities/document";
import FileEntity from "../../../../domain/entities/file";
import { ResourceDocumentEvaluationState } from "../../../../domain/entities/resourceDocumentEvaluationState.enum";
import Site from "../../../../domain/entities/site";
import { COLORS } from "../../../assets/theme/colors";
import { DocumentCommunicationHook } from "../../../hooks/Document/useDocumentCommunicationVIewModel";
import { UseDownloader } from "../../../hooks/Document/useDocumentsFilesDownloader";
import CommentButton from "../../Common/CommentButton";
import { formatDateBasedOnLanguage } from "../../../../utils";
import { RequirementSubject } from "../../../../domain/entities/requirementSubject.enum";
import CommentAuditButton from "../../Common/CommentAuditButton";
import useSitesAuditsViewModel from "../../../hooks/Site/useSiteAuditsViewModel";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import { ResourceDocumentsPermissions } from "./SiteResourceDocuments";
import RenderIf from "../../Permissions/RenderIf";
import { useAuth } from "../../../providers/Auth0JWTProvider";
import { Alert } from "../../../screens/Common/Alert";
import { RequirementExpirationComponent } from "./RequirementExpirationComponent";
import { RequirementEvaluationComponent } from "./RequirementEvaluationComponent";

interface Props {
  document: SiteResourceDocument;
  renameModels: string[];
  downloader: UseDownloader;
  evaluateDocument: (
    documentId: string,
    evaluationState?: ResourceDocumentEvaluationState,
    expirationDate?: Date,
    siteIds?: string[]
  ) => void;
  setAction: (action: "add" | "delete" | "evaluate" | "edit") => void;

  selectedEvaluationState: ResourceDocumentEvaluationState;
  setSelectedEvaluationState: (state: ResourceDocumentEvaluationState) => void;

  setShowEdit: (showEdit: boolean) => void;
  setActiveFile: (file: FileEntity | null) => void;
  setShowUpdateEvaluationDate?: (showEvaluationDate: boolean) => void;
  hasSites?: (action: string) => Promise<boolean>;
  communicationProps: DocumentCommunicationHook;
  updateEvaluationDate: string;
  sites?: Site[];
  siteGeneralDocument?: boolean;
  isWorkingSite?: boolean;
  documentsFetching?: boolean;
  requirementSubject?: RequirementSubject;
  onPrev: () => void;
  onNext: () => void;
  permissions: ResourceDocumentsPermissions;
  action?: "add" | "delete" | "evaluate" | "edit" | "editFile" | string | null;
  resourceId?: string;
  resourceCategory?: DocumentTypeCategory;
  showOverlay?: boolean;
  canComment?: boolean;
}

export const RequirementDetailHeader = ({
                                          document,
                                          renameModels,
                                          downloader,
                                          requirementSubject,
                                          isWorkingSite,
                                          hasSites,
                                          documentsFetching,
                                          action,
                                          communicationProps,
                                          evaluateDocument,
                                          setAction,
                                          setShowEdit,
                                          selectedEvaluationState,
                                          setSelectedEvaluationState,
                                          showOverlay,
                                          setActiveFile,
                                          onPrev,
                                          onNext,
                                          setShowUpdateEvaluationDate,
                                          permissions,
                                          resourceId,
                                          resourceCategory,
                                          canComment=true
                                        }: Props) => {
  const toast = useToast();
  const { t } = useTranslation("documents");
  const { siteId } = useAuth();
  const [evaluationValidity, setEvaluationValidity] = useState<string>(
    document?.evaluationExpiresAt
      ? formatDateBasedOnLanguage(document?.evaluationExpiresAt)
      : null
  );
  const [currentRequirementRemarks, setCurrentRequirementRemarks] =
    useState<string>(document?.reportRemarks);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [noExpiration, setNoExpiration] = useState<boolean>(
    document?.noExpiration
  );

  const {
    createAuditNote,
    updateAuditNote,
    createAuditNoteIsLoading,
    updateAuditNoteIsLoading
  } = useSitesAuditsViewModel(
    siteId,
    document.id,
    resourceCategory,
    resourceId,
    isWorkingSite
  );

  const handleEvaluation = (option?: ResourceDocumentEvaluationState) => {
    setAction("evaluate");
    setSelectedEvaluationState(option);
    setShowUpdateEvaluationDate(true);
    evaluateDocument(document.id, option, new Date(evaluationValidity), []);
  };

  const handleUpdateEvaluationDate = async () => {
    setAction("evaluate");
    const hasSiteItems = hasSites && (await hasSites("evaluate"));
    setShowEdit(true);
    if (hasSiteItems) {
      setShowUpdateEvaluationDate(false);
    } else {
      setShowUpdateEvaluationDate(true);
    }
  };
  useEffect(() => {
    if (document) {
      setEvaluationValidity(
        document?.evaluationExpiresAt
          ? formatDateBasedOnLanguage(document?.evaluationExpiresAt)
          : null
      );
      setSelectedEvaluationState(document?.evaluationResult);
      setCurrentRequirementRemarks(document.reportRemarks);
    }
  }, [document]);

  return (
    <>
      <GridItem
        colSpan={1}
        rowSpan={1}
        w={"sm"}
        bg={COLORS.yellow}
        borderRadius={"5px 0 0 0 "}
        zIndex={action ? 2 : 1}
        borderBottom={"1px solid #efefef"}
      >
        {(action === "evaluate" ||
            action === "add" ||
            action === "edit" ||
            action === "editFile" ||
            action === "delete") &&
          showOverlay && <ModalOverlay></ModalOverlay>}
        {document && (
          <Flex
            py={8}
            px={6}
            gap={2}
            justifyContent={"space-between"}
            flexDirection={"column"}
          >
            <Flex flexDirection={"column"}>
              <Text fontWeight={600}>{document.documentTypeName}</Text>
              <Text fontSize={"small"}>{document?.filesCount} file</Text>
            </Flex>
            <Flex alignItems={"center"} gap={3}>
              {canComment && communicationProps && (
                <RenderIf permissions={permissions.chat}>
                  <CommentButton
                    showShadow
                    onClick={(comment) => {
                      if (comment)
                        communicationProps.createResourceDocumentComment(
                          `#[${document.documentTypeName}](${document.id}) ${comment}`
                        );
                    }}
                    comments={
                      communicationProps.resourceDocumentComments?.filter((c) =>
                        c.comment.includes(`(${document.id})`)
                      ) ?? []
                    }
                    isLoading={communicationProps.isFetching}
                    color="black"
                    fontSize="23px"
                    taggableUsers={communicationProps?.taggableUsers}
                  />
                </RenderIf>
              )}
              {communicationProps && (
                <RenderIf permissions={permissions.report}>
                  <CommentAuditButton
                    onClick={async (comment) => {
                      document.reportRemarks = comment;
                      if (comment) {
                        if (document.reportRemarks) {
                          await updateAuditNote(comment);
                        } else {
                          await createAuditNote(comment);
                        }
                        setShowSuccessAlert(true);
                      }
                    }}
                    comment={currentRequirementRemarks}
                    color="black"
                    fontSize="24px"
                    taggableUsers={communicationProps?.taggableUsers}
                    isLoading={
                      createAuditNoteIsLoading || updateAuditNoteIsLoading
                    }
                  />
                </RenderIf>
              )}
              <RenderIf permissions={permissions.downloadAll}>
                <Menu placement="auto">
                  <Tooltip label={t("downloadAllFiles", { ns: "documents" })}>
                    <MenuButton
                      minWidth="22px"
                      paddingLeft={2}
                      paddingRight={2}
                      paddingTop={2}
                      paddingBottom={2}
                      borderRadius={25}
                      _hover={{
                        paddingLeft: "2",
                        paddingRight: "2",
                        borderRadius: "25",
                        boxShadow: "0 0 12px 4px rgba(0, 0, 0, 0.2)"
                      }}
                    >
                      <IoMdDownload fontSize="24px" color={"gray"} />
                    </MenuButton>
                  </Tooltip>
                  <MenuList>
                    <MenuGroup
                      title={t("downloadAllFiles", { ns: "documents" })}
                    >
                      {renameModels?.map((name) => (
                        <MenuItem
                          key={name}
                          minH="48px"
                          onClick={() => {
                            const documentId =
                              requirementSubject === RequirementSubject.SITE
                                ? document.id
                                : document.documentId;
                            return downloader?.initiateZipDownload(
                              documentId,
                              name,
                              (ok) => {
                                toast({
                                  duration: 5000,
                                  isClosable: true,
                                  colorScheme: ok ? "green" : "red",
                                  icon: ok ? <FiInfo /> : <FiAlertTriangle />,
                                  description: ok
                                    ? t("downloadAllFilesOk")
                                    : t("downloadAllFilesError")
                                });
                              }
                            );
                          }}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </MenuGroup>
                  </MenuList>
                </Menu>
              </RenderIf>
            </Flex>
          </Flex>
        )}
      </GridItem>
      <GridItem
        colSpan={2}
        rowSpan={1}
        w={"full"}
        borderBottom={"1px solid #efefef"}
      >
        {document && (
          <Flex
            gap={4}
            zIndex={10}
            h={"full"}
            justifyContent={isWorkingSite ? "flex-end" : "space-between"}
            py={4}
            pr={4}
          >
            <Flex gap={4} px={4}>
              <RenderIf
                permissions={permissions.evaluate}
                fallback={
                  <RenderIf permissions={permissions.viewEvaluations}>
                    <RequirementEvaluationComponent
                      action={action}
                      documentsFetching={documentsFetching}
                      readOnly
                      handleEvaluation={handleEvaluation}
                      selectedState={selectedEvaluationState}
                      setSelectedState={setSelectedEvaluationState}
                    />
                  </RenderIf>
                }
              >
                <RequirementEvaluationComponent
                  action={action}
                  documentsFetching={documentsFetching}
                  handleEvaluation={handleEvaluation}
                  readOnly={false}
                  selectedState={selectedEvaluationState}
                  setSelectedState={setSelectedEvaluationState}
                />
              </RenderIf>
              <RenderIf
                permissions={permissions.setExpiration}
                fallback={
                  <RenderIf permissions={permissions.viewEvaluations}>
                    +{" "}
                    <RequirementExpirationComponent
                      document={document}
                      documentsFetching={documentsFetching}
                      evaluationValidity={evaluationValidity}
                      handleUpdateEvaluationDate={handleUpdateEvaluationDate}
                      readOnly
                      selectedState={selectedEvaluationState}
                    />
                  </RenderIf>
                }
              >
                <RequirementExpirationComponent
                  document={document}
                  documentsFetching={documentsFetching}
                  evaluationValidity={evaluationValidity}
                  handleUpdateEvaluationDate={handleUpdateEvaluationDate}
                  readOnly={false}
                  selectedState={selectedEvaluationState}
                />
              </RenderIf>
            </Flex>

            <Flex
              gap={4}
              fontSize={"24px"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <Tooltip label={t("previousDocument")} shouldWrapChildren>
                <FaChevronLeft
                  onClick={() => {
                    setActiveFile(null);
                    onPrev();
                    setAction(null);
                    setSelectedEvaluationState(null);
                  }}
                  cursor={"pointer"}
                />
              </Tooltip>
              <Tooltip label={t("nextDocument")} shouldWrapChildren>
                <FaChevronRight
                  onClick={() => {
                    setActiveFile(null);
                    onNext();
                    setAction(null);
                    setSelectedEvaluationState(null);
                  }}
                  cursor={"pointer"}
                />
              </Tooltip>
            </Flex>
          </Flex>
        )}
      </GridItem>
      {showSuccessAlert && (
        <Alert
          onClose={() => setShowSuccessAlert(false)}
          title={t("warning", { ns: "common" })}
          message={t("noteAuditSaved", { ns: "communication" })}
          variant="info"
        />
      )}
    </>
  );
};
