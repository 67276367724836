import { Company } from './company';
import { Tag } from './tag';
import { Document } from './document';

export type DocumentType = {
	id: string;
	name: string;
	description: string;
	duration: number;
	owner: Company;
	type: DocumentTypeResourceType;
	tags: Tag[];
	documents: Document[];
};
export enum DocumentTypeResourceType {
	COMPANY = 'company',
	VEHICLE = 'vehicle',
	TOOL = 'tool',
	WORKER = 'worker',
	CHEMICAL = 'chemical',
	MACHINE = 'machine',
	SITE = 'site',
}
