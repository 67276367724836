import { ResourceDocumentEvaluationState } from '../entities/resourceDocumentEvaluationState.enum';
import { SortDirection, SortMeta } from '../entities/interfaces/paginatedResults';
import { UserState } from '../entities/user';
import { formatISODate } from '../../infrastructure/utilities/filters';
import { RoleContext } from '../entities/role';

const namedSortParam = (params: Array<string>, fieldName?: string, desc?: boolean) => {
	if (!fieldName) {
		console.warn('cannot build sort param: no field name is null or undefined');
		return;
	}

	params.push(`field=${fieldName}&direction=${desc ? 'desc' : 'asc'}`);
};

const param = (params: Array<string>, paramName: string, value?: any, toLower?: boolean) => {
	if (!value) {
		return;
	}

	let stringVal = '';
	if (typeof value === 'string') {
		value = value.trim();

		if (toLower === true) {
			value = value.toLowerCase();
		}

		stringVal = `${value}`;
	} else if (Array.isArray(value) && value.length > 0) {
		stringVal = `${value.join(',')}`;
	} else {
		stringVal = `${value}`;
	}

	if (stringVal !== '') {
		params.push(`${paramName}=${encodeURIComponent(stringVal)}`);
	}
};

const dateParam = (params: Array<string>, paramName: string, value?: Date) => {
	if (value) {
		params.push(`${paramName}=${formatISODate(value)}`);
	}
};

// Sites.
export type GetWorkingSiteDocumentsSortField = 'name';
export interface GetWorkingSiteDocumentsFilter {
	sort?: GetWorkingSiteDocumentsSortField;
	desc?: boolean;

	name?: string;
	eligibility?: ResourceDocumentEvaluationState;
}

// Users.
export interface GetUsersFilter {
	sortField?: 'name' | 'email' | 'state' | 'lastLogin';
	sortDirection?: SortDirection;
	state?: UserState;
	fromDate?: Date;
	toDate?: Date;
	name?: string;
	search?: string;
}

export const getUsersFilterQuery = (filter?: GetUsersFilter): string => {
	if (!filter) {
		return '';
	}

	const params = Array<string>();
	namedSortParam(params, filter.sortField, SortDirection.Descending === filter.sortDirection);
	param(params, 'state', filter.state);

	dateParam(params, 'fromDate', filter.fromDate);
	dateParam(params, 'toDate', filter.toDate);
	param(params, 'name', filter.name);
	param(params, 'search', filter.search);

	return params.length > 0 ? `?${params.join('&')}` : '';
};

export type GetSiteRolesFilterSortFields = 'role' | 'site';
export interface GetSiteRolesFilter {
	sort: SortMeta;
	context?: RoleContext;
	site?: string;
}
