import UseCase from '../../../core/app/usecase';
import RequirementsGroupRepository from '../../repositories/requirementsGroupRepository';
import RemoveReqFromGroupParameters from './removeReqFromGroupParameter';

class RemoveRequirementFromGroup implements UseCase<RemoveReqFromGroupParameters, void> {
	private requirementsGroupRepository: RequirementsGroupRepository;

	constructor(requirementsGroupRepository: RequirementsGroupRepository) {
		this.requirementsGroupRepository = requirementsGroupRepository;
	}

	async Call(parameters) {
		return await this.requirementsGroupRepository.removeRequirementFromGroup(
			parameters.companyId,
			parameters.requirementGroupId,
			parameters.requirementId,
			parameters.siteIds,
		);
	}
}

export default RemoveRequirementFromGroup;
