import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../assets/theme/colors";
import Role, { RoleContext } from "../../../../domain/entities/role";
import useRolesViewModel from "../../../hooks/Roles/useRolesViewModel";
import InputAnimatedLabel from "../../../components/Common/InputAnimatedLabel";
import InputAnimatedSelect from "../../../components/Common/InputAnimatedSelect";

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
} from "@chakra-ui/react";
import { SortDirection } from "../../../../domain/entities/interfaces/paginatedResults";

interface Props {
  onCreate: (name: string, email: string, role: Role) => void;
  onCancel: () => void;
}

const CreateUserView: FC<Props> = ({ onCreate, onCancel }) => {
  const { t } = useTranslation("settings");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState<Role>(undefined);
  const [userIsCreating, setUserIsCreating] = useState(false);
  const [emailNotValid, setEmailNotValid] = useState(false);

  const { roles } = useRolesViewModel(
    SortDirection.Ascending,
    RoleContext.GENERAL
  );

  const selectRole = (id: string) => {
    const role = roles?.find((r) => r.id === id);
    setRole(role);
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleCreateUser = () => {
    if (!validateEmail(email)) {
      setEmailNotValid(true);
      setUserIsCreating(false);
      return;
    }

    setEmailNotValid(false);
    setUserIsCreating(true);
    onCreate(name, email, role);
  };

  const handleEmailChange = (_: any, value: string) => {
    setEmail(value);
    if (emailNotValid && validateEmail(value)) {
      setEmailNotValid(false);
    }
  };

  return (
    <Modal isOpen onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("users.add")}</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <InputAnimatedLabel
            id="name"
            marginTop={8}
            isRequired
            defaultValue={name}
            label={t("users.name")}
            handleOnChange={(_, value) => setName(value)}
          />

          <InputAnimatedLabel
            id="email"
            marginTop={8}
            isRequired
            defaultValue={email}
            label={t("users.email")}
            type="email"
            handleOnChange={handleEmailChange}
          />
          {emailNotValid && (
            <Text marginTop={2} marginLeft={2} color={"red"} fontSize={14}>
              {t("errors.invalidEmail", { ns: "supplier" })}
            </Text>
          )}

          <InputAnimatedSelect
            id="role"
            marginTop={8}
            noSelectionLabel={t("roles.noRoleSelected")}
            label={t("roles.select")}
            selectedValue={role?.id}
            onSelect={selectRole}
            items={roles ?? []}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={userIsCreating}
            bg={COLORS.sikuroBlue}
            textColor="white"
            onClick={handleCreateUser}
            isDisabled={!name || !email || !role || emailNotValid}
          >
            {t("create", { ns: "common" })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateUserView;
