import SiteRepository, { GetSitesFilter } from '../../repositories/siteRepository';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import UseCase from '../../../core/app/usecase';
import Site from '../../entities/site';

interface Args {
	companyId: string;
	resourceType?: string;
	resourceId?: string;
	filters?: GetSitesFilter;
	sort?: SortMeta;
	pageParam?: number;
	includeWorkingSites?: boolean;
}

class GetResourceLinkableSites implements UseCase<Args, Site[]> {
	private siteRepository: SiteRepository;

	constructor(siteRepository: SiteRepository) {
		this.siteRepository = siteRepository;
	}

	async Call({ companyId, resourceType, resourceId, filters, sort, pageParam }: Args): Promise<Site[]> {
		return await this.siteRepository.getResourceLinkableSites(companyId, resourceType, resourceId, filters, sort, pageParam);
	}
}

export default GetResourceLinkableSites;
