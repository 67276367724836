import { useParams } from "react-router-dom";
import { Permission } from "../../components/Permissions/Permissions";
import { SiteWorkerParams } from "../../../domain/entities/siteWorker";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import SiteResourceDetailView from "../../components/Views/Sites/SiteResourceDetailView";
import useWorkingSiteWorkerDetailViewModel from "../../hooks/WorkingSite/useWorkingSiteWorkerDetailViewModel";
import { useResourceFilesDownloader } from "../../hooks/Document/useDocumentsFilesDownloader";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";

const WorkingSiteWorkerDetailView = () => {
  const { siteId, workerId } = useParams();
  const resourceColumns = [
    "firstName",
    "lastName",
    "dateOfBirth",
    "dateOfEmployment",
    "email",
    "fiscalCode",
    "placeOfBirth",
    "jobTitle",
    "phone",
  ];

  const downloader = useResourceFilesDownloader(siteId, workerId, "workers");
  return (
    <SiteResourceDetailView<SiteWorkerParams>
      downloader={downloader}
      resourceId={workerId}
      type={DocumentTypeResourceType.WORKER}
      useViewModel={useWorkingSiteWorkerDetailViewModel(
        siteId,
        workerId,
        resourceColumns,
        DocumentTypeResourceType.WORKER,
        AiTaskSectionCode.WORKING_SITE_RESOURCES_WORKERS
      )}
      resourceColumns={resourceColumns}
      isWorkingSite={true}
      isReadOnly={true}
      permissions={{
        downloadDocuments: Permission.Worksite_DownloadWorkerDoc,
        showGlobalEvaluation: Permission.Worksite_ShowWorkerGlobalEval,
        setGlobalEvaluation: Permission.Worksite_Show,
        addRequirement: Permission.Worksite_NewWorkerReq,
        viewRequirements: Permission.Worksite_Show,
        editTags: Permission.Worksite_EditWorkerReq,
        manageDocumentWithAi: Permission.Company_AIManageFile,
        documents: {
          delete: Permission.Worksite_Worker_DeleteFile,
          edit: Permission.Worksite_Worker_EditFile,
          evaluate: Permission.Worksite_EvalWorkerReq,
          viewEvaluations: Permission.Worksite_EvalWorkerReq,
          downloadAll: Permission.Worksite_DownloadAllFile,
          addFile: Permission.Worksite_EditWorkerReq,
          addNewFile: Permission.Worksite_Worker_AddFile,
          chat: Permission.Worksite_WorkerChat,
          report: Permission.Worksite_WorkerChat,
          setExpiration: Permission.Worksite_Worker_SetExpiration,
        },
      }}
    />
  );
};

export default WorkingSiteWorkerDetailView;
