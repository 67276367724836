import { FC, ReactNode, useRef, useState } from "react";
import { COLORS } from "../../../assets/theme/colors";
import { HiFilter, HiOutlineFilter } from "react-icons/hi";
import { BsArrowDown, BsArrowDownUp, BsArrowUp } from "react-icons/bs";
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Text,
  useFormControlStyles,
  useOutsideClick,
} from "@chakra-ui/react";
import { createPortal } from "react-dom";
import { SortDirection } from "../../../../domain/entities/interfaces/paginatedResults";

interface Props {
  overflowHidden?: boolean;
  text: string;
  filter?: { component: ReactNode; isActive: boolean };
  sort?: {
    direction: SortDirection;
    handler: (direction: SortDirection) => void;
  };
}

const TableColumnHeader: FC<Props> = ({
  text,
  filter,
  sort,
  overflowHidden,
}) => {
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [buttonX, setButtonX] = useState<number | null>(null);
  const [buttonY, setButtonY] = useState<number | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLDivElement | null>(null);

  const updateSorting = () => {
    const nextState =
      sort.direction === SortDirection.Ascending
        ? SortDirection.Descending
        : SortDirection.Ascending;

    sort?.handler(nextState);
  };

  const handleEdit = () => {
    const rect = ref.current.getBoundingClientRect();
    setButtonX(rect.left);
    setButtonY(rect.bottom);
    setShowFilter(true);
  };

  useOutsideClick({
    ref: inputRef,
    handler: () => {
      if (showFilter) {
        setShowFilter(false);
      }
    },
  });

  const directionIcon = {
    [SortDirection.Ascending]: BsArrowUp,
    [SortDirection.Descending]: BsArrowDown,
  };

  return (
    <Flex ref={ref} direction="row" alignItems="center" position="relative">
      {filter &&
        showFilter &&
        buttonX &&
        buttonY &&
        typeof document !== "undefined" &&
        createPortal(
          <Box
            ref={inputRef}
            padding={1}
            style={{
              position: "absolute",
              left: `${buttonX}px`,
              top: `${buttonY}px`,
              zIndex: 1400,
              backgroundColor: "white",
              height: "fit-content",
              borderRadius: "10px",
              border: "1px solid",
              borderColor: "lightGray",
              ...(overflowHidden && { overflow: "hidden" }),
            }}
          >
            {filter.component}
          </Box>,
          document.body,
        )}
      {filter && (
        <IconButton
          variant="ghost"
          aria-label="toggle-filter"
          size="sm"
          onClick={handleEdit}
          sx={{
            height: "16px",
            minWidth: "16px",
            padding: 0,
            marginRight: "2px",
          }}
        >
          <Icon
            color={filter.isActive ? COLORS.sikuroBlue : undefined}
            as={filter.isActive ? HiFilter : HiOutlineFilter}
          />
        </IconButton>
      )}

      <Text>{text}</Text>

      <div style={{ flexGrow: 1 }} />

      {sort && (
        <IconButton
          variant="ghost"
          aria-label="toggle-sort"
          size="sm"
          onClick={updateSorting}
          sx={{ height: "16px" }}
        >
          <Icon as={directionIcon[sort.direction] ?? BsArrowDownUp} />
        </IconButton>
      )}
    </Flex>
  );
};

export default TableColumnHeader;
