import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingView from "../Common/LoadingView";
import DataBox from "../../components/Common/DataBox";
import ContentLayout from "../../layout/ContentLayout";
import Company from "../../../domain/entities/company";
import CompanyDetailActionBar from "./CompanyDetailActionBar";
import Staff, { StaffType } from "../../../domain/entities/staff";
import { Permission } from "../../components/Permissions/Permissions";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import FormTextField from "../../components/Common/forms/FormTextField";
import StaffTableView from "../../components/Views/Company/StaffTableView";
import FormSelectField from "../../components/Common/forms/FormSelectField";
import CreateUpdateStaffModal from "../../components/Views/Staff/CreateStaffModal";
import { useCompanyDetailViewModel } from "../../hooks/Company/useCompanyDetailViewModel";
import {
  Flex,
  Grid,
  GridItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import {
  businessSizes,
  euVatCodes,
  vatCodeValidators,
} from "../../../infrastructure/utilities/validator";
import { useMediaQuery } from "@chakra-ui/react";

const CompanyDetailView = () => {
  const [init, setInit] = useState(false);
  const { t } = useTranslation("companies");
  const [isEditing, setIsEditing] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [staffType, setStaffType] = useState(StaffType.SYSTEM);
  const [uploadedImageFile, setUploadedImageFile] = useState<File>();
  const [isTablet] = useMediaQuery("(max-width: 1300px)");

  const {
    company,
    isFetching,
    updateCompanyLogo,
    updateCompanyFields,
    staff,
    staffIsFetching,
    staffFilter,
    staffSort,
    createStaff,
    updateFilter,
    setStaffSort,
    deleteStaff,
    updateStaff,
    deleteStaffIsLoading,
    createStaffIsLoading,
    updateStaffIsLoading,
    systemStaff,
    systemStaffIsFetching,
    systemStaffFilter,
    systemStaffSort,
    setSystemStaffSort,
    updateSystemFilter,
    updateSystemStaff,
    coordinates,
  } = useCompanyDetailViewModel();
  const onTabSelect = (t) => {
    setStaffType(t === 0 ? StaffType.SYSTEM : StaffType.CUSTOM);
  };
  const onSubmit: SubmitHandler<Company> = (data) => {
    if (uploadedImageFile) {
      updateCompanyLogo(
        {
          id: company.id,
          ccnl: data.ccnl,
          vatCountryCode: data.vatCountryCode,
          address: data.address,
          pec: data.pec,
          vat: data.vat,
          email: data.email,
          businessSize: data.businessSize,
          name: data.name,
          fiscalCode: data.fiscalCode.toUpperCase(),
          phoneNumber: data.phoneNumber,
        },
        uploadedImageFile,
      );
    } else {
      updateCompanyFields({
        id: company.id,
        ccnl: data.ccnl,
        vatCountryCode: data.vatCountryCode,
        address: data.address,
        pec: data.pec,
        vat: data.vat,
        email: data.email,
        businessSize: data.businessSize,
        name: data.name,
        fiscalCode: data.fiscalCode.toUpperCase(),
        phoneNumber: data.phoneNumber,
      });
    }
    setIsEditing(false);
  };

  const methods = useForm<Company>({ mode: "all", defaultValues: company });
  const {
    handleSubmit,
    watch,
    reset,
    setError,
    clearErrors,
    setValue,
    formState: { isValid },
  } = methods;

  useEffect(() => {
    if (!company || init) {
      return;
    }

    reset({
      ccnl: company.ccnl,
      vatCountryCode: company.vatCountryCode,
      address: company.address,
      pec: company.pec,
      vat: company.vat,
      email: company.email,
      businessSize: company.businessSize,
      name: company.name,
      fiscalCode: company.fiscalCode?.toUpperCase(),
      phoneNumber: company.phoneNumber,
    });

    setInit(true);
  }, [company, init, isEditing, reset]);

  const vatCountryCode = watch("vatCountryCode");
  const vatCodeRegex = vatCodeValidators.get(vatCountryCode);

  const validateVat = (vat: string) => {
    let message = undefined;
    if (!vat) {
      message = t("vatFormatError.-");
    } else if (!vatCodeRegex.exec(vat)) {
      message = t(`vatFormatError.${vatCountryCode}`);
    }

    if (message) {
      setError("vat", { message });
      return message;
    }

    clearErrors("vat");
    return true;
  };

  const validateFiscalCode = (code: string) => {
    let message = undefined;

    if (code?.trim() === "") {
      message = t("fiscalCodeIsRequired");
    }

    if (message) {
      setError("fiscalCode", { message });
      return message;
    }

    clearErrors("fiscalCode");
    return true;
  };

  const toggleIsEditing = (isEditing: boolean) => {
    if (!isEditing) {
      setUploadedImageFile(undefined);
      reset({
        ccnl: company.ccnl,
        vatCountryCode: company.vatCountryCode,
        address: company.address,
        name: company.name,
        fiscalCode: company.fiscalCode,
        phoneNumber: company.phoneNumber,
        pec: company.pec,
        vat: company.vat,
        email: company.email,
        businessSize: company.businessSize,
      });
    }

    setIsEditing(isEditing);
  };

  const checkVat = async () => {
    validateVat(company.vat);
  };

  const [link, setLink] = useState(null);

  useEffect(() => {
    if (coordinates) {
      setLink(
        `https://www.google.com/maps/place/${coordinates.lat},${coordinates.lng}`,
      );
    }
  }, [coordinates]);

  if (!company) {
    // Company data not yet available.
    return <LoadingView />;
  }

  const createStaffAction = async (staffElement: Staff) => {
    await createStaff(staffElement);
    setShowCreate(false);
  };

  return (
    <>
      <ContentLayout
        action={
          <CompanyDetailActionBar
            isEditing={isEditing}
            canSave={isEditing && isValid}
            onSave={handleSubmit(onSubmit)}
            onAdd={
              staffType === StaffType.CUSTOM
                ? () => setShowCreate(true)
                : undefined
            }
            onToggleEdit={isFetching ? undefined : toggleIsEditing}
            editDetailsPermissions={[Permission.Company_EditRecords]}
            editStaffPermissions={[Permission.Company_EditRoles]}
          />
        }
      >
        <FormProvider {...methods}>
          <Grid
            templateColumns={"repeat(2, 1fr)"}
            gap={4}
            paddingLeft={10}
            paddingRight={3}
            py={10}
            h="100%"
            width={"calc(100vw - 120px)"}
          >
            <GridItem colSpan={2}>
              <Flex
                gap={10}
                border="1px solid"
                borderColor="gray.300"
                borderRadius="10px"
                width={"calc(100vw - 180px)"}
                position={"relative"}
                overflow="hidden"
              >
                <DataBox
                  title={t("details", { ns: "common" })}
                  isEditing={isEditing}
                  isLoading={isFetching}
                  image={{
                    url: uploadedImageFile
                      ? URL.createObjectURL(uploadedImageFile)
                      : company.logo,
                    onUpdate: (f) => setUploadedImageFile(f),
                  }}
                  fields={[
                    <FormTextField
                      key="name"
                      name="name"
                      label={t("name")}
                      rules={{ required: t("companyNameIsRequired") }}
                    />,

                    <FormTextField
                      key="fiscalCode"
                      name="fiscalCode"
                      label={t("fiscalCode")}
                      rules={{
                        validate: async (value) => validateFiscalCode(value),
                      }}
                    />,

                    <FormTextField
                      key="phoneNumber"
                      name="phoneNumber"
                      label={t("phoneNumber")}
                    />,
                    <FormSelectField
                      key="vatCountryCode"
                      onChange={checkVat}
                      name="vatCountryCode"
                      label={t("vatCountryCode.title")}
                      displayValue={t(
                        `vatCountryCode.${watch("vatCountryCode")}`,
                      )}
                      options={euVatCodes.map((c) => {
                        return {
                          id: c.id,
                          name: t(`vatCountryCode.${c.name}`),
                        };
                      })}
                    />,

                    <FormTextField
                      key="vat"
                      name="vat"
                      label={t("vat")}
                      rules={{ validate: async (value) => validateVat(value) }}
                    />,
                    <FormTextField key="pec" name="pec" label={t("pec")} />,
                    <FormTextField
                      key="email"
                      name="email"
                      label={t("email")}
                      rules={{ required: t("emailIsRequired") }}
                    />,

                    <FormSelectField
                      key="businessSize"
                      name="businessSize"
                      label={t("businessSize")}
                      displayValue={t(`size.${watch("businessSize")}`)}
                      options={businessSizes.map((s) => {
                        return { id: s.id, name: t(`size.${s.name}`) };
                      })}
                    />,
                    <FormTextField
                      key="ccnl"
                      name="ccnl"
                      label={t("contractType")}
                    />,
                    <FormTextField
                      key="street"
                      name="address.street"
                      label={t("street")}
                      link={link}
                    />,
                    <FormTextField
                      key="city"
                      name="address.city"
                      label={t("city")}
                    />,
                    <FormTextField
                      key="zipCode"
                      name="address.cap"
                      label={t("zipCode")}
                    />,
                  ]}
                />
              </Flex>
            </GridItem>
            <GridItem colSpan={2}>
              <Tabs
                height="100%"
                defaultIndex={0}
                marginTop={5}
                marginBottom={5}
                onChange={onTabSelect}
              >
                <TabList>
                  <Tab width="50%">{t("staff.system")}</Tab>
                  <Tab width="50%">{t("staff.custom")}</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel
                    key={StaffType.SYSTEM}
                    paddingLeft={0}
                    paddingRight={0}
                  >
                    <StaffTableView
                      staffIsLoading={updateStaffIsLoading}
                      deleteStaffIsLoading={deleteStaffIsLoading}
                      staffType={staffType}
                      staffs={systemStaff}
                      isFetching={systemStaffIsFetching}
                      sorting={systemStaffSort}
                      filters={systemStaffFilter}
                      updateStaffsSorting={setSystemStaffSort}
                      updateStaffsFilter={updateSystemFilter}
                      updateStaffElement={updateSystemStaff}
                      canEditPermissions={[Permission.Company_EditRoles]}
                      isTablet={true}
                    />
                  </TabPanel>
                  <TabPanel
                    key={StaffType.CUSTOM}
                    paddingLeft={0}
                    paddingRight={0}
                  >
                    <StaffTableView
                      staffIsLoading={updateStaffIsLoading}
                      deleteStaffIsLoading={deleteStaffIsLoading}
                      staffType={staffType}
                      staffs={staff}
                      isFetching={staffIsFetching}
                      sorting={staffSort}
                      filters={staffFilter}
                      updateStaffsSorting={setStaffSort}
                      updateStaffsFilter={updateFilter}
                      updateStaffElement={updateStaff}
                      deleteStaffElement={deleteStaff}
                      canEditPermissions={[Permission.Company_EditRoles]}
                      from={"company"}
                      isTablet={true}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </GridItem>
          </Grid>
        </FormProvider>
        {showCreate && (
          <CreateUpdateStaffModal
            createUpdateStaffIsLoading={createStaffIsLoading}
            staffType={staffType}
            onCancel={() => {
              setShowCreate(false);
            }}
            onConfirm={createStaffAction}
            title={t("createStaff", { ns: "companies" })}
          />
        )}
      </ContentLayout>
    </>
  );
};

export default CompanyDetailView;
