import UseCase from '../../../core/app/usecase';
import Supplier from '../../entities/supplier';
import AuditRepository from '../../repositories/auditRepository';

interface Args {
	companyId: string;
	siteId?: string;
}

class GetSelectableSuppliers implements UseCase<Args, Supplier[]> {
	constructor(private repository: AuditRepository) {}

	async Call({ companyId, siteId }: Args): Promise<Supplier[]> {
		return await this.repository.getSelectableSuppliers(companyId, siteId);
	}
}

export default GetSelectableSuppliers;
