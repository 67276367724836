import { useParams } from "react-router-dom";
import { Permission } from "../../components/Permissions/Permissions";
import { SiteMachineParams } from "../../../domain/entities/siteMachine";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import SiteResourcesListView from "../../components/Views/Sites/SiteResourcesListView";
import useWorkingSiteMachinesViewModel from "../../hooks/WorkingSite/useWorkingSiteMachinesViewModel";

const SiteMachinesView = () => {
  const { siteId } = useParams();
  const resourceColumns = ["name", "plate", "model"];
  return (
    <SiteResourcesListView<SiteMachineParams>
      type={DocumentTypeResourceType.MACHINE}
      useViewModel={useWorkingSiteMachinesViewModel(
        siteId,
        resourceColumns,
        DocumentTypeResourceType.MACHINE,
      )}
      resourceColumns={resourceColumns}
      addToSitePermissions={[Permission.Worksite_AttachMachine]}
      removeFromSitePermissions={[Permission.Worksite_DetachMachine]}
    />
  );
};

export default SiteMachinesView;
