import { useTranslation } from "react-i18next";
import { useDocumentWithCommunicationViewModel } from "../../hooks/Document/useDocumentsViewModel";
import GeneralDocumentsView from "../../components/Views/Documents/GeneralDocumentsView";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { CompanyGeneralDocumentsViewModel } from "../../viewmodels/documents/CompanyGeneralDocumentsViewModel";
import { useDocumentFilesDownloader } from "../../hooks/Document/useDocumentsFilesDownloader";
import { Permission } from "../../components/Permissions/Permissions";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";

const CompanyGeneralDocumentsView = () => {
  const { t } = useTranslation();
  const viewModel = new CompanyGeneralDocumentsViewModel();
  const hook = useDocumentWithCommunicationViewModel(
    viewModel,
    DocumentTypeCategory.COMPANY,
    "company-general-documents",
    undefined,
    AiTaskSectionCode.MY_COMPANY_DOCUMENTS
  );
  const downloader = useDocumentFilesDownloader();

  return (
    <GeneralDocumentsView
      aiTaskSectionCode={AiTaskSectionCode.MY_COMPANY_DOCUMENTS}
      title={t("generalDocuments", { ns: "companies" })}
      subtitle={t("generalDocumentsSub", { ns: "companies" })}
      props={hook}
      downloader={downloader}
      showPublic={true}
      hasEvaluation={true}
      fromSite={false}
      siteGeneralDocument={false}
      permissions={{
        addDocument: Permission.Company_AddNewCompanyDoc,
        manageDocumentWithAi: Permission.Company_AIManageFile,
        documents: {
          edit: Permission.Company_EditCompanyDocs,
          delete: Permission.Company_DeleteCompanyDoc,
          downloadAll: Permission.Company_DownloadDocs,
          setExpiration: Permission.Company_SetCompanyDocExpiration,
          chat: Permission.Company_EditCompanyDocs,
          evaluate: Permission.Company_EvalCompanyDocs,
          viewEvaluations: Permission.Company_EvalCompanyDocs,
          addFile: Permission.Company_EditCompanyDocs,
        },
      }}
      aiFilesPropagable={true}
    />
  );
};

export default CompanyGeneralDocumentsView;
