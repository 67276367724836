import { Permission } from "./Permissions";
import { FC, ReactNode } from "react";
import { useAuth, PermissionCheck } from "../../providers/Auth0JWTProvider";

interface Props {
  children?: ReactNode;
  fallback?: ReactNode;
  check?: PermissionCheck;
  permissions?: Permission[] | Permission;
}

export const useHasPermissions = (
  permissions?: Permission[] | Permission,
  check?: PermissionCheck
): boolean => {
  const { hasPermissions } = useAuth();
  const p = (Array.isArray(permissions) ? permissions : [permissions]).filter(
    (p) => p
  );
  return p.length === 0 || hasPermissions(p, check);
};

const RenderIf: FC<Props> = ({ children, fallback, permissions, check }) => (
  <>{useHasPermissions(permissions, check) ? children : fallback}</>
);

export default RenderIf;
