import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Spinner,
  Tag,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { UserNotification } from "../../../../domain/entities/interfaces/userNotification";
import { Fragment, useEffect, useState } from "react";
import { COLORS } from "../../../assets/theme/colors";
import { IoIosMailUnread } from "react-icons/io";
import { IoMdNotifications } from "react-icons/io";

type UserNotificationProps = {
  notifications: UserNotification[];
  setSaveUpdatedNotifications: (hasChanges: boolean) => void;
  onSubmit: (data: UserNotification[]) => void;
  notificationsIsFetching: boolean;
  saveUpdatedNotifications: boolean;
};
type NotificationsGroup = {
  notification?: string;
  channels: { id: string; channel: string; isActive: boolean }[];
};
const UserNotificationsView = ({
  notifications,
  notificationsIsFetching,
  setSaveUpdatedNotifications,
  onSubmit,
  saveUpdatedNotifications,
}: UserNotificationProps) => {
  const { t } = useTranslation("notifications");
  const { register, handleSubmit, formState } = useForm<UserNotification[]>();
  const [checkboxState, setCheckboxState] = useState<{
    [key: string]: boolean;
  }>({});
  const dirtyFields = formState.dirtyFields;

  const remapNotifications = (
    notifications: UserNotification[],
  ): NotificationsGroup[] => {
    const remappedNotifications: { [key: string]: NotificationsGroup } = {};
    notifications?.forEach((notification) => {
      const {
        id,
        notification: notificationType,
        channel,
        isActive,
      } = notification;

      if (!remappedNotifications[notificationType]) {
        remappedNotifications[notificationType] = {
          notification: notificationType,
          channels: [],
        };
      }
      remappedNotifications[notificationType].channels.push({
        id,
        channel,
        isActive,
      });
    });

    return Object.values(remappedNotifications);
  };

  const remappedNotifications: NotificationsGroup[] =
    remapNotifications(notifications);

  const handleCheckboxChange = (id: string) => {
    setCheckboxState((prevState) => {
      return {
        ...prevState,
        [id]: !prevState[id],
      };
    });
    setSaveUpdatedNotifications(true);
  };

  useEffect(() => {
    if (notifications) {
      const initialState: { [key: string]: boolean } = {};
      remappedNotifications.forEach((notification) => {
        notification.channels.forEach((channel) => {
          initialState[channel.id] = channel.isActive;
        });
      });
      setCheckboxState(initialState);
    }
  }, [notifications]);

  const onSubmitForm = (data: UserNotification[]) => {
    const updatedData = [];
    const updatedNotifications = notifications.reduce(
      (acc: UserNotification[], notification, index) => {
        if (
          notification.isActive !==
          (data[notification.id] as unknown as boolean)
        ) {
          return [...acc, { ...notification, isActive: data[notification.id] }];
        }
        return acc;
      },
      [],
    ) as unknown as UserNotification[];

    for (const key in dirtyFields) {
      const foundItem = updatedNotifications?.find((item) => item.id === key);
      if (foundItem) {
        updatedData.push(foundItem);
      }
    }
    onSubmit(updatedData);
  };

  return (
    <Flex
      width={"100%"}
      border="1px solid"
      borderColor="gray.300"
      borderRadius="10px"
    >
      <Accordion
        allowToggle
        width={"100%"}
        borderTop={"transparent"}
        borderBottom={"transparent"}
        defaultIndex={0}
      >
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <Text textStyle="h2" marginLeft={4} marginTop={1}>
                  {t("main.notifications", { ns: "navbar" })}
                </Text>
              </Box>

              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel marginLeft={2} marginRight={2}>
            {saveUpdatedNotifications && (
              <Box
                my={4}
                py={5}
                borderRadius="10px"
                bg={COLORS.lightRed}
                position={"sticky"}
                top={4}
                zIndex={1}
                w={"full"}
              >
                <Text px={4}>{t("usavedChanges", { ns: "sites" })}</Text>
              </Box>
            )}
            {!notificationsIsFetching && notifications?.length > 0 ? (
              <Flex flexDirection={"column"}>
                <form
                  onSubmit={handleSubmit(onSubmitForm)}
                  id="notificationForm"
                >
                  <VStack spacing={2} align="start">
                    {Object.keys(remappedNotifications).map(
                      (notificationKey, index) => {
                        const notification =
                          remappedNotifications[notificationKey];
                        return (
                          <Fragment key={notification.id}>
                            <FormControl
                              display={"flex"}
                              justifyContent={"space-between"}
                            >
                              <FormLabel fontWeight={600}>
                                {t(
                                  "user." + notification.notification + ".name",
                                )}
                              </FormLabel>
                              <Flex gap={8} justifyContent={"flex-start"}>
                                {notification.channels.map(
                                  (channel, channelIndex: number) => {
                                    const isChecked =
                                      checkboxState[channel.id] ??
                                      channel.isActive;
                                    return (
                                      <Box
                                        position="relative"
                                        key={`${channel.id}_${channelIndex}`}
                                      >
                                        <Checkbox
                                          id={`${channel.id}`}
                                          style={{
                                            position: "absolute",
                                            opacity: 0,
                                            zIndex: -1,
                                            cursor: "pointer",
                                            width: "1px",
                                            height: "1px",
                                            overflow: "hidden",
                                          }}
                                          defaultChecked={isChecked}
                                          {...register(
                                            `${channel.id}` as const,
                                          )}
                                        />
                                        <label
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleCheckboxChange(channel.id);
                                          }}
                                          htmlFor={`${channel.id}`}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <Tooltip
                                            label={
                                              isChecked
                                                ? channel.channel === "mail"
                                                  ? t("disableMail")
                                                  : t("disableNotification")
                                                : channel.channel === "push"
                                                  ? t("enableNotification")
                                                  : t("enableMail")
                                            }
                                          >
                                            <Tag
                                              bgColor={
                                                isChecked
                                                  ? COLORS.active
                                                  : COLORS.error
                                              }
                                              color="white"
                                              fontWeight={600}
                                              p={2}
                                              border={`3.5px solid ${isChecked ? COLORS.active : COLORS.error}`}
                                              _hover={
                                                isChecked
                                                  ? {
                                                      boxSizing: "border-box",
                                                      border:
                                                        "3.5px solid #38B2AC ",
                                                      boxShadow:
                                                        "10px 11px 21px -10px rgba(0,0,0,0.72)",
                                                      transition:
                                                        "all 0.1s ease-out",
                                                    }
                                                  : {
                                                      boxSizing: "border-box",
                                                      border:
                                                        "3.5px solid #C53030",
                                                      boxShadow:
                                                        "10px 11px 21px -10px rgba(0,0,0,0.72)",
                                                      transition:
                                                        "all 0.1s ease-out",
                                                    }
                                              }
                                            >
                                              {!notificationsIsFetching &&
                                                (channel.channel === "push" ? (
                                                  <IoMdNotifications
                                                    size={"1.5rem"}
                                                  />
                                                ) : (
                                                  <IoIosMailUnread
                                                    size={"1.5rem"}
                                                  />
                                                ))}
                                              {notificationsIsFetching && (
                                                <Spinner size="md" />
                                              )}
                                            </Tag>
                                          </Tooltip>
                                        </label>
                                      </Box>
                                    );
                                  },
                                )}
                              </Flex>
                            </FormControl>
                            <Text
                              fontStyle={"italic"}
                              fontSize={"0,85rem"}
                              color={"gray.500"}
                            >
                              {" "}
                              {t(
                                "user." +
                                  notification.notification +
                                  ".description",
                              )}
                            </Text>
                            <Divider />
                          </Fragment>
                        );
                      },
                    )}
                  </VStack>
                </form>
              </Flex>
            ) : (
              <Text>{t("noItems", { ns: "notifications" })}</Text>
            )}
            {saveUpdatedNotifications && (
              <Flex justifyContent={"flex-end"} mt={6}>
                <Button
                  type="submit"
                  form={"notificationForm"}
                  colorScheme={"blue"}
                >
                  {t("save", { ns: "common" })}
                </Button>
              </Flex>
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  );
};

export default UserNotificationsView;
