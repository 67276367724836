import { makeExport } from "../../../core/app/factory/export/useCases";

class ExportViewModel {
  async export(
    companyId: string,
    topic: string,
    siteId?: string,
    siteIds?: string[],
    startDate?: Date,
    endDate?: Date,
    permissionSites?: { [key: string]: string }
  ): Promise<boolean> {
    return await makeExport().Call({
      companyId,
      topic,
      siteId,
      siteIds,
      startDate,
      endDate,
      permissionSites,
    });
  }
}

export default ExportViewModel;
