import {
  Box,
  Button,
  Flex,
  ModalHeader,
  Select,
  Skeleton,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";
import { HiPencil } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { SortDirection } from "../../../../domain/entities/interfaces/paginatedResults";
import Role, { RoleContext } from "../../../../domain/entities/role";
import { COLORS } from "../../../assets/theme/colors";
import ActionBar from "../../../components/Common/ActionBar";
import ActionBarItem from "../../../components/Common/ActionBarItem";
import BaseModal from "../../../components/Common/alerts/BaseModal";
import DeleteButton from "../../../components/Common/table/DeleteButton";
import TableColumnHeader from "../../../components/Common/table/TableColumnHeader";
import useRolesViewModel from "../../../hooks/Roles/useRolesViewModel";
import ContentLayout from "../../../layout/ContentLayout";
import { Alert } from "../../Common/Alert";
import CreateUpdateRoleView from "./CreateRoleView";
import SimpleTable from "../../../components/Common/table/SimpleTable";
import { Permission } from "../../../components/Permissions/Permissions";
import { useHasPermissions } from "../../../components/Permissions/RenderIf";
import { useMediaQuery } from "@chakra-ui/react";
import ColumnFilterComponent from "../../../components/Common/table/ColumnFilterComponent";
import TextInput from "../../../components/Common/TextInput";
import { Filter } from "../../../hooks/Site/useSiteResourceDetailViewModel";
import { updateFilterWithDelete } from "../../../../utils";

export type RolesFilter = {
  name?: string;
};

const RolesView = () => {
  // Filter.
  const [nameSort, setNameSort] = useState<SortDirection>(null);
  const [filter, setFilter] = useState<RolesFilter>({});
  const [contextFilter, setContextFilter] = useState<RoleContext | undefined>(
    undefined,
  );

  // Hooks.
  const navigate = useNavigate();
  const { t } = useTranslation("settings");
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const {
    roles,
    isLoading,
    updateError,
    clearUpdateError,
    createRole,
    updateRole,
    setRoleLinkedUsersParams,
    roleLinkedUsersResponse,
    roleLinkedUsersFetching,
    deleteRole,
  } = useRolesViewModel(nameSort, contextFilter, filter);

  // Local state.
  const [deleteTarget, setDeleteTarget] = useState<Role | undefined>();
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showUpdate, setShowUpdate] = useState<boolean>(false);
  const [role, setRole] = useState<Role>();
  const [context, setContext] = useState<string>();
  const [fallbackRole, setFallbackRole] = useState<string | undefined>(
    undefined,
  );
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const canEditRoles = useHasPermissions([Permission.Settings_EditRoles]);

  // Helper functions.
  const cancelCreate = () => {
    setShowCreate(false);
    setIsCreating(false);
  };
  const cancelUpdate = () => setShowUpdate(false);

  const onCreate = (name: string, context: RoleContext) => {
    setIsCreating(true);
    createRole({ name, context }, cancelCreate);
  };

  const onUpdate = (name: string) => {
    updateRole({ roleId: role.id, name }, cancelUpdate);
  };

  const cancelDelete = () => {
    setFallbackRole(undefined);
    setDeleteTarget(undefined);
  };

  const askDelete = (role: Role) => {
    setRole(role);
    setContext(role.context);
    return (
      setDeleteTarget(role),
      setRoleLinkedUsersParams({ roleId: role.id, context: role.context })
    );
  };

  const onDelete = () => {
    return deleteRole(
      {
        roleId: deleteTarget.id,
        newRoleId: fallbackRole,
        contextRole: context,
      },
      cancelDelete,
    );
  };

  const updateContextFilter = (e: ChangeEvent<HTMLSelectElement>) => {
    switch (e.target.value) {
      case RoleContext.GENERAL:
        setContextFilter(RoleContext.GENERAL);
        break;
      case RoleContext.SITE:
        setContextFilter(RoleContext.SITE);
        break;
      case RoleContext.WORKSITE:
        setContextFilter(RoleContext.WORKSITE);
        break;
      default:
        setContextFilter(undefined);
        break;
    }
  };

  return (
    <>
      <ContentLayout
        action={
          <ActionBar>
            <ActionBarItem
              onClick={() => setShowCreate(true)}
              icon={FiPlus}
              description={t("roles.add")}
            />
          </ActionBar>
        }
      >
        <Flex
          flex={1}
          h="100%"
          width={isMobile ? "900px" : "100%"}
          padding={10}
          textAlign="center"
          flexDirection="column"
          alignItems="start"
          justifyContent="start"
        >
          <Text textColor={COLORS.sikuroBlue} fontSize={20} fontWeight={"bold"}>
            {t("roles.title")}
          </Text>
          {roles && roles.length > 0 && (
            <Text fontSize={16} color="black" fontWeight={"normal"}>
              {t("roles.subtitle")}
            </Text>
          )}
          {
            // No role available.
            roles?.length <= 0 && (
              <Text marginBottom={4} fontWeight="bold">
                {t("roles.noRoleFound")}
              </Text>
            )
          }

          <Flex
            flexDirection={"column"}
            alignItems={"start"}
            border="1px solid"
            borderColor="gray.300"
            borderRadius="10px"
            width="100%"
            position="relative"
            overflow={"hidden"}
            marginTop={3}
          >
            <SimpleTable
              tableId="roles-table"
              isLoading={isLoading}
              emptyText={t("noRoles")}
              showEmptyText={roles.length === 0}
            >
              <Thead>
                <Tr>
                  <Th>
                    <TableColumnHeader
                      text={t("roles.name")}
                      sort={{
                        direction: nameSort,
                        handler: (d) => setNameSort(d),
                      }}
                      filter={{
                        isActive: !!filter["name"],
                        component: (
                          <TextInput
                            value={filter["name"] as string}
                            onChange={(value) =>
                              setFilter({
                                name: value,
                              })
                            }
                            placeholder={t("search", { ns: "common" })}
                          />
                        ),
                      }}
                    />
                  </Th>
                  <Th colSpan={2}>
                    <TableColumnHeader
                      text={t("roles.context")}
                      filter={{
                        component: (
                          <Select
                            value={contextFilter}
                            onChange={updateContextFilter}
                          >
                            <option value={undefined}>
                              {t("roles.allContexts")}
                            </option>
                            <option value={RoleContext.GENERAL}>
                              {t("roles.generalContext")}
                            </option>
                            <option value={RoleContext.SITE}>
                              {t("roles.siteContext")}
                            </option>
                            <option value={RoleContext.WORKSITE}>
                              {t("roles.worksiteContext")}
                            </option>
                          </Select>
                        ),
                        isActive: contextFilter !== undefined,
                      }}
                    />
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {roles?.map((role) => (
                  <Tr
                    key={role.id}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      !showUpdate && canEditRoles && !role.isSystem
                        ? navigate(`/settings/roles/${role.id}`)
                        : null
                    }
                  >
                    <Td>
                      {!showUpdate && (
                        <Box display={"flex"} alignItems={"center"} gap={4}>
                          <Text>{role.name}</Text>
                          {canEditRoles && !role.isSystem && (
                            <Button bg={"transparent"}>
                              <HiPencil
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowUpdate(true);
                                  setRole(role);
                                }}
                              />
                            </Button>
                          )}
                        </Box>
                      )}
                    </Td>
                    <Td>{t(`roles.${role.context}Context`)}</Td>
                    <Td alignContent="flex-end" isNumeric>
                      {canEditRoles && !role.isSystem && (
                        <DeleteButton
                          aria-label="delete-role"
                          onClick={(e) => {
                            e.stopPropagation();
                            askDelete(role);
                          }}
                        />
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </SimpleTable>
          </Flex>
        </Flex>
      </ContentLayout>

      {showCreate && (
        <CreateUpdateRoleView
          onCancel={cancelCreate}
          onCreate={onCreate}
          isCreating={isCreating}
        />
      )}
      {showUpdate && (
        <CreateUpdateRoleView
          onCancel={cancelUpdate}
          onUpdate={onUpdate}
          update={true}
          value={role.name}
        />
      )}
      {deleteTarget && (
        <BaseModal
          onConfirm={onDelete}
          onClose={cancelDelete}
          onConfirmDisabled={
            roleLinkedUsersResponse > 0 &&
            context === "general" &&
            (fallbackRole === undefined || fallbackRole === "")
          }
        >
          <ModalHeader>{t("warning", { ns: "common" })}</ModalHeader>
          {!roleLinkedUsersFetching && roleLinkedUsersResponse == 0 && (
            <Box pl={6}>
              <Text>
                {t("roles.confirmDeleteRole", { roleName: deleteTarget.name })}
              </Text>
            </Box>
          )}
          {!roleLinkedUsersFetching &&
            roleLinkedUsersResponse > 0 &&
            context === "general" && (
              <Box pl={6}>
                <Text py="5">
                  {t("roles.deleteWithFallback", { roleLinkedUsersResponse })}
                </Text>
                <Select
                  onChange={(e) => setFallbackRole(e.target.value)}
                  value={undefined}
                  placeholder={t("roles.fallbackRole", { ns: "settings" })}
                >
                  {roles &&
                    roles
                      .filter(
                        (r) =>
                          r.id !== deleteTarget.id &&
                          r.context === RoleContext.GENERAL,
                      )
                      .map((role) => (
                        <option key={role.id} value={role.id}>
                          {role.name}
                        </option>
                      ))}
                </Select>
              </Box>
            )}
          {!roleLinkedUsersFetching &&
            roleLinkedUsersResponse > 0 &&
            (context === "site" || context === "worksite") && (
              <Box pl={6}>
                <Text py="5">
                  {t("roles.deteleSiteRole", { roleLinkedUsersResponse })}
                </Text>
              </Box>
            )}
          <Skeleton isLoaded={!roleLinkedUsersFetching} height="20px" />
        </BaseModal>
      )}
      {
        // Show update error.
        updateError && (
          <Alert
            variant="warning"
            title={t(updateError)}
            onClose={clearUpdateError}
            message={t(`${updateError}Desc`)}
          />
        )
      }
    </>
  );
};

export default RolesView;
