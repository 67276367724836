import SiteRepository, { LinkResourcesToSiteWarning } from '../../repositories/siteRepository';
import UseCase from '../../../core/app/usecase';
import { ResourceType } from '../../../presentation/screens/Site/ResourceSelectableTable';

interface Args {
	companyId: string;
	resourceType: ResourceType;
	resourceIds: string[];
	siteIds: string[];
	selectAllSites?: boolean;
	selectAllResources?: boolean;
}

class AddResourcesToSites implements UseCase<Args, LinkResourcesToSiteWarning> {
	private siteRepository: SiteRepository;

	constructor(siteRepository: SiteRepository) {
		this.siteRepository = siteRepository;
	}

	async Call({ companyId, resourceType, resourceIds, siteIds, selectAllSites, selectAllResources }: Args): Promise<LinkResourcesToSiteWarning> {
		return await this.siteRepository.addResourcesToSites(companyId, resourceType, resourceIds, siteIds, selectAllSites, selectAllResources);
	}
}

export default AddResourcesToSites;
