import { Box, Flex } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DeleteActionAlert } from "../../Common/DeleteActionAlert";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { RequirementSubject } from "../../../../domain/entities/requirementSubject.enum";
import AddDocumentTypesModal from "../../../components/Views/common/AddDocumentTypesModal";
import SiteResourceDocuments, {
  ResourceDocumentsPermissions,
} from "../../../components/Views/common/SiteResourceDocuments";
import useSupplierDocumentsViewModel from "../../../hooks/Document/useSupplierDocumentsViewModel";
import { DocumentCommunicationHook } from "../../../hooks/Document/useDocumentCommunicationVIewModel";
import { useSupplierOrSiteFilesDownloader } from "../../../hooks/Document/useDocumentsFilesDownloader";
import RejectionSummary, {
  mapRejectionGroups,
  RejectionReasonGroup,
} from "../../../components/Views/Documents/RejectionSummary";
import { AiTaskSectionCode } from "../../../../domain/entities/aiTaskSectionCode";
import { UseDocumentAI } from "../../../hooks/Document/useDocumentAI";

type SupplierDocumentsViewProps = {
  target: "company" | "site";
  showAddRequirementModal: boolean;
  setShowAddRequirementModal: Dispatch<SetStateAction<boolean>>;
  communicationProps: DocumentCommunicationHook;
  autosize?: boolean;
  readOnly?: boolean;
  permissions: ResourceDocumentsPermissions;
  isTablet: boolean;
  aiTaskSectionCode: AiTaskSectionCode;
  aiDocumentHook: UseDocumentAI;
  showManageTask: boolean;
  setShowManageTaskWithAi: (show: boolean) => void;
};

const SupplierDocumentsView = ({
  showAddRequirementModal,
  permissions,
  setShowAddRequirementModal,
  communicationProps,
  isTablet,
  target,
  autosize,
  readOnly = false,
  aiTaskSectionCode,
  aiDocumentHook,
  showManageTask,
  setShowManageTaskWithAi,
}: SupplierDocumentsViewProps) => {
  const { siteId } = useParams();

  const { t } = useTranslation("supplier");
  const {
    docs,
    refetchDocuments,
    fetchingDocuments,
    filterDocuments,
    sortDocuments,
    updateSortDocuments,
    updateFilterDocuments,
    documentEvaluationsProps,
    documentInfoProps,
    documentTypesProps,
    deleteRequirement,
    addRequirementsToTarget,
  } = useSupplierDocumentsViewModel(siteId, target, aiTaskSectionCode, (docs) =>
    updateRejectionItem(target, docs)
  );

  const [selectedRequirement, setSelectedRequirement] = useState<string>();
  const [showAskConfirmDelete, setShowAskConfirmDelete] =
    useState<boolean>(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState<boolean>(false);
  const [addRequirementsIsLoading, setAddRequirementsIsLoading] =
    useState<boolean>(false);
  const [rejectionReasons, setRejectionReasons] = useState<
    RejectionReasonGroup[]
  >([]);

  const downloader = useSupplierOrSiteFilesDownloader(siteId, target);

  const updateRejectionItem = (resource, documents) => {
    // Create a copy of the original array
    const updatedRejectedReasons = [...rejectionReasons];

    // Find the index of the item to update
    const index = rejectionReasons.findIndex(
      (item) => item.resource === resource
    );

    const updatedRejectedGroups = mapRejectionGroups([
      {
        resource: resource,
        documents: documents,
      },
    ])[0];

    // guard to check that the group has been created
    if (!updatedRejectedGroups) {
      return;
    }

    // If the item exists, update its mapping
    if (index !== -1) {
      updatedRejectedReasons[index] = updatedRejectedGroups;

      // Update the state with the modified array
      setRejectionReasons(updatedRejectedReasons);
    } else {
      setRejectionReasons((prevRejectionReasons) => [
        ...prevRejectionReasons,
        updatedRejectedGroups,
      ]);
    }
  };

  const availableDocumentTypes = useMemo(
    () =>
      documentTypesProps.systemDocTypes.filter((doc) => {
        return !docs.find((document) => document?.documentTypeId === doc.id);
      }),
    [documentTypesProps.systemDocTypes, docs]
  );

  const handleAddRequirements = async (documentTypes) => {
    setAddRequirementsIsLoading(true);
    await addRequirementsToTarget(documentTypes);
    setShowAddRequirementModal(false);
    setAddRequirementsIsLoading(false);
  };

  const cancelConfirmDelete = () => {
    setShowAskConfirmDelete(false);
    setSelectedRequirement(undefined);
  };

  const confirmDelete = async () => {
    setDeleteIsLoading(true);
    await deleteRequirement(
      selectedRequirement,
      target as unknown as RequirementSubject
    );
    cancelConfirmDelete();
    setDeleteIsLoading(false);
  };
  useEffect(() => {
    documentTypesProps.setGetSystemDocTypes(true);
  }, []);

  return (
    <Flex width={"100%"} flexDirection={"column"}>
      <Box my={4}>
        <RejectionSummary groups={rejectionReasons} />
      </Box>
      <SiteResourceDocuments
        autosize={autosize}
        downloader={downloader}
        documents={docs}
        documentsRefetch={refetchDocuments}
        sortDocuments={sortDocuments}
        documentsHasNextPage={null}
        documentsFetchNextPage={null}
        filterDocuments={filterDocuments}
        updateFilterDocuments={updateFilterDocuments}
        updateSortDocuments={updateSortDocuments}
        isWorkingSite={true}
        {...documentEvaluationsProps}
        {...documentInfoProps}
        requirementSubject={target as unknown as RequirementSubject}
        isFetchingDocuments={fetchingDocuments}
        communicationProps={communicationProps}
        permissions={permissions}
        isReadOnly={readOnly}
        setSiteResourceHookDocumentId={
          documentEvaluationsProps.setSupplierHookDocumentId
        }
        isTablet={isTablet}
        aiTaskSectionCode={aiTaskSectionCode}
        documentsAiHook={aiDocumentHook}
        showManageTaskWithAi={showManageTask}
        setShowManageTaskWIthAi={setShowManageTaskWithAi}
        showCommunications={true}
      />

      {showAddRequirementModal && (
        <AddDocumentTypesModal
          {...documentTypesProps}
          isRequirement
          isAddingDocumentsApi={addRequirementsIsLoading}
          documentTypes={availableDocumentTypes}
          onClose={() => setShowAddRequirementModal(false)}
          onConfirm={handleAddRequirements}
          isFetching={documentTypesProps.systemDocumentTypesFetching}
          isWorkingSite={true}
          showAvailableDocuments={target === "company"}
        />
      )}

      <DeleteActionAlert
        isLoading={deleteIsLoading}
        onConfirm={confirmDelete}
        onCancel={cancelConfirmDelete}
        mainTitle={t("warning", { ns: "common" })}
        title={t("confirmDelete", { ns: "requirements" })}
        leftButtonText={t("confirm", { ns: "common" })}
        rightButtonText={t("cancel", { ns: "common" })}
        isOpen={showAskConfirmDelete}
      />
    </Flex>
  );
};

export default SupplierDocumentsView;
