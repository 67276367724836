import { Tr as ChakraTr, Thead, Th, Tbody, Td } from "@chakra-ui/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import Requirement from "../../../../domain/entities/requirement";
import Variant from "../../../../domain/entities/variant";
import BaseModal from "../../Common/alerts/BaseModal";
import DnDTable from "../../Common/table/DnDTable";
import TagSelect from "../../Common/TagSelect";

interface OrderRequirementsByVariantModalProps {
  onClose: () => void;
  requirements: Requirement[];
  isButtonDisabled: boolean;
  autosize?: boolean;
  variants: Variant[];
  setVariantId: Dispatch<SetStateAction<string>>;
  moveRequirementsByVariant: (requirements: Requirement[]) => void;
  isLoading: boolean;
}

export const OrderRequirementsByVariantModal = ({
  onClose,
  requirements,
  variants,
  setVariantId,
  moveRequirementsByVariant,
  isLoading,
}: OrderRequirementsByVariantModalProps) => {
  const { t } = useTranslation();
  const [selectedVariant, setSelectedVariant] = useState<string>();

  useEffect(() => {
    if (variants) {
      setSelectedVariant(variants.filter((v) => v.name === "default")[0].id);
      setVariantId(variants.filter((v) => v.name === "default")[0].id);
    }
  }, [variants]);

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const newArray = [...requirements];
    const index = requirements.findIndex(
      (obj) => obj.id === result.draggableId,
    );
    const movedObject = newArray.splice(index, 1)[0];
    newArray.splice(result.destination.index, 0, movedObject);
    moveRequirementsByVariant(newArray);
  };

  return (
    <BaseModal
      title={t("orderRequirements", { ns: "requirements" })}
      onClose={() => {
        setSelectedVariant(undefined), onClose();
      }}
      size="6xl"
      onCancel={false}
      bodyOverflow={"scroll"}
    >
      <TagSelect
        tags={variants}
        isMulti={false}
        style={{ zIndex: "2" }}
        selectedTags={selectedVariant ? [selectedVariant] : []}
        setSelectedTags={(variant) => {
          setVariantId(variant[0]);
          setSelectedVariant(variant[0]);
        }}
        label={t("variant", { ns: "siteResources" })}
        defaultMenuIsOpen={false}
      />
      <DnDTable
        dnd={{ onDragEnd, droppableId: "requirements-table" }}
        isLoading={isLoading}
        emptyText={t("noRequirementsFound", { ns: "requirements" })}
        showEmptyText={
          selectedVariant === undefined || requirements?.length === 0
        }
      >
        <Thead>
          <ChakraTr>
            <Th>{t("name")}</Th>
          </ChakraTr>
        </Thead>
        <Tbody>
          {selectedVariant &&
            requirements?.map((requirement) => (
              <ChakraTr key={requirement.id} id={requirement.id}>
                <Td>{requirement.documentType.name}</Td>
              </ChakraTr>
            ))}
        </Tbody>
      </DnDTable>
    </BaseModal>
  );
};
