import User from "../../../domain/entities/user";
import {
  makeCreateUser,
  makeDeleteUser,
  makeGetUsers,
  makeRenewInvitation,
} from "../../../core/app/factory/users/usecases";
import { GetUsersFilter } from "../../../domain/repositories/filters";

class UsersListViewModel {
  private getUC = makeGetUsers();
  private createUC = makeCreateUser();
  private deleteUC = makeDeleteUser();
  private renewInvitationUC = makeRenewInvitation();

  constructor(private companyId: string) {}

  // Public.
  async get(filters?: GetUsersFilter): Promise<User[]> {
    // TODO: add support for sorting (propagate to server).
    return await this.getUC.Call({ companyId: this.companyId, filters });
  }

  async renewInvitation(invitationToken): Promise<void> {
    return await this.renewInvitationUC.Call({
      companyId: this.companyId,
      invitationToken,
    });
  }

  async create(name: string, email: string, role): Promise<User> {
    return await this.createUC.Call({
      companyId: this.companyId,
      email,
      name,
      role,
    });
  }

  async delete(userId: string): Promise<boolean> {
    return await this.deleteUC.Call({ companyId: this.companyId, userId });
  }
}

export default UsersListViewModel;
