import { useMemo } from "react";
import { useAuth } from "../../providers/Auth0JWTProvider";
import ExportViewModel from "../../viewmodels/export/ExportViewModel";

const useExportViewModel = () => {
  const { companyId } = useAuth();
  const viewModel = useMemo(() => new ExportViewModel(), []);

  const initiateExportFiles = async (
    topic: string,
    onComplete?: (ok: boolean) => void,
    id?: string,
    ids?: string[],
    startDate?: Date,
    endDate?: Date,
    permissionSites?: { [key: string]: string }
  ) => {
    viewModel
      .export(companyId, topic, id, ids, startDate, endDate, permissionSites)
      .then((ok) => onComplete(ok))
      .catch(() => onComplete(false));
  };

  return {
    initiateExportFiles,
  };
};

export default useExportViewModel;
