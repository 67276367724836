import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		ns: [
			'common',
			'errors',
			'navbar',
			'permissions',
			'enum',
			'onboarding',
			'workers',
			'vehicles',
			'machines',
			'tools',
			'chemicals',
			'sites',
			'documents',
			'companies',
			'requirements',
			'settings',
			'documentsPresets',
			'onboarding',
			'siteResources',
			'supplier',
			'evaluations',
			'badges',
			'badgeReaders',
			'notifications',
			'dashboard',
			'staff',
			'communication',
			'validation',
			'supplier',
			'account',
			'userSettings',
			'audit',
			'webhooks',
			'privacyPolicy',
			'export',
		],
		defaultNS: 'common',
		fallbackLng: { 'it-it': ['it'], 'it-IT': ['it'], default: ['en'] },
		load: 'languageOnly',
		supportedLngs: ['en', 'it', 'it-it', 'it-IT'],
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		react: {
			useSuspense: false //   <---- this will do the magic
		}
	});

export default i18n;
