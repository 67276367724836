import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useLocation, useSearchParams } from "react-router-dom";
import { ActivationViewModel } from "../../viewmodels/activation/ActivationViewModel";

const useActivationViewModel = () => {
  const [query] = useSearchParams();
  const location = useLocation();
  const type = location.pathname.split("/")[1];
  const token = query.get("token");
  const companyId = query.get("company");
  const owner = query.get("owner");
  const siteId = query.get("site");
  const [activationUserMsg, setActivationUserStatus] = useState<string>();
  const [activationSupplierMsg, setActivationSupplierStatus] =
    useState<string>();
  const [activationSubcontractorMsg, setActivationSubcontractorStatus] =
    useState<string>();
  const [confirmSubcontractorMsg, setConfirmSubcontractorStatus] =
    useState<string>();
  const [showExploreBtn, setShowExploreBtn] = useState<boolean>(false);
  const viewModel = new ActivationViewModel();

  const activateUser = useMutation(
    ["activate-user", token, companyId, siteId],
    async () => {
      return await viewModel.activateUser(token, companyId, siteId);
    },
    {
      onSuccess: () => (
        setActivationUserStatus("successUserActivation"),
        setShowExploreBtn(true)
      ),
      onError: (error: Error) => setActivationUserStatus(error.message),
      retry: false,
    },
  );
  const activateSupplier = useMutation(
    ["activate-supplier", token, companyId, siteId],
    async () => {
      return await viewModel.activateSupplier(token, companyId, siteId);
    },
    {
      onSuccess: () => (
        setActivationSupplierStatus("successSupplierActivation"),
        setShowExploreBtn(true)
      ),
      onError: (error: Error) => setActivationSupplierStatus(error.message),
      retry: false,
    },
  );

  const activateSubcontractor = useMutation(
    ["activate-subcontractor", token, companyId, siteId],
    async () => {
      return await viewModel.activateSubcontractor(token, companyId, siteId);
    },
    {
      onSuccess: () => (
        setActivationSubcontractorStatus("successSubcontractorActivation"),
        setShowExploreBtn(true)
      ),
      onError: (error: Error) =>
        setActivationSubcontractorStatus(error.message),
      retry: false,
    },
  );

  const confirmSubcontractor = useMutation(
    ["confirm-subcontractor", token, owner, siteId],
    async () => {
      return await viewModel.confirmSubcontractor(token, owner, siteId);
    },
    {
      onSuccess: () => (
        setConfirmSubcontractorStatus("successConfirmSubcontractorActivation"),
        setShowExploreBtn(true)
      ),
      onError: (error: Error) => setConfirmSubcontractorStatus(error.message),
      retry: false,
    },
  );

  const actionMap = {
    "activate-user": {
      action: activateUser.mutate,
      msg: activationUserMsg,
      isLoading: activateUser.isLoading,
    },
    "activate-supplier": {
      action: activateSupplier.mutate,
      msg: activationSupplierMsg,
      isLoading: activateSupplier.isLoading,
      redirect: "/workingsites/" + siteId + "/details/",
    },
    "activate-subcontractor": {
      action: activateSubcontractor.mutate,
      msg: activationSubcontractorMsg,
      isLoading: activateSubcontractor.isLoading,
      redirect: "/workingsites/",
    },
    "confirm-subcontractor": {
      action: confirmSubcontractor.mutate,
      msg: confirmSubcontractorMsg,
      isLoading: confirmSubcontractor.isLoading,
      redirect: "/sites/" + siteId + "/suppliers",
    },
  };

  return {
    ...actionMap[type],
    showExploreBtn,
    siteId,
  };
};

export { useActivationViewModel };
