import UseCase from '../../../../core/app/usecase';
import Requirement from '../../../entities/requirement';
import SiteToolRepository from '../../../repositories/siteToolRepository';

type ARGS = {
	companyId: string;
	siteId: string;
	toolId: string;
	documentTypeId: string;
	isOptional: boolean;
	graceDays: number;
};

class AddRequirementToSiteTool implements UseCase<ARGS, Requirement> {
	private siteRepository: SiteToolRepository;

	constructor(siteRepository: SiteToolRepository) {
		this.siteRepository = siteRepository;
	}

	async Call(parameters: ARGS): Promise<Requirement> {
		return await this.siteRepository.addRequirementToSiteTool(
			parameters.companyId,
			parameters.siteId,
			parameters.toolId,
			parameters.documentTypeId,
			parameters.isOptional,
			parameters.graceDays,
		);
	}
}

export default AddRequirementToSiteTool;
