import { Box, Tbody, Td, Tr as ChakraTr } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { DragHandleIcon } from "@chakra-ui/icons";
import {
  BodyElement,
  getTableElement,
  HeadElement,
  SimpleTableProps,
} from "./SimpleTable";

interface DnDTableProps extends SimpleTableProps {
  dnd: {
    onDragEnd: (result: DropResult) => void;
    droppableId: string;
    overflow?: string;
  };
}

const handleDragStart = () => {
  // Loop over each row
  document.querySelectorAll("tr").forEach((tr) => {
    if (tr.style.width) return; // Skip if width already set
    // Loop over each cell in the row
    tr.querySelectorAll("td").forEach((td) => {
      // Store the computed width and apply it as an inline style
      td.style.width = window.getComputedStyle(td).width;
    });
  });
};

const DnDTable: React.FC<DnDTableProps> = ({ children, dnd, ...rest }) => {
  const bodyElem = getTableElement(children, Tbody);

  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "10px",
        overflow: dnd.overflow ? dnd.overflow : "hidden",
        border: "1px solid #CBD5E0",
      }}
    >
      <HeadElement {...rest}>{children}</HeadElement>
      <BodyElement
        {...rest}
        tableId={dnd.droppableId}
        tbody={
          <DragDropContext
            onDragEnd={dnd.onDragEnd}
            onBeforeDragStart={handleDragStart}
          >
            <Droppable droppableId={dnd.droppableId}>
              {(droppableProvided) => (
                <>
                  {React.cloneElement(bodyElem as ReactElement, {
                    ...droppableProvided.droppableProps,
                    ref: droppableProvided.innerRef,
                    children: (
                      <>
                        {Array.isArray(bodyElem?.props?.children) &&
                          bodyElem?.props?.children?.map((row, index) => {
                            const { children, id, ...rest } = row.props;
                            return (
                              <Draggable
                                draggableId={id}
                                key={id}
                                index={index}
                              >
                                {(provided) => (
                                  <ChakraTr
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...rest}
                                  >
                                    <DragIcon
                                      columnID={id}
                                      draggableProvided={provided}
                                    />
                                    {children}
                                  </ChakraTr>
                                )}
                              </Draggable>
                            );
                          })}
                        {droppableProvided.placeholder}
                      </>
                    ),
                  })}
                </>
              )}
            </Droppable>
          </DragDropContext>
        }
      />
    </Box>
  );
};

const DragIcon = ({
  columnID,
  draggableProvided,
}: {
  columnID: string;
  draggableProvided: DraggableProvided;
}) => {
  return (
    <Td width="50px" key={columnID}>
      <div {...draggableProvided.dragHandleProps}>
        <DragHandleIcon />
      </div>
    </Td>
  );
};

export default DnDTable;
