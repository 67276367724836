import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import Worker, { WorkerStatus } from "../../../../domain/entities/worker";
import { GetSitesFilter } from "../../../../domain/repositories/siteRepository";
import { updateFilterWithDelete } from "../../../../utils";
import { useAuth } from "../../../providers/Auth0JWTProvider";
import { WorkerViewModel } from "../../../viewmodels/workers/WorkerViewModel";
import { ResourceType } from "../../../screens/Site/ResourceSelectableTable";
import {
  GetAvailableBadgesFilters,
  GetBadgesFilters,
} from "../../../../domain/repositories/badgeRepository";
import { BadgeResourceType } from "../../../../domain/entities/badgeResourceType.enum";

const useWorkerViewModel = (id: string) => {
  const { companyId } = useAuth();
  const [sortResourceSites, setSortResourceSites] = useState<SortMeta>();
  const [filterResourceSites, setFilterResourceSites] =
    useState<GetSitesFilter>();
  const [sortSiteCollection, setSortSiteCollection] = useState<SortMeta>();
  const [filterSiteCollection, setFilterSiteCollection] =
    useState<GetSitesFilter>();
  const [sortWorkerBadge, setSortWorkerBadge] = useState<SortMeta>();
  const [filterWorkerBadge, setFilterWorkerBadge] =
    useState<GetAvailableBadgesFilters>({});
  const [badgeIds, setBadgeIds] = useState<string[]>([]);
  const [badgeId, setBadgeId] = useState<string>();
  const [selectAll, setSelectAll] = useState(false);
  const [enableGetResourceSites, setEnableGetResourceSites] = useState<{
    getSites: boolean;
    action: string;
  }>({ getSites: false, action: "" });
  const viewModel = useMemo(() => new WorkerViewModel(), []);
  const [act, setAct] = useState<string>();
  const [availableBadgesFilters, setAvailableBadgesFilters] =
    useState<GetAvailableBadgesFilters>({});
  const [availableBadgesSort, setAvailableBadgesSort] =
    useState<SortMeta>(null);
  const [showInfoSuccess, setShowInfoSuccess] = useState(false);
  const queryClient = useQueryClient();

  const {
    data: worker,
    isFetching,
    refetch: getWorker,
  } = useQuery<Worker, Error>(
    ["worker", companyId, id],
    async () => await viewModel.get(companyId, id),
    {
      retry: false,
      initialData: undefined,
    },
  );

  const getWorkerSites = useInfiniteQuery(
    [
      "resource-sites",
      companyId,
      id,
      sortResourceSites,
      filterResourceSites,
      act,
    ],
    async ({ pageParam = 1 }) => {
      const includeWorkingSites = act === "add";
      return await viewModel.getWorkerSites(
        companyId,
        id,
        sortResourceSites,
        filterResourceSites,
        pageParam,
        includeWorkingSites,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.results.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: !!sortResourceSites || !!filterResourceSites,
    },
  );

  const hasSites = async (action) => {
    setAct(action);
    await queryClient.invalidateQueries([
      "resource-sites",
      companyId,
      id,
      sortResourceSites,
      filterResourceSites,
      action,
    ]);
    const data = await getWorkerSites.refetch();
    const sites = data.data?.pages.flatMap((page) => page.results) ?? [];
    return sites.length > 0;
  };

  const getResourceBadge = useInfiniteQuery(
    ["resource-badge", companyId, id, sortWorkerBadge, filterWorkerBadge],
    async ({ pageParam = 1 }) => {
      const { results } = await viewModel.getResourceBadge(
        companyId,
        "workers",
        id,
        sortWorkerBadge,
        filterWorkerBadge,
        pageParam,
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    },
  );

  const getBadgeSitesQuery = useQuery(
    ["get-badge-sites", companyId, badgeId],
    async () => {
      return viewModel.getBadgeSites(companyId, badgeId);
    },
    {
      enabled: !!badgeId,
    },
  );

  const getAvailableBadgesQuery = useQuery(
    [
      "available-badges",
      companyId,
      availableBadgesFilters,
      availableBadgesSort,
    ],
    async () => {
      return await viewModel.getAvailableBadges(
        companyId,
        "workers",
        id,
        availableBadgesSort,
        availableBadgesFilters,
      );
    },
  );

  const linkBadgesToResourceMutation = useMutation(
    async () => {
      return await viewModel.linkBadgesToResource(
        companyId,
        badgeIds,
        BadgeResourceType.WORKER,
        id,
      );
    },
    {
      onError: (e) => console.error(e),
      onSuccess: () => {
        getResourceBadge.refetch(), setBadgeIds([]), setShowInfoSuccess(true);
      },
    },
  );

  const unlinkBadgeResourceMutation = useMutation(
    async () => {
      return await viewModel.unlinkBadgeResource(companyId, badgeId);
    },
    {
      onError: (e) => console.error(e),
      onSuccess: () => {
        getResourceBadge.refetch();
      },
    },
  );

  const updateMutation = useMutation(
    async ({ worker, imageFile }: { worker: Worker; imageFile?: File }) => {
      const { updatedAt, ...workerObj } = worker;
      return await viewModel.update(companyId, workerObj, imageFile);
    },
    {
      onError: (err) => {
        console.error(err);
      },
      onSuccess: () => getWorker(),
    },
  );

  const updateStatusMutation = useMutation(
    async (status: WorkerStatus) => {
      return await viewModel.setStatus(companyId, id, status);
    },
    {
      onError: (e) => console.error(e),
      onSuccess: () => getWorker(),
    },
  );

  const updateWorker = (worker: Worker) => {
    return updateMutation.mutateAsync({ worker });
  };

  const updateImage = (worker: Worker, imageFile?: File) => {
    return updateMutation.mutateAsync({
      worker,
      imageFile,
    });
  };

  const unlinkBadgeResource = () => {
    return unlinkBadgeResourceMutation.mutateAsync();
  };

  const linkBadgesToResource = () => {
    linkBadgesToResourceMutation.mutateAsync();
  };
  const updateStatus = (status: WorkerStatus) => {
    updateStatusMutation.mutateAsync(status);
  };
  const getResourceLinkableSites = useInfiniteQuery(
    [
      "site-collection",
      companyId,
      id,
      sortSiteCollection,
      filterSiteCollection,
    ],
    async ({ pageParam = 1 }) => {
      return await viewModel.getResourceLinkableSites(
        companyId,
        "worker" as ResourceType,
        id,
        sortSiteCollection,
        filterSiteCollection,
        pageParam,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    },
  );
  const linkWorkerToSites = (siteIds: string[]) => {
    linkWorkerToSitesMutation.mutateAsync(siteIds);
  };
  const linkWorkerToSitesMutation = useMutation(
    async (siteIds: string[]) => {
      return await viewModel.linkResourceToSites(companyId, id, siteIds);
    },
    {
      onError: (e) => console.error(e),
      onSuccess: () => getWorker(),
    },
  );

  const resourceBadges = getResourceBadge.data?.pages?.flat() ?? [];
  const badgeSites = getBadgeSitesQuery.data;
  const availableBadgesResource = getAvailableBadgesQuery.data;

  const resourceSites =
    getWorkerSites.data?.pages?.flatMap((page) => page.results) ?? [];
  const totalSiteCount = getWorkerSites.data?.pages?.[0]?.count ?? 0;

  return {
    worker,
    getWorker,
    isFetching,
    updateImage,
    updateWorker,
    updateStatus,
    resourceSites,
    isFetchingSites: getWorkerSites.isFetching,
    hasNextPage: getWorkerSites.hasNextPage,
    fetchNextPage: getWorkerSites.fetchNextPage,
    setEnableGetResourceSites,
    updateFilterResourceSites: (field, value) => {
      updateFilterWithDelete(setFilterResourceSites, field, value);
    },
    filterResourceSites,
    setSortResourceSites,
    sortResourceSites,
    hasSites,
    siteCollection: getResourceLinkableSites.data?.pages?.flat() ?? [],
    sortSiteCollection,
    setSortSiteCollection,
    filterSiteCollection,
    updateFilterSiteCollection: (field, value) => {
      updateFilterWithDelete(setFilterSiteCollection, field, value);
    },
    siteCollectionHasNextPage: getResourceLinkableSites.hasNextPage,
    siteCollectionFetchNextPage: getResourceLinkableSites.fetchNextPage,
    refetchSiteCollection: getResourceLinkableSites.refetch,
    linkWorkerToSites,
    isLinking: linkWorkerToSitesMutation.isLoading,
    resourceBadges,
    sortWorkerBadge,
    filterWorkerBadge,
    setFilterWorkerBadge,
    setSortWorkerBadge,
    hasBadgeNextPage: getResourceBadge.hasNextPage,
    fetchBadgeNextPage: getResourceBadge.fetchNextPage,
    isFetchingBadge: getResourceBadge.isFetching,
    setBadgeIds,
    badgeIds,
    badgeId,
    setBadgeId,
    badgeSites,
    updateFilterBadge: (field, value) => {
      updateFilterWithDelete(setFilterWorkerBadge, field, value);
    },
    availableBadgesResource,
    availableBadgesFilters,
    availableBadgesSort,
    setAvailableBadgesSort,
    availableBadgesIsLoading: getAvailableBadgesQuery.isLoading,
    availableBadgesRefetch: getAvailableBadgesQuery.refetch,
    updateFilterAvailableBadge: (field, value) => {
      updateFilterWithDelete(setAvailableBadgesFilters, field, value);
    },
    linkBadgesToResource,
    linkBadgesToResourceIsLoading: linkBadgesToResourceMutation.isLoading,
    showInfoSuccess,
    setShowInfoSuccess,
    unlinkBadgeResource,
    unlinkBadgeResourceIsLoading: unlinkBadgeResourceMutation.isLoading,
    totalSiteCount,
    selectAll,
    setSelectAll,
  };
};

export default useWorkerViewModel;
