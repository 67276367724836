import { EditableFormFieldProps } from "./FormField";
import { FC, ReactElement, cloneElement } from "react";
import { SimpleGrid, GridItem } from "@chakra-ui/react";

interface FormProps {
  isEditing: boolean;
  templateColumns?: string;
  fields: Array<ReactElement<Omit<EditableFormFieldProps, "isEditing">>>;
}

const Form: FC<FormProps> = ({
  isEditing = false,
  templateColumns = "1fr 1fr 1fr",
  fields,
}) => {
  return (
    <SimpleGrid templateColumns={templateColumns} gap={4}>
      {fields.map((f) => {
        const props = { ...f.props, isEditing };
        return (
          <GridItem key={f.key} colSpan={f.props.colSpan}>
            {cloneElement(f, { ...props })}
          </GridItem>
        );
      })}
    </SimpleGrid>
  );
};

export default Form;
