import { NewResource, SiteResourcesViewModel } from '../../hooks/Site/useSiteResourcesViewModel';
import {
	makeCreateSiteMachine,
	makeGetSiteMachines,
	makeDeleteSiteMachine,
	makeRestoreSiteMachine,
} from '../../../core/app/factory/siteMachines/usecases';
import { makeGetAvailableMachinesCount, makeGetMachines } from '../../../core/app/factory/machines/usecases';
import { makeGetSiteVariants } from '../../../core/app/factory/variants/usecases';
import { DocumentTypeResourceType } from '../../../mock/models/document-type';
import { makeGetSiteSpecializations } from '../../../core/app/factory/specializations/usecases';
import { SiteMachine, SiteMachineParams } from '../../../domain/entities/siteMachine';
import { GetSiteMachinesFilters } from '../../../domain/repositories/siteMachineRepository';
import { PaginatedResults, SortMeta } from '../../../domain/entities/interfaces/paginatedResults';
import { GetMachinesFilter } from '../../../domain/repositories/machineRepository';

class SiteMachinesViewModel implements SiteResourcesViewModel<SiteMachineParams> {
	async getSiteResources(
		companyId: string,
		siteId: string,
		page: number,
		archived?: boolean,
		filter?: GetSiteMachinesFilters,
		sort?: SortMeta,
		supplierId?: string,
	): Promise<PaginatedResults<SiteMachine>> {
		return await makeGetSiteMachines().Call({ companyId, siteId, page, perPage: 25, filter, sort, supplierId, archived });
	}

	async getOwnedResources(
		companyId: string,
		siteId: string,
		filter?: GetMachinesFilter,
		sort?: SortMeta,
		pageParam?: number,
	): Promise<PaginatedResults<SiteMachineParams>> {
		return (await makeGetMachines().Call({ companyId, filter: { siteAvailable: siteId, ...filter }, sort, pageParam })) as unknown as Promise<
			PaginatedResults<SiteMachineParams>
		>;
	}

	async getAvailableResourcesTotalCount(companyId: string, siteId: string, filter?: GetMachinesFilter, sort?: SortMeta): Promise<number> {
		return (await makeGetAvailableMachinesCount().Call({
			companyId,
			filter: { siteAvailable: siteId, ...filter },
			sort,
		})) as unknown as Promise<number>;
	}

	async getVariants(companyId: string, siteId: string): Promise<any[]> {
		return await makeGetSiteVariants().Call({ companyId, siteId, type: DocumentTypeResourceType.MACHINE });
	}

	async getSpecializations(companyId: string, siteId: string): Promise<any[]> {
		return await makeGetSiteSpecializations().Call({ companyId, siteId, type: DocumentTypeResourceType.MACHINE });
	}

	/*async addVariantToSiteResource(companyId: string, machineId: string, siteId: string, variantName: string): Promise<void> {
    await makeAddVariantToSiteMachine().Call({companyId, machineId, siteId, variantName})
  }

  async removeVariantFromSiteResource(companyId: string, machineId: string, siteId: string, variantId: string): Promise<void> {
    await makeRemoveVariantFromSiteMachine().Call({companyId, machineId, siteId, variantId})
  }

  async addSpecializationToSiteResource(companyId: string, machineId: string, siteId: string, specializationName: string): Promise<void> {
    await makeAddSpecializationToSiteMachine().Call({companyId, machineId, siteId, specializationName})
  }

  async removeSpecializationFromSiteResource(companyId: string, machineId: string, siteId: string, specializationId: string): Promise<void> {
    await makeRemoveSpecializationFromSiteMachine().Call({companyId, machineId, siteId, specializationId})
  }*/

	async createSiteResource(
		companyId: string,
		siteId: string,
		resources: NewResource[],
		selectAll: boolean,
		selectedResourceNumber: number,
		selectAllVariants: string,
		selectAllSpecializations: string[],
		filterSiteResources?: Record<string, string | string[]>,
	): Promise<boolean> {
		return await makeCreateSiteMachine().Call({
			companyId,
			siteId,
			resources,
			selectAll,
			selectedResourceNumber,
			selectAllVariants,
			selectAllSpecializations,
			filterSiteResources,
		});
	}

	async deleteSiteResource(companyId: string, machineId: string, siteId: string): Promise<void> {
		await makeDeleteSiteMachine().Call({ companyId, machineId, siteId });
	}

	async restoreSiteResource(companyId: string, machineId: string, siteId: string): Promise<void> {
		await makeRestoreSiteMachine().Call({ companyId, machineId, siteId });
	}
}

export default SiteMachinesViewModel;
