import { makePerformLogin } from "../../../domain/interactors/auth/performLogin";
import {
  SignUpCompany,
  SignUpUser,
} from "../../../domain/interactors/auth/args";
import {
  makeCreateBusinessAccount,
  makeGetBusinessTypes,
  makeUserAlreadyRegistered,
} from "../../../domain/interactors/auth/createBusinessAccount";
import { makeCreatePersonalAccount } from "../../../domain/interactors/auth/createPersonalAccount";
import { LoginResponse } from "../../../infrastructure/responses/auth";
import { makeRequestPasswordReset } from "../../../domain/interactors/auth/requestPasswordReset";
import { makeUserEmailAvailable } from "../../../core/app/factory/users/usecases";

class OnboardingViewModel {
  private createPersonalAccountUC = makeCreatePersonalAccount();
  private createBusinessAccountUC = makeCreateBusinessAccount();
  private userAlreadyRegisteredUC = makeUserAlreadyRegistered();
  private requestPasswordResetUC = makeRequestPasswordReset();
  private userEmailAvailableUC = makeUserEmailAvailable();
  private loginUC = makePerformLogin();
  private getBusinessTypesUC = makeGetBusinessTypes();

  async userAlreadyRegistered(
    token: string,
    company: string,
  ): Promise<{ already_registered: string }> {
    return this.userAlreadyRegisteredUC.Call(token, company);
  }

  async createBusinessAccount(data: SignUpCompany): Promise<boolean> {
    return this.createBusinessAccountUC.Call(data);
  }

  async createPersonalAccount(data: SignUpUser): Promise<boolean> {
    return this.createPersonalAccountUC.Call(data);
  }

  async login(email: string, password: string): Promise<LoginResponse> {
    return this.loginUC.Call({ email, password });
  }

  async requestPasswordReset(email: string): Promise<boolean> {
    return this.requestPasswordResetUC.Call(email);
  }

  async userEmailAvailable(email: string): Promise<boolean> {
    return await this.userEmailAvailableUC.Call({ email });
  }
  async getBusinessTypes(): Promise<string[]> {
    return await this.getBusinessTypesUC.Call();
  }
}

export default OnboardingViewModel;
