import { useParams } from "react-router-dom";
import { Permission } from "../../components/Permissions/Permissions";
import { SiteVehicleParams } from "../../../domain/entities/siteVehicle";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import useSiteVehiclesViewModel from "../../hooks/Site/useSiteVehiclesViewModel";
import SiteResourcesListView from "../../components/Views/Sites/SiteResourcesListView";

const SiteVehiclesView = () => {
  const { siteId } = useParams();
  const resourceColumns = ["name", "plate", "model"];
  return (
    <SiteResourcesListView<SiteVehicleParams>
      type={DocumentTypeResourceType.VEHICLE}
      useViewModel={useSiteVehiclesViewModel(
        siteId,
        resourceColumns,
        DocumentTypeResourceType.VEHICLE,
      )}
      resourceColumns={resourceColumns}
      addToSitePermissions={[
        Permission.Sites_Manage,
        Permission.Sites_AddVehicle,
      ]}
      removeFromSitePermissions={[
        Permission.Sites_Manage,
        Permission.Sites_RemoveVehicle,
      ]}
    />
  );
};

export default SiteVehiclesView;
