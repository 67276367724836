import { Box, Icon, Tooltip, forwardRef, keyframes } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { useState } from "react";
import { COLORS } from "../../assets/theme/colors";


// Define the keyframes for the rotation animation
const scaleAnimation = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.75);
  }
`;

// Define the keyframes for the opacity animation
const opacityAnimation = keyframes`
  0%,
  100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
`;

const ActionBarItem = forwardRef(
  (
    {
      icon,
      onClick,
      bgColor,
      color,
      description,
      isDisabled,
      disabledDescription,
      isAnimated
    }: ActionBarItemProps,
    ref,
  ) => {
    const defaultBgColor = "#eece23";
    const defaultIconColor = "black";
    const disabledIconColor = "gray.100";
    const disabledBgColor = "#ccc27f";
    let defaultHighlightColor = isDisabled ? "transparent" : COLORS.sikuroBlue;
    const [isButtonHovered, setIsButtonHovered] = useState(false);

    if (!bgColor) {
      bgColor = isDisabled ? disabledBgColor : defaultBgColor;
    }
    if (!color) {
      color = isDisabled ? disabledIconColor : defaultIconColor;
    }
    if (bgColor === COLORS.sikuroBlue) {
      defaultHighlightColor = defaultBgColor;
    }

    return (
      <Tooltip
        label={isDisabled ? disabledDescription : description}
        aria-label={description}
        placement="left"
      >
        <Box
          onClick={isDisabled ? null : onClick}
          cursor="pointer"
          ref={ref}
          paddingTop={1}
        >
          <Box
            onMouseEnter={() => setIsButtonHovered(true)}
            onMouseLeave={() => setIsButtonHovered(false)}
            borderRadius={"full"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            w={"2.8rem"}
            h={"2.8rem"}
            background={isButtonHovered ? defaultHighlightColor : "transparent"}
            cursor={"pointer"}
          >
            <Box
              borderRadius={"full"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              w={"88%"}
              h={"88%"}
              background={bgColor}
              animation={isAnimated ? `${opacityAnimation} 2s ease-in-out infinite` : "none"} // Adding the scaling and opacity animations
              cursor={"pointer"}
            >
              <Icon
                as={icon}
                boxSize={5}
                color={color}
                animation={isAnimated ? `${scaleAnimation} 2s ease-in-out infinite` : "none"} // Adding the scaling and opacity animations
              />
            </Box>
          </Box>
        </Box>
      </Tooltip>
    );
  },
);

interface ActionBarItemProps {
  icon: IconType;
  onClick?: () => void;
  bgColor?: string;
  color?: string;
  description: string;
  disabledDescription?: string;
  isDisabled?: boolean;
  isAnimated?: boolean;
}

export default ActionBarItem;
