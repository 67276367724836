import { makeGetSiteVariants } from "../../../core/app/factory/variants/usecases";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import Evaluation from "../../../domain/entities/evaluation";
import DocumentType from "../../../domain/entities/documentType";
import { makeGetDocumentTypes } from "../../../core/app/factory/documentTypes/usecases";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { SiteResourceDocument } from "../../../domain/entities/document";
import { BaseSiteEntityDetailViewModel } from "./BaseSiteResourceDetailViewModel";
import {
  PaginatedResults,
  SortMeta,
} from "../../../domain/entities/interfaces/paginatedResults";
import {
  makeAddRequirementToSupplier,
  makeAddSiteRequirementToSupplier,
  makeAutoEvaluateSupplier,
  makeDeleteSiteSupplierRequirement,
  makeEvaluateSupplier,
  makeGetSupplierDetail,
  makeGetSupplierEvaluations,
  makeSetSupplierStatus,
  makeUpdateSupplierVariant,
} from "../../../core/app/factory/suppliers/useCases";
import Supplier from "../../../domain/entities/supplier";
import {
  makeGetSiteSupplierDocuments,
  makeUpdateFile,
} from "../../../core/app/factory/documents/usecases";
import { SupplierEvaluationState } from "../../../domain/entities/supplierEvaluationState.enum";
import { RequirementSubject } from "../../../domain/entities/requirementSubject.enum";
import { SupplierStatus } from "../../../domain/entities/supplierStatus.enum";
import { makeGetSiteWorkers } from "../../../core/app/factory/siteWorkers/usecases";
import { makeGetSiteChemicals } from "../../../core/app/factory/siteChemicals/usecases";
import { SiteVehicle } from "../../../domain/entities/siteVehicle";
import { SiteChemical } from "../../../domain/entities/siteChemical";
import { SiteMachine } from "../../../domain/entities/siteMachine";
import { SiteTool } from "../../../domain/entities/siteTool";
import { SiteWorker } from "../../../domain/entities/siteWorker";
import { makeGetSiteTools } from "../../../core/app/factory/siteTools/usecases";
import { makeGetSiteMachines } from "../../../core/app/factory/siteMachines/usecases";
import { makeGetSiteVehicles } from "../../../core/app/factory/siteVehicles/usecases";
import { GetSiteResourcesFilters } from "../../hooks/Site/useSiteResourcesViewModel";
import { makeGetSpecializations } from "../../../core/app/factory/specializations/usecases";
import FileEntity from "../../../domain/entities/file";
import { GetResourceEvaluationFilter } from "../../../domain/repositories/siteRepository";
import { GetWorkingSiteDocumentsFilter } from "../../../domain/repositories/filters";

class SiteSupplierDetailViewModel extends BaseSiteEntityDetailViewModel {
  async getSiteResource(
    companyId: string,
    siteId: string,
    resourceId: string,
  ): Promise<Supplier> {
    return await makeGetSupplierDetail().Call({
      companyId,
      siteId,
      supplierId: resourceId,
    });
  }

  async getSiteResourceDocuments(
    companyId: string,
    siteId: string,
    resourceId: string,
    filterDocuments: GetWorkingSiteDocumentsFilter,
    sortDocuments: SortMeta,
    pageParam?: number,
  ): Promise<SiteResourceDocument[]> {
    return makeGetSiteSupplierDocuments().Call({
      siteId: siteId,
      companyId: companyId,
      supplierId: resourceId,
      target: "company",
      filter: filterDocuments,
      sort: sortDocuments,
      pageParam,
    });
  }

  async getSiteResourceSiteDocuments(
    companyId: string,
    siteId: string,
    resourceId: string,
    filterDocuments: GetWorkingSiteDocumentsFilter,
    sortDocuments: SortMeta,
    pageParam: number,
  ): Promise<SiteResourceDocument[]> {
    return makeGetSiteSupplierDocuments().Call({
      siteId: siteId,
      companyId: companyId,
      supplierId: resourceId,
      filter: filterDocuments,
      sort: sortDocuments,
      target: "site",
      pageParam,
    });
  }

  async evaluateSiteResource(
    companyId: string,
    siteId: string,
    resourceId: string,
    value: SupplierEvaluationState,
  ): Promise<void> {
    await makeEvaluateSupplier().Call({
      companyId,
      siteId,
      supplierId: resourceId,
      value,
    });
  }

  async autoEvaluateSiteResource(
    companyId: string,
    siteId: string,
    resourceId: string,
  ): Promise<void> {
    await makeAutoEvaluateSupplier().Call({
      companyId,
      siteId,
      supplierId: resourceId,
    });
  }

  async getSiteResourceEvaluations(
    companyId: string,
    siteId: string,
    resourceId: string,
    pageParam: number,
    sort?: SortMeta,
    filter?: GetResourceEvaluationFilter,
  ): Promise<Evaluation<SupplierEvaluationState>[]> {
    return (await makeGetSupplierEvaluations().Call({
      companyId,
      siteId,
      supplierId: resourceId,
      pageParam,
      sort,
      filter,
    })) as unknown as Promise<Evaluation<SupplierEvaluationState>[]>;
  }

  async getVariants(companyId: string, siteId: string): Promise<any[]> {
    return await makeGetSiteVariants().Call({
      companyId,
      siteId,
      type: DocumentTypeResourceType.COMPANY,
    });
  }

  async getSiteVariants(companyId: string, siteId: string): Promise<any[]> {
    return await makeGetSiteVariants().Call({
      companyId,
      siteId,
      type: DocumentTypeResourceType.SITE,
    });
  }

  async getSiteSpecializations(
    companyId: string,
    siteId: string,
  ): Promise<any[]> {
    return await makeGetSpecializations().Call({
      companyId,
      type: DocumentTypeResourceType.SITE,
    });
  }

  async updateSiteResourceVariant(
    companyId: string,
    resourceId: string,
    siteId: string,
    variantId?: string,
  ): Promise<void> {
    await makeUpdateSupplierVariant().Call({
      companyId,
      siteId,
      supplierId: resourceId,
      type: RequirementSubject.COMPANY,
      variantId,
    });
  }

  async updateSiteResourceSiteVariant(
    companyId: string,
    resourceId: string,
    siteId: string,
    variantId?: string,
  ): Promise<void> {
    await makeUpdateSupplierVariant().Call({
      companyId,
      siteId,
      supplierId: resourceId,
      type: RequirementSubject.SITE,
      variantId,
    });
  }

  async addRequirementToSiteResource(
    companyId: string,
    resourceId: string,
    siteId: string,
    documentTypeId: string,
    isOptional: boolean,
    graceDays: number,
  ): Promise<void> {
    await makeAddRequirementToSupplier().Call({
      companyId,
      supplierId: resourceId,
      siteId,
      documentTypeId,
      isOptional,
      graceDays,
    });
  }

  async addSiteRequirementToSupplier(
    companyId: string,
    resourceId: string,
    siteId: string,
    documentTypeId: string,
    isOptional: boolean,
    graceDays: number,
  ): Promise<void> {
    await makeAddSiteRequirementToSupplier().Call({
      companyId,
      supplierId: resourceId,
      siteId,
      documentTypeId,
      isOptional,
      graceDays,
    });
  }

  async getDocumentTypes(
    companyId: string,
    search?: string,
    tags?: string[],
    sort?: SortMeta,
    pageParam?: number,
    resourceId?: string,
    systemOnly?: boolean,
    category?: DocumentTypeCategory,
  ): Promise<DocumentType[]> {
    return await makeGetDocumentTypes().Call({
      companyId,
      filter: category,
      includePublic: true,
      search,
      tags,
      sort,
      pageParam,
      withFileCount: true,
      resourceId,
      systemOnly: null,
    });
  }

  async getSiteSupplierResources(
    companyId: string,
    siteId: string,
    type: string,
    sort?: SortMeta,
    perPage?: number,
    filter?: GetSiteResourcesFilters,
    page?: number,
    supplierId?: string,
  ): Promise<
    PaginatedResults<
      SiteWorker | SiteChemical | SiteTool | SiteMachine | SiteVehicle
    >
  > {
    switch (type) {
      case "worker":
        return await makeGetSiteWorkers().Call({
          companyId,
          siteId,
          sort,
          perPage,
          filter,
          page,
          supplierId,
        });
      case "chemical":
        return await makeGetSiteChemicals().Call({
          companyId,
          siteId,
          sort,
          perPage,
          filter,
          page,
          supplierId,
        });
      case "tool":
        return await makeGetSiteTools().Call({
          companyId,
          siteId,
          sort,
          perPage,
          filter,
          page,
          supplierId,
        });
      case "machine":
        return await makeGetSiteMachines().Call({
          companyId,
          siteId,
          sort,
          perPage,
          filter,
          page,
          supplierId,
        });
      case "vehicle":
        return await makeGetSiteVehicles().Call({
          companyId,
          siteId,
          sort,
          perPage,
          filter,
          page,
          supplierId,
        });
      default:
        return await makeGetSiteWorkers().Call({
          companyId,
          siteId,
          sort,
          perPage,
          filter,
          page,
          supplierId,
        });
    }
  }

  async setSupplierStatus(
    companyId: string,
    siteId: string,
    supplierId: string,
    status: SupplierStatus,
  ): Promise<Supplier> {
    return await makeSetSupplierStatus().Call({
      companyId,
      siteId,
      supplierId,
      status,
    });
  }

  async updateFile(
    companyId: string,
    documentId: string,
    fileId: string,
    updatedFiles: Partial<FileEntity>[],
    siteIds?: string[],
    target?: string,
    resourceId?: string,
    selectAll?: boolean,
  ): Promise<void> {
    return await makeUpdateFile().Call({
      companyId,
      documentId,
      updatedFiles,
      fileId,
      siteIds,
      target,
      resourceId,
      selectAll,
    });
  }

  async deleteSiteSupplierRequirements(
    companyId: string,
    siteId: string,
    requirementId: string,
    subject: RequirementSubject,
    supplierId: string,
  ): Promise<void> {
    return await makeDeleteSiteSupplierRequirement().Call({
      companyId,
      siteId,
      requirementId,
      subject,
      resourceId: supplierId,
    });
  }
}

export default SiteSupplierDetailViewModel;
