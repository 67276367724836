import { useMemo, useState } from 'react';
import { useAuth } from '../../providers/Auth0JWTProvider';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DocumentTypeCategory } from '../../../domain/entities/documentTypeCategory.enum';
import { ResourceDocumentsViewModel } from '../../viewmodels/resources/ResourceDocumentsViewModel';
import useDocuments from '../Document/useDocuments';
import useAddDocumentType from '../Document/useAddDocumentType';
import useDocumentInfo from '../Document/useDocumentInfo';
import File from '../../../domain/entities/file';
import DocumentTypeWithPublic from '../../../domain/entities/documentTypeWithPublic';
import { ResourceDocumentEvaluationState } from '../../../domain/entities/resourceDocumentEvaluationState.enum';
import useDocumentCommunicationViewModel from '../Document/useDocumentCommunicationVIewModel';
import FileEntity from '../../../domain/entities/file';
import { useTranslation } from 'react-i18next';
import { AiTaskSectionCode } from '../../../domain/entities/aiTaskSectionCode';
import useDocumentAI from '../Document/useDocumentAI';

function useResourceDocumentsViewModel(viewModel: ResourceDocumentsViewModel, documentTypeCategory: DocumentTypeCategory, resourceId: string) {
	const { companyId } = useAuth();
	const { t } = useTranslation();

	const aiSectionCode = (() => {
		switch (documentTypeCategory) {
			case DocumentTypeCategory.CHEMICAL:
				return AiTaskSectionCode.MY_RESOURCES_CHEMICALS;;
			case DocumentTypeCategory.MACHINE:
				return AiTaskSectionCode.MY_RESOURCES_MACHINES;
			case DocumentTypeCategory.TOOL:
				return AiTaskSectionCode.MY_RESOURCES_TOOLS;
			case DocumentTypeCategory.VEHICLE:
				return AiTaskSectionCode.MY_RESOURCES_VEHICLES;
			case DocumentTypeCategory.WORKER:
				return AiTaskSectionCode.MY_RESOURCES_WORKERS;
			default:
				return null; // or some default value
		}
	})();

	const [docId, setDocId] = useState<string>();
	const [successMsg, setSuccessMsg] = useState<string>();
	const [errorMsg, setErrorMsg] = useState<string>();
	const { ...docsProps } = useDocuments(viewModel, 'documents' + resourceId + documentTypeCategory, "", resourceId, documentTypeCategory);
	const documentTypesProps = useAddDocumentType(viewModel, documentTypeCategory);
	const documentInfo = useDocumentInfo(viewModel, null, documentTypeCategory, resourceId);
	const communicationProps = useDocumentCommunicationViewModel(resourceId, documentTypeCategory);
	const documentAIProps = useDocumentAI(viewModel, "", resourceId, documentTypeCategory, aiSectionCode);

	const resourceQuery = useQuery(['resource', companyId, resourceId, documentTypeCategory], () => viewModel.getResource(companyId, resourceId), {
		initialData: [],
	});

	const typologiesQuery = useQuery(['typologies', companyId], () => viewModel.getTypologies(companyId), {
		initialData: [],
	});

	const typologiesAssociatedQuery = useQuery(['typologiesAssociated', companyId, resourceId], () =>
		viewModel.getTypologiesAssociated(companyId, resourceId, documentTypeCategory),
	);

	const addTypologyToResourceMutation = useMutation(
		async (typologyName: string) => await viewModel.addTypologyToResource(companyId, resourceId, typologyName),
		{
			onSuccess: () => {
				resourceQuery.refetch();
				typologiesAssociatedQuery.refetch();
				docsProps.refetchDocuments();
				communicationProps.refetchTaggableDocuments();
			},
		},
	);

	const removeTypologyFromResourceMutation = useMutation(
		async (typologyId: string) => await viewModel.removeTypologyFromResource(companyId, resourceId, typologyId),
		{
			onSuccess: () => {
				resourceQuery.refetch();
				docsProps.refetchDocuments();
				communicationProps.refetchTaggableDocuments();
			},
		},
	);

	const availableDocumentTypes = useMemo(
		() =>
			documentTypesProps.documentTypes?.filter((documentType) => {
				return !docsProps.documents.find((document) => document.type?.id === documentType.id);
			}),
		[documentTypesProps.documentTypes, docsProps.documents],
	);

	const updateResourceTypologies = async (typologyIds: string[]) => {
		const currentTypologyIds = resourceQuery.data?.typologies?.map((t) => t.id) || [];

		// Determine which typologies were added and removed
		const typologiesToRemove = currentTypologyIds.filter((t) => !typologyIds.includes(t));
		const typologiesToAdd = Array.isArray(typologyIds) ? typologyIds.filter((t) => !currentTypologyIds.includes(t)) : typologyIds;

		// Call mutations for each removed and added typology
		await Promise.all(typologiesToRemove.map((typologyId) => removeTypologyFromResourceMutation.mutateAsync(typologyId)));
		if (Array.isArray(typologiesToAdd)) {
			await Promise.all(
				typologiesToAdd.map((typologyId) =>
					addTypologyToResourceMutation.mutateAsync(typologiesQuery.data.find((t) => t.id === typologyId)?.id || ''),
				),
			);
		}
	};

	const createDocuments = useMutation(async (documents: string[]) => await docsProps.createDocuments(documents), {
		onSuccess: () => {
			docsProps.refetchDocuments();
			communicationProps.refetchTaggableDocuments();
		},
	});

	const createPropagableDocuments = useMutation(
		async (documents: DocumentTypeWithPublic[]) => await docsProps.createPropagableDocuments(documents),
		{
			onSuccess: () => {
				docsProps.refetchDocuments();
				communicationProps.refetchTaggableDocuments();
			},
		},
	);

	const evaluateDocument = useMutation(
		async (
			documentId: string,
			evaluationState?: ResourceDocumentEvaluationState,
			expirationDate?: Date,
			noEvaluationExpiration?: boolean,
			selectAll?: boolean,
		) => await docsProps.evaluateDocument({ documentId, evaluationState, expirationDate, noEvaluationExpiration, selectAll }),
		{
			onSuccess: () => {
				docsProps.refetchDocuments();
			},
		},
	);

	const addFileToResourceDocumentMutation = useMutation(
		['add-file-to-resource-document'],
		async (params: { documentId: string; file: FileEntity; siteIds?: string[]; target?: string; selectAll?: boolean }) => {
			setDocId(params.documentId);
			await viewModel.addFileToResourceDocument(
				companyId,
				params.documentId,
				params.file,
				params.siteIds,
				params.target,
				resourceId,
				params.selectAll,
			);
		},
		{
			onSuccess: async () => {
				setSuccessMsg(t('uploadSuccess', { ns: 'common' }));
				docsProps.refetchDocuments();
				await documentInfo.setHookDocumentId(docId);
				documentInfo.refetchDocumentFiles();
			},
			onError: (err: Error) => {
				setErrorMsg(err.message);
				console.error(err.message);
				docsProps.refetchDocuments();
			},
		},
	);
	const addFileToResourceDocument = (documentId: string, file: File, siteIds: string[], target?: string, selectAll?: boolean) => {
		return addFileToResourceDocumentMutation.mutateAsync({ documentId, file, siteIds, target, selectAll });
	};

	return {
		resource: resourceQuery.data,
		typologies: typologiesQuery.data,
		typologiesAssociated: typologiesAssociatedQuery.data,
		updateResourceTypologies,
		addTypologyToResource: addTypologyToResourceMutation.mutate,
		availableDocumentTypes,
		docsProps,
		documentTypesProps,
		documentProps: documentInfo,
		documentAIProps,
		createDocuments: createDocuments.mutateAsync,
		createPropagableDocuments: createPropagableDocuments.mutateAsync,
		evaluateDocument: evaluateDocument.mutateAsync,
		addFileToResourceDocument,
		communicationProps,
		successMsg,
		setSuccessMsg,
		errorMsg,
		setErrorMsg,
	};
}

export { useResourceDocumentsViewModel };
