import { useParams } from "react-router-dom";
import { SiteChemicalParams } from "../../../domain/entities/siteChemical";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import SiteResourceDetailView from "../../components/Views/Sites/SiteResourceDetailView";
import useWorkingSiteChemicalDetailViewModel from "../../hooks/WorkingSite/useWorkingSiteChemicalDetailViewModel";
import { useResourceFilesDownloader } from "../../hooks/Document/useDocumentsFilesDownloader";
import { Permission } from "../../components/Permissions/Permissions";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";

const WorkingSiteChemicalDetailView = () => {
  const { siteId, chemicalId } = useParams();
  const resourceColumns = ["name"];

  const downloader = useResourceFilesDownloader(
    siteId,
    chemicalId,
    "chemicals"
  );

  return (
    <SiteResourceDetailView<SiteChemicalParams>
      downloader={downloader}
      resourceId={chemicalId}
      type={DocumentTypeResourceType.CHEMICAL}
      useViewModel={useWorkingSiteChemicalDetailViewModel(
        siteId,
        chemicalId,
        resourceColumns,
        DocumentTypeResourceType.CHEMICAL,
        AiTaskSectionCode.WORKING_SITE_RESOURCES_CHEMICALS
      )}
      resourceColumns={resourceColumns}
      isWorkingSite
      isReadOnly={true}
      permissions={{
        downloadDocuments: Permission.Worksite_DownloadChemicalDoc,
        showGlobalEvaluation: Permission.Worksite_ShowChemicalGlobalEval,
        setGlobalEvaluation: Permission.Worksite_Show,
        addRequirement: Permission.Worksite_NewChemicalReq,
        viewRequirements: Permission.Worksite_Show,
        editTags: Permission.Worksite_EditChemicalReq,
        manageDocumentWithAi: Permission.Company_AIManageFile,
        documents: {
          delete: Permission.Worksite_Chemical_DeleteFile,
          edit: Permission.Worksite_Chemical_EditFile,
          evaluate: Permission.Worksite_EvalChemicalReq,
          viewEvaluations: Permission.Worksite_EvalChemicalReq,
          downloadAll: Permission.Worksite_DownloadAllFile,
          addFile: Permission.Worksite_EditChemicalReq,
          addNewFile: Permission.Worksite_Chemical_AddFile,
          chat: Permission.Worksite_ChemicalChat,
          report: Permission.Worksite_ChemicalChat,
          setExpiration: Permission.Worksite_Chemical_SetExpiration,
        },
      }}
    />
  );
};

export default WorkingSiteChemicalDetailView;
