import React from "react";
import { SiteData } from "../../../../domain/entities/badge";
import { styles } from "./badgeStyles";
import { Image, Text, View } from "@react-pdf/renderer";
import QRCode from "qrcode";

type QrCodeBadgeBackProps = {
  site: SiteData;
  serial: string;
};

const QrCodeBadgeBack = ({ site, serial }: QrCodeBadgeBackProps) => {
  const { company, protocol } = site as SiteData;
  const qrCodeDataURL = QRCode.toDataURL(serial);
  return (
    <View style={styles.badgeContainer}>
      <View style={{ flexDirection: "row", marginBottom: "1mm" }}>
        <Text style={{ fontWeight: 700 }}>Appalto N: </Text>
        <Text>{protocol}</Text>
      </View>
      <View style={{ flexDirection: "row", marginBottom: "3mm" }}>
        <Text style={{ fontWeight: 700 }}>Committente: </Text>
        <Text>{company}</Text>
      </View>
      <View style={styles.qrCodeContainer}>
        <Image style={styles.photo} src={qrCodeDataURL} />
      </View>
    </View>
  );
};

export default QrCodeBadgeBack;
