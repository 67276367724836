import Company from "../../../domain/entities/company";
import UpdateCompanyParameter from "../../hooks/Company/updateCompanyParameter";
import {
  makeGetCompany,
  makeUpdateCompany,
} from "../../../core/app/factory/companies/usecases";

class CompanyDetailViewModel {
  private getUC = makeGetCompany();
  private updateUC = makeUpdateCompany();

  async getCompany(id: string): Promise<Company> {
    return await this.getUC.Call(id);
  }

  async updateCompany(
    updateCompanyParameter: UpdateCompanyParameter,
  ): Promise<Company> {
    return await this.updateUC.Call(updateCompanyParameter);
  }
}

export default CompanyDetailViewModel;
