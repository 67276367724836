import { Box, keyframes } from '@chakra-ui/react';

const wobble = keyframes`
  0%,
  100% {
    transform: translateX(-95%);
  }
  50% {
    transform: translateX(95%);
  }
`;

interface LoaderProps {
  color: string,
  animated: boolean
}

const ProgressLoader = ({color, animated}: LoaderProps) => {
  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="5px" // var(--uib-stroke)
      width="100%" // var(--uib-size)
      borderRadius="2.5px" // calc(var(--uib-stroke) / 2)
      overflow="hidden"
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        height="100%"
        width="100%"
        bg="black"
        opacity="0.1"
      />
      <Box
        height="100%"
        width="100%"
        borderRadius="2.5px" // calc(var(--uib-stroke) / 2)
        animation={animated ? `${wobble} 4s ease-in-out infinite` : ""}
        bg={color}
      />
    </Box>
  );
};

export default ProgressLoader;