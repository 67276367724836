import UseCase from '../../../../core/app/usecase';
import { ResourceDocumentEvaluationState } from '../../../entities/resourceDocumentEvaluationState.enum';
import VehicleRepository from '../../../repositories/vehicleRepository';

type EvaluateVehicleDocumentParameters = {
	companyId: string;
	resourceId: string;
	documentId: string;
	evaluationState?: ResourceDocumentEvaluationState;
	expirationDate?: Date;
	noEvaluationExpiration?: boolean;
	siteIds?: string[];
	selectAll?: boolean;
};

/**
 * Returns the list of all the existing Document.
 */
class EvaluateVehicleDocument implements UseCase<EvaluateVehicleDocumentParameters, void> {
	constructor(private repository: VehicleRepository) {}

	async Call({
		companyId,
		resourceId,
		documentId,
		evaluationState,
		expirationDate,
		noEvaluationExpiration,
		siteIds,
		selectAll,
	}: EvaluateVehicleDocumentParameters): Promise<void> {
		return await this.repository.evaluateVehicleDocument(
			companyId,
			resourceId,
			documentId,
			evaluationState,
			expirationDate,
			noEvaluationExpiration,
			siteIds,
			selectAll,
		);
	}
}

export default EvaluateVehicleDocument;
