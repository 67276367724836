import { Image, Text, View } from "@react-pdf/renderer";
import workerPlaceholder from "../../../../assets/images/worker.png";
import Badge, {
  SiteData,
  WorkerMetas,
} from "../../../../domain/entities/badge";
import { formatStringToDate } from "../../../../utils";
import { styles } from "./badgeStyles";

type NfcBadgeWorkerProps = {
  badge: Badge;
};

const NfcBadgeWorker = ({ badge }: NfcBadgeWorkerProps) => {
  const { name } = badge.site as SiteData;
  const {
    firstName,
    lastName,
    photo,
    fiscalCode,
    jobTitle,
    dateOfBirth,
    dateOfEmployment,
  } = badge.resource.meta as WorkerMetas;

  return (
    <View style={styles.badgeContainer}>
      <View style={styles.headerContainer}>
        <Text style={styles.siteName}>{name}</Text>
        <View style={{ flexDirection: "row" }}>
          <Text style={{ fontWeight: 700 }}>Datore di lavoro: </Text>
          <Text>{badge.resource.companyName}</Text>
        </View>
      </View>
      <View style={styles.mainContainer}>
        <View style={styles.photoContainer}>
          <Image style={styles.photo} src={photo || workerPlaceholder} />
        </View>
        <View style={styles.workerDetailsContainer}>
          <View style={styles.workerDetailsRow}>
            <Text style={{ fontWeight: 700 }}>Nome: </Text>
            <Text>{firstName}</Text>
          </View>
          <View style={styles.workerDetailsRow}>
            <Text style={{ fontWeight: 700 }}>Cognome: </Text>
            <Text>{lastName}</Text>
          </View>
          {dateOfBirth && (
            <View style={styles.workerDetailsRow}>
              <Text style={{ fontWeight: 700 }}>Nato il: </Text>
              <Text>{formatStringToDate(dateOfBirth)}</Text>
            </View>
          )}
          <View style={styles.workerDetailsRow}>
            <Text style={{ fontWeight: 700 }}>C.F.: </Text>
            <Text>{fiscalCode}</Text>
          </View>
          {jobTitle && (
            <View style={styles.workerDetailsRow}>
              <Text style={{ fontWeight: 700 }}>Ruolo: </Text>
              <Text>{jobTitle}</Text>
            </View>
          )}
          {dateOfEmployment && (
            <View style={styles.workerDetailsRow}>
              <Text style={{ fontWeight: 700 }}>Assunto il: </Text>
              <Text>{formatStringToDate(dateOfEmployment)}</Text>
            </View>
          )}
        </View>
      </View>
      <View style={styles.footerContainer}>
        <Text>{`Codice badge: ${badge.code || "-"}`}</Text>
      </View>
    </View>
  );
};
export default NfcBadgeWorker;
