import TagList from "../Common/TagList";
import { FC, useEffect, useMemo, useState } from "react";
import LoadingView from "../Common/LoadingView";
import { COLORS } from "../../assets/theme/colors";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { Trans, useTranslation } from "react-i18next";
import ContentLayout from "../../layout/ContentLayout";
import { FiAlertTriangle, FiInfo } from "react-icons/fi";
import TagSelect from "../../components/Common/TagSelect";
import ActionBar from "../../components/Common/ActionBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MdClose, MdOutlineHistory } from "react-icons/md";
import PlusButton from "../../components/Common/PlusButton";
import ToolIcon from "../../components/Common/icons/ToolIcon";
import { DeleteActionAlert } from "../Common/DeleteActionAlert";
import BaseModal from "../../components/Common/alerts/BaseModal";
import WorkerIcon from "../../components/Common/icons/WorkerIcon";
import ActionBarItem from "../../components/Common/ActionBarItem";
import DetailsHeader from "../../components/Common/DetailsHeader";
import MachineIcon from "../../components/Common/icons/MachineIcon";
import VehicleIcon from "../../components/Common/icons/VehicleIcon";
import ChemicalIcon from "../../components/Common/icons/ChemicalIcon";
import { Permission } from "../../components/Permissions/Permissions";
import { InfoBanner } from "../../components/Common/alerts/InfoBanner";
import SupplierStatus from "../../components/Views/Sites/SupplierStatus";
import DownloadAllButton from "../../components/Common/DownloadAllButton";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import SiteSupplierResourcesViewModal from "./SiteSupplierResourcesViewModal";
import RenderIf, {
  useHasPermissions,
} from "../../components/Permissions/RenderIf";
import { DownloadFileScope } from "../../../domain/repositories/documentsDownloader";
import { RequirementSubject } from "../../../domain/entities/requirementSubject.enum";
import AddDocumentTypesModal from "../../components/Views/common/AddDocumentTypesModal";
import SiteResourceDocuments, {
  ResourceDocumentsPermissions,
} from "../../components/Views/common/SiteResourceDocuments";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { SupplierEvaluationState } from "../../../domain/entities/supplierEvaluationState.enum";
import { useSiteSupplierDetailViewModel } from "../../hooks/Site/useSiteSupplierDetailViewModel";
import DocumentCommunicationModal from "../../components/Views/common/DocumentCommunicationModal";
import ResourceEvaluationHistoryModal from "../../components/Views/common/ResourceEvaluationHistoryModal";
import ResourceEvaluationToggle, {
  EvaluationButton,
} from "../../components/Common/ResourceEvaluationToggle";
import {
  useDocumentsFilesDownloader,
  useSupplierOrSiteFilesDownloader,
} from "../../hooks/Document/useDocumentsFilesDownloader";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  IconButton,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
  useToast,
  VStack,
} from "@chakra-ui/react";
import RejectionSummary, {
  mapRejectionGroups,
} from "../../components/Views/Documents/RejectionSummary";
import DOMPurify from "dompurify";
import ContactField from "../../components/Common/table/ContactField";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";
import AiButton from "../../components/Common/AiButton";
import { AiTaskStatus } from "../../../domain/entities/aiTask";
import { PermissionCheck } from "../../providers/Auth0JWTProvider";

interface Props {
  fromList: boolean;
  permissions: SiteSupplierDetailPermissions;
  isWorkingSite?: boolean;
  isSubcontract?: boolean;
  deltaWidth?: string;
  aiTaskCompanySectionCode?: AiTaskSectionCode;
  aiTaskSiteSectionCode?: AiTaskSectionCode;
}

type SiteSupplierDetailPermissions = {
  downloadDocuments: Permission;
  showGlobalEvaluation: Permission;
  setGlobalEvaluation: Permission;
  viewRequirements: Permission;
  addRequirement: Permission;
  editTags: Permission;
  setStatus: Permission;
  showResources: Permission;
  documents: ResourceDocumentsPermissions;
  manageDocumentWithAi: Permission;
};

const SiteSupplierDetailView: FC<Props> = ({
  fromList,
  permissions,
  isWorkingSite = false,
  isSubcontract,
  deltaWidth,
  aiTaskCompanySectionCode,
  aiTaskSiteSectionCode,
}) => {
  const { siteId } = useParams();
  const { supplierId } = useParams();
  const { t } = useTranslation("supplier");
  const navigate = useNavigate();
  const location = useLocation();
  const [isTablet] = useMediaQuery("(max-width: 1300px)");

  const [showResources, setShowResources] = useState<boolean>(false);
  const [selectedRequirement, setSelectedRequirement] = useState<string>();
  const downloader = useSupplierOrSiteFilesDownloader(siteId, "site");
  const [resourceType, setResourceType] = useState<DocumentTypeResourceType>();
  const [selectedSubject, setSelectedSubject] = useState<RequirementSubject>();
  const [showAskConfirmDelete, setShowAskConfirmDelete] =
    useState<boolean>(false);
  const [showNeedsRequirementsModal, setShowNeedsRequirementsModal] =
    useState<boolean>(false);
  const [
    showConfirmEvaluateResourceModal,
    setShowConfirmEvaluateResourceModal,
  ] = useState<false | SupplierEvaluationState>(false);
  const [
    showResourceEvaluationHistoryModal,
    setShowResourceEvaluationHistoryModal,
  ] = useState(false);
  const [showInfoBanner, setShowInfoBanner] = useState(true);
  const [deleteIsLoading, setDeleteIsLoading] = useState<boolean>(false);
  const [showCommunicationModal, setShowCommunicationModal] = useState(false);
  const variantTranslation = t("showUpdateTagsModal", { ns: "siteResources" });
  const [showAddRequirementModal, setShowAddRequirementModal] = useState(false);
  const [showAddSiteRequirementModal, setShowAddSiteRequirementModal] =
    useState(false);
  const [showUpdateVariantsModal, setShowUpdateVariantsModal] = useState<
    false | string
  >(false);
  const [showUpdateSiteVariantsModal, setShowUpdateSiteVariantsModal] =
    useState<false | string>(false);
  const [showManageCompanyTaskWithAI, setShowManageCompanyTaskWithAI] =
    useState<boolean>(false);
  const [showManageSiteTaskWithAI, setShowManageSiteTaskWithAI] =
    useState<boolean>(false);
  const [addRequirementsIsLoading, setAddRequirementsIsLoading] =
    useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState(
    location.state?.fromPrevUrl === "site" ? 1 : 0
  );

  const {
    siteResource,
    siteResourceRefetch,
    variants,
    siteSupplierResources,
    siteSupplierResourcesFetching,
    setSiteSupplierResourcesSort,
    siteSupplierResourcesSort,
    filterSiteSupplierResources,
    updateFilterSiteSupplierResources,
    evaluateSiteResource,
    updateSiteResourceVariant,
    siteResourceDocuments,
    siteResourceDocumentsFetchNextPage,
    siteResourceDocumentsHasNextPage,
    siteResourceDocumentsRefetch,

    siteResourceEvaluations,
    getSiteResourceEvaluations,
    getSiteResourceEvaluationsIsLoading,
    setEnableGetEvaluationsHistory,
    evaluateDocument,
    filterResourceEvaluations,
    updateFilterResourceEvaluations,
    sortResourceEvaluations,
    updateSortResourceEvaluations,

    sortCompanyDocuments,
    updateSortCompanyDocuments,
    updateFilterCompanyDocuments,
    filterCompanyDocuments,

    sortDocuments,
    updateSortDocuments,
    updateFilterDocuments,
    filterDocuments,
    getDocumentEvaluations,
    documentEvaluations,
    documentEvaluationsFetching,
    addRequirementsToSiteResource,
    filterDocumentEvaluations,
    updateFilterDocumentEvaluations,
    updateSortDocumentEvaluations,
    sortDocumentEvaluations,
    documentTypesProps,
    documentInfoProps,
    updateSiteResourceSiteVariant,
    siteResourceSiteDocuments,
    siteResourceSiteDocumentsFetchNextPage,
    siteResourceSiteDocumentsHasNextPage,
    siteResourceSiteDocumentsRefetch,
    siteVariants,
    siteSpecializations,
    addSiteRequirementsToSiteResource,
    setSupplierDocumentType,
    setSupplierStatus,
    isCompanyDocumentsFetching,
    isSiteDocumentsFetching,
    autoEvaluateSiteResource,
    deleteRequirement,
    communicationProps,
    statusIsLoading,
    systemStaff,
    documentAICompanyProps,
    documentAISiteProps,
  } = useSiteSupplierDetailViewModel(
    siteId,
    supplierId,
    resourceType,
    isWorkingSite,
    aiTaskCompanySectionCode,
    aiTaskSiteSectionCode,
    supplierId ? true : false
  );

  const resourceColumns = ["name"];
  const resourceWorkerColumns = [
    "lastName",
    "firstName",
    "fiscalCode",
    "jobTitle",
  ];

  const availableDocumentTypes = useMemo(
    () =>
      documentTypesProps.documentTypes.filter((documentType) => {
        return !siteResourceDocuments.find(
          (document) => document.documentTypeId === documentType.id
        );
      }),
    [documentTypesProps.documentTypes, siteResourceDocuments]
  );

  const handleAddRequirements = async (documentTypes) => {
    setAddRequirementsIsLoading(true);
    await addRequirementsToSiteResource(documentTypes);
    setShowAddRequirementModal(false);
    setAddRequirementsIsLoading(false);
  };

  const handleAddSiteRequirements = async (documentTypes) => {
    setAddRequirementsIsLoading(true);
    await addSiteRequirementsToSiteResource(documentTypes);
    setShowAddSiteRequirementModal(false);
    setAddRequirementsIsLoading(false);
  };

  const askConfirmDelete = (id: string, subject: RequirementSubject) => {
    setSelectedRequirement(id);
    setSelectedSubject(subject);
    setShowAskConfirmDelete(true);
  };

  const cancelConfirmDelete = () => {
    setShowAskConfirmDelete(false);
    setSelectedRequirement(undefined);
  };

  const confirmDelete = async () => {
    setDeleteIsLoading(true);
    await deleteRequirement(selectedRequirement, selectedSubject, supplierId);
    cancelConfirmDelete();
    setDeleteIsLoading(false);
  };

  const street = siteResource?.company?.address?.street;
  const cap = siteResource?.company?.address?.cap;
  const city = siteResource?.company?.address?.city;
  const addressString = [
    street && `${street}`,
    cap && `${cap}`,
    city && `${city}`,
  ]
    .filter(Boolean)
    .join(" - ");

  const detailAttributes = [
    "name",
    "fiscalCode",
    "vat",
    "email",
    "pec",
    "address",
    "phoneNumber",
  ].map((column) => ({
    label: t(column, { ns: "companies" }),
    value: column === "address" ? addressString : siteResource?.company[column],
  }));

  const contactAttributes = [
    "doctor",
    "rspp",
    "contact",
    "employer",
    "rls",
  ].map((role) => ({
    label: t(role, { ns: "companies" }),
    value: (
      <ContactField staff={systemStaff?.find((staff) => staff.role === role)} />
    ),
  }));

  const onTabSelect = (t) => {
    setSupplierDocumentType(
      t === 0 ? DocumentTypeCategory.COMPANY : DocumentTypeCategory.SITE
    );
    setSelectedTab(t);
  };

  const getSupplierDocumentEvaluations =
    (type: "site" | "company") => (documentId: string) => {
      getDocumentEvaluations(documentId, type);
    };

  const documentDownloaderCompany = useDocumentsFilesDownloader(
    siteId,
    supplierId,
    "company" as DownloadFileScope
  );

  const documentDownloaderSite = useDocumentsFilesDownloader(
    siteId,
    supplierId,
    "site" as DownloadFileScope
  );

  const toast = useToast();

  const canGloballyEvaluateSupplier = useHasPermissions(
    permissions.setGlobalEvaluation
  );
  const canEditRequirements = useHasPermissions(permissions.documents.edit);

  const sanitizedHtmlContent = DOMPurify.sanitize(
    t("tagSelect", {
      ns: "siteResources",
      tag: t("variant", { ns: "siteResources" }),
      color: COLORS.sikuroBlue,
    })
  );
  useEffect(() => {
    documentTypesProps.setSupplierId(supplierId);
  }, []);

  const showToast = (ok: boolean) => {
    toast({
      duration: 5000,
      isClosable: true,
      colorScheme: ok ? "green" : "red",
      icon: ok ? <FiInfo /> : <FiAlertTriangle />,
      description: ok
        ? t("downloadAllFilesOk", {
            ns: "documents",
          })
        : t("downloadAllFilesError", {
            ns: "documents",
          }),
    });
  };

  const refetchCompanyTasks = () => {
    documentAICompanyProps.aiTasksRefetch();
    setShowManageCompanyTaskWithAI(true);
  };

  const refetchSiteTasks = () => {
    documentAISiteProps.aiTasksRefetch();
    setShowManageSiteTaskWithAI(true);
  };

  const refetchCommentsAndNotes = () => {
    communicationProps.refetchResourceDocumentComments(),
      communicationProps.refetchResourceDocumentNotes();
  };

  if (!siteResource) {
    return <LoadingView />;
  }

  return (
    <ContentLayout
      action={
        <ActionBar>
          {fromList && (
            <ActionBarItem
              bgColor={COLORS.sikuroBlue}
              color="white"
              icon={MdClose}
              description={t("close", { ns: "common" })}
              onClick={() => navigate(`/sites/${siteId}/suppliers`)}
            />
          )}
          <RenderIf permissions={permissions.documents.chat}>
            <ActionBarItem
              bgColor={COLORS.yellow}
              color="black"
              icon={HiOutlineChatAlt2}
              description={t("chat", { ns: "communication" })}
              onClick={() => {
                refetchCommentsAndNotes(), setShowCommunicationModal(true);
              }}
            />
          </RenderIf>
        </ActionBar>
      }
    >
      {siteResource && (
        <>
          <Flex px={3} py={5} gap="20px" flexWrap={"wrap"}>
            <DetailsHeader
              isLoaded={!!siteResource.company.name}
              title={t("details", { ns: "common" })}
              shouldShowImage={true}
              image={{ url: siteResource.company.logo, disableFlag: true }}
              attributes={[...detailAttributes, ...contactAttributes]}
            />
            <VStack gap={2} flex="1 1">
              <SupplierStatus
                status={siteResource.status}
                onChange={(s) => setSupplierStatus(s)}
                permissions={permissions.setStatus}
                statusIsLoading={statusIsLoading}
              />
              <RenderIf permissions={permissions.showResources}>
                <ResourceLinks
                  setType={setResourceType}
                  showResourcesModal={setShowResources}
                />
              </RenderIf>
            </VStack>
          </Flex>
          <Flex padding={3}>
            <VStack
              rounded="md"
              borderWidth="1px"
              borderColor="gray.300"
              px={5}
              pt={3}
            >
              {!siteResource.isGlobal && siteResource.isForcedEvaluation && (
                <Alert my={2} mx={-3} py={1} status="error" width="100%">
                  <AlertIcon />
                  <div>
                    <Trans t={t} i18nKey="evaluationForced">
                      Warning: the evaluation is forced.{" "}
                      <Link
                        sx={{ fontWeight: 700, textDecoration: "underline" }}
                        onClick={() => autoEvaluateSiteResource()}
                      >
                        Click here
                      </Link>{" "}
                      to remove the force
                    </Trans>
                  </div>
                </Alert>
              )}
              <Flex
                justifyContent="center"
                gap={5}
                alignItems="center"
                w="100%"
              >
                {siteResource.isGlobal ? (
                  <EvaluationButton
                    evaluationType="resource"
                    value={siteResource.evaluationResult}
                    disabled={!canGloballyEvaluateSupplier}
                  />
                ) : (
                  <ResourceEvaluationToggle
                    type="resource"
                    status={siteResource.evaluationResult}
                    disabled={!canGloballyEvaluateSupplier}
                    onChange={(status) =>
                      setShowConfirmEvaluateResourceModal(
                        status as unknown as SupplierEvaluationState
                      )
                    }
                    showExpirationDate={false}
                  />
                )}
                <IconButton
                  fontSize="3xl"
                  backgroundColor="transparent"
                  onClick={() => {
                    setEnableGetEvaluationsHistory(true);
                    setShowResourceEvaluationHistoryModal(true);
                  }}
                  aria-label="document-history"
                >
                  <MdOutlineHistory />
                </IconButton>
              </Flex>

              <Tabs
                onChange={onTabSelect}
                width="100%"
                height="100%"
                defaultIndex={selectedTab}
                marginTop={5}
                marginBottom={5}
              >
                <TabList>
                  <Tab width="50%">
                    {t("companyDocuments", { ns: "documents" })}
                  </Tab>
                  <Tab width="50%">
                    {t("siteDocuments", { ns: "documents" })}
                  </Tab>
                </TabList>
                <TabPanels>
                  {["company", "site"].map((type: "company" | "site") => (
                    <TabPanel key={type}>
                      <Flex
                        gap={5}
                        mt={5}
                        minHeight={"100"}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box sx={{ minWidth: "200px" }}>
                          {siteResource.isGlobal && type === "company" ? (
                            <div>
                              {siteResource?.companyVariant ? (
                                <Flex>
                                  <Text
                                    fontSize={14}
                                    textColor={COLORS.sikuroBlue}
                                    marginRight={5}
                                  >
                                    {t("variants", { ns: "requirements" })}
                                  </Text>
                                  <TagList
                                    tags={
                                      siteResource?.companyVariant
                                        ? [siteResource?.companyVariant]
                                        : []
                                    }
                                  />
                                </Flex>
                              ) : (
                                <Flex>
                                  <Text
                                    mt={-4}
                                    fontSize={14}
                                    textColor={COLORS.sikuroBlue}
                                  >
                                    {t("variants", { ns: "requirements" })}
                                  </Text>
                                  <Text mt={-3} fontSize={10} marginLeft="5">
                                    {t("noVariantSelected", { ns: "workers" })}
                                  </Text>
                                </Flex>
                              )}
                            </div>
                          ) : (
                            <div>
                              <TagSelect
                                tags={
                                  type === "company" ? variants : siteVariants
                                }
                                isMulti={false}
                                style={{ zIndex: "2" }}
                                selectedTags={[
                                  siteResource[
                                    type === "company"
                                      ? "companyVariant"
                                      : "siteVariant"
                                  ]?.id,
                                ].filter((v) => v)}
                                setSelectedTags={(variant) => {
                                  type === "company"
                                    ? setShowUpdateVariantsModal(
                                        variant[0] ?? undefined
                                      )
                                    : setShowUpdateSiteVariantsModal(
                                        variant[0] ?? undefined
                                      );
                                }}
                                label={t("variant", { ns: "siteResources" })}
                                editable={canEditRequirements}
                                defaultMenuIsOpen={false}
                              />
                              <Text
                                mt={-4}
                                fontSize={10}
                                dangerouslySetInnerHTML={{
                                  __html: sanitizedHtmlContent,
                                }}
                              />
                            </div>
                          )}
                        </Box>
                        <Flex flexGrow={1} />
                        {siteResource.isGlobal && type === "company" ? null : (
                          <RenderIf
                            check={PermissionCheck.All}
                            permissions={[
                              Permission.Sites_Manage,
                              Permission.Supplier_Global_AddFile,
                              Permission.Company_AIManageFile,
                            ]}
                          >
                            <AiButton
                              tooltipMessage={t("manageAi", {
                                ns: "documents",
                              })}
                              onClick={() => {
                                selectedTab === 0
                                  ? refetchCompanyTasks()
                                  : refetchSiteTasks();
                              }}
                              isAnimated={
                                selectedTab === 0
                                  ? documentAICompanyProps.aiTasks.some(
                                      (task) =>
                                        task.status === AiTaskStatus.RUNNING
                                    )
                                  : documentAISiteProps.aiTasks.some(
                                      (task) =>
                                        task.status === AiTaskStatus.RUNNING
                                    )
                              }
                            />
                          </RenderIf>
                        )}
                        {siteResource.isGlobal && type === "company" ? null : (
                          <RenderIf
                            permissions={[
                              Permission.Sites_Manage,
                              Permission.Sites_AddSupplierReq,
                            ]}
                          >
                            <PlusButton
                              tooltipMessage={t("createNewDocument", {
                                ns: "documents",
                              })}
                              onClick={() => {
                                setSupplierDocumentType(
                                  type as DocumentTypeCategory
                                );
                                if (selectedTab === 1) {
                                  setShowAddSiteRequirementModal(true);
                                } else {
                                  setShowAddRequirementModal(true);
                                }
                              }}
                            />
                          </RenderIf>
                        )}
                        {siteResourceDocuments.length > 0 && (
                          <RenderIf permissions={permissions.downloadDocuments}>
                            <DownloadAllButton
                              onClick={() => {
                                if (type === "company") {
                                  documentDownloaderCompany.initiateDocumentsZipDownload(
                                    (ok: boolean) => {
                                      showToast(ok);
                                    }
                                  );
                                } else {
                                  documentDownloaderSite.initiateDocumentsZipDownload(
                                    (ok: boolean) => {
                                      showToast(ok);
                                    }
                                  );
                                }
                              }}
                            />
                          </RenderIf>
                        )}
                      </Flex>

                      <Box mt={2}>
                        <RejectionSummary
                          groups={mapRejectionGroups([
                            {
                              resource: type,
                              documents:
                                type === "company"
                                  ? siteResourceDocuments
                                  : siteResourceSiteDocuments,
                            },
                          ])}
                        />
                      </Box>

                      <>
                        {showInfoBanner && (
                          <InfoBanner
                            text={t("dragAndDropInfo", { ns: "documents" })}
                            onClose={() => setShowInfoBanner(false)}
                          />
                        )}
                      </>
                      <SiteResourceDocuments
                        downloader={downloader}
                        evaluateDocument={evaluateDocument}
                        siteResourceRefetch={siteResourceRefetch}
                        isSubcontract={isSubcontract}
                        showCommunications={true}
                        {...(type === "company"
                          ? {
                              documents: siteResourceDocuments,
                              documentsRefetch: siteResourceDocumentsRefetch,
                              documentsHasNextPage:
                                siteResourceDocumentsHasNextPage,
                              documentsFetchNextPage:
                                siteResourceDocumentsFetchNextPage,
                              sortDocuments: sortCompanyDocuments,
                              filterDocuments: filterCompanyDocuments,
                              updateFilterDocuments:
                                updateFilterCompanyDocuments,
                              updateSortDocuments: updateSortCompanyDocuments,
                              isWorkingSite: siteResource.isGlobal,
                            }
                          : {
                              documents: siteResourceSiteDocuments,
                              documentsRefetch:
                                siteResourceSiteDocumentsRefetch,
                              documentsHasNextPage:
                                siteResourceSiteDocumentsHasNextPage,
                              documentsFetchNextPage:
                                siteResourceSiteDocumentsFetchNextPage,
                              sortDocuments: sortDocuments,
                              filterDocuments: filterDocuments,
                              updateFilterDocuments: updateFilterDocuments,
                              updateSortDocuments: updateSortDocuments,
                              isWorkingSite: false,
                            })}
                        setSiteResourceHookDocumentId={getSupplierDocumentEvaluations(
                          type
                        )}
                        documentEvaluations={documentEvaluations}
                        documentEvaluationsFetching={
                          documentEvaluationsFetching
                        }
                        filterDocumentEvaluations={filterDocumentEvaluations}
                        updateFilterDocumentEvaluations={
                          updateFilterDocumentEvaluations
                        }
                        sortDocumentEvaluations={sortDocumentEvaluations}
                        updateSortDocumentEvaluations={
                          updateSortDocumentEvaluations
                        }
                        {...documentInfoProps}
                        isAddingFile={
                          isCompanyDocumentsFetching || isSiteDocumentsFetching
                        }
                        isFetchingDocuments={
                          isCompanyDocumentsFetching || isSiteDocumentsFetching
                        }
                        isReadOnly={
                          isWorkingSite ||
                          (siteResource.isGlobal && type === "company")
                        }
                        onDelete={(id, subject) =>
                          askConfirmDelete(id, subject)
                        }
                        requirementSubject={type as RequirementSubject}
                        communicationProps={communicationProps}
                        permissions={permissions.documents}
                        resourceCategory={type as DocumentTypeCategory}
                        resourceId={supplierId}
                        deltaWidth={deltaWidth}
                        aiTaskSectionCode={
                          type === "company"
                            ? aiTaskCompanySectionCode
                            : aiTaskSiteSectionCode
                        }
                        documentsAiHook={
                          type === "company"
                            ? documentAICompanyProps
                            : documentAISiteProps
                        }
                        showManageTaskWithAi={
                          type === "company"
                            ? showManageCompanyTaskWithAI
                            : showManageSiteTaskWithAI
                        }
                        setShowManageTaskWIthAi={
                          type === "company"
                            ? setShowManageCompanyTaskWithAI
                            : setShowManageSiteTaskWithAI
                        }
                        isTablet={isTablet}
                      />
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </VStack>
          </Flex>
        </>
      )}

      {showAddRequirementModal && (
        <AddDocumentTypesModal
          {...documentTypesProps}
          isRequirement
          documentTypes={availableDocumentTypes}
          onClose={() => setShowAddRequirementModal(false)}
          onConfirm={handleAddRequirements}
          isAddingDocumentsApi={addRequirementsIsLoading}
          isFetching={documentTypesProps.documentTypesIsFetching}
          showAvailableDocuments={selectedTab === 0}
        />
      )}

      {showResources && (
        <SiteSupplierResourcesViewModal
          type={resourceType}
          resourceColumns={
            resourceType === "worker" ? resourceWorkerColumns : resourceColumns
          }
          resources={siteSupplierResources}
          isWorkingSite={location.pathname.includes("workingsite")}
          siteResourcesHasNextPage={null}
          siteResourcesFetchNextPage={null}
          isFetching={siteSupplierResourcesFetching}
          setShowResources={(show) => setShowResources(show)}
          setSiteSupplierResourcesSort={(sort) =>
            setSiteSupplierResourcesSort(sort)
          }
          siteSupplierResourcesSort={siteSupplierResourcesSort}
          filterSiteSupplierResources={filterSiteSupplierResources}
          updateFilterSiteSupplierResources={(
            column: string,
            value: string | string[]
          ) => updateFilterSiteSupplierResources(column, value)}
          variants={siteVariants}
          specializations={siteSpecializations}
        />
      )}

      {showAddSiteRequirementModal && (
        <AddDocumentTypesModal
          {...documentTypesProps}
          isRequirement
          documentTypes={availableDocumentTypes}
          onClose={() => setShowAddSiteRequirementModal(false)}
          onConfirm={handleAddSiteRequirements}
          isFetching={documentTypesProps.documentTypesIsFetching}
          showAvailableDocuments={selectedTab === 0}
        />
      )}

      {showConfirmEvaluateResourceModal && (
        <BaseModal
          title={t("supplierEvaluationManulOverride")}
          onClose={() => setShowConfirmEvaluateResourceModal(false)}
          onConfirm={() => {
            evaluateSiteResource(
              showConfirmEvaluateResourceModal as SupplierEvaluationState
            );
          }}
        >
          <Box>{t("showConfirmEvaluateSupplierModal")}</Box>
        </BaseModal>
      )}

      {showUpdateVariantsModal && (
        <BaseModal
          title={t("warning", { ns: "common" })}
          onClose={() => setShowUpdateVariantsModal(false)}
          onConfirm={() => updateSiteResourceVariant(showUpdateVariantsModal)}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: t(variantTranslation, {
                tag: t("variant", { ns: "workers" }),
              }),
            }}
          />
        </BaseModal>
      )}

      {showUpdateSiteVariantsModal && (
        <BaseModal
          title={t("warning", { ns: "common" })}
          onClose={() => setShowUpdateSiteVariantsModal(false)}
          onConfirm={() =>
            updateSiteResourceSiteVariant(showUpdateSiteVariantsModal)
          }
        >
          <div
            dangerouslySetInnerHTML={{
              __html: t(variantTranslation, {
                tag: t("variant", { ns: "workers" }),
              }),
            }}
          />
        </BaseModal>
      )}

      {showResourceEvaluationHistoryModal && (
        <ResourceEvaluationHistoryModal
          onClose={() => {
            setEnableGetEvaluationsHistory(false);
            setShowResourceEvaluationHistoryModal(false);
          }}
          evaluations={siteResourceEvaluations}
          getEvaluations={getSiteResourceEvaluations}
          getEvaluationsIsLoading={getSiteResourceEvaluationsIsLoading}
          filterResourceEvaluations={filterResourceEvaluations}
          updateFilterResourceEvaluations={updateFilterResourceEvaluations}
          sortResourceEvaluations={sortResourceEvaluations}
          updateSortResourceEvaluations={updateSortResourceEvaluations}
        />
      )}

      {showNeedsRequirementsModal && (
        <BaseModal
          title={t("warning", { ns: "common" })}
          onClose={() => setShowNeedsRequirementsModal(false)}
        >
          <Text>{t("needRequirements")}</Text>
        </BaseModal>
      )}

      {showCommunicationModal && (
        <DocumentCommunicationModal
          onClose={() => setShowCommunicationModal(false)}
          hook={communicationProps}
          permissions={permissions.documents}
        />
      )}
      <DeleteActionAlert
        isLoading={deleteIsLoading}
        onConfirm={confirmDelete}
        onCancel={cancelConfirmDelete}
        mainTitle={t("warning", { ns: "common" })}
        title={t("confirmDelete", { ns: "requirements" })}
        leftButtonText={t("confirm", { ns: "common" })}
        rightButtonText={t("cancel", { ns: "common" })}
        isOpen={showAskConfirmDelete}
      />
    </ContentLayout>
  );
};

export const ResourceLinks = ({
  setType,
  showResourcesModal,
}: {
  setType: (type: DocumentTypeResourceType) => void;
  showResourcesModal: (show: boolean) => void;
}) => {
  const { t } = useTranslation("supplier");
  const [isTablet] = useMediaQuery("(max-width: 1550px)");

  const buttons = [
    {
      type: DocumentTypeResourceType.WORKER,
      Icon: WorkerIcon,
      label: t("goToWorkers"),
    },
    {
      type: DocumentTypeResourceType.VEHICLE,
      Icon: VehicleIcon,
      label: t("goToVehicles"),
    },
    {
      type: DocumentTypeResourceType.MACHINE,
      Icon: MachineIcon,
      label: t("goToMachines"),
    },
    {
      type: DocumentTypeResourceType.TOOL,
      Icon: ToolIcon,
      label: t("goToTools"),
    },
    {
      type: DocumentTypeResourceType.CHEMICAL,
      Icon: ChemicalIcon,
      label: t("goToChemicals"),
    },
  ];
  return (
    <Flex
      direction={"column"}
      rounded="md"
      borderWidth="1px"
      borderColor="gray.300"
      p="10px 40px"
      width="100%"
    >
      <Box textStyle="h2">{t("resources", { ns: "sites" })}</Box>
      <Flex flexWrap={"wrap"} gap={isTablet && 4}>
        {buttons.map(({ type, label, Icon }) => (
          <Button
            key={type}
            sx={{
              marginTop: "5px",
              width: "232px",
              height: "50px",
              borderRadius: "4px",
              border: "1px solid #E2E8F0",
              backgroundColor: "#FFFFFF",
              fontSize: "16px",
              fontWeight: 300,
              padding: "0  20px 0 70px",
              position: "relative",
              justifyContent: "flex-start",
              textWrap: "wrap",
              textAlign: "left",
            }}
            _hover={{
              backgroundColor: COLORS.sikuroBlue,
              color: "#FFFFFF",
            }}
            onClick={() => {
              return setType(type), showResourcesModal(true);
            }}
          >
            <Box
              sx={{
                backgroundColor: COLORS.yellow,
                height: "50px",
                width: "50px",
                position: "absolute",
                left: 0,
                top: 0,
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Icon fontSize="20px" />
            </Box>
            {label}
          </Button>
        ))}
      </Flex>
    </Flex>
  );
};

export default SiteSupplierDetailView;
