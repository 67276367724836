import UseCase from '../../../core/app/usecase';
import RequirementRepository from '../../repositories/requirementRepository';
import { CreateRequirementParameter } from './createRequirementParameter';

class UpdateRequirement implements UseCase<CreateRequirementParameter, void> {
	private requirementRepository: RequirementRepository;

	constructor(requirementRepository: RequirementRepository) {
		this.requirementRepository = requirementRepository;
	}

	async Call({ companyId, requirement, requirementGroupId, siteIds }: CreateRequirementParameter): Promise<void> {
		const result = await this.requirementRepository.updateRequirement(companyId, requirement, requirementGroupId, siteIds);
		return result;
	}
}

export default UpdateRequirement;
