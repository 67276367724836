import { useTranslation } from "react-i18next";
import Typology from "../../../../domain/entities/typology";
import DocumentPreset from "../../../../domain/entities/documentPreset";
import { Thead, Tbody, Td, Flex, Text, Tr, Tooltip } from "@chakra-ui/react";
import TableViewLabelFiltersHeader from "../../../components/Common/table/TableViewLabelFiltersHeader";
import { DropResult } from "react-beautiful-dnd";
import DeleteButton from "../../../components/Common/table/DeleteButton";
import Tag from "../../../../domain/entities/tag";
import Variant from "../../../../domain/entities/variant";
import { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import CellTagList from "../../../components/Common/table/CellTagList";
import DnDTable from "../../../components/Common/table/DnDTable";

type Props = {
  rows: Array<DocumentPreset>;
  presetsAreLoading: boolean;
  onSelect?: (row: DocumentPreset) => void;
  onReorder: (presets: DocumentPreset[]) => void;
  typologies: Array<Typology>;
  typologiesFilter: Array<Typology>;
  renameTypologyError: string;
  onDelete?: (documentPreset: DocumentPreset) => void;
  onFilterByTypology?: (typologies: Array<Typology>) => void;
  onAddTypology?: (documentPreset: DocumentPreset, typology: Tag) => void;
  onDeleteTypology?: (
    documentPreset: DocumentPreset,
    typology: Typology,
  ) => void;
  onUpdate?: (tag: Tag) => void;

  setShowDeleteAlert?: (value: boolean) => void;
  setAskConfirmDelete?: (value: boolean) => void;
  setTypologyToRemove?: (typology: Variant) => void;
  setDocuPresetToUnlink?: (documentPreset: DocumentPreset) => void;
  isTagLoading?: boolean;
  autosize?: boolean;
};

const DocumentPresetsTable = ({
  rows,
  presetsAreLoading,
  onSelect,
  onReorder,
  typologies,
  onDelete,
  onUpdate,
  onAddTypology,
  onDeleteTypology,
  onFilterByTypology,
  typologiesFilter,
  setShowDeleteAlert,
  setAskConfirmDelete,
  setTypologyToRemove,
  setDocuPresetToUnlink,
  renameTypologyError,
  isTagLoading,
  autosize,
}: Props) => {
  const { t } = useTranslation("documentsPresets");
  const toast = useToast();

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const element = rows.find((el) => el.id === result.draggableId);

    if (element.order === result.destination.index + 1) {
      return;
    }

    const newArray = [...rows];
    const index = rows.findIndex((obj) => obj.id === result.draggableId);
    const movedObject = newArray.splice(index, 1)[0];
    newArray.splice(result.destination.index, 0, movedObject);

    await onReorder(newArray);
  };

  useEffect(() => {
    if (renameTypologyError)
      toast({
        title: t(renameTypologyError, { ns: "common" }),
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
  }, [renameTypologyError]);

  return (
    <Flex
      flexDirection={"column"}
      alignItems={"start"}
      width="100%"
      position="relative"
      overflow={"hidden"}
      marginTop={4}
    >
      <DnDTable
        autosize={autosize}
        dnd={{
          onDragEnd,
          droppableId: "document-presets",
        }}
        showEmptyText={!rows || rows.length === 0}
        emptyText={t("noPreset")}
        isLoading={presetsAreLoading}
      >
        <Thead>
          <TableViewLabelFiltersHeader
            columns={[
              { id: "drag", title: "", width: "50px" },
              { id: "name", title: t("name"), width: "40%" },
              {
                id: "typologies",
                title: t("typologies"),
                labels: typologies,
                onFilter: onFilterByTypology,
                items: typologiesFilter,
              },
              { id: "actions", title: "", width: "90px" },
            ]}
          />
        </Thead>
        <Tbody>
          {rows.map((row) => (
            <Tr key={row.id} id={row.id} onClick={() => onSelect?.(row)}>
              <Tooltip
                label={row?.documentType?.description}
                key={row?.id}
                placement="bottom-start"
                openDelay={500}
              >
                <Td textColor={"gray.700"} w="40%">
                  {row.documentType.name}
                </Td>
              </Tooltip>
              <Td textColor={"gray.700"}>
                <CellTagList
                  tags={typologies}
                  selectedTags={
                    Array.isArray(row?.typologies) ? row.typologies : []
                  }
                  onAdd={(name) => onAddTypology?.(row, name)}
                  onDelete={(typology) => {
                    if (row.typologies.length === 1) {
                      setDocuPresetToUnlink(row);
                      setTypologyToRemove(typology as Variant);
                      setShowDeleteAlert(true);
                    } else {
                      onDeleteTypology?.(row, typology as Typology);
                    }
                  }}
                  onUpdate={onUpdate}
                  editable={true}
                  isTagLoading={isTagLoading}
                />
              </Td>

              <Td
                width="90px"
                style={{ textAlign: "right", cursor: "pointer" }}
              >
                <DeleteButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete?.(row), setAskConfirmDelete(true);
                  }}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </DnDTable>
    </Flex>
  );
};

export default DocumentPresetsTable;
