import {
  Flex,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Switch,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr as ChakraTr,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { FC, useEffect } from "react";
import { DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { MdOutlineDocumentScanner } from "react-icons/md";
import { Identifiable } from "../../../domain/entities/interfaces/identifiable";
import Requirement from "../../../domain/entities/requirement";
import { RequirementSubject } from "../../../domain/entities/requirementSubject.enum";
import Specialization from "../../../domain/entities/specialization";
import Tag from "../../../domain/entities/tag";
import Variant from "../../../domain/entities/variant";
import { COLORS } from "../../assets/theme/colors";
import ImportButton from "../../components/Common/ImportButton";
import CellTagList from "../../components/Common/table/CellTagList";
import DnDTable from "../../components/Common/table/DnDTable";
import { TableViewRowActionDef } from "../../components/Common/table/TableViewRowAction";
import { UseDownloader } from "../../hooks/Document/useDocumentsFilesDownloader";
import useVerifiedVariantsAndSpecializationsForRequirement from "../../hooks/Requirements/useVerifiedVariantsAndSpecializationsForRequirement";
import RenderIf from "../../components/Permissions/RenderIf";

type BaseProps = {
  requirements: Array<Requirement>;
  variants: Variant[];
  specializations: Specialization[];
  actions?: Array<TableViewRowActionDef>;
  onToggleOptional?: (row: Identifiable, newValue: boolean) => void;
  requirementSubject?: RequirementSubject;
  onUpdateVariant?: (tag: Tag) => void;
  onUpdateSpecialization?: (tag: Tag) => void;
  isTagLoading?: boolean;
  downloader?: UseDownloader;
};

export type Props = BaseProps & {
  isLoading: boolean;
  onSelect?: (row: Identifiable) => void;
  moveRequirement?: (requirements: Requirement[]) => void;
  variantRenameError?: string;
  specializationRenameError?: string;
  autosize?: boolean;
};

const RequirementsTable: FC<Props> = ({
  isLoading,
  onSelect,
  moveRequirement,
  variantRenameError,
  specializationRenameError,
  autosize,
  ...baseProps
}) => {
  const { t } = useTranslation("requirements");
  const toast = useToast();
  const [isTablet] = useMediaQuery("(max-width: 1300px)");
  const {
    requirements,
    variants,
    specializations,
    actions,
    requirementSubject,
  } = baseProps;
  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const element = requirements.find((el) => el.id === result.draggableId);
    if (element.order === result.destination.index + 1) {
      return;
    }

    const newArray = [...requirements];
    const index = requirements.findIndex(
      (obj) => obj.id === result.draggableId,
    );
    const movedObject = newArray.splice(index, 1)[0];
    newArray.splice(result.destination.index, 0, movedObject);

    moveRequirement(newArray);
  };

  useEffect(() => {
    if (variantRenameError) {
      toast({
        title: t(variantRenameError, { ns: "common" }),
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    }
    if (specializationRenameError) {
      toast({
        title: t(specializationRenameError, { ns: "common" }),
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [variantRenameError, specializationRenameError, toast, t]);

  const colsWidth = [
    "50px",
    "auto",
    "135px",
    "170px",
    "auto",
    "auto",
    actions.length * 60 + 50 + "px",
  ];

  return (
    <Flex width={"calc(100vw - 264px)"}>
      <DnDTable
        autosize={autosize}
        dnd={{ onDragEnd, droppableId: "requirements-table", overflow: "auto" }}
        isLoading={isLoading}
        emptyText={t("noRequirements", { ns: "requirements" })}
        showEmptyText={requirements?.length === 0}
      >
        <Thead>
          <ChakraTr>
            <Th width={colsWidth[0]} />
            <Th width={isTablet ? "200px" : colsWidth[1]}>{t("name")}</Th>
            <Th width={isTablet ? "200px" : colsWidth[2]}>{t("graceDays")}</Th>
            <Th width={isTablet ? "200px" : colsWidth[3]}>{t("isOptional")}</Th>
            <Th width={isTablet ? "200px" : colsWidth[4]}>{t("variants")}</Th>
            {requirementSubject !== RequirementSubject.COMPANY &&
              requirementSubject !== RequirementSubject.SITE && (
                <Th width={isTablet ? "200px" : colsWidth[5]}>
                  {t("specializations")}
                </Th>
              )}
            {actions && actions.length > 0 && (
              <Th width={isTablet ? "200px" : colsWidth[6]}> </Th>
            )}
          </ChakraTr>
        </Thead>
        <Tbody>
          {variants &&
            specializations &&
            requirements?.map((requirement) => (
              <ChakraTr
                key={requirement.id}
                id={requirement.id}
                onClick={() => onSelect?.(requirement)}
              >
                <RequirementsTableRow
                  requirement={requirement}
                  colsWidth={colsWidth}
                  {...baseProps}
                />
              </ChakraTr>
            ))}
        </Tbody>
      </DnDTable>
    </Flex>
  );
};

const RequirementsTableRow = ({
  requirements,
  requirement,
  variants,
  specializations,
  onToggleOptional,
  onUpdateVariant,
  onUpdateSpecialization,
  actions,
  requirementSubject,
  isTagLoading,
  colsWidth,
}: BaseProps & {
  requirement: Requirement;
  colsWidth: (string | undefined)[];
}) => {
  const {
    variants: verifiedVariants,
    specializations: verifiedSpecializations,
  } = useVerifiedVariantsAndSpecializationsForRequirement(
    requirements.filter(
      (r) =>
        r.id !== requirement.id &&
        r.documentType.id === requirement.documentType.id,
    ),
    variants,
    specializations,
    requirement,
  );
  const { t } = useTranslation("requirements");
  const [isTablet] = useMediaQuery("(max-width: 1300px)");

  return (
    <>
      <Tooltip
        label={requirement?.documentType?.description}
        key={requirement?.id}
        placement="bottom-start"
        openDelay={500}
      >
        <Td width={isTablet ? "200px" : colsWidth[1]}>
          {requirement.documentType.name}
        </Td>
      </Tooltip>
      <Td width={isTablet ? "200px" : colsWidth[2]}>
        {requirement["graceDays"]}
      </Td>
      <Td width={isTablet ? "200px" : colsWidth[3]}>
        <Switch
          isDisabled={true}
          isChecked={!requirement.isOptional}
          onChange={() =>
            onToggleOptional?.(requirement, !requirement.isOptional)
          }
        />
      </Td>

      <Td width={isTablet ? "200px" : colsWidth[4]} textColor={"gray.700"}>
        <CellTagList
          editable={true}
          tags={verifiedVariants}
          selectedTags={requirement?.variants}
          onUpdate={onUpdateVariant}
          isTagLoading={isTagLoading}
          required
        />
      </Td>
      {requirementSubject !== RequirementSubject.COMPANY &&
      requirementSubject !== RequirementSubject.SITE ? (
        <Td width={isTablet ? "200px" : colsWidth[5]} textColor={"gray.700"}>
          <CellTagList
            editable={true}
            tags={verifiedSpecializations}
            selectedTags={requirement?.specializations}
            onUpdate={onUpdateSpecialization}
            isTagLoading={isTagLoading}
          />
        </Td>
      ) : undefined}
      {actions && actions.length > 0 && (
        <Td
          width={isTablet ? "200px" : colsWidth[6]}
          style={{ textAlign: "right", cursor: "pointer" }}
        >
          <Flex flexDirection={"row"}>
            {Array.isArray(actions) &&
              actions.map((action) => (
                <RowActionButton
                  key={action.label}
                  action={action}
                  requirement={requirement}
                />
              ))}
          </Flex>
        </Td>
      )}
    </>
  );
};

type RowActionButtonProps = {
  action: TableViewRowActionDef;
  requirement: Requirement;
};

const RowActionButton = ({ action, requirement }: RowActionButtonProps) => {
  const { t } = useTranslation("requirements");
  return (
    <RenderIf permissions={action.permissions}>
      <Menu>
        <Tooltip label={action.label}>
          <IconButton
            fontSize="18px"
            backgroundColor="transparent"
            onClick={(event) => {
              event.stopPropagation();
              action.onClick?.(requirement);
            }}
            aria-label={action.label}
            sx={action.sx}
          >
            {action.templateActions ? (
              <MenuButton>
                <MdOutlineDocumentScanner
                  color={requirement.template ? COLORS.sikuroBlue : "#767676"}
                />
              </MenuButton>
            ) : (
              action.icon
            )}
          </IconButton>
        </Tooltip>
        {action.templateActions && (
          <MenuList>
            <MenuItem>
              <ImportButton
                description={t(
                  requirement.template ? "replaceTemplate" : "uploadTemplate",
                  { ns: "documents" },
                )}
                type="text"
                uploadFile={(file) =>
                  file && action.templateActions.add(requirement.id, file)
                }
              />
            </MenuItem>
            {requirement.template && (
              <MenuItem>
                <Link
                  href={requirement.template.uri}
                  isExternal
                  _hover={{ textDecoration: "none" }}
                >
                  {t("downloadTemplate", { ns: "documents" })}
                </Link>
              </MenuItem>
            )}
          </MenuList>
        )}
      </Menu>
    </RenderIf>
  );
};

export default RequirementsTable;
