import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../assets/theme/colors";
import TagBadge from "./TagBadge";
import {
  CombinedEvaluationStates,
  SupplierEvaluationState,
} from "../../../domain/entities/supplierEvaluationState.enum";
import { ResourceDocumentEvaluationState } from "../../../domain/entities/resourceDocumentEvaluationState.enum";
import { UserState } from "../../../domain/entities/user";
import { SupplierStatus } from "../../../domain/entities/supplierStatus.enum";
import { SiteState } from "../../../domain/entities/site";
import { AiTaskStatus } from "../../../domain/entities/aiTask";

interface Props {
  value: string;
  type:
    | "supplierEvaluation"
    | "documentEvaluation"
    | "supplierStatus"
    | "siteStatus"
    | "userState"
    | "combinedEvaluation"
    | "aiTaskStatus";
  context?: string;
  marginLeft?: string;
}

const stateColorsMap = {
  supplierEvaluation: {
    [SupplierEvaluationState.ELIGIBLE]: COLORS.active,
    [SupplierEvaluationState.NOT_ELIGIBLE]: COLORS.error,
    [SupplierEvaluationState.ELIGIBLE_WITH_INTEGRATION]: COLORS.yellow,
    [SupplierEvaluationState.UNEVALUATED]: COLORS.yellow,
  },
  documentEvaluation: {
    [ResourceDocumentEvaluationState.AVAILABLE]: COLORS.active,
    [ResourceDocumentEvaluationState.NOT_AVAILABLE]: COLORS.error,
    [ResourceDocumentEvaluationState.NOT_APPLICABLE]: COLORS.yellow,
  },
  userState: {
    [UserState.ENABLED]: COLORS.active,
    [UserState.BLOCKED]: COLORS.error,
    [UserState.INVITED]: COLORS.yellow,
  },
  supplierStatus: {
    [SupplierStatus.ACTIVE]: COLORS.active,
    [SupplierStatus.BLOCKED]: COLORS.error,
    [SupplierStatus.PENDING]: COLORS.yellow,
  },
  siteStatus: {
    [SiteState.ACTIVE]: COLORS.active,
    [SiteState.CLOSED]: COLORS.error,
    [SiteState.SUSPENDED]: COLORS.yellow,
  },

  combinedEvaluation: {
    [CombinedEvaluationStates.ELIGIBLE]: COLORS.active,
    [CombinedEvaluationStates.NOT_ELIGIBLE]: COLORS.error,
    [CombinedEvaluationStates.ELIGIBLE_WITH_INTEGRATION]: COLORS.yellow,
    [CombinedEvaluationStates.PENDING]: COLORS.yellow,
    [CombinedEvaluationStates.EXPIRED]: COLORS.red,
    [CombinedEvaluationStates.UNEVALUATED]: COLORS.gray,
    [CombinedEvaluationStates.AVAILABLE]: COLORS.active,
    [CombinedEvaluationStates.NOT_AVAILABLE]: COLORS.error,
    [CombinedEvaluationStates.NOT_APPLICABLE]: COLORS.yellow,
  },
  aiTaskStatus: {
    [AiTaskStatus.PENDING]: COLORS.gray,
    [AiTaskStatus.RUNNING]: COLORS.sikuroBlue,
    [AiTaskStatus.TERMINATED]: COLORS.lightGreen,
    [AiTaskStatus.ERROR]: COLORS.error,
  },
};

const StateTag: FC<Props> = ({ type, context, value, marginLeft }) => {
  const { t } = useTranslation("enum", { keyPrefix: context ? context : type });

  return (
    <TagBadge
      marginLeft={marginLeft}
      tag={{
        id: value,
        name: t(value),
        color: stateColorsMap[type][value] ?? COLORS.gray,
      }}
    />
  );
};

export default StateTag;
