import { useParams } from "react-router-dom";
import { SiteMachineParams } from "../../../domain/entities/siteMachine";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import SiteResourceDetailView from "../../components/Views/Sites/SiteResourceDetailView";
import useWorkingSiteMachineDetailViewModel from "../../hooks/WorkingSite/useWorkingSiteMachineDetailViewModel";
import { useResourceFilesDownloader } from "../../hooks/Document/useDocumentsFilesDownloader";
import { Permission } from "../../components/Permissions/Permissions";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";

const WorkingSiteMachineDetailView = () => {
  const { siteId, machineId } = useParams();
  const resourceColumns = ["name", "plate", "model"];

  const downloader = useResourceFilesDownloader(siteId, machineId, "machines");

  return (
    <SiteResourceDetailView<SiteMachineParams>
      downloader={downloader}
      resourceId={machineId}
      type={DocumentTypeResourceType.MACHINE}
      useViewModel={useWorkingSiteMachineDetailViewModel(
        siteId,
        machineId,
        resourceColumns,
        DocumentTypeResourceType.MACHINE,
        AiTaskSectionCode.WORKING_SITE_RESOURCES_MACHINES
      )}
      resourceColumns={resourceColumns}
      isWorkingSite={true}
      isReadOnly={true}
      permissions={{
        downloadDocuments: Permission.Worksite_DownloadMachineDoc,
        showGlobalEvaluation: Permission.Worksite_ShowMachineGlobalEval,
        setGlobalEvaluation: Permission.Worksite_Show,
        addRequirement: Permission.Worksite_NewMachineReq,
        viewRequirements: Permission.Worksite_Show,
        editTags: Permission.Worksite_EditMachineReq,
        manageDocumentWithAi: Permission.Company_AIManageFile,
        documents: {
          delete: Permission.Worksite_Machine_DeleteFile,
          edit: Permission.Worksite_Machine_EditFile,
          evaluate: Permission.Worksite_EvalMachineReq,
          viewEvaluations: Permission.Worksite_EvalMachineReq,
          downloadAll: Permission.Worksite_DownloadAllFile,
          addFile: Permission.Worksite_EditMachineReq,
          addNewFile: Permission.Worksite_Machine_AddFile,
          chat: Permission.Worksite_MachineChat,
          report: Permission.Worksite_MachineChat,
          setExpiration: Permission.Worksite_Machine_SetExpiration,
        },
      }}
    />
  );
};

export default WorkingSiteMachineDetailView;
