import SubNavBar from "../../Common/SubNavBar";
import { useTranslation } from "react-i18next";
import BookIcon from "../../Common/icons/BookIcon";
import { NavItemProps } from "../../Common/NavItem";
import UsersIcon from "../../Common/icons/UsersIcon";
import { Permission } from "../../Permissions/Permissions";
import WebHookIcon from "../../Common/icons/WebHookIcon";

const SettingsSubNav = () => {
  const { t } = useTranslation();

  const items: NavItemProps[] = [
    {
      Icon: BookIcon,
      path: "/settings/roles",
      name: t("roles.title", { ns: "settings" }),
      permissions: [Permission.Settings_EditRoles],
    },
    {
      Icon: UsersIcon,
      path: "/settings/users",
      name: t("users.title", { ns: "settings" }),
      permissions: [Permission.Settings_EditUsers],
    },
    {
      Icon: WebHookIcon,
      path: "/settings/webhooks",
      name: t("title", { ns: "webhooks" }),
      permissions: [Permission.Settings_EditWebHooks],
    },
  ];

  return <SubNavBar items={items} />;
};

export default SettingsSubNav;
