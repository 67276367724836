import {
  Box,
  forwardRef,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";

import { useState } from "react";
import { COLORS } from "../../assets/theme/colors";
import { IconType } from "react-icons";

const ActionBarItemMenu = forwardRef(
  (
    {
      menuItems,
      icon: Icon,
      onClick,
      bgColor,
      color,
      description,
      isDisabled,
      disabledDescription,
    }: ActionBarItemMenuProps,
    ref,
  ) => {
    const defaultBgColor = "#eece23";
    const defaultIconColor = "black";
    const disabledIconColor = "gray.100";
    const disabledBgColor = "#ccc27f";
    let defaultHighlightColor = isDisabled ? "transparent" : COLORS.sikuroBlue;
    const [isButtonHovered, setIsButtonHovered] = useState(false);
    if (!bgColor) {
      bgColor = isDisabled ? disabledBgColor : defaultBgColor;
    }
    if (!color) {
      color = isDisabled ? disabledIconColor : defaultIconColor;
    }
    if (bgColor === COLORS.sikuroBlue) {
      defaultHighlightColor = defaultBgColor;
    }

    return (
      <Box
        onMouseEnter={() => setIsButtonHovered(true)}
        onMouseLeave={() => setIsButtonHovered(false)}
        borderRadius={"full"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        w={"2.8rem"}
        h={"2.8rem"}
        background={isButtonHovered ? defaultHighlightColor : "transparent"}
        cursor={"pointer"}
      >
        <Box
          borderRadius={"full"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          w={"88%"}
          h={"88%"}
          cursor={"pointer"}
          background={bgColor}
        >
          <Menu>
            <Tooltip
              label={isDisabled ? disabledDescription : description}
              aria-label={description}
            >
              <MenuButton
                isActive={!isDisabled}
                as={IconButton}
                icon={<Icon size={20} />}
                color={color}
                rounded={50}
                _hover={{ backgroundColor: "transparent" }}
                _active={{ backgroundColor: "transparent" }}
                background={isButtonHovered ? defaultBgColor : "transparent"}
                cursor={"pointer"}
              />
            </Tooltip>
            {!isDisabled && (
              <MenuList>
                {menuItems.map((item) => {
                  return (
                    <MenuItem key={item.key} onClick={() => onClick(item.key)}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </MenuList>
            )}
          </Menu>
        </Box>
      </Box>
    );
  },
);

interface ActionBarItemMenuProps {
  menuItems: { label: string; key: string }[];
  icon: IconType;
  onClick?: (type: string) => void;
  bgColor?: string;
  color?: string;
  description: string;
  disabledDescription?: string;
  isDisabled?: boolean;
}

export default ActionBarItemMenu;
