import { useTranslation } from "react-i18next";
import TopBar, { TopBarItem } from "../../components/Common/TopBar";

const UserSettingsTopBar = () => {
  const { t } = useTranslation();

  const items: TopBarItem[] = [
    {
      title: t("userSettingsTopBar", { ns: "userSettings" }),
      path: "/userSettings",
    },
  ];

  return <TopBar title={""} items={items} />;
};

export default UserSettingsTopBar;
