import React, { ChangeEvent, KeyboardEvent, useState } from "react";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { AiOutlineClear } from "react-icons/ai";
import { useTranslation } from "react-i18next";

interface SearchInputProps {
  onSearch: (query: string) => void;
  debounceTime?: number;
}

const SearchInput: React.FC<SearchInputProps> = ({
  onSearch,
  debounceTime = 1000,
}) => {
  const { t } = useTranslation("common");
  const [query, setQuery] = useState<string>("");
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newQuery = e.target.value;
    setQuery(newQuery);

    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      onSearch(newQuery);
    }, debounceTime);

    setTimer(newTimer);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSearch(query);
    }
  };
  const clearSearch = () => {
    setQuery("");
    onSearch("");
  };

  return (
    <InputGroup w="300px">
      <Input
        type="text"
        value={query}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={t("search")}
      />
      <InputRightElement>
        {query.length === 0 ? (
          <FiSearch cursor={"pointer"} />
        ) : (
          <AiOutlineClear cursor={"pointer"} onClick={() => clearSearch()} />
        )}
      </InputRightElement>
    </InputGroup>
  );
};

export default SearchInput;
