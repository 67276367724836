import ActionButton, {ActionButtonProps} from "./ActionButton";
import {Icon, Tooltip} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaRegSave } from "react-icons/fa";

const SaveButton = ({ onClick, buttonColor, buttonWidth, iconSize }: ActionButtonProps) => {
  const { t } = useTranslation();

  return (
    <Tooltip label={t("save", { ns: "common" })} aria-label="Save">
      <span>
        <ActionButton aria-label="edit" onClick={onClick} buttonWidth={buttonWidth} 
            icon={<Icon as={FaRegSave} fontSize={iconSize ? iconSize : "22px"} color={buttonColor}/>} />
      </span>
    </Tooltip>
  );
}


export default SaveButton