import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import DocumentType from "../../../domain/entities/documentType";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { SiteChemical } from "../../../domain/entities/siteChemical";
import { SiteMachine } from "../../../domain/entities/siteMachine";
import { SiteTool } from "../../../domain/entities/siteTool";
import { SiteVehicle } from "../../../domain/entities/siteVehicle";
import { SiteWorker } from "../../../domain/entities/siteWorker";
import { GetSiteResourcesAuditFilters } from "../../hooks/Site/useSiteAuditsViewModel";
import RequirementSelectableTable from "./RequirementSelectableTable";
import ResourceSelectableTable, {
  ResourceType,
} from "./ResourceSelectableTable";

interface CreateAuditProp {
  documentTypeList?: DocumentType[];
  resourceList?:
    | SiteWorker[]
    | SiteChemical[]
    | SiteMachine[]
    | SiteVehicle[]
    | SiteTool[];
  resourcesAreLoading?: boolean;
  includedResource?: string[];
  setIncludedResource?: (resourceIds: string[]) => void;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  requirementsAreLoading?: boolean;
  excludedDocumentTypes?: string[];
  setExcludedDocumentTypes?: (requirementIds: string[]) => void;
  title: string;
  type: "requirement" | "resource";
  setSiteResourcesSort?: Dispatch<SetStateAction<SortMeta>>;
  siteResourcesSort?: SortMeta;
  resourceType?: ResourceType;
  setSiteResourceFilter?: Dispatch<
    SetStateAction<GetSiteResourcesAuditFilters>
  >;
  siteResourceFilter?: GetSiteResourcesAuditFilters;
}
export const AuditAccordion = ({
  documentTypeList,
  hasNextPage,
  fetchNextPage,
  requirementsAreLoading,
  type,
  resourceList,
  includedResource,
  resourcesAreLoading,
  setIncludedResource,
  setSiteResourcesSort,
  siteResourcesSort,
  resourceType,
  setSiteResourceFilter,
  siteResourceFilter,
  setExcludedDocumentTypes,
  excludedDocumentTypes,
  title,
}: CreateAuditProp) => {
  const { t } = useTranslation("audit");

  return (
    <Accordion
      allowToggle
      borderRadius={8}
      border="1px solid #E2E8F0"
      marginTop={2}
    >
      {
        <AccordionItem border="none" key={title}>
          <AccordionButton>
            <HStack spacing={2} flex={1}>
              <Text>{title}</Text>
            </HStack>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <VStack spacing={2} alignItems="start">
              {type === "requirement" ? (
                <RequirementSelectableTable
                  documentTypeList={documentTypeList}
                  hasNextPage={hasNextPage}
                  fetchNextPage={fetchNextPage}
                  requirementsListIsLoading={requirementsAreLoading}
                  excludedRequirements={excludedDocumentTypes}
                  setExcludedDocumentTypes={setExcludedDocumentTypes}
                  tableId={title}
                />
              ) : (
                <ResourceSelectableTable
                  resourceList={resourceList}
                  hasNextPage={hasNextPage}
                  fetchNextPage={fetchNextPage}
                  resourceIsLoading={resourcesAreLoading}
                  includedResource={includedResource}
                  setIncludedResource={setIncludedResource}
                  setSiteResourcesSort={setSiteResourcesSort}
                  siteResourcesSort={siteResourcesSort}
                  setSiteResourceFilter={setSiteResourceFilter}
                  siteResourceFilter={siteResourceFilter}
                  tableId={title}
                  type={resourceType}
                />
              )}
            </VStack>
          </AccordionPanel>
        </AccordionItem>
      }
    </Accordion>
  );
};
