import Tool from "../../../domain/entities/tool";
import {
  makeGetTool,
  makeUpdateTool,
} from "../../../core/app/factory/tools/usecases";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";
import Site, { ResourceSites } from "../../../domain/entities/site";
import {
  makeGetResourceLinkableSites,
  makeGetToolSites,
} from "../../../core/app/factory/sites/usecases";
import { makeAddResourceToSites } from "../../../core/app/factory/workers/usecases";

class ToolViewModel {
  private getToolUseCase = makeGetTool();
  private updateToolUseCase = makeUpdateTool();
  private getToolSitesUseCase = makeGetToolSites();
  private getResourceLinkableSitesUseCase = makeGetResourceLinkableSites();
  private linkResourceToSitesUseCase = makeAddResourceToSites();

  async get(companyId: string, toolId: string): Promise<Tool> {
    return await this.getToolUseCase.Call({ companyId, toolId });
  }

  async update(companyId: string, tool: Tool, imageFile?: File): Promise<Tool> {
    return await this.updateToolUseCase.Call({ companyId, tool, imageFile });
  }
  async getToolSites(
    companyId: string,
    toolId: string,
    sort: SortMeta,
    filter: GetSitesFilter,
    pageParam: number,
    includeWorkingSites?: boolean,
  ): Promise<ResourceSites> {
    return await this.getToolSitesUseCase.Call({
      companyId,
      toolId,
      sort,
      filter,
      pageParam,
      includeWorkingSites,
    });
  }
  async getResourceLinkableSites(
    companyId: string,
    resourceType: string,
    resourceId: string,
    sort: SortMeta,
    filters: GetSitesFilter,
    pageParam: number,
  ): Promise<Site[]> {
    return await this.getResourceLinkableSitesUseCase.Call({
      companyId,
      resourceType,
      resourceId,
      sort,
      filters,
      pageParam,
    });
  }
  async linkResourceToSites(
    companyId: string,
    workerId: string,
    siteIds: string[],
  ): Promise<void> {
    return await this.linkResourceToSitesUseCase.Call({
      companyId,
      resourceType: "tool",
      resourceId: workerId,
      siteIds,
    });
  }
}

export { ToolViewModel };
