import {
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import {
  DocumentActionLogEntry,
  DocumentFileLogEntry,
} from "../../../../domain/entities/documentLogEntry";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import {
  Filter,
  UpdateFilter,
} from "../../../hooks/Site/useSiteResourceDetailViewModel";
import DocumentActionsHistoryTable from "../Documents/DocumentActionsHistoryTable";
import DocumentHistoryTable from "../Documents/DocumentHistoryTable";

type DocumentHistoryModalProps = {
  onClose: () => void;
  documentFileLogs: DocumentFileLogEntry[];
  documentActionLogs: DocumentActionLogEntry[];
  updateFilterDocumentLog: UpdateFilter;
  filterDocumentLog: Filter;
  sortDocumentLog: SortMeta;
  setSortDocumentLog: (sort: SortMeta) => void;
  documentLogsFetching: boolean;
  documentFileLogsFetching: boolean;
  fileLogsHasNextPage: boolean;
  fileLogsFetchNextPage: () => void;
  docLogsHasNextPage?: boolean;
  docLogsFetchNextPage?: () => void;
  fromSite?: boolean;
};

const DocumentHistoryModal = ({
  onClose,
  documentFileLogs,
  updateFilterDocumentLog,
  documentActionLogs,
  filterDocumentLog,
  sortDocumentLog,
  setSortDocumentLog,
  documentLogsFetching,
  documentFileLogsFetching,
  fileLogsHasNextPage,
  fileLogsFetchNextPage,
  fromSite,
  docLogsHasNextPage,
  docLogsFetchNextPage,
}: DocumentHistoryModalProps) => {
  const { t } = useTranslation("documents");

  const tabs = [
    { name: "fileHistory", canView: true },
    { name: "documentHistory", canView: fromSite ? false : true },
  ];
  return (
    <Modal isOpen={true} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent style={{ background: "white", padding: "2rem" }}>
        <ModalHeader display="flex" justifyContent="space-between">
          {t("documentHistory")}
          <IconButton
            fontSize="3xl"
            size="2xl"
            backgroundColor="transparent"
            onClick={onClose}
            aria-label="close"
          >
            <MdClose />
          </IconButton>
        </ModalHeader>
        <ModalBody>
          <Tabs>
            <TabList>
              {tabs.map((tab, index) => {
                return (
                  tab.canView && (
                    <Tab width={"100%"} key={index}>
                      {t(tab.name, { ns: "documents" })}
                    </Tab>
                  )
                );
              })}
            </TabList>
            <TabPanels>
              <TabPanel paddingLeft={0} paddingRight={0}>
                <DocumentHistoryTable
                  autosize
                  documentFileLogs={documentFileLogs}
                  updateFilterDocumentLog={updateFilterDocumentLog}
                  filterDocumentLog={filterDocumentLog}
                  sortDocumentLog={sortDocumentLog}
                  setSortDocumentLog={setSortDocumentLog}
                  documentLogsFetching={documentLogsFetching}
                  hasNextPage={fileLogsHasNextPage}
                  fetchNextPage={fileLogsFetchNextPage}
                />
              </TabPanel>
              <TabPanel paddingLeft={0} paddingRight={0}>
                <DocumentActionsHistoryTable
                  autosize
                  documentFileLogs={documentActionLogs}
                  updateFilterDocumentLog={updateFilterDocumentLog} // Same table, same filters, may change in future releases
                  filterDocumentLog={filterDocumentLog}
                  sortDocumentLog={sortDocumentLog}
                  setSortDocumentLog={setSortDocumentLog}
                  documentLogsFetching={documentFileLogsFetching}
                  hasNextPage={docLogsHasNextPage}
                  fetchNextPage={docLogsFetchNextPage}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DocumentHistoryModal;
