import { injectable } from 'tsyringe';
import User from '../../domain/entities/user';
import UsersRepository from '../../domain/repositories/usersRepository';
import { GetUsersFilter, getUsersFilterQuery } from '../../domain/repositories/filters';
import Permission from '../../domain/entities/permission';
import { ApiService } from '../utilities/apiService';

@injectable()
class ServerUsersRepository implements UsersRepository {
	constructor(private apiService: ApiService) {}

	private apiBaseURL = process.env.REACT_APP_SERVER_API_ENDPOINT;

	// Public.
	async getUsers(companyId: string, filters?: GetUsersFilter): Promise<Array<User>> {
		const query = getUsersFilterQuery(filters);
		const url = `${this.baseURL(companyId)}/users${query}`;
		const response = await this.apiService.fetchWithToken(url);
		const { results } = await response.json();
		return results ?? [];
	}

	async getUser(companyId: string, userId: string): Promise<User> {
		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/users/${userId}`);
		return response.json();
	}

	async deleteUser(companyId: string, userId: string): Promise<boolean> {
		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/users/${userId}`, { method: 'DELETE' });
		return Promise.resolve(response.ok);
	}

	async createUser(companyId: string, name: string, email: string, roleId: string): Promise<User> {
		const payload = {
			name: name,
			email: email,
			roleId: roleId,
		};

		try {
			const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/users`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(payload),
			});
			if (!response.ok) {
				if (response.status === 403) {
					const message = 'userAlreadyRegistered';
					return Promise.reject(new Error(message));
				}
			}
			return await response.json();
		} catch (e) {
			const message = e.message || 'Unknown error occurred';
			return Promise.reject(new Error(message));
		}
	}

	async updateUser(companyId: string, id: string, user: User): Promise<User> {
		const payload = {
			name: user.name,
			email: user.email,
			state: user.state,
			roleId: user.role.id,
			language: user.language,
		};

		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/users/${id}`, {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(payload),
		});

		return response.json();
	}

	async updateUserImage(companyId: string, userId: string, image: File): Promise<User> {
		const request = new FormData();
		request.append('photo', image);

		const url = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/users/${userId}/photo`;
		const response = await this.apiService.fetchWithToken(url, { method: 'POST', body: request });
		return await response.json();
	}

	async getGeneralPermissions(companyId: string, userId: string): Promise<Permission[]> {
		const response = await this.apiService.fetchWithToken(`${this.apiBaseURL}/auth/users/${userId}/permissions?tenant=${companyId}`);

		const { results } = await response.json();
		return results ?? [];
	}

	async getSitePermissions(companyId: string, userId: string, siteId: string): Promise<Permission[]> {
		const response = await this.apiService.fetchWithToken(
			`${this.apiBaseURL}/auth/users/${userId}/sites/${siteId}/permissions?tenant=${companyId}`,
		);

		const { results } = await response.json();
		return results ?? [];
	}

	async userEmailAvailable(email: string): Promise<boolean> {
		const response = await this.apiService.fetchWithToken(`${this.apiBaseURL}/users/email-available?email=${email}`);
		const { result } = await response.json();
		return result;
	}

	async getBusinessTypes(): Promise<string[]> {
		const response = await this.apiService.fetchWithToken(`${this.apiBaseURL}/available-business-types`);
		const { result } = await response.json();
		return result;
	}

	async renewInvitation(companyId: string, invitationToken: string, siteId?: string): Promise<void> {
		try {
			const url = siteId
				? `${this.baseURL(companyId)}/sites/${siteId}/token/${invitationToken}/renew`
				: `${this.baseURL(companyId)}/users/token/${invitationToken}/renew`;
			const response = await this.apiService.fetchWithToken(url, {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
			});
			if (!response.ok) {
				if (response.status === 425) {
					const error = 'tooEarly';
					return Promise.reject(new Error(error));
				}
				if (response.status === 117) {
					const error = 'invitationAlreadyAccepted';
					return Promise.reject(new Error(error));
				} else {
					const message = 'generic';
					return Promise.reject(new Error(message));
				}
			}
		} catch (e) {
			const message = e.message || 'Unknown error occurred';
			return Promise.reject(new Error(message));
		}
	}

	// Internal.
	private baseURL = (companyId: string): string => {
		return `${this.apiBaseURL}/companies/${companyId}`;
	};
}

export default ServerUsersRepository;
