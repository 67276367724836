import UseCase from '../../../core/app/usecase';
import SupplierRepository, { GetSupplierToolsFilters } from '../../repositories/supplierRepository';
import { PaginatedResults, SortMeta } from '../../entities/interfaces/paginatedResults';
import { SiteToolParams } from '../../entities/siteTool';
import { SupplierResource } from '../../entities/supplierResource';
type ARGS = {
	companyId: string;
	supplierId: string;
	page: number;
	perPage?: number;
	filter?: GetSupplierToolsFilters;
	sort?: SortMeta;
};
class GetCompanySupplierTools implements UseCase<ARGS, PaginatedResults<SupplierResource<SiteToolParams>>> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, supplierId, page, filter, sort }: ARGS): Promise<PaginatedResults<SupplierResource<SiteToolParams>>> {
		return await this.supplierRepository.getCompanySupplierTools(companyId, supplierId, page, 10, filter, sort);
	}
}

export default GetCompanySupplierTools;
