import { useMutation, useQuery } from "@tanstack/react-query";
import { Coords } from "google-map-react";
import { useState } from "react";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import Site, { SiteState } from "../../../domain/entities/site";
import Staff from "../../../domain/entities/staff";
import { updateFilterWithDelete } from "../../../utils";
import { GetStaffFilters } from "../../components/Views/Company/StaffTableView";
import { useAuth } from "../../providers/Auth0JWTProvider";
import SiteDetailsViewModel from "../../viewmodels/sites/SiteDetailsViewModel";
import StaffViewModel from "../../viewmodels/staff/StaffViewModel";

interface UpdateSiteArgs {
  site: Site;
  image?: File;
}

const useSiteDetailsViewModel = (id: string) => {
  const { companyId } = useAuth();
  const viewModel = new SiteDetailsViewModel(companyId);
  const staffViewModel = new StaffViewModel();

  const [staffFilter, setStaffFilter] = useState<GetStaffFilters>({});
  const [staffSort, setStaffSort] = useState<SortMeta>(null);

  const {
    data: site,
    isFetching,
    error,
    refetch,
  } = useQuery(
    ["get-site-details", companyId, id],
    async () => await viewModel.get(id),
  );

  const updateMut = useMutation(
    ({ site, image }: UpdateSiteArgs) => viewModel.update(site, image),
    {
      onError: (err) => console.error("cannot update site state", err),
      onSuccess: () => refetch(),
    },
  );

  const updateSite = async (site: Site) => {
    return updateMut.mutateAsync({ site });
  };

  const updateSiteImage = async (site: Site, image: File) => {
    return updateMut.mutateAsync({ site, image });
  };

  const updateStateMut = useMutation(
    async (state: SiteState) => {
      await viewModel.updateState(id, state);
      return true;
    },
    {
      onError: (err) => console.error("cannot update site state", err),
      onSuccess: () => {
        refetch();
      },
    },
  );

  const updateFilter = (field: string, value: string | string[]) => {
    updateFilterWithDelete(setStaffFilter, field, value);
  };

  const {
    data: staff,
    isFetching: staffIsFetching,
    error: staffError,
    refetch: refetchStaff,
  } = useQuery<Staff[], Error>(
    ["staff-list", companyId, staffFilter, staffSort],
    async () => staffViewModel.getStaff(companyId, staffFilter, staffSort, id),
    {
      initialData: [],
    },
  );

  const createStaffMutation = useMutation(
    async (staffElement: Staff) =>
      staffViewModel.createStaff(companyId, staffElement, id),
    {
      onSuccess: () => refetchStaff(),
    },
  );

  const updateStaffMutation = useMutation(
    async (staffElement: Staff) =>
      staffViewModel.updateStaffElement(companyId, staffElement, id),
    {
      onError: (err) => {
        // handle error
        console.error(err);
      },
      onSuccess: () => refetchStaff(),
    },
  );

  const deleteStaffMutation = useMutation(
    async (staffElementId: string) =>
      staffViewModel.deleteStaffElement(companyId, staffElementId, id),
    {
      onError: (err) => console.error("cannot delete supplier", err),
      onSuccess: () => refetchStaff(),
    },
  );

  const { data: coordinates, isLoading } = useQuery<Coords, Error>(
    ["coordinates", site?.address, site?.city, site?.zipCode],
    async () => {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(`${site.address}, ${site.city}, ${site.zipCode}`)}&key=AIzaSyD0SMt6OZ4C1BfJNlw5b6ZoAOAPO-F_RrQ`,
      );
      const data = await response.json();
      const { lat, lng } = data.results[0].geometry.location;
      return { lat, lng };
    },
    { enabled: !!(site?.address && site?.city && site?.zipCode) },
  );

  return {
    site: site,
    isFetching,
    error,
    coordinates: coordinates,
    updateSite,
    updateSiteImage,
    updateState: updateStateMut.mutate,
    staff,
    staffIsFetching,
    createStaff: createStaffMutation.mutateAsync,
    updateFilter,
    staffFilter,
    staffSort,
    setStaffSort,
    deleteStaff: deleteStaffMutation.mutateAsync,
    deleteStaffIsLoading: deleteStaffMutation.isLoading,
    updateStaff: updateStaffMutation.mutateAsync,
    createStaffIsLoading: createStaffMutation.isLoading,
    updateStaffIsLoading: updateStaffMutation.isLoading,
  };
};

export default useSiteDetailsViewModel;
