import {
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import { ErrorBanner } from "./alerts/ErrorBanner";
import DragDropImageView from "./DragDropImageView";

// Props.
interface UploadImageProps {
  isOpen: boolean;
  title: string;
  onSave: (file: File) => void;
  onCancel: () => void;
  droppedImage?: File;
}

/**
 * Renders a modal view that allow user to upload a new image
 *
 * @param isOpen the flag indicating if the view is visible
 * @param title - The title of the modal.
 * @param onSave the handler that saves the uploaded file on the backend
 * @param onCancel the handler that closes the modal
 * @param droppedImage - The file that was dropped into the modal.
 * * @returns
 */
const UploadImageView: FC<UploadImageProps> = ({
  isOpen,
  title,
  onSave,
  onCancel,
  droppedImage,
}) => {
  const { t } = useTranslation();
  const [imageFile, setImageFile] = useState<File>();
  const [error, setError] = useState<string>("");
  const [cropper, setCropper] = useState<Cropper>();
  const onUploadedCallback = (uploadedFile: File) => {
    setImageFile(uploadedFile);
  };
  const onClose = () => {
    onCancel();
  };
  const clearError = () => {
    setError("");
  };
  const getCropData = async () => {
    if (cropper) {
      const croppedFile = await fetch(cropper.getCroppedCanvas().toDataURL())
        .then((res) => res.blob())
        .then((blob) => {
          return new File([blob], imageFile.name, { type: "image/png" });
        });
      if (croppedFile) {
        onSave(croppedFile);
      }
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent backgroundColor="white" padding={5}>
        <ModalHeader>
          <Flex
            flex={1}
            h="100%"
            w="100%"
            alignItems="center"
            textAlign="center"
            flexDirection="row"
          >
            <Text textColor={"black"} fontSize={14}>
              {title}
            </Text>
            <Flex flex={1} />
            <IconButton
              marginLeft={3}
              fontSize="3xl"
              size="2xl"
              backgroundColor="transparent"
              onClick={onClose}
              aria-label="Edit"
            >
              <MdClose />
            </IconButton>
          </Flex>
        </ModalHeader>
        <ModalBody>
          {error && <ErrorBanner text={error} onClose={clearError} />}

          <Flex
            flex={1}
            h="100%"
            w="100%"
            rounded="md"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            flexDirection="column"
          >
            <DragDropImageView
              onUploaded={onUploadedCallback}
              externalDroppedImage={droppedImage}
              setParentCropperReference={setCropper}
            />
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex grow={1} />
          <Button
            colorScheme="blue"
            onClick={() => {
              if (imageFile) {
                getCropData();
                onClose();
              } else {
                setError("File not uploaded");
              }
            }}
            marginRight={5}
          >
            {t("continue", { ns: "common" })}
          </Button>
          <Button colorScheme="red" onClick={onClose}>
            {t("cancel", { ns: "common" })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { UploadImageView };
