export enum AiTaskSectionCode {
    MY_RESOURCES_WORKERS = 'resources.workers',
    MY_RESOURCES_VEHICLES = 'resources.vehicles',
    MY_RESOURCES_MACHINES = 'resources.machines',
    MY_RESOURCES_TOOLS = 'resources.tools',
    MY_RESOURCES_CHEMICALS = 'resources.chemicals',
    MY_COMPANY_DOCUMENTS = 'company.documents',
    SITE_GENERAL_DOCUMENTS = 'sites.documents.general',
    SITE_DOCUMENTS_COMPANY = 'sites.documents.company',
    SITE_DOCUMENTS_SITE = 'sites.documents.site',

    SITE_RESOURCES_WORKERS = 'sites.resources.workers',
    SITE_RESOURCES_VEHICLES = 'sites.resources.vehicles',
    SITE_RESOURCES_MACHINES = 'sites.resources.machines',
    SITE_RESOURCES_TOOLS = 'sites.resources.tools',
    SITE_RESOURCES_CHEMICALS = 'sites.resources.chemicals',

    SITE_SUPPLIERS_COMPANY = 'sites.suppliers.documents.company',
    SITE_SUPPLIERS_SITE = 'sites.suppliers.documents.site',

    WORKING_SITE_RESOURCES_WORKERS = 'workingsites.resources.workers',
    WORKING_SITE_RESOURCES_VEHICLES = 'workingsites.resources.vehicles',
    WORKING_SITE_RESOURCES_MACHINES = 'workingsites.resources.machines',
    WORKING_SITE_RESOURCES_TOOLS = 'workingsites.resources.tools',
    WORKING_SITE_RESOURCES_CHEMICALS = 'workingsites.resources.chemicals',
    WORKING_SITE_RESOURCES_COMPANY = 'workingsites.documents.company',
    WORKING_SITE_RESOURCES_SITE = 'workingsites.documents.site',
  }