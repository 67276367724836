import Badge, {
  SiteData,
  VehicleMetas,
} from "../../../../domain/entities/badge";
import { Image, Text, View } from "@react-pdf/renderer";
import { styles } from "./badgeStyles";
import React from "react";
import vehiclePlaceholder from "../../../../assets/images/vehicle.png";

type NfcBadgeVehicleProps = {
  badge: Badge;
};
const NfcBadgeVehicle = ({ badge }: NfcBadgeVehicleProps) => {
  const { name } = badge.site as SiteData;
  const { employer, plate } = badge.resource.meta as VehicleMetas;
  return (
    <View style={styles.badgeContainer}>
      <View style={styles.headerContainer}>
        <Text style={styles.siteName}>{name}</Text>
        <View style={{ flexDirection: "row" }}>
          <Text style={{ fontWeight: 700 }}>Datore di lavoro: </Text>
          <Text>{employer}</Text>
        </View>
      </View>
      <View style={styles.mainContainer}>
        <View style={styles.photoContainer}>
          <Image style={styles.photo} src={vehiclePlaceholder} />
        </View>
        <View style={styles.workerDetailsContainer}>
          <View style={styles.workerDetailsRow}>
            <Text style={{ fontWeight: 700 }}>Veicolo: </Text>
            <Text>{badge.resource.name}</Text>
          </View>
          <View style={styles.workerDetailsRow}>
            <Text style={{ fontWeight: 700 }}>Targa: </Text>
            <Text>{plate}</Text>
          </View>
        </View>
      </View>
      <View style={styles.footerContainer}>
        <Text>{`Codice badge: ${badge.code}`}</Text>
      </View>
    </View>
  );
};
export default NfcBadgeVehicle;
