import { FC } from "react";
import { useTranslation } from "react-i18next";
import TopBar, { TopBarItem } from "../../Common/TopBar";

const SettingsTopBar: FC<{ title?: string; backPath?: string }> = ({
  title,
  backPath,
}) => {
  const { t } = useTranslation();

  const items: TopBarItem[] = [
    {
      title: t("access", { ns: "settings" }),
      path: "/settings",
    },
  ];

  return (
    <TopBar
      title={title ?? t("title", { ns: "settings" })}
      items={items}
      backPath={backPath}
    />
  );
};

export default SettingsTopBar;
