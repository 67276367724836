import FormInput from "../../components/Common/FormInput";
import { useTranslation } from "react-i18next";
import { UseFormReturn } from "react-hook-form";
import { FormType } from "../../hooks/Onboarding/useOnboardingViewModel";
import PasswordFields from "./PasswordFields";
import OnboardingCheckboxes from "./OnboardingCheckboxes";
import { licenseTypes } from "../../../constants";
import { FiInfo } from "react-icons/fi";
import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Tooltip,
} from "@chakra-ui/react";
import { COLORS } from "../../assets/theme/colors";
import LicenseTableInfo from "./LicenseTableInfo";
import { useParams } from "react-router-dom";

type PROPS = {
  methods: UseFormReturn<FormType>;
  activeStep: number;
};

const SignUpCompanyStep3 = ({ activeStep, methods }: PROPS) => {
  const { t } = useTranslation("onboarding");

  return (
    <div style={{ display: activeStep === 2 ? "block" : "none" }}>
      <FormInput
        selectProps={methods.register("licenseType")}
        items={licenseTypes(t)}
        label={t("licenseType")}
        isRequired
        type="select"
        actions={[
          {
            icon: (
              <Popover>
                <PopoverTrigger>
                  <Button variant={"link"}>
                    <FiInfo color={COLORS.sikuroBlue} />
                  </Button>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent width={"auto"} p={2}>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>
                      <LicenseTableInfo />
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </Popover>
            ),
            hint: "licenseInfo",
            handler: () => {
              return;
            },
          },
        ]}
      />

      <FormInput
        inputProps={methods.register("email", {
          required: t("errors.email is required"),
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: t("errors.insert a valid email"),
          },
        })}
        label={t("ui.email")}
        isRequired
        error={methods.formState.errors.email?.message ?? false}
        type="email"
      />

      <PasswordFields
        errors={methods.formState.errors}
        register={methods.register}
        watch={methods.watch}
      />

      <OnboardingCheckboxes
        errors={methods.formState.errors}
        register={methods.register}
      />
    </div>
  );
};

export default SignUpCompanyStep3;
