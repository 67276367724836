import SiteRepository, { GetSitesFilter } from '../../repositories/siteRepository';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import UseCase from '../../../core/app/usecase';
import Site from '../../entities/site';

interface Args {
	companyId: string;
	resourceType: string;
	resourceId: string;
	siteIds: string[];
}

class AddResourceToSites implements UseCase<Args, void> {
	private siteRepository: SiteRepository;

	constructor(siteRepository: SiteRepository) {
		this.siteRepository = siteRepository;
	}

	async Call({ companyId, resourceType, resourceId, siteIds }: Args): Promise<void> {
		return await this.siteRepository.addResourceToSites(companyId, resourceType, resourceId, siteIds);
	}
}

export default AddResourceToSites;
