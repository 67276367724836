import { Button, Flex, Select, Text, useSteps } from "@chakra-ui/react";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuditType } from "../../../domain/entities/audit";
import DocumentType from "../../../domain/entities/documentType";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import Requirement from "../../../domain/entities/requirement";
import { SiteChemical } from "../../../domain/entities/siteChemical";
import { SiteMachine } from "../../../domain/entities/siteMachine";
import { SiteTool } from "../../../domain/entities/siteTool";
import { SiteVehicle } from "../../../domain/entities/siteVehicle";
import { SiteWorker } from "../../../domain/entities/siteWorker";
import Supplier from "../../../domain/entities/supplier";
import { SupplierStatus } from "../../../domain/entities/supplierStatus.enum";
import BaseModal from "../../components/Common/alerts/BaseModal";
import { GetSiteResourcesAuditFilters } from "../../hooks/Site/useSiteAuditsViewModel";
import { useAuth } from "../../providers/Auth0JWTProvider";
import { AuditAccordion } from "./AuditAccordion";
import { ResourceType } from "./ResourceSelectableTable";

interface CreateAuditProp {
  onClose: () => void;
  onConfirm: (supplierId: string, auditType: AuditType) => Promise<void>;
  createAuditLoading: boolean;

  companyRequirements: DocumentType[];
  companyRequirementsHasNextPage: boolean;
  companyRequirementsFetchNextPage: () => void;
  companyRequirementsIsLoading: boolean;

  siteRequirements: DocumentType[];
  siteRequirementsHasNextPage: boolean;
  siteRequirementsFetchNextPage: () => void;
  siteRequirementsIsLoading: boolean;

  workersRequirements: DocumentType[];
  workersRequirementsHasNextPage: boolean;
  workersRequirementsFetchNextPage: () => void;
  workersRequirementsIsLoading: boolean;
  machinesRequirements: DocumentType[];
  machinesRequirementsHasNextPage: boolean;
  machinesRequirementsFetchNextPage: () => void;
  machinesRequirementsIsLoading: boolean;
  vehiclesRequirements: DocumentType[];
  vehiclesRequirementsHasNextPage: boolean;
  vehiclesRequirementsFetchNextPage: () => void;
  vehiclesRequirementsIsLoading: boolean;
  toolsRequirements: DocumentType[];
  toolsRequirementsHasNextPage: boolean;
  toolsRequirementsFetchNextPage: () => void;
  toolsRequirementsIsLoading: boolean;
  chemicalsRequirements: DocumentType[];
  chemicalsRequirementsHasNextPage: boolean;
  chemicalsRequirementsFetchNextPage: () => void;
  chemicalsRequirementsIsLoading: boolean;
  siteChemicals: SiteChemical[];
  siteChemicalsHasNextPage: boolean;
  siteChemicalsFetchNextPage: () => void;
  siteChemicalsIsLoading: boolean;
  siteMachines: SiteMachine[];
  siteMachinesFetchNextPage: () => void;
  siteMachinesHasNextPage: boolean;
  siteMachinesIsLoading: boolean;
  siteTools: SiteTool[];
  siteToolsFetchNextPage: () => void;
  siteToolsHasNextPage: boolean;
  siteToolsIsLoading: boolean;
  siteVehicles: SiteVehicle[];
  siteVehiclesFetchNextPage: () => void;
  siteVehiclesHasNextPage: boolean;
  siteVehiclesIsLoading: boolean;
  siteWorkers: SiteWorker[];
  siteWorkersFetchNextPage: () => void;
  siteWorkersHasNextPage: boolean;
  siteWorkersIsLoading: boolean;
  siteRequirementsToExclude: string[];
  companyRequirementsToExclude: string[];
  workersRequirementsToExclude: string[];
  vehiclesRequirementsToExclude: string[];
  machinesRequirementsToExclude: string[];
  toolsRequirementsToExclude: string[];
  chemicalsRequirementsToExclude: string[];
  setSiteRequirementToExclude: (requirementsIds: string[]) => void;
  setCompanyRequirementToExclude: (requirementsIds: string[]) => void;
  setSWorkersRequirementToExclude: (requirementsIds: string[]) => void;
  setVehiclesRequirementToExclude: (requirementsIds: string[]) => void;
  setMachinesRequirementToExclude: (requirementsIds: string[]) => void;
  setToolsRequirementToExclude: (requirementsIds: string[]) => void;
  setChemicalsRequirementToExclude: (requirementsIds: string[]) => void;
  siteWorkersToInclude: string[];
  siteMachinesToInclude: string[];
  siteVehiclesToInclude: string[];
  siteToolsToInclude: string[];
  siteChemicalsToInclude: string[];
  setSiteWorkersToInclude: (resourceIds: string[]) => void;
  setSiteMachinesToInclude: (resourceIds: string[]) => void;
  setSiteVehiclesToInclude: (resourceIds: string[]) => void;
  setSiteToolsToInclude: (resourceIds: string[]) => void;
  setSiteChemicalsToInclude: (resourceIds: string[]) => void;
  selectableSupplier: Supplier[];
  setHookSelectedSupplier: (supplier: Supplier) => void;
  setEnableGetCompanyRequirements: (enable: boolean) => void;
  setEnableGetSiteRequirements: (enable: boolean) => void;
  setEnableGetWorkersRequirements: (enable: boolean) => void;
  setEnableGetVehiclesRequirements: (enable: boolean) => void;
  setEnableGetMachinesRequirements: (enable: boolean) => void;
  setEnableGetToolsRequirements: (enable: boolean) => void;
  setEnableGetChemicalsRequirements: (enable: boolean) => void;
  setEnableGetChemicals: (enable: boolean) => void;
  setEnableGetMachines: (enable: boolean) => void;
  setEnableGetTools: (enable: boolean) => void;
  setEnableGetVehicles: (enable: boolean) => void;
  setEnableGetWorkers: (enable: boolean) => void;
  setFilterSiteWorkers: Dispatch<SetStateAction<GetSiteResourcesAuditFilters>>;
  setFilterSiteChemicals: Dispatch<
    SetStateAction<GetSiteResourcesAuditFilters>
  >;
  setFilterSiteMachines: Dispatch<SetStateAction<GetSiteResourcesAuditFilters>>;
  setFilterSiteVehicles: Dispatch<SetStateAction<GetSiteResourcesAuditFilters>>;
  setFilterTools: Dispatch<SetStateAction<GetSiteResourcesAuditFilters>>;
  filterSiteChemicals: GetSiteResourcesAuditFilters;
  filterSiteMachines: GetSiteResourcesAuditFilters;
  filterSiteTools: GetSiteResourcesAuditFilters;
  filterSiteVehicles: GetSiteResourcesAuditFilters;
  filterSiteWorkers: GetSiteResourcesAuditFilters;
}
const CreateAuditModal = ({
  onClose,
  onConfirm,
  createAuditLoading,

  companyRequirements,
  companyRequirementsHasNextPage,
  companyRequirementsFetchNextPage,
  companyRequirementsIsLoading,

  siteRequirements,
  siteRequirementsHasNextPage,
  siteRequirementsFetchNextPage,
  siteRequirementsIsLoading,

  workersRequirements,
  workersRequirementsHasNextPage,
  workersRequirementsFetchNextPage,
  workersRequirementsIsLoading,
  machinesRequirements,
  machinesRequirementsHasNextPage,
  machinesRequirementsFetchNextPage,
  machinesRequirementsIsLoading,
  vehiclesRequirements,
  vehiclesRequirementsHasNextPage,
  vehiclesRequirementsFetchNextPage,
  vehiclesRequirementsIsLoading,
  toolsRequirements,
  toolsRequirementsHasNextPage,
  toolsRequirementsFetchNextPage,
  toolsRequirementsIsLoading,
  chemicalsRequirements,
  chemicalsRequirementsHasNextPage,
  chemicalsRequirementsFetchNextPage,
  chemicalsRequirementsIsLoading,
  siteMachines,
  siteMachinesFetchNextPage,
  siteMachinesHasNextPage,
  siteMachinesIsLoading,
  siteTools,
  siteToolsFetchNextPage,
  siteToolsHasNextPage,
  siteToolsIsLoading,
  siteVehicles,
  siteVehiclesFetchNextPage,
  siteVehiclesHasNextPage,
  siteVehiclesIsLoading,
  siteWorkers,
  siteWorkersFetchNextPage,
  siteWorkersHasNextPage,
  siteWorkersIsLoading,
  siteChemicals,
  siteChemicalsFetchNextPage,
  siteChemicalsHasNextPage,
  siteChemicalsIsLoading,
  siteRequirementsToExclude,
  companyRequirementsToExclude,
  workersRequirementsToExclude,
  vehiclesRequirementsToExclude,
  machinesRequirementsToExclude,
  toolsRequirementsToExclude,
  chemicalsRequirementsToExclude,
  setSiteRequirementToExclude,
  setCompanyRequirementToExclude,
  setSWorkersRequirementToExclude,
  setVehiclesRequirementToExclude,
  setMachinesRequirementToExclude,
  setToolsRequirementToExclude,
  setChemicalsRequirementToExclude,
  siteWorkersToInclude,
  siteMachinesToInclude,
  siteVehiclesToInclude,
  siteToolsToInclude,
  siteChemicalsToInclude,
  setSiteWorkersToInclude,
  setSiteMachinesToInclude,
  setSiteVehiclesToInclude,
  setSiteToolsToInclude,
  setSiteChemicalsToInclude,
  selectableSupplier,
  setHookSelectedSupplier,
  setEnableGetCompanyRequirements,
  setEnableGetSiteRequirements,
  setEnableGetWorkersRequirements,
  setEnableGetVehiclesRequirements,
  setEnableGetMachinesRequirements,
  setEnableGetToolsRequirements,
  setEnableGetChemicalsRequirements,
  setEnableGetChemicals,
  setEnableGetMachines,
  setEnableGetTools,
  setEnableGetVehicles,
  setEnableGetWorkers,
  setFilterSiteWorkers,
  setFilterSiteChemicals,
  setFilterSiteMachines,
  setFilterSiteVehicles,
  setFilterTools,
  filterSiteChemicals,
  filterSiteMachines,
  filterSiteTools,
  filterSiteVehicles,
  filterSiteWorkers,
}: CreateAuditProp) => {
  const { siteId } = useAuth();

  const { t } = useTranslation("audit");
  const enumToArray = (enumObj) => {
    return Object.keys(enumObj).map((key) => ({ key, value: enumObj[key] }));
  };

  const [selectedAuditType, setSelectedAuditType] =
    useState<AuditType>(undefined);
  const [selectedSupplier, setSelectedSupplier] = useState<Supplier>(undefined);

  const { activeStep, setActiveStep } = useSteps({ index: 0, count: 2 });
  const auditTypeOptions = enumToArray(AuditType);

  const handleSupplierChange = (event) => {
    setHookSelectedSupplier(
      selectableSupplier.find(
        (supplier) => supplier.company.id === event.target.value,
      ),
    );
    setSelectedSupplier(
      selectableSupplier.find(
        (supplier) => supplier.company.id === event.target.value,
      ),
    );
    if (!selectedAuditType) {
      return;
    }
    if (selectedAuditType === AuditType.RESOURCE) {
      setEnableGetWorkersRequirements(true);
      setEnableGetVehiclesRequirements(true);
      setEnableGetMachinesRequirements(true);
      setEnableGetToolsRequirements(true);
      setEnableGetChemicalsRequirements(true);
    } else {
      setEnableGetCompanyRequirements(true);
      setEnableGetSiteRequirements(true);
    }
  };

  useEffect(() => {
    if (activeStep === 1) {
      setEnableGetChemicals(true);
      setEnableGetMachines(true);
      setEnableGetTools(true);
      setEnableGetVehicles(true);
      setEnableGetWorkers(true);
    }
  }, [activeStep]);

  const handleAuditTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const selectedKey = event.target.value as keyof typeof AuditType;
    setSelectedAuditType(AuditType[selectedKey]);
    if (selectedSupplier) {
      if (AuditType[selectedKey] === AuditType.RESOURCE) {
        setEnableGetWorkersRequirements(true);
        setEnableGetVehiclesRequirements(true);
        setEnableGetMachinesRequirements(true);
        setEnableGetToolsRequirements(true);
        setEnableGetChemicalsRequirements(true);
      } else {
        setEnableGetCompanyRequirements(true);
        setEnableGetSiteRequirements(true);
      }
    }
  };

  const handleCreateAudit = () => {
    onConfirm(selectedSupplier?.company?.id, selectedAuditType);
  };

  const handleNext = async () => {
    switch (activeStep) {
      case 0:
        // from step 0 we go to step 1 if selectAll is false otherwise to step 2 where we will select
        // the same variants and specializations for every resource
        if (selectedAuditType === AuditType.ITP) {
          handleCreateAudit();
        } else {
          setActiveStep(1);
          setSiteChemicalsToInclude([]);
          setSiteMachinesToInclude([]);
          setSiteWorkersToInclude([]), setSiteToolsToInclude([]);
          setSiteVehiclesToInclude([]);
        }
        break;
      case 1:
        // in this case we will select variants and specializations ad hoc for every resource
        await handleCreateAudit();
        break;
    }
  };

  const handleCancel = () => {
    switch (activeStep) {
      case 0:
        onClose();
        break;
      case 1:
        setActiveStep(0);
        break;
    }
  };

  return (
    <BaseModal
      title={t("createAudit")}
      onClose={handleCancel}
      onConfirm={handleNext}
      footer={
        <>
          <Button
            colorScheme="blue"
            onClick={handleNext}
            isDisabled={!selectedAuditType || !selectedSupplier}
            isLoading={createAuditLoading}
          >
            {t("confirm", { ns: "common" })}
          </Button>

          <Button colorScheme="red" onClick={handleCancel}>
            {t("cancel", { ns: "common" })}
          </Button>
        </>
      }
    >
      <Flex flexDirection={"column"}>
        {activeStep === 0 && (
          <>
            <Text
              marginBottom={2}
              width={"100%"}
              display="flex"
              color={"black"}
            >
              {t("supplier", { ns: "audit" })}
            </Text>

            <Select
              placeholder={t("selectSupplier", { ns: "audit" })}
              value={selectedSupplier?.company?.id}
              onChange={handleSupplierChange}
              isRequired
            >
              {selectableSupplier &&
                selectableSupplier.map(
                  (option, index) =>
                    option.status === SupplierStatus.ACTIVE && (
                      <option key={index} value={option.company.id}>
                        {option.company.name}
                      </option>
                    ),
                )}
            </Select>

            <Text
              marginTop={5}
              marginBottom={2}
              width={"100%"}
              display="flex"
              color={"black"}
            >
              {t("auditType", { ns: "audit" })}
            </Text>

            <Select
              placeholder={t("selectAuditType", { ns: "audit" })}
              value={AuditType[selectedAuditType]}
              onChange={handleAuditTypeChange}
              isRequired
            >
              {auditTypeOptions.map((option, index) => {
                return (
                  <option key={index} value={option.key}>
                    {t(option.value.toLowerCase())}
                  </option>
                );
              })}
            </Select>
          </>
        )}
        {(() => {
          switch (activeStep) {
            case 0:
              return (
                <>
                  {selectedSupplier && selectedAuditType && (
                    <>
                      <Text
                        marginTop={5}
                        width={"100%"}
                        display="flex"
                        color={"black"}
                      >
                        {t("selectRequirements", { ns: "audit" })}
                      </Text>

                      {selectedAuditType === AuditType.ITP && (
                        <Flex flexDirection={"column"} marginTop={2}>
                          <AuditAccordion
                            documentTypeList={companyRequirements}
                            hasNextPage={companyRequirementsHasNextPage}
                            fetchNextPage={companyRequirementsFetchNextPage}
                            requirementsAreLoading={
                              companyRequirementsIsLoading
                            }
                            excludedDocumentTypes={companyRequirementsToExclude}
                            setExcludedDocumentTypes={
                              setCompanyRequirementToExclude
                            }
                            title={t("companyRequirements", { ns: "audit" })}
                            type="requirement"
                          />
                          <AuditAccordion
                            documentTypeList={siteRequirements}
                            hasNextPage={siteRequirementsHasNextPage}
                            fetchNextPage={siteRequirementsFetchNextPage}
                            requirementsAreLoading={siteRequirementsIsLoading}
                            excludedDocumentTypes={siteRequirementsToExclude}
                            setExcludedDocumentTypes={
                              setSiteRequirementToExclude
                            }
                            title={t("siteRequirements", { ns: "audit" })}
                            type="requirement"
                          />
                        </Flex>
                      )}

                      {selectedAuditType === AuditType.RESOURCE && (
                        <Flex flexDirection={"column"} marginTop={4}>
                          <AuditAccordion
                            documentTypeList={workersRequirements}
                            hasNextPage={workersRequirementsHasNextPage}
                            fetchNextPage={workersRequirementsFetchNextPage}
                            requirementsAreLoading={
                              workersRequirementsIsLoading
                            }
                            excludedDocumentTypes={workersRequirementsToExclude}
                            setExcludedDocumentTypes={
                              setSWorkersRequirementToExclude
                            }
                            title={t("workersRequirements", { ns: "audit" })}
                            type="requirement"
                          />
                          <AuditAccordion
                            documentTypeList={vehiclesRequirements}
                            hasNextPage={vehiclesRequirementsHasNextPage}
                            fetchNextPage={vehiclesRequirementsFetchNextPage}
                            requirementsAreLoading={
                              vehiclesRequirementsIsLoading
                            }
                            excludedDocumentTypes={
                              vehiclesRequirementsToExclude
                            }
                            setExcludedDocumentTypes={
                              setVehiclesRequirementToExclude
                            }
                            title={t("vehiclesRequirements", { ns: "audit" })}
                            type="requirement"
                          />
                          <AuditAccordion
                            documentTypeList={machinesRequirements}
                            hasNextPage={machinesRequirementsHasNextPage}
                            fetchNextPage={machinesRequirementsFetchNextPage}
                            requirementsAreLoading={
                              machinesRequirementsIsLoading
                            }
                            excludedDocumentTypes={
                              machinesRequirementsToExclude
                            }
                            setExcludedDocumentTypes={
                              setMachinesRequirementToExclude
                            }
                            title={t("machinesRequirements", { ns: "audit" })}
                            type="requirement"
                          />
                          <AuditAccordion
                            documentTypeList={toolsRequirements}
                            hasNextPage={toolsRequirementsHasNextPage}
                            fetchNextPage={toolsRequirementsFetchNextPage}
                            requirementsAreLoading={toolsRequirementsIsLoading}
                            excludedDocumentTypes={toolsRequirementsToExclude}
                            setExcludedDocumentTypes={
                              setToolsRequirementToExclude
                            }
                            title={t("toolsRequirements", { ns: "audit" })}
                            type="requirement"
                          />
                          <AuditAccordion
                            documentTypeList={chemicalsRequirements}
                            hasNextPage={chemicalsRequirementsHasNextPage}
                            fetchNextPage={chemicalsRequirementsFetchNextPage}
                            requirementsAreLoading={
                              chemicalsRequirementsIsLoading
                            }
                            excludedDocumentTypes={
                              chemicalsRequirementsToExclude
                            }
                            setExcludedDocumentTypes={
                              setChemicalsRequirementToExclude
                            }
                            title={t("chemicalsRequirements", { ns: "audit" })}
                            type="requirement"
                          />
                        </Flex>
                      )}
                    </>
                  )}
                </>
              );
            case 1:
              return (
                <Flex flexDirection={"column"} marginTop={4}>
                  <Text
                    marginBottom={2}
                    width={"100%"}
                    display="flex"
                    color={"black"}
                  >
                    {t("selectResources", { ns: "audit" })}
                  </Text>
                  <AuditAccordion
                    resourceList={siteWorkers}
                    hasNextPage={siteWorkersHasNextPage}
                    fetchNextPage={siteWorkersFetchNextPage}
                    resourcesAreLoading={siteWorkersIsLoading}
                    includedResource={siteWorkersToInclude}
                    setIncludedResource={setSiteWorkersToInclude}
                    setSiteResourceFilter={setFilterSiteWorkers}
                    siteResourceFilter={filterSiteWorkers}
                    resourceType={ResourceType.WORKER}
                    title={t("workers", { ns: "audit" })}
                    type="resource"
                  />
                  <AuditAccordion
                    resourceList={siteMachines}
                    hasNextPage={siteMachinesHasNextPage}
                    fetchNextPage={siteMachinesFetchNextPage}
                    resourcesAreLoading={siteMachinesIsLoading}
                    includedResource={siteMachinesToInclude}
                    setIncludedResource={setSiteMachinesToInclude}
                    setSiteResourceFilter={setFilterSiteMachines}
                    siteResourceFilter={filterSiteMachines}
                    resourceType={ResourceType.MACHINE}
                    title={t("machines", { ns: "audit" })}
                    type="resource"
                  />
                  <AuditAccordion
                    resourceList={siteVehicles}
                    hasNextPage={siteVehiclesHasNextPage}
                    fetchNextPage={siteVehiclesFetchNextPage}
                    resourcesAreLoading={siteVehiclesIsLoading}
                    includedResource={siteVehiclesToInclude}
                    setIncludedResource={setSiteVehiclesToInclude}
                    setSiteResourceFilter={setFilterSiteVehicles}
                    siteResourceFilter={filterSiteVehicles}
                    resourceType={ResourceType.VEHICLE}
                    title={t("vehicles", { ns: "audit" })}
                    type="resource"
                  />
                  <AuditAccordion
                    resourceList={siteTools}
                    hasNextPage={siteToolsHasNextPage}
                    fetchNextPage={siteToolsFetchNextPage}
                    resourcesAreLoading={siteToolsIsLoading}
                    includedResource={siteToolsToInclude}
                    setIncludedResource={setSiteToolsToInclude}
                    setSiteResourceFilter={setFilterTools}
                    siteResourceFilter={filterSiteTools}
                    resourceType={ResourceType.TOOL}
                    title={t("tools", { ns: "audit" })}
                    type="resource"
                  />
                  <AuditAccordion
                    resourceList={siteChemicals}
                    hasNextPage={siteChemicalsHasNextPage}
                    fetchNextPage={siteChemicalsFetchNextPage}
                    resourcesAreLoading={siteChemicalsIsLoading}
                    includedResource={siteChemicalsToInclude}
                    setIncludedResource={setSiteChemicalsToInclude}
                    setSiteResourceFilter={setFilterSiteChemicals}
                    siteResourceFilter={filterSiteChemicals}
                    resourceType={ResourceType.CHEMICAL}
                    title={t("chemicals", { ns: "audit" })}
                    type="resource"
                  />
                </Flex>
              );
            default:
              return null;
          }
        })()}
      </Flex>
    </BaseModal>
  );
};

export default CreateAuditModal;
