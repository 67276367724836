import UseCase from '../../../core/app/usecase';
import Specialization from '../../entities/specialization';
import SpecializationRepository from '../../repositories/specializationRepository';
import { DocumentTypeResourceType } from '../../../mock/models/document-type';

type ARGS = {
	companyId: string;
	siteId: string;
	type: DocumentTypeResourceType;
	search?: string;
};

class GetSpecializations implements UseCase<ARGS, Specialization[]> {
	private specializationRepository: SpecializationRepository;

	constructor(specializationRepository: SpecializationRepository) {
		this.specializationRepository = specializationRepository;
	}

	async Call(args: ARGS): Promise<Specialization[]> {
		return await this.specializationRepository.getSiteSpecializations(args.companyId, args.siteId, args.type, args.search);
	}
}

export default GetSpecializations;
