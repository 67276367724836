import { useTranslation } from "react-i18next";
import { GetSiteResourcesFilters } from "../../hooks/Site/useSiteResourcesViewModel";
import { Flex, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import TableColumnHeader from "../../components/Common/table/TableColumnHeader";
import { useNavigate, useParams } from "react-router-dom";
import BaseModal from "../../components/Common/alerts/BaseModal";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import { SiteChemical } from "../../../domain/entities/siteChemical";
import { SiteMachine } from "../../../domain/entities/siteMachine";
import { SiteTool } from "../../../domain/entities/siteTool";
import { SiteVehicle } from "../../../domain/entities/siteVehicle";
import { SiteWorker } from "../../../domain/entities/siteWorker";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import ColumnFilterComponent from "../../components/Common/table/ColumnFilterComponent";
import Specialization from "../../../domain/entities/specialization";
import Variant from "../../../domain/entities/variant";
import CellTagList from "../../components/Common/table/CellTagList";
import InfiniteTable from "../../components/Common/table/InfiniteTable";

type SiteSupplierResourcesProps = {
  type: DocumentTypeResourceType;
  resourceColumns: string[];
  resources: (
    | SiteWorker
    | SiteChemical
    | SiteTool
    | SiteMachine
    | SiteVehicle
  )[];
  isWorkingSite: boolean;
  siteResourcesHasNextPage: boolean;
  siteResourcesFetchNextPage: () => void;
  setShowResources: (show: boolean) => void;
  isFetching: boolean;
  setSiteSupplierResourcesSort: (sort: SortMeta) => void;
  siteSupplierResourcesSort: SortMeta;
  filterSiteSupplierResources: Record<string, string | string[]>;
  updateFilterSiteSupplierResources: (
    column: string,
    value: string | string[]
  ) => void;
  variants: Variant[];
  specializations: Specialization[];
};

const SiteSupplierResourcesViewModal = ({
  type,
  resources,
  resourceColumns,
  isWorkingSite,
  siteResourcesHasNextPage,
  siteResourcesFetchNextPage,
  isFetching,
  setShowResources,
  setSiteSupplierResourcesSort,
  siteSupplierResourcesSort,
  filterSiteSupplierResources,
  updateFilterSiteSupplierResources,
  variants,
  specializations,
}: SiteSupplierResourcesProps) => {
  const { t } = useTranslation("siteResources");
  const navigate = useNavigate();
  const { siteId } = useParams();

  const columns = [
    ...resourceColumns.map((col) => ({
      field: col,
      type: "text",
      label:
        ["machine", "vehicle"].includes(type) && col === "name"
          ? t("brand")
          : t(col),
    })),
    { field: "variantIds", type: "tags", label: t("variant") },
    { field: "specializationIds", type: "tags", label: t("specialization") },
  ] as {
    field: keyof GetSiteResourcesFilters;
    type: "text" | "tags";
    label: string;
  }[];

  if (!isWorkingSite) {
    // Remove the 'company' column from its previous position.
    const companyColumnIndex = columns.findIndex(
      (col) => col.field === "company"
    );
    if (companyColumnIndex !== -1) {
      columns.splice(companyColumnIndex, 1);
    }

    // Insert the 'company' column into the desired new position, specifically before the 'variantIds' column."
    const variantColumnIndex = columns.findIndex(
      (col) => col.field === "variantIds"
    );
    columns.splice(variantColumnIndex, 0, {
      field: "company",
      type: "text",
      label: t("company"),
    });
  }

  return (
    <BaseModal
      title={t(`resources.${type + "s"}`, { ns: "supplier" })}
      size="6xl"
      onClose={() => setShowResources(false)}
      onCancel={false}
    >
      {" "}
      <Flex
        alignItems="center"
        justifyContent="start"
        textAlign="center"
        flex={1}
        h="100%"
        w="100%"
        bg="white"
        flexDirection="column"
      >
        <InfiniteTable
          autosize
          infiniteScroll={{
            dataLength: resources.length,
            hasNextPage: siteResourcesHasNextPage,
            fetchNextPage: siteResourcesFetchNextPage,
          }}
          isLoading={isFetching}
          showEmptyText={resources.length === 0}
          emptyText={t("noActiveSiteResource", { ns: "siteResources" })}
        >
          <Thead>
            <Tr>
              {columns.map((column) => (
                <Th key={column.field}>
                  <TableColumnHeader
                    text={column.label}
                    filter={{
                      component: (
                        <ColumnFilterComponent
                          type={column.type}
                          value={filterSiteSupplierResources[column.field]}
                          updateFilter={(value) =>
                            updateFilterSiteSupplierResources(
                              column.field,
                              value as string | string[]
                            )
                          }
                          tags={
                            column.field === "variantIds"
                              ? variants
                              : specializations
                          }
                          namespace="siteResources"
                        />
                      ),
                      isActive: !!(Array.isArray(
                        filterSiteSupplierResources[column.field]
                      )
                        ? filterSiteSupplierResources[column.field][0]
                        : filterSiteSupplierResources[column.field]),
                    }}
                    sort={
                      column.field !== "variantIds" &&
                      column.field !== "specializationIds" && {
                        handler: (direction) =>
                          setSiteSupplierResourcesSort({
                            field: column.field,
                            direction,
                          }),
                        direction:
                          siteSupplierResourcesSort &&
                          siteSupplierResourcesSort.field === column.field
                            ? siteSupplierResourcesSort.direction
                            : null,
                      }
                    }
                  />
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {Array.isArray(resources) &&
              resources.map((siteResource) => (
                <Tr
                  key={siteResource.id}
                  onClick={() => {
                    navigate(
                      !isWorkingSite
                        ? `/sites/${siteId}/resources/${type}s/${siteResource.id}`
                        : `/workingsites/${siteId}/resources/${type}s/${siteResource.id}`
                    );
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  {Array.isArray(columns) &&
                    columns.map((column) => (
                      <Td key={column.field}>
                        {column.type === "tags" ? (
                          <CellTagList
                            tags={
                              column.field === "variantIds"
                                ? siteResource.variants
                                : siteResource.specializations
                            }
                            selectedTags={
                              siteResource[
                                column.field === "variantIds"
                                  ? "variants"
                                  : "specializations"
                              ]
                            }
                          />
                        ) : (
                          siteResource[column.field]
                        )}
                      </Td>
                    ))}
                </Tr>
              ))}
          </Tbody>
        </InfiniteTable>
      </Flex>
    </BaseModal>
  );
};

export default SiteSupplierResourcesViewModal;
