import { AddIcon, WarningTwoIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaEye, FaEyeSlash, FaRegSave } from "react-icons/fa";
import { MdCancel, MdEdit } from "react-icons/md";
import { UserNotification } from "../../../domain/entities/interfaces/userNotification";
import User from "../../../domain/entities/user";
import i18n from "../../../i18n";
import {
  languageCodes,
  passwordIsConfirmed,
  passwordIsValid,
} from "../../../infrastructure/utilities/validator";
import { COLORS } from "../../assets/theme/colors";
import ActionBar from "../../components/Common/ActionBar";
import ActionBarItem from "../../components/Common/ActionBarItem";
import DataBox from "../../components/Common/DataBox";
import FormSelectField from "../../components/Common/forms/FormSelectField";
import FormTextField from "../../components/Common/forms/FormTextField";
import ActionButton from "../../components/Common/table/ActionButton";
import DeleteButton from "../../components/Common/table/DeleteButton";
import TextField from "../../components/Common/TextField";
import useUserSettingsViewModel, {
  UpdateAlertParams,
  UpdatePasswordArgs,
} from "../../hooks/Users/useUserSettingsViewModel";
import ContentLayout from "../../layout/ContentLayout";
import { Alert } from "../Common/Alert";
import { ConfirmAlert } from "../Common/ConfirmAlert";
import LoadingView from "../Common/LoadingView";
import UserNotificationsView from "../Settings/Users/UserNotificationsView";

interface Password extends UpdatePasswordArgs {
  show: boolean;
  errors?: Array<string>;
}

const UserSettingsView = () => {
  const { t } = useTranslation("settings");
  const {
    currentUser,
    isLoading,
    updateUser,
    updateUserImage,
    passwordUpdateError,
    oldPasswordError,
    updatePassword,
    updatePasswordIsLoading,
    updateAlertNotification,
    errorMessage,
    setErrorMessage,
    updatePasswordData,
    userNotifications,
    updateUserNotifications,
    notificationsIsFetching,
    isLoadingUpdateUserNotifications,
  } = useUserSettingsViewModel(() => setPasswordUpdateSuccess(true));

  const [init, setInit] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showConfirmUpdateDetails, setShowConfirmUpdateDetails] =
    useState<boolean>(false);

  const [passwordUpdateSuccess, setPasswordUpdateSuccess] =
    useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState<Password>({
    value: "",
    confirm: "",
    show: false,
  });
  const [password, setPassword] = useState<Password>({
    value: "",
    confirm: "",
    show: false,
  });
  const [saveUpdatedNotifications, setSaveUpdatedNotifications] =
    useState<boolean>(false);
  const [uploadedImageFile, setUploadedImageFile] = useState<File>();

  const validate = (password: string, confirm: string): Array<string> => {
    const errors: Array<string> = [];
    errors.push(...(passwordIsValid(password, "errors.password.") ?? []));
    errors.push(
      ...(passwordIsConfirmed(password, confirm, "errors.password.") ?? [])
    );

    return errors.length > 0
      ? errors.map((e) => t(e, { ns: "onboarding" }))
      : undefined;
  };

  const validatePassword = (v: string) => {
    const errors = validate(v, password.confirm);
    setPassword({ ...password, value: v, errors });
  };

  const validateConfirmPassword = (v: string) => {
    const errors = validate(password.value, v);
    setPassword({ ...password, confirm: v, errors });
  };

  const methods = useForm<User>({ mode: "all", defaultValues: currentUser });
  const requiredRule = { required: t("requiredField", { ns: "common" }) };
  const {
    handleSubmit,
    reset,
    formState: { isValid },
    watch,
  } = methods;

  const confirmUpdateDetails = () => {
    const newEmail = watch("email", "").toLowerCase().trim();
    const oldEmail = currentUser.email.trim().toLocaleLowerCase().trim();

    if (newEmail == oldEmail) {
      handleSubmit(onSubmit)();
    } else {
      setShowConfirmUpdateDetails(true);
    }
  };

  const onSubmit: SubmitHandler<User> = async (data) => {
    setIsEditing(false);
    setShowConfirmUpdateDetails(false);
    if (uploadedImageFile) {
      await updateUserImage(uploadedImageFile);
    }
    await updateUser({
      ...currentUser,
      name: data.name,
      email: data.email,
      language: data.language,
    });
    i18n.changeLanguage(data.language);
  };

  useEffect(() => {
    if (!currentUser || init) {
      return;
    }

    reset({ ...currentUser, name: currentUser.name, email: currentUser.email });
    setInit(true);
  }, [currentUser, init, isEditing, reset]);

  const notifications = [
    {
      notification: "expiring_documents",
      label: t("alertGeneralDocuments"),
      isActive: userNotifications?.find(
        (el) => el.notification === "expiring_documents"
      )?.isActive,
    },
    {
      notification: "expiring_owned_sites_evaluations",
      label: t("alertEvaluationsMade"),
      isActive: userNotifications?.find(
        (el) => el.notification === "expiring_owned_sites_evaluations"
      )?.isActive,
    },
    {
      notification: "expiring_working_sites_evaluations",
      label: t("alertEvaluationsReceived"),
      isActive: userNotifications?.find(
        (el) => el.notification === "expiring_working_sites_evaluations"
      )?.isActive,
    },
  ];

  const [inputsData, setInputsData] = useState(
    notifications.map(() => [{ id: 1, value: 1 } ])
  );

  useEffect(() => {
    if (userNotifications?.length > 0) {
      const updatedInputsData = notifications.map((notification) => {
        const userNotification = userNotifications.find(
          (el) => el.notification === notification.notification
        );
        return userNotification
          ? userNotification.daysBeforeAlert.map((day, index) => ({
              id: index,
              value: Number(day),
            }))
          : [{ id: 1, value: 1 }];
      });
      setInputsData(updatedInputsData);
    } else {
      setInputsData(notifications.map(() => [{ id: 1, value: 1 }]));
    }
  }, [userNotifications]);

  const handleSubmitNotifications = (data: UserNotification[]) => {
    setSaveUpdatedNotifications(false);
    return updateUserNotifications(data);
  };

const addInput = (notificationIndex) => {
  setInputsData((prevInputsData) => {
    const updatedInputsData = [...prevInputsData];
    updatedInputsData[notificationIndex] = [
      ...updatedInputsData[notificationIndex],
      { id: Date.now(), value: 1 },
    ];
    return updatedInputsData;
  });
};

const handleChange = (notificationIndex, inputIndex, valueString) => {
  const value = Number(valueString);
  setInputsData((prevInputsData) => {
    const updatedInputsData = [...prevInputsData];
    updatedInputsData[notificationIndex][inputIndex].value = value;
    return updatedInputsData;
  });
};

const removeInput = (notificationIndex, inputId) => {
  setInputsData((prevInputsData) => {
    const updatedInputsData = [...prevInputsData];
    updatedInputsData[notificationIndex] = updatedInputsData[
      notificationIndex
    ].filter((input) => input.id !== inputId);
    return updatedInputsData;
  });
};


  const hasNotifications = notifications.some((n) =>
    userNotifications?.some((el) => el.notification === n.notification)
  );

  const getAlertParams = (notifications, userNotifications) => {
    const alertParams: UpdateAlertParams = {
      expiringDocumentsDaysBeforeAlert: [],
      expiringOwnedSitesDaysBeforeAlert: [],
      expiringWorkingSitesDaysBeforeAlert: [],
    };

    notifications.forEach((notification, notificationIndex) => {
      const userNotificationGroup = userNotifications[notificationIndex];

      if (userNotificationGroup) {
        switch (notification.notification) {
          case "expiring_documents":
            alertParams.expiringDocumentsDaysBeforeAlert = removeDuplicates(
              userNotificationGroup.map((item) => item.value)
            );
            break;
          case "expiring_owned_sites_evaluations":
            alertParams.expiringOwnedSitesDaysBeforeAlert = removeDuplicates(
              userNotificationGroup.map((item) => item.value)
            );
            break;
          case "expiring_working_sites_evaluations":
            alertParams.expiringWorkingSitesDaysBeforeAlert = removeDuplicates(
              userNotificationGroup.map((item) => item.value)
            );
            break;
          default:
            break;
        }
      }
    });

    return alertParams;
  };

  const removeDuplicates = (array) => {
    return array.filter((value, index, self) => self.indexOf(value) === index);
  };

  if (isLoading) {
    return <LoadingView />;
  }

  const handleUpdateNotification = () => {
    const alertParams = getAlertParams(notifications, inputsData);
    updateAlertNotification(alertParams);
  };

  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            icon={isEditing ? MdCancel : MdEdit}
            onClick={() => {
              setIsEditing(!isEditing);
              if (isEditing) {
                setUploadedImageFile(undefined);
              }
            }}
            description={t(isEditing ? "cancel" : "edit", { ns: "common" })}
          />
          {isEditing && isValid && (
            <ActionBarItem
              description={t("confirm", { ns: "common" })}
              icon={FaRegSave}
              onClick={confirmUpdateDetails}
            />
          )}
        </ActionBar>
      }
    >
      {currentUser && (
        <Flex px={3} py={5} gap="20px">
          <Box
            width={"100%"}
            paddingBottom={6}
            border="1px solid"
            borderColor="gray.300"
            borderRadius="10px"
          >
            <FormProvider {...methods}>
              <DataBox
                title={t("details", { ns: "common" })}
                isEditing={isEditing}
                isLoading={isLoading}
                image={{
                  url: uploadedImageFile
                    ? URL.createObjectURL(uploadedImageFile)
                    : currentUser.photo,
                  onUpdate: (f) => setUploadedImageFile(f),
                }}
                fields={[
                  <FormTextField
                    key="name"
                    name="name"
                    label={t("name", { ns: "userSettings" })}
                    rules={requiredRule}
                  />,
                  <FormTextField
                    key="email"
                    name="email"
                    label={t("email", { ns: "userSettings" })}
                    rules={requiredRule}
                  />,
                  <FormSelectField
                    key="language"
                    name="language"
                    label={t("language", { ns: "userSettings" })}
                    displayValue={t(`languages.${currentUser.language}`, {
                      ns: "userSettings",
                    })}
                    options={languageCodes.map((s) => {
                      return {
                        id: s.id,
                        name: t(`languages.${s.id}`, { ns: "userSettings" }),
                      };
                    })}
                  />,
                ]}
              />
            </FormProvider>
          </Box>
        </Flex>
      )}

      <Flex px={3} py={5} gap="20px" flexDirection={"column"}>
        <Flex
          width={"100%"}
          border="1px solid"
          borderColor="gray.300"
          borderRadius="10px"
        >
          <Accordion
            allowToggle
            width={"100%"}
            borderTop={"transparent"}
            borderBottom={"transparent"}
          >
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    <Text textStyle="h2" marginLeft={4} marginTop={1}>
                      {t("updatePassword", { ns: "account" })}
                    </Text>
                  </Box>

                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel marginLeft={2} marginRight={2}>
                <Flex flexDirection={"column"}>
                  <Text marginTop={4}>
                    {t("insertYourCurrentPassword", { ns: "account" })}
                  </Text>
                  <TextField
                    marginTop={6}
                    isRequired
                    id="oldPassword"
                    isProtected={!oldPassword.show}
                    label={t("oldPassword", { ns: "account" })}
                    defaultValue={oldPassword.value}
                    onChange={(_, v) =>
                      setOldPassword({ ...oldPassword, value: v })
                    }
                    actions={[
                      {
                        handler: () =>
                          setOldPassword({
                            ...oldPassword,
                            show: !oldPassword.show,
                          }),
                        icon: oldPassword.show ? <FaEyeSlash /> : <FaEye />,
                        hint: t(
                          oldPassword.show ? "hidePassword" : "showPassword"
                        ),
                      },
                    ]}
                  />
                  {oldPasswordError && (
                    <Box
                      backgroundColor="orange.100"
                      borderRadius={5}
                      marginTop={4}
                      padding={4}
                    >
                      <HStack spacing={4}>
                        <WarningTwoIcon color="red.500" />
                        <Text>
                          {t("oldPasswordNoMatch", { ns: "account" })}
                        </Text>
                      </HStack>
                    </Box>
                  )}
                </Flex>

                <Flex
                  flexDirection={"column"}
                  display={
                    passwordUpdateError?.message === "error.wrong-credentials"
                      ? "block"
                      : "none"
                  }
                >
                  <Box
                    backgroundColor="orange.100"
                    borderRadius={5}
                    marginTop={4}
                    padding={4}
                  >
                    <HStack spacing={4}>
                      <WarningTwoIcon color="red.500" />
                      <Text>
                        {t("error.wrongCurrentPassword", { ns: "account" })}
                      </Text>
                    </HStack>
                  </Box>
                </Flex>

                <Flex flexDirection={"column"}>
                  <Text marginTop={6}>
                    {t("insertYourNewPassword", { ns: "account" })}
                  </Text>
                  <TextField
                    marginTop={4}
                    isRequired
                    id="password"
                    isProtected={!password.show}
                    label={t("newPassword", { ns: "account" })}
                    defaultValue={password.value}
                    onChange={(_, v) => validatePassword(v)}
                    actions={[
                      {
                        handler: () =>
                          setPassword({ ...password, show: !password.show }),
                        icon: password.show ? <FaEyeSlash /> : <FaEye />,
                        hint: t(
                          password.show ? "hidePassword" : "showPassword"
                        ),
                      },
                    ]}
                  />

                  <TextField
                    isRequired
                    marginTop={4}
                    id="password-confirm"
                    isProtected={!password.show}
                    label={t("confirmNewPassword", { ns: "account" })}
                    defaultValue={password.confirm}
                    onChange={(_, v) => validateConfirmPassword(v)}
                    errorMessages={password.errors}
                  />

                  <Box
                    backgroundColor="orange.100"
                    borderRadius={5}
                    marginTop={4}
                    display={
                      passwordUpdateError &&
                      passwordUpdateError?.message != "error.wrong-credentials"
                        ? "block"
                        : "none"
                    }
                    padding={4}
                  >
                    <HStack spacing={4}>
                      <WarningTwoIcon color="red.500" />
                      <Text>{t(passwordUpdateError?.message)}</Text>
                    </HStack>
                  </Box>
                </Flex>

                <Flex justifyContent={"right"}>
                  <Flex marginTop={4} flexDirection={"column"}>
                    <Button
                      borderRadius={6}
                      isLoading={updatePasswordIsLoading}
                      onClick={() =>
                        updatePassword({
                          oldPassword: oldPassword.value,
                          value: password.value,
                          confirm: password.confirm,
                        })
                      }
                      colorScheme={"blue"}
                      isDisabled={
                        !oldPassword.value ||
                        !password.value ||
                        password.errors?.length > 0
                      }
                    >
                      {t("savePassword", { ns: "account" })}
                    </Button>
                  </Flex>
                </Flex>

                <Flex
                  flexDirection={"column"}
                  display={
                    updatePasswordData && passwordUpdateSuccess
                      ? "block"
                      : "none"
                  }
                >
                  <Text marginBottom={2} fontWeight={"bold"}>
                    {t("passwordUpdateSuccess", { ns: "account" })}
                  </Text>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Flex>

        <Flex>
          <UserNotificationsView
            notifications={userNotifications}
            saveUpdatedNotifications={saveUpdatedNotifications}
            setSaveUpdatedNotifications={(hasChanges) =>
              setSaveUpdatedNotifications(hasChanges)
            }
            onSubmit={handleSubmitNotifications}
            notificationsIsFetching={notificationsIsFetching}
          />
        </Flex>
        <Flex
          width={"100%"}
          border="1px solid"
          borderColor="gray.300"
          borderRadius="10px"
        >
          <Accordion
            defaultIndex={0}
            allowToggle
            width={"100%"}
            borderTop={"transparent"}
            borderBottom={"transparent"}
          >
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    <Text textStyle="h2" marginLeft={4} marginTop={1}>
                      {t("configNotifications")}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel marginLeft={2} marginRight={2}>
                <Flex
                  flexDirection={"row"}
                  justifyContent="space-between"
                  flexWrap={"wrap"}
                >
                  {hasNotifications ? (
                    notifications.map((n, notificationIndex) =>
                      userNotifications?.some(
                        (el) => el.notification === n.notification
                      ) ? (
                        <Flex flexDirection="column" key={n.notification}>
                          <Flex key={n.notification} flexDirection="column">
                            <Text marginRight={4}>{n.label}</Text>
                            {inputsData[notificationIndex]?.map(
                              (input, inputIndex) => (
                                <Flex
                                  key={input.id}
                                  alignItems="center"
                                  marginTop={4}
                                >
                                  <NumberInput
                                    isDisabled={!n.isActive}
                                    value={input.value}
                                    min={1}
                                    max={365}
                                    width={100}
                                    onChange={(valueString) =>
                                      handleChange(
                                        notificationIndex,
                                        inputIndex,
                                        valueString
                                      )
                                    }
                                  >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                      <NumberIncrementStepper />
                                      <NumberDecrementStepper />
                                    </NumberInputStepper>
                                  </NumberInput>
                                  <Box ml={2}>{t("baysBefore")}</Box>
                                  {inputIndex !== 0 && (
                                    <Box marginLeft={2}>
                                      <DeleteButton
                                        isDisabled={!n.isActive}
                                        tooltipLabel={t("remove")}
                                        onClick={() =>
                                          removeInput(
                                            notificationIndex,
                                            input.id
                                          )
                                        }
                                      />
                                    </Box>
                                  )}
                                   {inputIndex === inputsData[notificationIndex].length - 1 &&
                                      inputsData[notificationIndex].length < 3 && (
                                    <Tooltip label={t("add")}>
                                      <ActionButton
                                        isDisabled={!n.isActive}
                                        aria-label="add"
                                        marginLeft={2}
                                        onClick={() =>
                                          addInput(notificationIndex)
                                        }
                                        icon={<AddIcon />}
                                      />
                                    </Tooltip>
                                  )}
                                </Flex>
                              )
                            )}
                          </Flex>
                        </Flex>
                      ) : null
                    )
                  ) : (
                    <Text>{t("noNotificationsToConfigure")}</Text>
                  )}
                </Flex>

                {hasNotifications && (
                  <Flex justifyContent={"flex-end"}>
                    <Button
                      mt={4}
                      borderRadius={6}
                      isLoading={isLoadingUpdateUserNotifications}
                      onClick={() => handleUpdateNotification()}
                      colorScheme={"blue"}
                    >
                      {t("save", { ns: "common" })}
                    </Button>
                  </Flex>
                )}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Flex>
      </Flex>

      {showConfirmUpdateDetails && (
        <ConfirmAlert
          onCancel={() => setShowConfirmUpdateDetails(false)}
          onConfirm={handleSubmit(onSubmit)}
          title={t("confirmChangeUserEmailTitle")}
          message={t("confirmChangeUserEmailMessage")}
          variant={"question"}
        />
      )}

      {errorMessage && (
        <Alert
          onClose={() => setErrorMessage(undefined)}
          title={t("warning", { ns: "common" })}
          message={t(errorMessage, { ns: "errors" })}
          variant={"warning"}
        />
      )}
    </ContentLayout>
  );
};

export default UserSettingsView;
