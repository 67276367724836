import { Named } from './interfaces/named';
import { Identifiable } from './interfaces/identifiable';
import Permission from './permission';

export enum SiteState {
	ACTIVE = 'active',
	SUSPENDED = 'suspended',
	CLOSED = 'closed',
}

type Site = Identifiable &
	Named & {
		protocol: string;
		startDate: Date;
		photo?: string;
		lifespan: number;
		description: string;
		contract: string;
		address: string;
		city: string;
		zipCode: string;
		state: SiteState;
		permissions?: Permission[];
	};

export type ResourceSites = {
	results: Site[];
	count: number;
};

export type DocumentSites = {
	results: Site[];
	count: number;
};

export const makeEmptySite = (): Site => {
	return {
		id: '',
		name: '',
		photo: '',
		protocol: '',
		startDate: new Date(),
		lifespan: 0,
		description: '',
		contract: '',
		address: '',
		city: '',
		zipCode: '',
		state: SiteState.ACTIVE,
	};
};

export default Site;
