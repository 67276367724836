import { useTranslation } from "react-i18next";
import MainLayout from "../../../layout/MainLayout";
import MainNavBar from "../../../components/Common/MainNavBar";
import SettingsTopBar from "../../../components/Views/Settings/SettingsTopBar";
import { useEffect } from "react";
import { useApp } from "../../../../app";
import { useAuth } from "../../../providers/Auth0JWTProvider";

const UserDetailsMainView = () => {
  const { t } = useTranslation("settings");
  const { updateContext } = useApp();
  const { updateSiteContext } = useAuth();

  useEffect(() => {
    updateContext({ topBarTitle: undefined, site: undefined });
    updateSiteContext(undefined);
  }, []);

  return (
    <MainLayout
      header={<SettingsTopBar title={t("user")} backPath="/settings/users" />}
      nav={<MainNavBar />}
    />
  );
};

export default UserDetailsMainView;
