import Site from "../../../domain/entities/site";
import Typology from "../../../domain/entities/typology";
import RequirementsGroup from "../../../domain/entities/requirementsGroup";
import {
  makeCreateSite,
  makeGetSites,
} from "../../../core/app/factory/sites/usecases";
import { makeGetRequirementsGroups } from "../../../core/app/factory/requirementsGroup/useCases";
import { makeGetTypologies } from "../../../core/app/factory/typologies/usecases";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { makeGetRequirementVariants } from "../../../core/app/factory/variants/usecases";
import Variant from "../../../domain/entities/variant";
import Permission from "../../../domain/entities/permission";

class SitesListViewModel {
  private getUC = makeGetSites();
  private createUC = makeCreateSite();
  private getRequirementsGroupsUC = makeGetRequirementsGroups();
  private getTypologiesUC = makeGetTypologies();

  constructor(private companyId: string) {}

  // Public.
  async get(
    filter?: GetSitesFilter,
    sort?: SortMeta,
    pageParam?: number,
    withPermissions?: boolean
  ): Promise<Site[]> {
    return await this.getUC.Call({
      companyId: this.companyId,
      filter,
      sort,
      pageParam,
      withPermissions,
    });
  }

  async create(
    site: Site,
    requirementsGroup?: RequirementsGroup,
    typology?: Typology,
    companyVariant?: Variant,
    siteVariant?: Variant
  ): Promise<Site> {
    return await this.createUC.Call({
      companyId: this.companyId,
      site,
      requirementsGroup,
      typology,
      companyVariant,
      siteVariant,
    });
  }

  async getRequirementsGroups(): Promise<RequirementsGroup[]> {
    return await this.getRequirementsGroupsUC.Call(this.companyId);
  }

  async getTypologies(search?: string): Promise<Typology[]> {
    return await this.getTypologiesUC.Call({
      companyId: this.companyId,
      type: DocumentTypeResourceType.SITE,
      search,
    });
  }

  async getCompanyVariants(
    companyId: string,
    requirementGroupId: string
  ): Promise<any[]> {
    return await makeGetRequirementVariants().Call({
      companyId,
      requirementGroupId,
      type: DocumentTypeResourceType.COMPANY,
    });
  }

  async getSiteVariants(companyId: string, requirementGroupId): Promise<any[]> {
    return await makeGetRequirementVariants().Call({
      companyId,
      requirementGroupId,
      type: DocumentTypeResourceType.SITE,
    });
  }
}

export default SitesListViewModel;
