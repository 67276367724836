import { useMemo, useState } from 'react';
import { useAuth } from '../../providers/Auth0JWTProvider';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import Variant from '../../../domain/entities/variant';
import Specialization from '../../../domain/entities/specialization';
import { PaginatedResults, SortMeta } from '../../../domain/entities/interfaces/paginatedResults';
import { GetSiteWorkersFilters } from '../../../domain/repositories/siteWorkerRepository';
import { GetSiteVehiclesFilters } from '../../../domain/repositories/siteVehicleRepository';
import { GetSiteMachinesFilters } from '../../../domain/repositories/siteMachineRepository';
import { GetSiteToolsFilters } from '../../../domain/repositories/siteToolRepository';
import { GetSiteChemicalsFilters } from '../../../domain/repositories/siteChemicalRepository';
import { SupplierResource } from '../../../domain/entities/supplierResource';
import { useParams } from 'react-router-dom';
import { DocumentTypeResourceType } from '../../../mock/models/document-type';

export type GetSupplierResourcesFilters = Omit<
	GetSiteWorkersFilters | GetSiteMachinesFilters | GetSiteVehiclesFilters | GetSiteToolsFilters | GetSiteChemicalsFilters,
	'badgeAvailable'
>;

export interface SupplierResourcesViewModel<Resource> {
	getSupplierResources: (
		companyId: string,
		siteId: string,
		page: number,
		filter?: GetSupplierResourcesFilters,
		sort?: SortMeta,
	) => Promise<PaginatedResults<SupplierResource<Resource>>>;
	getVariants: (companyId: string, siteId: string) => Promise<Variant[]>;
	getSpecializations: (companyId: string, siteId: string) => Promise<Specialization[]>;
}

export type useSupplierResourcesViewModelType<Resource> = {
	supplierResources: SupplierResource<Resource>[];
	filterSupplierResources: Record<string, string | string[]>;
	supplierResourcesSort: SortMeta;
	updateFilterSupplierResources: (column: string, value: string | string[]) => void;
	setSupplierResourcesSort: (sort: SortMeta) => void;
	isFetching: boolean;
	supplierResourcesFetchNextPage: () => void;
	supplierResourcesHasNextPage: boolean;
	setSearch?: (query: string) => void;
};

function useSupplierResourcesViewModel<Resource>(
	viewModel: SupplierResourcesViewModel<Resource>,
	type: DocumentTypeResourceType,
): useSupplierResourcesViewModelType<Resource> {
	const { companyId } = useAuth();
	const { supplierId } = useParams();

	const [filterSupplierResources, setFilterSupplierResources] = useState<GetSupplierResourcesFilters>({});
	const [supplierResourcesSort, setSupplierResourcesSort] = useState<SortMeta>(null);
	const [search, setSearch] = useState<string>();

	const updateFilterSupplierResources = (column: string, value: string) => {
		setFilterSupplierResources({
			...filterSupplierResources,
			[column]: value,
		});
	};

	const supplierResourcesQuery = useInfiniteQuery(
		['supplier-resources', type, companyId, filterSupplierResources, search, supplierResourcesSort],
		async ({ pageParam = 1 }) => {
			const filters = search ? { ...filterSupplierResources, search } : filterSupplierResources;
			const { results } = await viewModel.getSupplierResources(companyId, supplierId, pageParam, filters, supplierResourcesSort);
			return results;
		},
		{
			getNextPageParam: (lastPage, pages) => {
				if (lastPage?.length === 25) {
					return pages.length + 1;
				}
			},
		},
	);

	const supplierResources = useMemo(() => supplierResourcesQuery.data?.pages?.flat(), [supplierResourcesQuery.data]);

	return {
		supplierResources,
		supplierResourcesHasNextPage: supplierResourcesQuery.hasNextPage,
		supplierResourcesFetchNextPage: supplierResourcesQuery.fetchNextPage,
		isFetching: supplierResourcesQuery.isLoading,
		filterSupplierResources,
		updateFilterSupplierResources,
		supplierResourcesSort,
		setSupplierResourcesSort,
		setSearch,
	};
}

export default useSupplierResourcesViewModel;
