import {
  makeAddTypologyToVehicle,
  makeCreatePropagableVehicleDocument,
  makeCreateVehicleDocument,
  makeEvaluateVehicleDocument,
  makeGetVehicle,
  makeGetVehicleDocuments,
  makeRemoveTypologyFromVehicle,
} from "../../../core/app/factory/vehicles/usecases";
import { ResourceDocumentsViewModel } from "../resources/ResourceDocumentsViewModel";
import { makeGetTypologies } from "../../../core/app/factory/typologies/usecases";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { GetDocumentsFilter } from "../../../domain/repositories/documentRepository";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import Document from "../../../domain/entities/document";
import DocumentTypeWithPublic from "../../../domain/entities/documentTypeWithPublic";
import { ResourceDocumentEvaluationState } from "../../../domain/entities/resourceDocumentEvaluationState.enum";

class VehicleDocumentsViewModel extends ResourceDocumentsViewModel {
  private getResourceUseCase = makeGetVehicle();
  private getTypologiesUseCase = makeGetTypologies();

  constructor(private vehicleId: string) {
    super(vehicleId, DocumentTypeCategory.VEHICLE);
  }

  //#region Extends class: ResourceDocumentsViewModel

  async getDocuments(
    companyId: string,
    filter?: GetDocumentsFilter,
    sort?: SortMeta,
    pageParam?: number,
  ): Promise<Document[]> {
    return await makeGetVehicleDocuments().Call({
      companyId,
      resourceId: this.vehicleId,
      sort,
      filter,
      pageParam,
    });
  }

  async createDocument(companyId: string, documents: string[]): Promise<void> {
    return await makeCreateVehicleDocument().Call({
      companyId,
      resourceId: this.vehicleId,
      documents,
    });
  }

  async createPropagableDocuments(
    companyId: string,
    documents: DocumentTypeWithPublic[],
  ): Promise<void> {
    return await makeCreatePropagableVehicleDocument().Call({
      companyId,
      resourceId: this.vehicleId,
      documents,
    });
  }

  async evaluateDocument(
    companyId: string,
    documentId: string,
    evaluationState?: ResourceDocumentEvaluationState,
    expirationDate?: Date,
    noEvaluationExpiration?: boolean,
    siteIds?: string[],
    selectAll?: boolean,
  ): Promise<void> {
    return await makeEvaluateVehicleDocument().Call({
      companyId,
      resourceId: this.vehicleId,
      documentId,
      evaluationState,
      expirationDate,
      noEvaluationExpiration,
      siteIds,
      selectAll,
    });
  }

  async addTypologyToResource(
    companyId: string,
    resourceId: string,
    typologyId: string,
  ) {
    return await makeAddTypologyToVehicle().Call({
      companyId,
      resourceId,
      typologyId,
    });
  }

  async removeTypologyFromResource(
    companyId: string,
    resourceId: string,
    typologyId: string,
  ) {
    return await makeRemoveTypologyFromVehicle().Call({
      companyId,
      resourceId,
      typologyId,
    });
  }

  //#endregion

  async getResource(companyId: string, resourceId: string) {
    return await this.getResourceUseCase.Call({
      companyId,
      vehicleId: resourceId,
    });
  }

  async getTypologies(companyId: string) {
    return await this.getTypologiesUseCase.Call({
      companyId,
      type: DocumentTypeResourceType.VEHICLE,
    });
  }
}

export { VehicleDocumentsViewModel };
