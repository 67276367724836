import { useParams } from "react-router-dom";
import { Permission } from "../../components/Permissions/Permissions";
import { SiteWorkerParams } from "../../../domain/entities/siteWorker";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import useSiteWorkersViewModel from "../../hooks/Site/useSiteWorkersViewModel";
import SiteResourcesListView from "../../components/Views/Sites/SiteResourcesListView";

const SiteWorkersView = () => {
  const { siteId } = useParams();
  const resourceColumns = ["lastName", "firstName", "fiscalCode", "jobTitle"];
  return (
    <SiteResourcesListView<SiteWorkerParams>
      type={DocumentTypeResourceType.WORKER}
      useViewModel={useSiteWorkersViewModel(
        siteId,
        resourceColumns,
        DocumentTypeResourceType.WORKER,
      )}
      resourceColumns={resourceColumns}
      addToSitePermissions={[
        Permission.Sites_Manage,
        Permission.Sites_AddWorker,
      ]}
      removeFromSitePermissions={[
        Permission.Sites_Manage,
        Permission.Sites_RemoveWorker,
      ]}
    />
  );
};

export default SiteWorkersView;
