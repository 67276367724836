import { makeGetSiteDocuments } from "../../../core/app/factory/sites/usecases";
import {
  makeDeleteSubcontractor,
  makeGetActiveSubcontractors,
  makeGetAvailableSupplier,
  makeGetInvitedSupplier,
  makeInviteSubcontractors,
} from "../../../core/app/factory/suppliers/useCases";
import { makeGetTags } from "../../../core/app/factory/tags/usecases";
import {
  makeRenewInvitation,
  makeUserEmailAvailable,
} from "../../../core/app/factory/users/usecases";
import { makeGetSiteVariants } from "../../../core/app/factory/variants/usecases";
import Company from "../../../domain/entities/company";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { InvitedSupplier } from "../../../domain/entities/invitedSupplier";
import Supplier from "../../../domain/entities/supplier";
import Tag from "../../../domain/entities/tag";
import { GetDocumentsFilter } from "../../../domain/repositories/documentRepository";
import {
  AvailableSuppliersFilter,
  InvitedSuppliersFilter,
  UnlistedCompany,
} from "../../../domain/repositories/supplierRepository";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";

class WorkingSitesSubcontractorsViewModel {
  private getActiveContractorsUseCase = makeGetActiveSubcontractors();
  private getAvailableContractorsUseCase = makeGetAvailableSupplier();
  private getAvailableDocumentUseCase = makeGetSiteDocuments();
  private deleteSubcontractorUseCase = makeDeleteSubcontractor();
  private inviteSubcontractorsUseCase = makeInviteSubcontractors();
  private userEmailAvailableUC = makeUserEmailAvailable();
  private getInvitedContractorsUC = makeGetInvitedSupplier();
  private renewInvitationUc = makeRenewInvitation();
  private getTagsUC = makeGetTags();

  async listActiveSubcontractors(
    companyId: string,
    siteId: string,
    filter?: AvailableSuppliersFilter,
    sort?: SortMeta,
    pageParam?: number,
  ): Promise<Supplier[]> {
    return await this.getActiveContractorsUseCase.Call({
      companyId,
      siteId,
      filter,
      sort,
      pageParam,
    });
  }

  async listAvailableSubcontractors(
    companyId: string,
    siteId: string,
    filter?: AvailableSuppliersFilter,
    sort?: SortMeta,
    pageParam?: number,
  ): Promise<Company[]> {
    return await this.getAvailableContractorsUseCase.Call({
      companyId,
      siteId,
      filter,
      sort,
      pageParam,
    });
  }

  async listAvailableDocumentsForSubcontractors(
    companyId: string,
    siteId: string,
    filter?: GetDocumentsFilter,
    sort?: SortMeta,
    pageParam?: number,
  ) {
    return await this.getAvailableDocumentUseCase.Call({
      companyId,
      siteId,
      filter,
      sort,
      pageParam,
    });
  }

  async inviteSubcontractors(
    companyId: string,
    siteId: string,
    subcontractorIds: string[],
    companyVariant: string,
    siteVariant: string,
    documentIds?: string[],
    unlistedCompany?: UnlistedCompany,
  ): Promise<void> {
    await this.inviteSubcontractorsUseCase.Call({
      companyId,
      siteId,
      subcontractorIds,
      companyVariant,
      siteVariant,
      documentIds,
      unlistedCompany,
    });
  }

  async renewInvitation(
    companyId: string,
    invitationToken: string,
    siteId: string,
  ): Promise<void> {
    return await this.renewInvitationUc.Call({
      companyId,
      invitationToken,
      siteId,
    });
  }

  async deleteSubcontractor(
    clientId: string,
    siteId: string,
    subcontractorId: string,
  ): Promise<void> {
    return await this.deleteSubcontractorUseCase.Call({
      clientId: clientId,
      siteId: siteId,
      subcontractorId: subcontractorId,
    });
  }
  async getInvitedSuppliers(
    companyId: string,
    siteId: string,
    filter?: InvitedSuppliersFilter,
    sort?: SortMeta,
    pageParam?: number,
  ): Promise<InvitedSupplier[]> {
    return await this.getInvitedContractorsUC.Call({
      companyId,
      siteId,
      filter,
      sort,
      pageParam,
    });
  }
  async getTags(companyId: string): Promise<Tag[]> {
    return await this.getTagsUC.Call({ companyId });
  }

  async userEmailAvailable(email: string): Promise<boolean> {
    return await this.userEmailAvailableUC.Call({ email });
  }

  async getCompanyVariants(companyId: string, siteId: string): Promise<any[]> {
    return await makeGetSiteVariants().Call({
      companyId,
      siteId,
      type: DocumentTypeResourceType.COMPANY,
    });
  }

  async getSiteVariants(companyId: string, siteId: string): Promise<any[]> {
    return await makeGetSiteVariants().Call({
      companyId,
      siteId,
      type: DocumentTypeResourceType.SITE,
    });
  }
}

export { WorkingSitesSubcontractorsViewModel };
