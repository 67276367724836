import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  Link,
  VStack,
} from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { MouseEvent, useState } from "react";
import PdfPreviewModal from "../../components/Common/PDF/PdfPreviewModal";
import { COLORS } from "../../assets/theme/colors";

type Props = {
  errors: any;
  register?: any;
};
const OnboardingCheckboxes = ({ errors, register }: Props) => {
  const { t } = useTranslation("onboarding");
  const [isPolicyPrivacyModalOpen, setIsPolicyPrivacyModalOpen] =
    useState<boolean>(false);
  const [privacyPolicyOpened, setPrivacyPolicyOpened] =
    useState<boolean>(false);
  const checkboxStyle = {
    "& .chakra-checkbox__label": {
      fontSize: "14px",
    },
    "& .chakra-checkbox__label .css-6x44c9:disabled, .css-6x44c9[disabled], .css-6x44c9[aria-disabled=true], .css-6x44c9[data-disabled]":
      {
        opacity: 1,
      },
  };
  const handleOpenModal = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setPrivacyPolicyOpened(true);
    setIsPolicyPrivacyModalOpen(true);
  };
  return (
    <VStack width="100%" spacing={3} mt={5}>
      <FormControl>
        <Checkbox
          {...register("privacyPolicy", {
            validate: (value) => value === true,
          })}
          isInvalid={!!errors.privacyPolicy}
          sx={checkboxStyle}
          disabled={!privacyPolicyOpened}
        >
          <span style={{ color: "red" }}>* </span>
          <Trans
            i18nKey="ui.privacyPolicy"
            t={t}
            components={{
              1: <Link onClick={handleOpenModal} color={COLORS.sikuroBlue} />,
            }}
          />
        </Checkbox>
        {errors.privacyPolicy && (
          <FormErrorMessage>{t(errors.privacyPolicy.message)}</FormErrorMessage>
        )}
      </FormControl>

      <FormControl>
        <Checkbox {...register("newsletter")} sx={checkboxStyle}>
          {t("ui.newsletter")}
        </Checkbox>
      </FormControl>

      {isPolicyPrivacyModalOpen && (
        <PdfPreviewModal
          onClose={() => setIsPolicyPrivacyModalOpen(false)}
          isOpen={isPolicyPrivacyModalOpen}
          url="/documents/PolicyPrivacy.pdf"
        />
      )}
    </VStack>
  );
};

export default OnboardingCheckboxes;
