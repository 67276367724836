import GetRequirements from '../../../../domain/interactors/requirement/getRequirements';
import UpdateRequirement from '../../../../domain/interactors/requirement/updateRequirement';
import { makeDocumentTypesRepository } from '../documentTypes/repositories';
import GetDocumentTypes from '../../../../domain/interactors/documentTypes/getDocumentTypes';
import GetGroupRequirementsByResource from '../../../../domain/interactors/requirement/getGroupRequirementsByResource';
import { makeRequirementRepository } from './repositories';
import AddVariantToRequirement from '../../../../domain/interactors/requirement/addVariantToRequirement';
import RemoveVariantFromRequirement from '../../../../domain/interactors/requirement/removeVariantFromRequirement';
import AddSpecializationToRequirement from '../../../../domain/interactors/requirement/addSpecializationToRequirement';
import RemoveSpecializationFromRequirement from '../../../../domain/interactors/requirement/removeSpecializationFromRequirement';
import GetSiteRequirementsByResource from '../../../../domain/interactors/requirement/getSiteRequirementsByResource';
import UpdateRequirementsOrder from '../../../../domain/interactors/requirement/updateRequirementsOrder';
import UpdateSiteRequirementsOrder from '../../../../domain/interactors/requirement/updateSiteRequirementsOrder';
import AddVariantToSiteRequirement from '../../../../domain/interactors/requirement/addVariantToSiteRequirement';
import RemoveVariantFromSiteRequirement from '../../../../domain/interactors/requirement/removeVariantFromSiteRequirement';
import AddSpecializationToSiteRequirement from '../../../../domain/interactors/requirement/addSpecializationToSiteRequirement';
import RemoveSpecializationFromSiteRequirement from '../../../../domain/interactors/requirement/removeSpecializationFromSiteRequirement';
import UpdateSiteRequirement from '../../../../domain/interactors/requirement/updateSiteRequirement';
import DeleteSiteRequirement from '../../../../domain/interactors/requirement/deleteSiteRequirement';
import UpdateVariantToSiteRequirement from '../../../../domain/interactors/requirement/updateVariantToRequirement';
import { makeTagRepository } from '../tags/repositories';
import UpdateSpecializationToSiteRequirement from '../../../../domain/interactors/requirement/updateSpecializationToSiteRequirement';
import CreateVariant from '../../../../domain/interactors/requirement/createVariant';
import CreateSpecialization from '../../../../domain/interactors/requirement/createSpecialization';
import AddTemplateToSiteRequirement from '../../../../domain/interactors/requirement/addTemplateToSiteRequirement';
import DeleteTemplateFromSiteRequirement from '../../../../domain/interactors/requirement/deleteTemplateFromSiteRequirement';
import GetRequirementsByVariant from '../../../../domain/interactors/requirement/getRequirementsByVariant';
import UpdateRequirementsByVariantOrder from '../../../../domain/interactors/requirement/updateRequirementsByVariantOrder';
import GetRequirementsGroupByVariant from '../../../../domain/interactors/requirement/getRequirementsGroupByVariant';
import UpdateRequirementsGroupByVariantOrder from '../../../../domain/interactors/requirement/updateRequirementsGroupByVariantOrder';

export const makeGetRequirements = () => {
	return new GetRequirements(makeRequirementRepository());
};

export const makeGetRequirementsByVariant = () => {
	return new GetRequirementsByVariant(makeRequirementRepository());
};

export const makeGetRequirementsGroupByVariant = () => {
	return new GetRequirementsGroupByVariant(makeRequirementRepository());
};

export const makeGetGroupRequirementsByResource = () => {
	return new GetGroupRequirementsByResource(makeRequirementRepository());
};

export const makeGetSiteRequirementsByResource = () => {
	return new GetSiteRequirementsByResource(makeRequirementRepository());
};

export const makeGetDocumentTypes = () => {
	return new GetDocumentTypes(makeDocumentTypesRepository());
};

export const makeUpdateRequirement = () => {
	return new UpdateRequirement(makeRequirementRepository());
};

export const makeUpdateSiteRequirement = () => {
	return new UpdateSiteRequirement(makeRequirementRepository());
};

export const makeUpdateRequirementsOrder = () => {
	return new UpdateRequirementsOrder(makeRequirementRepository());
};

export const makeUpdateRequirementsByVariantOrder = () => {
	return new UpdateRequirementsByVariantOrder(makeRequirementRepository());
};

export const makeUpdateRequirementsGroupByVariantOrder = () => {
	return new UpdateRequirementsGroupByVariantOrder(makeRequirementRepository());
};

export const makeUpdateSiteRequirementsOrder = () => {
	return new UpdateSiteRequirementsOrder(makeRequirementRepository());
};

export const makeCreateRequirementVariant = () => {
	return new CreateVariant(makeRequirementRepository());
};

export const makeCreateRequirementSpecialization = () => {
	return new CreateSpecialization(makeRequirementRepository());
};

export const makeAddVariantToRequirement = () => {
	return new AddVariantToRequirement(makeRequirementRepository());
};

export const makeRemoveVariantFromRequirement = () => {
	return new RemoveVariantFromRequirement(makeRequirementRepository());
};
export const makeUpdateVariantFromRequirement = () => {
	return new UpdateVariantToSiteRequirement(makeRequirementRepository());
};
export const makeUpdateSpecializationFromRequirement = () => {
	return new UpdateSpecializationToSiteRequirement(makeRequirementRepository());
};
export const makeAddSpecializationToRequirement = () => {
	return new AddSpecializationToRequirement(makeRequirementRepository());
};

export const makeRemoveSpecializationFromRequirement = () => {
	return new RemoveSpecializationFromRequirement(makeRequirementRepository());
};

export const makeAddVariantToSiteRequirement = () => {
	return new AddVariantToSiteRequirement(makeRequirementRepository());
};

export const makeRemoveVariantFromSiteRequirement = () => {
	return new RemoveVariantFromSiteRequirement(makeRequirementRepository());
};

export const makeAddSpecializationToSiteRequirement = () => {
	return new AddSpecializationToSiteRequirement(makeRequirementRepository());
};

export const makeRemoveSpecializationFromSiteRequirement = () => {
	return new RemoveSpecializationFromSiteRequirement(makeRequirementRepository());
};

export const makeDeleteSiteRequirement = () => {
	return new DeleteSiteRequirement(makeRequirementRepository());
};
export const makeAddTemplateToSiteRequirement = () => {
	return new AddTemplateToSiteRequirement(makeRequirementRepository());
};
export const makeDeleteTemplateFromSiteRequirement = () => {
	return new DeleteTemplateFromSiteRequirement(makeRequirementRepository());
};
