import Tool from "../../../domain/entities/tool";
import {
  makeAddTypologyToTool,
  makeCreateTool,
  makeDeleteTool,
  makeGetTools,
  makeRestoreTool,
  makeUploadTools,
} from "../../../core/app/factory/tools/usecases";
import { ResourceListViewModel } from "../../hooks/Resources/useResourceListViewModel";
import { makeGetTypologies } from "../../../core/app/factory/typologies/usecases";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import Typology from "../../../domain/entities/typology";
import {
  PaginatedResults,
  SortMeta,
} from "../../../domain/entities/interfaces/paginatedResults";
import ImportInfo from "../../../domain/entities/importInfo";
import FileEntity from "../../../domain/entities/file";
import { GetResourcesFilter } from "../../../domain/repositories/workerRepository";
import {
  GetSitesFilter,
  LinkResourcesToSiteWarning,
} from "../../../domain/repositories/siteRepository";
import Site from "../../../domain/entities/site";
import {
  makeAddResourcesToSites,
  makeGetLinkableSites,
} from "../../../core/app/factory/sites/usecases";
import { ResourceType } from "../../screens/Site/ResourceSelectableTable";

class ToolsListViewModel implements ResourceListViewModel<Tool> {
  private getToolsUseCase = makeGetTools();
  private restoreToolUseCase = makeRestoreTool();
  private deleteToolUseCase = makeDeleteTool();
  private crateToolUseCase = makeCreateTool();
  private getTypologiesUseCase = makeGetTypologies();
  private linkTypologyUseCase = makeAddTypologyToTool();
  private uploadTools = makeUploadTools();
  private getLinkableSitesUseCase = makeGetLinkableSites();
  private addResourcesToSitesUseCase = makeAddResourcesToSites();

  async get(
    companyId: string,
    filter?: GetResourcesFilter,
    archived?: boolean,
    sort?: SortMeta,
    pageParam?: number,
  ): Promise<PaginatedResults<Tool>> {
    return await this.getToolsUseCase.Call({
      companyId,
      archived,
      filter,
      sort,
      pageParam,
    });
  }

  async delete(companyId: string, resourceId: string): Promise<void> {
    await this.deleteToolUseCase.Call({ companyId, toolId: resourceId });
  }

  async restore(companyId: string, resourceId: string): Promise<void> {
    await this.restoreToolUseCase.Call({ companyId, toolId: resourceId });
  }

  async create(companyId: string, resource: Tool, photo: File): Promise<Tool> {
    const newTool = await this.crateToolUseCase.Call({
      companyId,
      tool: resource,
      photo,
    });

    return newTool;
  }
  async uploadResources(
    companyId: string,
    file: FileEntity,
  ): Promise<ImportInfo> {
    return await this.uploadTools.Call({ companyId, file });
  }

  async getTypologies(companyId: string): Promise<Typology[]> {
    return await this.getTypologiesUseCase.Call({
      companyId,
      type: DocumentTypeResourceType.TOOL,
    });
  }

  async getResourceLinkableSites(
    companyId: string,
    type: string,
    filters?: GetSitesFilter,
    sort?: SortMeta,
    pageParam?: number,
  ): Promise<Site[]> {
    return await this.getLinkableSitesUseCase.Call({
      companyId,
      resourceType: type,
      filters,
      sort,
      pageParam,
    });
  }
  async addResourcesToSites(
    companyId: string,
    resourceIds: string[],
    siteIds: string[],
    selectAllSites?: boolean,
    selectAllResources?: boolean,
  ): Promise<LinkResourcesToSiteWarning> {
    return await this.addResourcesToSitesUseCase.Call({
      companyId,
      resourceType: ResourceType.TOOL,
      resourceIds,
      siteIds,
      selectAllSites,
      selectAllResources,
    });
  }
}

export { ToolsListViewModel };
