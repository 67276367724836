export interface ExportItem {
	title: string;
	description?: string;
	hasParams: boolean;
	topic: string;
	params: [{ type: string }];
}

export type ExportSection = {
	title: string;
	description: string;
};

export const exportSections: ExportSection[] = [
	{ title: 'mySites', description: 'mySitesSectionDescription' },
	{ title: 'myWorkingSites', description: 'myWorkingSitesSectionDescription' },
	{ title: 'myCompany', description: 'myCompanySectionDescription' },
	{ title: 'resources', description: 'resourcesSectionDescription' },
	// "suppliers",
	{ title: 'users', description: 'usersSectionDescription' },
];

export const exportItems: Record<string, ExportItem[]> = {
	mySites: [
		{
			title: 'mySites',
			description: 'mySitesItems.mySitesDescription',
			hasParams: false,
			topic: 'owned-sites',
			params: [null],
		},
		{
			title: 'mySitesUsers',
			description: 'mySitesItems.mySitesUsersDescription',
			hasParams: true,
			topic: 'users',
			params: [{ type: 'siteId' }],
		},
		{
			title: 'workersList',
			description: 'mySitesItems.workersListDescription',
			hasParams: true,
			topic: 'workers',
			params: [{ type: 'siteId' }],
		},
		{
			title: 'workersDocuments',
			description: 'mySitesItems.workersDocumentsDescription',
			hasParams: true,
			topic: 'workers-requirements',
			params: [{ type: 'siteId' }],
		},
		{
			title: 'vehiclesList',
			description: 'mySitesItems.vehiclesListDescription',
			hasParams: true,
			topic: 'vehicles',
			params: [{ type: 'siteId' }],
		},
		{
			title: 'vehiclesDocuments',
			description: 'mySitesItems.vehiclesDocumentsDescription',
			hasParams: true,
			topic: 'vehicles-requirements',
			params: [{ type: 'siteId' }],
		},
		{
			title: 'machinesList',
			description: 'mySitesItems.machinesListDescription',
			hasParams: true,
			topic: 'machines',
			params: [{ type: 'siteId' }],
		},
		{
			title: 'machinesDocuments',
			description: 'mySitesItems.machinesDocumentsDescription',
			hasParams: true,
			topic: 'machines-requirements',
			params: [{ type: 'siteId' }],
		},
		{
			title: 'toolsList',
			description: 'mySitesItems.toolsListDescription',
			hasParams: true,
			topic: 'tools',
			params: [{ type: 'siteId' }],
		},
		{
			title: 'toolsDocuments',
			description: 'mySitesItems.toolsDocumentsDescription',
			hasParams: true,
			topic: 'tools-requirements',
			params: [{ type: 'siteId' }],
		},
		{
			title: 'chemicalsList',
			description: 'mySitesItems.chemicalsListDescription',
			hasParams: true,
			topic: 'chemicals',
			params: [{ type: 'siteId' }],
		},
		{
			title: 'chemicalsDocuments',
			description: 'mySitesItems.chemicalsDocumentsDescription',
			hasParams: true,
			topic: 'chemicals-requirements',
			params: [{ type: 'siteId' }],
		},
		{
			title: 'suppliersList',
			description: 'mySitesItems.suppliersListDescription',
			hasParams: true,
			topic: 'suppliers',
			params: [{ type: 'siteId' }],
		},
		// {
		//   title: "REPORT DI COMMESSA",
		//   hasParams: true,
		//   topic: '',
		//   params: [{ type: "siteId" }],
		// },
		{
			title: 'suppliersExpirations',
			description: 'mySitesItems.suppliersExpirationsDescription',
			hasParams: true,
			topic: 'suppliers-requirements',
			params: [{ type: 'siteId' }],
		},
		{
			title: 'stampsList',
			description: 'mySitesItems.stampsListDescription',
			hasParams: true,
			topic: 'badges-records',
			params: [{ type: 'badgesParameters' }],
		},
		{
			title: 'badgesList',
			description: 'mySitesItems.badgesListDescription',
			hasParams: true,
			topic: 'badges',
			params: [{ type: 'siteId' }],
		},
		// {
		//   title: "TIMBRATURE",
		//   hasParams: true,
		//   topic: '',
		//   params: [{ type: "siteId" }],
		// },
	],
	myWorkingSites: [
		{
			title: 'workingSites',
			description: 'workingSitesItems.workingSitesDescription',
			hasParams: false,
			topic: 'working-sites',
			params: [null],
		},
		{
			title: 'workersList',
			description: 'workingSitesItems.workersListDescription',
			hasParams: true,
			topic: 'ws-workers',
			params: [{ type: 'workingSiteId' }],
		},
		{
			title: 'workersDocuments',
			description: 'workingSitesItems.workersDocumentsDescription',
			hasParams: true,
			topic: 'ws-workers-requirements',
			params: [{ type: 'workingSiteId' }],
		},
		{
			title: 'vehiclesList',
			description: 'workingSitesItems.vehiclesListDescription',
			hasParams: true,
			topic: 'ws-vehicles',
			params: [{ type: 'workingSiteId' }],
		},
		{
			title: 'vehiclesDocuments',
			description: 'workingSitesItems.vehiclesDocumentsDescription',
			hasParams: true,
			topic: 'ws-vehicles-requirements',
			params: [{ type: 'workingSiteId' }],
		},
		{
			title: 'machinesList',
			description: 'workingSitesItems.machinesListDescription',
			hasParams: true,
			topic: 'ws-machines',
			params: [{ type: 'workingSiteId' }],
		},
		{
			title: 'machinesDocuments',
			description: 'workingSitesItems.machinesDocumentsDescription',
			hasParams: true,
			topic: 'ws-machines-requirements',
			params: [{ type: 'workingSiteId' }],
		},
		{
			title: 'toolsList',
			description: 'workingSitesItems.toolsListDescription',
			hasParams: true,
			topic: 'ws-tools',
			params: [{ type: 'workingSiteId' }],
		},
		{
			title: 'toolsDocuments',
			description: 'workingSitesItems.toolsDocumentsDescription',
			hasParams: true,
			topic: 'ws-tools-requirements',
			params: [{ type: 'workingSiteId' }],
		},
		{
			title: 'chemicalsList',
			description: 'workingSitesItems.chemicalsListDescription',
			hasParams: true,
			topic: 'ws-chemicals',
			params: [{ type: 'workingSiteId' }],
		},
		{
			title: 'chemicalsDocuments',
			description: 'workingSitesItems.chemicalsDocumentsDescription',
			hasParams: true,
			topic: 'ws-chemicals-requirements',
			params: [{ type: 'workingSiteId' }],
		},
		{
			title: 'sitesCompanyDocuments',
			description: 'workingSitesItems.sitesCompanyDocumentsDescription',
			hasParams: true,
			topic: 'requirements',
			params: [{ type: 'workingSiteId' }],
		},
		{
			title: 'subcontractorsList',
			description: 'workingSitesItems.subcontractorsListDescription',
			hasParams: true,
			topic: 'subcontractors',
			params: [{ type: 'workingSiteId' }],
		},
	],
	myCompany: [
		{
			title: 'generalDocumentsExpirations',
			description: 'generalDocumentsExpirationsDescription',
			hasParams: false,
			topic: 'documents',
			params: [null],
		},
	],
	resources: [
		{
			title: 'workersList',
			description: 'companyItems.workersListDescription',
			hasParams: false,
			topic: 'workers',
			params: [null],
		},
		{
			title: 'workersExpirations',
			description: 'companyItems.workersExpirationsDescription',
			hasParams: false,
			topic: 'workers-documents',
			params: [null],
		},
		{
			title: 'vehiclesList',
			description: 'companyItems.vehiclesListDescription',
			hasParams: false,
			topic: 'vehicles',
			params: [null],
		},
		{
			title: 'vehiclesExpirations',
			description: 'companyItems.vehiclesExpirationsDescription',
			hasParams: false,
			topic: 'vehicles-documents',
			params: [null],
		},
		{
			title: 'machinesList',
			description: 'companyItems.machinesListDescription',
			hasParams: false,
			topic: 'machines',
			params: [null],
		},
		{
			title: 'machinesExpirations',
			description: 'companyItems.machinesExpirationsDescription',
			hasParams: false,
			topic: 'machines-documents',
			params: [null],
		},
		{
			title: 'toolsList',
			description: 'companyItems.toolsListDescription',
			hasParams: false,
			topic: 'tools',
			params: [null],
		},
		{
			title: 'toolsExpirations',
			description: 'companyItems.toolsExpirationsDescription',
			hasParams: false,
			topic: 'tools-documents',
			params: [null],
		},
		{
			title: 'chemicalsList',
			description: 'companyItems.chemicalsListDescription',
			hasParams: false,
			topic: 'chemicals',
			params: [null],
		},
		{
			title: 'chemicalsExpirations',
			description: 'companyItems.chemicalsExpirationsDescription',
			hasParams: false,
			topic: 'chemicals-documents',
			params: [null],
		},
	],
	// suppliers: [
	//   {
	//     title: "LISTA FORNITORI",
	//     hasParams: false,
	//     topic: '',
	//     params: [null],
	//   },
	// ],
	users: [
		{
			title: 'usersList',
			description: 'usersListDescription',
			hasParams: false,
			topic: 'users',
			params: [null],
		},
	],
};
