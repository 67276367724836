import { useParams } from "react-router-dom";
import { Permission } from "../../components/Permissions/Permissions";
import { SiteVehicleParams } from "../../../domain/entities/siteVehicle";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import SiteResourcesListView from "../../components/Views/Sites/SiteResourcesListView";
import useWorkingSiteVehiclesViewModel from "../../hooks/WorkingSite/useWorkingSiteVehiclesViewModel";

const SiteVehiclesView = () => {
  const { siteId } = useParams();
  const resourceColumns = ["name", "plate", "model"];
  return (
    <SiteResourcesListView<SiteVehicleParams>
      type={DocumentTypeResourceType.VEHICLE}
      useViewModel={useWorkingSiteVehiclesViewModel(
        siteId,
        resourceColumns,
        DocumentTypeResourceType.VEHICLE,
      )}
      resourceColumns={resourceColumns}
      addToSitePermissions={[Permission.Worksite_AttachVehicle]}
      removeFromSitePermissions={[Permission.Worksite_DetachVehicle]}
    />
  );
};

export default SiteVehiclesView;
