import React, { useState } from "react";
import { Tag as ChakraTag, Text } from "@chakra-ui/react";
import ReactSelect, {
  components,
  OptionProps,
  InputProps,
  ControlProps,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import Tag from "../../../../domain/entities/tag";
import { darken } from "polished";
import { CreatableProps } from "react-select/dist/declarations/src/Creatable";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";
import { useTranslation } from "react-i18next";

export type BaseTagSelectProps<T extends boolean> = {
  tags: Tag[];
  value: Tag[] | Tag;
  onChange: (selected: Tag[] | Tag) => void;
  noSelectionLabel?: string;
  createTag?: (tagName: string) => void;
  label?: string;
  fullWidth?: boolean;
  isMulti?: boolean;
  key?: number;
} & (
  | (CreatableProps<Tag, T, undefined> & {
      createTag: (tagName: string) => void;
    })
  | StateManagerProps<Tag, T, undefined>
);

const BaseTagSelect = ({
  tags,
  value,
  onChange,
  noSelectionLabel,
  label,
  fullWidth,
  isMulti,
  key,
  ...selectProps
}: BaseTagSelectProps<typeof value extends Tag[] ? true : false>) => {
  const { createTag, isLoading } = selectProps;
  const { t } = useTranslation();
  const Select = createTag ? CreatableSelect : ReactSelect;

  return (
    <Select
      key={key}
      placeholder={
        noSelectionLabel ? noSelectionLabel : t("select", { ns: "common" })
      }
      menuPosition={"fixed"}
      {...selectProps}
      value={value}
      defaultValue={value}
      onChange={(options) => {
        if (options && !isLoading) {
          onChange(options as unknown as Tag[] | Tag);
        }
      }}
      isMulti={isMulti}
      options={tags}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      isOptionDisabled={() => isLoading}
      onCreateOption={createTag}
      isClearable
      getNewOptionData={(inputValue) => ({
        name: inputValue,
        color: "#cccccc",
        id: "new",
      })}
      name={label}
      styles={{
        ...selectProps.styles,
        ...(fullWidth
          ? {
              container: (base) => ({
                ...base,
                ...(selectProps.styles?.container ?? {}),
                width: "100%",
              }),
            }
          : {}),
      }}
      components={{
        Control,
        Input,
        Option: InputOption,
        ...selectProps.components,
      }}
    />
  );
};

const Control = ({
  children,
  ...props
}: ControlProps<Tag, true, undefined> & { label: string }) => (
  <components.Control {...props}>
    {props.selectProps.name && (
      <Text
        sx={{
          position: "absolute",
          top: "-10px",
          left: "8px",
          padding: "0 10px",
          backgroundColor: "white",
          color: "gray.500",
        }}
        mb={-2}
        fontSize="10px"
      >
        {props.selectProps.name}
      </Text>
    )}
    {children}
  </components.Control>
);

const Input = (props: InputProps<Tag, true>) => (
  <components.Input
    {...props}
    disabled={props.disabled || props.selectProps.isLoading}
  />
);

const InputOption = ({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}: OptionProps<Tag>) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };
  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={{
        ...props,
        onClick: (e) => {
          if (
            isSelected &&
            rest.selectProps.required &&
            Array.isArray(rest.selectProps.value) &&
            rest.selectProps.value.length === 1
          ) {
            return;
          }
          props.onClick?.(e);
        },
      }}
    >
      <ChakraTag
        size="md"
        color={darken(
          0.6,
          isSelected ? "#eeeeee" : rest.data.color ?? "#eeeeee"
        )}
        bg={isSelected ? "#eeeeee" : rest.data.color}
      >
        {children}
      </ChakraTag>
    </components.Option>
  );
};

export default BaseTagSelect;
