import { FC, useState } from "react";
import { Alert } from "../Common/Alert";
import { useTranslation } from "react-i18next";
import OnboardingFooter from "./OnboardingFooter";
import OnboardingHeader from "./OnboardingHeader";
import TextField from "../../components/Common/TextField";
import useOnboardingViewModel from "../../hooks/Onboarding/useOnboardingViewModel";
import {
  CardHeader,
  CardBody,
  CardFooter,
  VStack,
  Button,
  Text,
} from "@chakra-ui/react";

interface SignInProps {
  onCancel: () => void;
}

const OnboardingRequestPasswordReset: FC<SignInProps> = ({ onCancel }) => {
  const { t } = useTranslation("onboarding");
  const [email, setEmail] = useState<string>("");
  const {
    setSuccessRequestPasswordReset,
    error,
    setError,
    requestPasswordReset,
    successRequestPasswordReset,
    isLoading,
    isUserEmailAvailable,
  } = useOnboardingViewModel();

  const [emailChecking, setEmailChecking] = useState<boolean>(false);
  const [emailNotRegisterd, setEmailNotRegistered] = useState<boolean>(false);
  const [emailNotValid, setEmailNotValid] = useState<boolean>(false);

  const checkEmailAndReset = async (email: string) => {
    if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      setEmailNotValid(true);
      return;
    }

    setEmailNotValid(false);
    setEmailChecking(true);
    const isAvailable = await isUserEmailAvailable(email);
    if (!isAvailable) {
      setEmailNotRegistered(true);
    } else {
      setEmailNotRegistered(false);
      await requestPasswordReset(email);
    }

    setEmailChecking(false);
  };

  return (
    <>
      <CardHeader>
        <OnboardingHeader
          title={t("ui.requestPasswordReset")}
          onBack={() => {
            onCancel();
            setEmail("");
          }}
        />
      </CardHeader>

      <CardBody>
        <TextField
          id="email"
          defaultValue={email}
          label={t("ui.email")}
          onChange={(_, value) => setEmail(value)}
        />
        {emailNotValid && (
          <Text marginTop={2} marginLeft={2} color={"red"} fontSize={14}>
            {t("errors.invalidEmail", { ns: "supplier" })}
          </Text>
        )}
      </CardBody>

      <CardFooter>
        <VStack width="100%" spacing={8}>
          <Button
            isLoading={emailChecking}
            isDisabled={!email}
            variant="ghost"
            color="white"
            _hover={{ bg: "#32A2DB", color: "#FFF" }}
            textColor="#32A2DB"
            onClick={() => checkEmailAndReset(email)}
          >
            {t("confirm", { ns: "common" })}
          </Button>
          <OnboardingFooter />
        </VStack>
      </CardFooter>

      {emailNotRegisterd && (
        <Alert
          title={t("apiErrors.emailNotRegistered")}
          message={t(`apiErrors.emailNotRegisteredDesc`)}
          onClose={() => setEmailNotRegistered(false)}
          variant="warning"
        />
      )}

      {error && (
        <Alert
          title={t("apiErrors.cannotLogin")}
          message={t(`apiErrors.${error}`)}
          onClose={() => setError("")}
          variant="warning"
        />
      )}

      {successRequestPasswordReset && (
        <Alert
          title={t("ui.requestPasswordResetSuccessful")}
          message={t("ui.requestPasswordResetSuccessfulHint")}
          onClose={() => {
            setSuccessRequestPasswordReset(false);
            onCancel();
          }}
          variant="info"
        />
      )}
    </>
  );
};

export default OnboardingRequestPasswordReset;
