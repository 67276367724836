import Typology from "../../../domain/entities/typology";
import DocumentType from "../../../domain/entities/documentType";
import DocumentPreset from "../../../domain/entities/documentPreset";
import { DocumentPresetTarget } from "../../../domain/entities/documentPresetTarget.enum";
import {
  makeGetDocumentsPresets,
  makeCreateDocumentPreset,
  makeUpdateDocumentPreset,
  makeDeleteDocumentPreset,
  makeAddTypologyToDocumentPreset,
  makeRemoveTypologyFromDocumentPreset,
  makeUpdateTypologyToDocumentPreset,
} from "../../../core/app/factory/documentsPresets/usecases";

import { makeGetTypologies } from "../../../core/app/factory/typologies/usecases";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { makeGetDocumentTypes } from "../../../core/app/factory/documentTypes/usecases";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import { AddDocumentTypeViewModel } from "../../hooks/Document/useAddDocumentType";
import Tag from "../../../domain/entities/tag";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { makeGetTags } from "../../../core/app/factory/tags/usecases";

class DocumentsPresetsListViewModel implements AddDocumentTypeViewModel {
  private _getTypologies = makeGetTypologies();
  private _addTypologyToPreset = makeAddTypologyToDocumentPreset();
  private _updateTypologyToPreset = makeUpdateTypologyToDocumentPreset();
  private _updateDocumentPreset = makeUpdateDocumentPreset();
  private _getDocumentsPresets = makeGetDocumentsPresets();
  private _setDocumentPreset = makeCreateDocumentPreset();
  private _unsetDocumentPreset = makeDeleteDocumentPreset();
  private _removeTypologyFromPreset = makeRemoveTypologyFromDocumentPreset();
  private _getDocumentTypes = makeGetDocumentTypes();
  private getTagsUseCase = makeGetTags();

  constructor(private readonly target: DocumentPresetTarget) { }

  async listTypologies(
    companyId: string,
    type: DocumentTypeResourceType,
    search?: string,
  ): Promise<Array<Typology>> {
    return await this._getTypologies.Call({ companyId, type, search });
  }

  async listPresets(
    companyId: string,
    target: DocumentPresetTarget,
    typologiesFilter: Array<Typology>,
  ): Promise<Array<DocumentPreset>> {
    return await this._getDocumentsPresets.Call({
      companyId,
      filter: target,
      typologies: typologiesFilter.map((v) => v.id),
    });
  }

  async setDocumentTypeAsPreset(
    companyId: string,
    documentType: DocumentType,
    target: DocumentPresetTarget,
  ): Promise<DocumentPreset> {
    return await this._setDocumentPreset.Call({
      companyId,
      documentType,
      target,
    });
  }

  async updateDocumentPreset(
    companyId: string,
    documentPresets: DocumentPreset[],
    target: DocumentPresetTarget,
  ): Promise<void> {
    return await this._updateDocumentPreset.Call({
      companyId,
      documentPresets,
      target,
    });
  }

  async unsetDocumentTypeAsPreset(
    companyId: string,
    presetId: string,
  ): Promise<void> {
    return await this._unsetDocumentPreset.Call({ companyId, presetId });
  }

  async addTypologyToPreset(
    companyId: string,
    presetId: string,
    typology: Tag,
    target: DocumentPresetTarget,
  ): Promise<void> {
    return await this._addTypologyToPreset.Call({
      companyId,
      presetId,
      typology,
      target,
    });
  }

  async updateTypologyToPreset(
    companyId: string,
    typology: Tag,
  ): Promise<void> {
    return await this._updateTypologyToPreset.Call({ companyId, typology });
  }

  async removeTypologyFromPreset(
    companyId: string,
    preset: DocumentPreset,
    typology: Typology,
  ): Promise<void> {
    return await this._removeTypologyFromPreset.Call({
      companyId,
      presetId: preset.id,
      typologyId: typology.id,
    });
  }

  async getTags(companyId: string, search?: string): Promise<Tag[]> {
    return await this.getTagsUseCase.Call({ companyId, search });
  }

  async getDocumentTypes(
    companyId: string,
    search?: string,
    tags?: string[],
    sort?: SortMeta,
    pageParam?: number,
  ): Promise<DocumentType[]> {
    return await this._getDocumentTypes.Call({
      companyId,
      filter: this.target as unknown as DocumentTypeCategory,
      includePublic: true,
      search,
      tags,
      sort,
      pageParam,
    });
  }

  async getDocumentTypesForAi(companyId: string, category: DocumentTypeCategory): Promise<DocumentType[]> {
    console.log("method not implemented");
    return;
  }
}

export default DocumentsPresetsListViewModel;
