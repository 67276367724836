import { makeCompanyRepository } from './repositories';
import GetCompany from '../../../../domain/interactors/company/getCompany';
import UpdateCompany from '../../../../domain/interactors/company/updateCompany';
import GetCompanyDocuments from '../../../../domain/interactors/company/getCompanyDocuments';
import GetCompanies from '../../../../domain/interactors/company/getCompanies';

export const makeGetCompany = () => {
	return new GetCompany(makeCompanyRepository());
};

export const makeUpdateCompany = () => {
	return new UpdateCompany(makeCompanyRepository());
};

export const makeCompanyDocuments = () => {
	return new GetCompanyDocuments(makeCompanyRepository());
};

export const makeGetCompanies = () => {
	return new GetCompanies(makeCompanyRepository());
};
