import { useState } from "react";
import Company from "../../../domain/entities/company";
import Supplier from "../../../domain/entities/supplier";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { useAuth } from "../../providers/Auth0JWTProvider";
import { SiteSupplierViewModel } from "../../viewmodels/sites/SiteSupplierViewModel";
import Document from "../../../domain/entities/document";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import {
  ActiveSuppliersFilter,
  AvailableSuppliersFilter,
  InvitedSuppliersFilter,
  UnlistedCompany,
} from "../../../domain/repositories/supplierRepository";
import { GetDocumentsFilter } from "../../../domain/repositories/documentRepository";
import Tag from "../../../domain/entities/tag";
import { InvitedSupplier } from "../../../domain/entities/invitedSupplier";
import { updateFilterWithDelete } from "../../../utils";

const useSiteSuppliersViewModel = (siteId: string) => {
  const { companyId } = useAuth();

  const [filterActive, setFilterActive] = useState<ActiveSuppliersFilter>({});
  const [sortActive, setSortActive] = useState<SortMeta>();
  const [filterInvited, setFilterInvited] = useState<InvitedSuppliersFilter>(
    {},
  );
  const [sortInvited, setSortInvited] = useState<SortMeta>();
  const [filterAvailable, setFilterAvailable] =
    useState<AvailableSuppliersFilter>({});
  const [sortAvailable, setSortAvailable] = useState<SortMeta>();
  const [filterDocuments, setFilterDocuments] = useState<GetDocumentsFilter>(
    {},
  );
  const [sortDocuments, setSortDocuments] = useState<SortMeta>();
  const [supplierCreationActive, setSupplierCreationActive] =
    useState<boolean>(false);
  const [messageInvitation, setMessageInvitation] = useState<
    string | undefined
  >(undefined);
  const [search, setSearch] = useState<string>();
  const [searchInvited, setSearchInvited] = useState<string>();
  const [searchToInvite, setSearchToInvite] = useState<string>();

  const viewModel = new SiteSupplierViewModel();

  const activeSuppliersQuery = useInfiniteQuery<Supplier[], Error>(
    ["active-suppliers", companyId, siteId, search, filterActive, sortActive],
    async ({ pageParam = 1 }) => {
      const filters = search ? { ...filterActive, search } : filterActive;
      return await viewModel.listActiveSupplier(
        companyId,
        siteId,
        filters,
        sortActive,
        pageParam,
        false,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    },
  );

  const activeSuppliersNestedQuery = useInfiniteQuery<Supplier[], Error>(
    ["active-suppliers-nested", companyId, siteId, filterActive, sortActive],
    async ({ pageParam = 1 }) => {
      return await viewModel.listActiveSupplier(
        companyId,
        siteId,
        filterActive,
        sortActive,
        pageParam,
        true,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    },
  );

  const invitedSuppliersQuery = useInfiniteQuery<InvitedSupplier[], Error>(
    [
      "invited-suppliers",
      companyId,
      siteId,
      searchInvited,
      filterInvited,
      sortInvited,
    ],
    async ({ pageParam = 1 }) => {
      const filters = searchInvited
        ? { ...filterActive, search: searchInvited }
        : filterActive;
      return await viewModel.getInvitedSuppliers(
        companyId,
        siteId,
        filters,
        sortInvited,
        pageParam,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    },
  );

  const availableSuppliersQuery = useInfiniteQuery<Company[], Error>(
    [
      `available-suppliers-${siteId}`,
      companyId,
      siteId,
      searchToInvite,
      filterAvailable,
      sortAvailable,
    ],
    async ({ pageParam = 1 }) => {
      const filters = searchToInvite
        ? { ...filterAvailable, search: searchToInvite }
        : filterAvailable;
      const result = await viewModel.getSuppliers(
        companyId,
        siteId,
        filters,
        sortAvailable,
        pageParam,
      );
      return result;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: supplierCreationActive,
    },
  );

  const isUserEmailAvailable = async (email: string) =>
    await viewModel.userEmailAvailable(email);

  const siteDocumentsQuery = useInfiniteQuery<Document[], Error>(
    [
      `available-supplier-documents-${siteId}`,
      companyId,
      siteId,
      filterDocuments,
      sortDocuments,
    ],
    async ({ pageParam = 1 }) => {
      const result = viewModel.getDocuments(
        companyId,
        siteId,
        filterDocuments,
        sortDocuments,
        pageParam,
      );
      return result;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: supplierCreationActive,
    },
  );

  const getTagsQuery = useQuery<Tag[], Error>(
    ["tags"],
    async () => await viewModel.getTags(companyId),
    {
      initialData: [],
    },
  );

  const inviteMutation = useMutation(
    ({
      supplierIds,
      documentIds,
      companyVariant,
      siteVariant,
      unlistedCompany,
    }: {
      supplierIds: string[];
      documentIds: string[];
      companyVariant: string;
      siteVariant: string;
      unlistedCompany: UnlistedCompany;
    }) =>
      viewModel.inviteSuppliers(
        companyId,
        siteId,
        supplierIds,
        documentIds,
        companyVariant,
        siteVariant,
        unlistedCompany,
      ),
    {
      onError: (err) => console.error("cannot create supplier", err),
      onSuccess: () => {
        activeSuppliersQuery.refetch();
        invitedSuppliersQuery.refetch();
      },
    },
  );

  const deleteMutation = useMutation(
    (supplierId: string) =>
      viewModel.deleteSupplier(companyId, siteId, supplierId),
    {
      onError: (err) => console.error("cannot delete supplier", err),
      onSuccess: () => {
        activeSuppliersQuery.refetch();
        activeSuppliersNestedQuery.refetch();
      },
    },
  );

  const renewInvitationMutation = useMutation(
    (invitationToken: string) => {
      setMessageInvitation(undefined);
      return viewModel.renewInvitation(companyId, invitationToken, siteId);
    },
    {
      onError: (err: Error) => {
        setMessageInvitation(err.message);
      },
      onSuccess: () => {
        setMessageInvitation("success"), invitedSuppliersQuery.refetch();
      },
    },
  );

  const companyVariantsQuery = useQuery(
    ["company-variants", companyId],
    () => viewModel.getCompanyVariants(companyId, siteId),
    {
      initialData: [],
    },
  );

  const siteVariantsQuery = useQuery(
    ["site-variants", companyId],
    () => viewModel.getSiteVariants(companyId, siteId),
    {
      initialData: [],
    },
  );

  const renewInvitation = async (invitationToken: string) => {
    try {
      await renewInvitationMutation.mutateAsync(invitationToken);
    } catch (err) {
      return undefined;
    }
  };

  const activeSuppliers = activeSuppliersQuery?.data?.pages?.flat() ?? [];
  const activeSuppliersNested =
    activeSuppliersNestedQuery?.data?.pages?.flat() ?? [];
  const invitedSuppliers = invitedSuppliersQuery?.data?.pages?.flat() ?? [];
  const documents = siteDocumentsQuery?.data?.pages?.flat() ?? [];
  const availableSuppliers = availableSuppliersQuery?.data?.pages?.flat() ?? [];

  return {
    activeSuppliers,
    activeSuppliersNested,
    activeSuppliersNestedHasNextPage: activeSuppliersNestedQuery.hasNextPage,
    activeSuppliersNestedFetchNextPage:
      activeSuppliersNestedQuery.fetchNextPage,
    activeSuppliersNestedIsFetching: activeSuppliersNestedQuery.isLoading,
    invitedSuppliers,
    suppliersHasNextPage: activeSuppliersQuery.hasNextPage,
    suppliersFetchNextPage: activeSuppliersQuery.fetchNextPage,
    invitedSuppliersHasNextPage: invitedSuppliersQuery.hasNextPage,
    invitedSuppliersFetchNextPage: invitedSuppliersQuery.fetchNextPage,
    isFetching: activeSuppliersQuery.isLoading,
    invitedIsFetching: invitedSuppliersQuery.isLoading,
    deleteSupplier: deleteMutation.mutateAsync,
    deleteSupplierIsLoading: deleteMutation.isLoading,
    inviteSuppliers: inviteMutation.mutateAsync,
    inviteSuppliersIsLoading: inviteMutation.isLoading,
    availableSuppliers,
    availableSuppliersHasNextPage: availableSuppliersQuery.hasNextPage,
    availableSuppliersFetchNextPage: availableSuppliersQuery.fetchNextPage,
    availableSuppliersFetching: availableSuppliersQuery.isLoading,
    documents,
    filterActive,
    updateFilterActive: updateFilter(setFilterActive),
    sortActive,
    setSortActive,
    filterInvited,
    updateFilterInvited: updateFilter(setFilterInvited),
    sortInvited,
    setSortInvited,
    filterAvailable,
    updateFilterAvailable: updateFilter(setFilterAvailable),
    sortAvailable,
    setSortAvailable,
    filterDocuments,
    updateFilterDocuments: updateFilter(setFilterDocuments),
    sortDocuments,
    setSortDocuments,
    tags: getTagsQuery.data,
    isUserEmailAvailable,
    setSupplierCreationActive,
    companyVariants: companyVariantsQuery.data,
    siteVariants: siteVariantsQuery.data,
    messageInvitation,
    setMessageInvitation,
    renewInvitation,
    setSearch,
    setSearchInvited,
    setSearchToInvite,
  };
};

const updateFilter = (setter) => (field: string, value: string | string[]) => {
  updateFilterWithDelete(setter, field, value);
};

export { useSiteSuppliersViewModel };
