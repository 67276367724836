import {
  makeInviteSuppliers,
  makeDeleteSupplier,
  makeGetActiveSupplier,
  makeGetAvailableSupplier,
  makeGetInvitedSupplier,
} from "../../../core/app/factory/suppliers/useCases";
import Company from "../../../domain/entities/company";
import Supplier from "../../../domain/entities/supplier";
import { makeGetSiteDocuments } from "../../../core/app/factory/sites/usecases";
import Document from "../../../domain/entities/document";
import {
  ActiveSuppliersFilter,
  AvailableSuppliersFilter,
  InvitedSuppliersFilter,
  UnlistedCompany,
} from "../../../domain/repositories/supplierRepository";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { GetDocumentsFilter } from "../../../domain/repositories/documentRepository";
import { makeGetTags } from "../../../core/app/factory/tags/usecases";
import Tag from "../../../domain/entities/tag";
import {
  makeRenewInvitation,
  makeUserEmailAvailable,
} from "../../../core/app/factory/users/usecases";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import { makeGetSiteVariants } from "../../../core/app/factory/variants/usecases";
import { InvitedSupplier } from "../../../domain/entities/invitedSupplier";

class SiteSupplierViewModel {
  private inviteSuppliersUseCase = makeInviteSuppliers();
  private deleteSupplierUseCase = makeDeleteSupplier();
  private getActiveSupplierUseCase = makeGetActiveSupplier();
  private getAvailableSuppliersUC = makeGetAvailableSupplier();
  private getInvitedSuppliersUC = makeGetInvitedSupplier();
  private getAvailableDocumentsUC = makeGetSiteDocuments();
  private userEmailAvailableUC = makeUserEmailAvailable();
  private renewInvitationUC = makeRenewInvitation();
  private getTagsUC = makeGetTags();

  async listActiveSupplier(
    companyId: string,
    siteId: string,
    filter?: ActiveSuppliersFilter,
    sort?: SortMeta,
    pageParam?: number,
    nested?: boolean,
  ): Promise<Supplier[]> {
    return await this.getActiveSupplierUseCase.Call({
      companyId,
      siteId,
      filter,
      sort,
      pageParam,
      nested,
    });
  }

  async inviteSuppliers(
    companyId: string,
    siteId: string,
    supplierIds: string[],
    documentIds?: string[],
    companyVariant?: string,
    siteVariant?: string,
    unlistedCompany?: UnlistedCompany,
  ): Promise<void> {
    await this.inviteSuppliersUseCase.Call({
      companyId,
      siteId,
      supplierIds,
      documentIds,
      companyVariant,
      siteVariant,
      unlistedCompany,
    });
  }

  async deleteSupplier(
    companyId: string,
    siteId: string,
    supplierId: string,
  ): Promise<void> {
    return await this.deleteSupplierUseCase.Call({
      companyId: companyId,
      siteId: siteId,
      supplierId: supplierId,
    });
  }

  async getSuppliers(
    companyId: string,
    siteId: string,
    filter?: AvailableSuppliersFilter,
    sort?: SortMeta,
    pageParam?: number,
  ): Promise<Company[]> {
    return await this.getAvailableSuppliersUC.Call({
      companyId,
      siteId,
      filter,
      sort,
      pageParam,
    });
  }

  async getInvitedSuppliers(
    companyId: string,
    siteId: string,
    filter?: InvitedSuppliersFilter,
    sort?: SortMeta,
    pageParam?: number,
  ): Promise<InvitedSupplier[]> {
    return await this.getInvitedSuppliersUC.Call({
      companyId,
      siteId,
      filter,
      sort,
      pageParam,
    });
  }

  async getDocuments(
    companyId: string,
    siteId: string,
    filter?: GetDocumentsFilter,
    sort?: SortMeta,
    pageParam?: number,
  ): Promise<Document[]> {
    return await this.getAvailableDocumentsUC.Call({
      companyId,
      siteId,
      filter,
      sort,
      pageParam,
    });
  }

  async getTags(companyId: string): Promise<Tag[]> {
    return await this.getTagsUC.Call({ companyId });
  }

  async userEmailAvailable(email: string): Promise<boolean> {
    return await this.userEmailAvailableUC.Call({ email });
  }

  async getCompanyVariants(companyId: string, siteId: string): Promise<any[]> {
    return await makeGetSiteVariants().Call({
      companyId,
      siteId,
      type: DocumentTypeResourceType.COMPANY,
    });
  }

  async renewInvitation(
    companyId: string,
    invitationToken: string,
    siteId: string,
  ): Promise<void> {
    return await this.renewInvitationUC.Call({
      companyId,
      invitationToken,
      siteId,
    });
  }

  async getSiteVariants(companyId: string, siteId: string): Promise<any[]> {
    return await makeGetSiteVariants().Call({
      companyId,
      siteId,
      type: DocumentTypeResourceType.SITE,
    });
  }
}

export { SiteSupplierViewModel };
