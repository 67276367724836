import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ContentLayout from "../../../layout/ContentLayout";
import { Box, Flex, Tag, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import ColumnFilterComponent from "../../Common/table/ColumnFilterComponent";
import { useSupplierResourcesViewModelType } from "../../../hooks/Suppliers/useSupplierResourcesViewModel";
import InfiniteTable from "../../Common/table/InfiniteTable";
import SearchInput from "../../../screens/Common/SearchInput";
import React from "react";

type SupplierResourcesListViewProps<Resource> = {
  type: "worker" | "machine" | "vehicle" | "tool" | "chemical";
  useViewModel: useSupplierResourcesViewModelType<Resource>;
  resourceColumns: string[];
  namespace: string;
};

const SupplierResourcesListView = <Resource extends { [key: string]: any }>({
  type,
  useViewModel,
  resourceColumns,
  namespace = "common",
}: SupplierResourcesListViewProps<Resource>) => {
  const { t } = useTranslation("siteResources");
  const navigate = useNavigate();

  const {
    supplierResources,
    supplierResourcesHasNextPage,
    supplierResourcesFetchNextPage,
    isFetching,
    filterSupplierResources,
    updateFilterSupplierResources,
    supplierResourcesSort,
    setSupplierResourcesSort,
    setSearch,
  } = useViewModel;

  const columns = [
    ...resourceColumns.map((col) => ({
      field: col,
      type: "text",
      label:
        ["machine", "vehicle"].includes(type) && col === "name"
          ? t("brand")
          : t(col),
    })),
  ] as {
    field: keyof { company; status };
    type: "text" | "tags";
    label: string;
  }[];
  return (
    <ContentLayout>
      <Flex
        justifyContent={"space-between"}
        alignItems="center"
        w={"100%"}
        paddingTop={5}
        pl={6}
        pr={10}
      >
        <Box textStyle="h1">{t("title", { ns: namespace })}</Box>
        <SearchInput onSearch={setSearch} />
      </Flex>
      <Flex
        flexDirection={"column"}
        alignItems={"start"}
        border="1px solid"
        borderColor="gray.300"
        borderRadius="10px"
        width="95%"
        margin={5}
        marginTop={5}
        position="relative"
        overflow={"hidden"}
      >
        <InfiniteTable
          infiniteScroll={{
            dataLength: supplierResources?.length,
            hasNextPage: supplierResourcesHasNextPage,
            fetchNextPage: supplierResourcesFetchNextPage,
          }}
          tableId="supplier-resources-table"
          emptyText={t("noActiveSiteResource", { ns: "siteResources" })}
          isLoading={isFetching}
        >
          <Thead>
            <Tr>
              {columns.map((column) => (
                <Th key={column.field}>
                  <TableColumnHeader
                    text={column.label}
                    filter={{
                      component: (
                        <ColumnFilterComponent
                          type={column.type}
                          value={filterSupplierResources[column.field]}
                          updateFilter={(value) =>
                            updateFilterSupplierResources(
                              column.field,
                              value as string | string[],
                            )
                          }
                          namespace="supplierResources"
                        />
                      ),
                      isActive: !!(Array.isArray(
                        filterSupplierResources[column.field],
                      )
                        ? filterSupplierResources[column.field][0]
                        : filterSupplierResources[column.field]),
                    }}
                    sort={{
                      handler: (direction) =>
                        setSupplierResourcesSort({
                          field: column.field,
                          direction,
                        }),
                      direction:
                        supplierResourcesSort &&
                        supplierResourcesSort.field === column.field
                          ? supplierResourcesSort.direction
                          : null,
                    }}
                  />
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {supplierResources?.map((siteResource) => (
              <Tr
                key={siteResource.id}
                onClick={() => navigate(`${siteResource.id}`)}
                sx={{ cursor: "pointer" }}
              >
                {Array.isArray(columns) &&
                  columns.map((column) => (
                    <Td key={column.field}>
                      {column.field === "status" ? (
                        <Tag
                          colorScheme={
                            siteResource.status === "active" ? "green" : "red"
                          }
                        >
                          {t("status.badge." + siteResource?.status, {
                            ns: "common",
                          })}
                        </Tag>
                      ) : (
                        siteResource[column.field]
                      )}
                    </Td>
                  ))}
              </Tr>
            ))}
          </Tbody>
        </InfiniteTable>
      </Flex>
    </ContentLayout>
  );
};

export default SupplierResourcesListView;
