import { SiteVehicleParams } from "../../../domain/entities/siteVehicle";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import SupplierResourcesListView from "../../components/Views/Suppliers/SupplierResourcesListView";
import useSupplierResourcesViewModel from "../../hooks/Suppliers/useSupplierResourcesViewModel";
import SupplierVehiclesViewModel from "../../viewmodels/suppliers/SupplierVehiclesViewModel";

const SupplierVehiclesView = () => {
  const resourceColumns = ["name", "plate", "model"];
  return (
    <SupplierResourcesListView<SiteVehicleParams>
      type={DocumentTypeResourceType.VEHICLE}
      useViewModel={useSupplierResourcesViewModel(
        new SupplierVehiclesViewModel(),
        DocumentTypeResourceType.VEHICLE,
      )}
      resourceColumns={resourceColumns}
      namespace={"vehicles"}
    />
  );
};

export default SupplierVehiclesView;
