import { FC } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useDocumentsListViewModel from "../../../hooks/Document/useDocumentsViewModel";
import GeneralDocumentsView, {
  GeneralDocumentsViewProps,
} from "../../../components/Views/Documents/GeneralDocumentsView";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import { useSupplierOrSiteFilesDownloader } from "../../../hooks/Document/useDocumentsFilesDownloader";
import { SiteGeneralDocumentsViewModel } from "../../../viewmodels/documents/SiteGeneralDocumentsViewModel";
import { AiTaskSectionCode } from "../../../../domain/entities/aiTaskSectionCode";

const SiteGeneralDocumentsView: FC<{
  permissions: GeneralDocumentsViewProps["permissions"];
}> = ({ permissions }) => {
  const { t } = useTranslation();
  const { siteId } = useParams<{ siteId: string }>();
  const viewModel = new SiteGeneralDocumentsViewModel(siteId);

  const hook = useDocumentsListViewModel(viewModel, DocumentTypeCategory.SITE, 'site-general-documents', siteId, AiTaskSectionCode.SITE_GENERAL_DOCUMENTS);

  const downloader = useSupplierOrSiteFilesDownloader(siteId, "site");

  return (
    <GeneralDocumentsView
      title={t("generalDocuments", { ns: "sites" })}
      subtitle={t("generalDocuments", { ns: "sites" })}
      props={hook}
      downloader={downloader}
      showExpireDate={false}
      fromSite={true}
      siteGeneralDocument={true}
      permissions={permissions}
      aiTaskSectionCode={AiTaskSectionCode.SITE_GENERAL_DOCUMENTS}
      aiFilesPropagable={false}
    />
  );
};

export default SiteGeneralDocumentsView;
