import { injectable } from 'tsyringe';
import ExportRepository from '../../domain/repositories/exportRepository';
import { ApiService } from '../utilities/apiService';
import { formatISODate } from '../utilities/filters';

@injectable()
class ServerExportRepository implements ExportRepository {
	constructor(private apiService: ApiService) {}

	async export(
		companyId: string,
		topic: string,
		siteId?: string,
		siteIds?: string[],
		startDate?: Date,
		endDate?: Date,
		permissionSites?: { [key: string]: string }
	): Promise<boolean> {
		const path = siteId ? `sites/${siteId}/${topic}` : `${topic}`;
		//NM: I perform a check on the string to modify the URLs of the sites where I work.
		const workingSitesPath = `sites/${siteId}/suppliers/${companyId}/${topic.replace('ws-', '')}`;

		let response = undefined;

		///unfortunately we need this check because in just this case we have to pass a body to the export request
		if (topic === 'badges-records') {
			const value = Object.entries(permissionSites).map(([key, value]) => {
				const parts = value.split('-');
				return { siteId: key, permission: parts.pop() };
			});
			response = await this.apiService.fetchWithToken(
				`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/exports/` + (topic.includes('ws-') ? workingSitesPath : path),
				{
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						siteIds: Object.keys(permissionSites).length > 0 ? value : siteIds,
						fromDate: formatISODate(startDate),
						toDate: formatISODate(endDate),
					}),
				}
			);
		} else {
			response = await this.apiService.fetchWithToken(
				`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/exports/` + (topic.includes('ws-') ? workingSitesPath : path),
				{
					method: 'GET',
					headers: { 'Content-Type': 'application/json' },
				}
			);
		}

		if (!response.ok) {
			console.error('cannot download file', response.statusText);
		}

		return Promise.resolve(response.ok);
	}
}

export default ServerExportRepository;
