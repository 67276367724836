import { SiteResource } from './siteResource';
import { SupplierResource } from './supplierResource';

export type SiteToolParams = {
	name: string;
	serial: string;
};

export type SiteTool = SiteResource<SiteToolParams>;

export type SupplierTool = SupplierResource<SiteToolParams>;

export const mapSiteTool =
	(siteId: string = null) =>
	(siteTool): SiteTool => ({
		name: siteTool.name,
		serial: siteTool.serial,
		variants: siteTool.variants,
		specializations: siteTool.specializations,
		company: siteTool.companyName,
		id: siteTool.id,
		siteId: siteId,
		companyId: siteTool.companyId,
		state: siteTool.state,
		isGlobal: siteTool.isGlobal,
		isForcedEvaluation: siteTool.isForcedEvaluation,
	});
