import { FiPlus } from "react-icons/fi";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FC, useState } from "react";
import { COLORS } from "../../assets/theme/colors";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import ContentLayout from "../../layout/ContentLayout";
import ActionBar from "../../components/Common/ActionBar";
import RenderIf from "../../components/Permissions/RenderIf";
import { PermissionCheck, useAuth } from "../../providers/Auth0JWTProvider";
import ActionBarItem from "../../components/Common/ActionBarItem";
import SupplierDocumentsView from "./Documents/SupplierDocumentsView";
import { Permission } from "../../components/Permissions/Permissions";
import { InfoBanner } from "../../components/Common/alerts/InfoBanner";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import {
  Flex,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import DocumentCommunicationModal from "../../components/Views/common/DocumentCommunicationModal";
import { useSiteSupplierDetailViewModel } from "../../hooks/Site/useSiteSupplierDetailViewModel";
import { MdOutlineHistory } from "react-icons/md";
import { EvaluationButton } from "../../components/Common/ResourceEvaluationToggle";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import ResourceEvaluationHistoryModal from "../../components/Views/common/ResourceEvaluationHistoryModal";
import LoadingView from "../Common/LoadingView";
import { ResourceDocumentsPermissions } from "../../components/Views/common/SiteResourceDocuments";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";
import { AiTaskStatus } from "../../../domain/entities/aiTask";
import { FaWandMagicSparkles } from "react-icons/fa6";

interface Props {
  permissions: {
    documents: ResourceDocumentsPermissions;
    downloadAll: Permission;
    addRequirement: Permission;
    showGlobalEvaluation: Permission;
    manageDocumentWithAi: Permission;
  };
}

const WorkingSiteDocumentView: FC<Props> = ({ permissions }) => {
  const { t } = useTranslation("supplier");
  const { siteId } = useParams();
  const { companyId } = useAuth();
  const [isTablet] = useMediaQuery("(max-width: 1300px)");
  const location = useLocation();
  const { fromPrevUrl } = location.state || {};
  const isWorkingSite = location.pathname.includes("workingsites");

  const [documentType, setDocumentType] = useState<DocumentTypeCategory>(
    DocumentTypeCategory.COMPANY
  );
  const [showAddSiteRequirementModal, setShowAddSiteRequirementModal] =
    useState(false);
  const [showAddCompanyRequirementModal, setShowAddCompanyRequirementModal] =
    useState(false);
  const [showCommunicationModal, setShowCommunicationmodal] = useState(false);
  const [showInfoBanner, setShowInfoBanner] = useState(true);
  const [showManageCompanyTaskWithAI, setShowManageCompanyTaskWithAI] =
    useState<boolean>(false);
  const [showManageSiteTaskWithAI, setShowManageSiteTaskWithAI] =
    useState<boolean>(false);

  const [
    showResourceEvaluationHistoryModal,
    setShowResourceEvaluationHistoryModal,
  ] = useState(false);

  const {
    siteResource,
    siteResourceEvaluations,
    getSiteResourceEvaluations,
    getSiteResourceEvaluationsIsLoading,
    setEnableGetEvaluationsHistory,
    filterResourceEvaluations,
    updateFilterResourceEvaluations,
    sortResourceEvaluations,
    updateSortResourceEvaluations,
    communicationProps,
    documentAICompanyProps,
    documentAISiteProps,
  } = useSiteSupplierDetailViewModel(
    siteId,
    companyId,
    DocumentTypeResourceType.COMPANY,
    isWorkingSite,
    AiTaskSectionCode.WORKING_SITE_RESOURCES_COMPANY,
    AiTaskSectionCode.WORKING_SITE_RESOURCES_SITE
  );

  const onTabSelect = (t) => {
    setDocumentType(
      t === 0 ? DocumentTypeCategory.COMPANY : DocumentTypeCategory.SITE
    );
  };

  const refetchCompanyTasks = () => {
    documentAICompanyProps.aiTasksRefetch();
    setShowManageCompanyTaskWithAI(true);
  };

  const refetchSiteTasks = () => {
    documentAISiteProps.aiTasksRefetch();
    setShowManageSiteTaskWithAI(true);
  };

  if (!siteResource) {
    return <LoadingView />;
  }

  return (
    <ContentLayout
      action={
        <ActionBar>
          <RenderIf permissions={permissions.addRequirement}>
            <ActionBarItem
              icon={FiPlus}
              description={t("addDocument", { ns: "documents" })}
              onClick={() => {
                if (documentType === DocumentTypeCategory.COMPANY) {
                  setShowAddCompanyRequirementModal(true);
                } else {
                  setShowAddSiteRequirementModal(true);
                }
              }}
            />
          </RenderIf>
          <RenderIf
            check={PermissionCheck.All}
            permissions={[
              permissions.documents.addFile,
              permissions.manageDocumentWithAi,
            ]}
          >
            <ActionBarItem
              icon={FaWandMagicSparkles}
              description={t("manageDocumentWithAi", { ns: "documents" })}
              onClick={() =>
                documentType === DocumentTypeCategory.COMPANY
                  ? refetchCompanyTasks()
                  : refetchSiteTasks()
              }
              isAnimated={
                documentType === DocumentTypeCategory.COMPANY
                  ? documentAICompanyProps.aiTasks.some(
                      (task) => task.status === AiTaskStatus.RUNNING
                    )
                  : documentAISiteProps.aiTasks.some(
                      (task) => task.status === AiTaskStatus.RUNNING
                    )
              }
            />
          </RenderIf>
          <RenderIf
            permissions={[
              permissions.documents.chat
            ]}
          >
            <ActionBarItem
              bgColor={COLORS.yellow}
              color="black"
              icon={HiOutlineChatAlt2}
              description={t("chat", { ns: "communication" })}
              onClick={() => setShowCommunicationmodal(true)}
            />
          </RenderIf>
        </ActionBar>
      }
    >
      <Flex
        marginLeft={10}
        marginRight={5}
        marginTop={10}
        flexDirection={"column"}
      >
        <Text textColor={COLORS.sikuroBlue} fontSize={20} fontWeight={"bold"}>
          {t("workingSiteDocuments", { ns: "documents" })}
        </Text>

        <RenderIf permissions={permissions.showGlobalEvaluation}>
          <Flex
            justifyContent="center"
            gap={5}
            alignItems="center"
            w="100%"
            marginTop={4}
          >
            {siteResource?.evaluationResult ? (
              <EvaluationButton
                value={siteResource?.evaluationResult}
                evaluationType={"resource"}
              />
            ) : (
              <Flex
                borderWidth="1px"
                borderRadius={"10px"}
                backgroundColor={"white"}
                padding={2}
              >
                <Flex
                  borderWidth="1px"
                  borderRadius={"10px"}
                  backgroundColor={"gray.200"}
                  color={"black"}
                  padding={3}
                  fontSize={"16px"}
                >
                  {t("supplierNotEvaluated", { ns: "supplier" })}
                </Flex>
              </Flex>
            )}

            {siteResource?.evaluationResult && (
              <IconButton
                fontSize="3xl"
                backgroundColor="transparent"
                onClick={() => {
                  setEnableGetEvaluationsHistory(true);
                  setShowResourceEvaluationHistoryModal(true);
                }}
                aria-label="document-history"
              >
                <MdOutlineHistory />
              </IconButton>
            )}
          </Flex>
        </RenderIf>

        <RenderIf permissions={permissions.documents.edit}>
          <>
            {showInfoBanner && (
              <InfoBanner
                text={t("dragAndDropInfo", { ns: "documents" })}
                onClose={() => setShowInfoBanner(false)}
              />
            )}
          </>
        </RenderIf>

        <Tabs
          width={"100%"}
          height="100%"
          defaultIndex={
            fromPrevUrl && fromPrevUrl === "company"
              ? 0
              : fromPrevUrl === "site"
              ? 1
              : 0
          }
          marginTop={2}
          marginBottom={5}
          onChange={onTabSelect}
        >
          <TabList>
            <Tab width="50%">{t("companyDocuments", { ns: "documents" })}</Tab>
            <Tab width="50%">{t("siteDocuments", { ns: "documents" })}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel padding={0} marginTop={1}>
              <SupplierDocumentsView
                target="company"
                autosize
                showAddRequirementModal={showAddCompanyRequirementModal}
                setShowAddRequirementModal={setShowAddCompanyRequirementModal}
                permissions={permissions.documents}
                communicationProps={communicationProps}
                readOnly={true}
                isTablet={isTablet}
                aiTaskSectionCode={
                  AiTaskSectionCode.WORKING_SITE_RESOURCES_COMPANY
                }
                aiDocumentHook={documentAICompanyProps}
                showManageTask={showManageCompanyTaskWithAI}
                setShowManageTaskWithAi={setShowManageCompanyTaskWithAI}
              />
            </TabPanel>
            <TabPanel padding={0} marginTop={1}>
              <SupplierDocumentsView
                target="site"
                autosize
                showAddRequirementModal={showAddSiteRequirementModal}
                setShowAddRequirementModal={setShowAddSiteRequirementModal}
                permissions={permissions.documents}
                communicationProps={communicationProps}
                readOnly={true}
                isTablet={isTablet}
                aiTaskSectionCode={
                  AiTaskSectionCode.WORKING_SITE_RESOURCES_SITE
                }
                aiDocumentHook={documentAISiteProps}
                showManageTask={showManageSiteTaskWithAI}
                setShowManageTaskWithAi={setShowManageSiteTaskWithAI}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
        {showCommunicationModal && (
          <DocumentCommunicationModal
            onClose={() => setShowCommunicationmodal(false)}
            hook={communicationProps}
            permissions={permissions.documents}
          />
        )}

        {showResourceEvaluationHistoryModal && (
          <ResourceEvaluationHistoryModal
            onClose={() => {
              setEnableGetEvaluationsHistory(false);
              setShowResourceEvaluationHistoryModal(false);
            }}
            evaluations={siteResourceEvaluations}
            getEvaluations={getSiteResourceEvaluations}
            getEvaluationsIsLoading={getSiteResourceEvaluationsIsLoading}
            filterResourceEvaluations={filterResourceEvaluations}
            updateFilterResourceEvaluations={updateFilterResourceEvaluations}
            sortResourceEvaluations={sortResourceEvaluations}
            updateSortResourceEvaluations={updateSortResourceEvaluations}
          />
        )}
      </Flex>
    </ContentLayout>
  );
};

export default WorkingSiteDocumentView;
