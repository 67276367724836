import { MetaObject } from './interfaces/metaObject';
import { Dictionary } from 'tsyringe/dist/typings/types';
import { BadgeResource } from './interfaces/badgeResource';
import { BadgeResourceType } from './badgeResourceType.enum';
import Typology from './typology';

export enum WorkerStatus {
	ACTIVE = 'active',
	BLOCKED = 'blocked',
}

class Worker implements BadgeResource, MetaObject {
	id: string;
	propertyNames: string[];
	meta: Dictionary<string>;
	badgeResource: BadgeResourceType;

	fiscalCodeCountryCode?: string;
	fiscalCode: string;
	firstName: string;
	lastName: string;
	email: string;
	phone: string;

	dateOfBirth: Date;
	placeOfBirth: string;

	dateOfEmployment: Date;
	jobTitle: string;

	typology: Typology;

	fullName: string;
	photo?: string;

	status: WorkerStatus;
	updatedAt?: string;

	constructor(
		firstName: string,
		lastName: string,
		fiscalCode: string,
		email: string,
		phone: string,
		photo: string,
		dateOfBirth: Date,
		placeOfBirth: string,
		dateOfEmployment: Date,
		status: WorkerStatus,
		meta?: Dictionary<string>,
		propertyNames?: Array<string>,
	) {
		this.lastName = lastName;
		this.firstName = firstName;
		this.fiscalCode = fiscalCode;
		this.fullName = `${firstName} ${lastName}`;

		this.email = email;
		this.phone = phone;
		this.photo = photo;
		this.dateOfBirth = dateOfBirth;
		this.placeOfBirth = placeOfBirth;
		this.dateOfEmployment = dateOfEmployment;
		this.status = status;

		this.badgeResource = BadgeResourceType.WORKER;

		this.meta = meta ?? {};
		this.propertyNames = propertyNames ?? [];
	}
}

// const makeWorker = (
//   firstName: string, lastName: string, fiscalCode: string, email: string, phone: string, photo: string,
//   dateOfBirth: Date, placeOfBirth: string, dateOfEmployment: Date,
//   meta?: Dictionary<string>, propertyNames?: Array<string>,
// ): Worker => {
//   return new Worker(firstName, lastName, fiscalCode, email, phone, photo, dateOfBirth, placeOfBirth, dateOfEmployment, meta, propertyNames);
// }
//
// const makeWorkerWithID = (
//   firstName: string, lastName: string, fiscalCode: string, email: string, phone: string,
//   dateOfBirth: Date, placeOfBirth: string, dateOfEmployment: Date,
//   photo: string
// ): Worker => {
//   const out = new Worker(firstName, lastName, fiscalCode, email, phone, photo, dateOfBirth, placeOfBirth, dateOfEmployment);
//   out.id = uuidv4();
//   return out;
// }

export default Worker;
// export { makeWorker, makeWorkerWithID };
