import { FC, useState } from "react";
import { Flex } from "@chakra-ui/react";
import { FiPlus } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ContentLayout from "../../layout/ContentLayout";
import Supplier from "../../../domain/entities/supplier";
import ActionBar from "../../components/Common/ActionBar";
import RenderIf from "../../components/Permissions/RenderIf";
import { DeleteActionAlert } from "../Common/DeleteActionAlert";
import ActionBarItem from "../../components/Common/ActionBarItem";
import { Permission } from "../../components/Permissions/Permissions";
import { useWorkingSubcontractorViewModel } from "../../hooks/WorkingSite/useWorkingSiteSubcontractors";
import WorkingSiteSubcontractTable from "../../components/Views/WorkingSiteDetails/WorkingSiteSubcontractTable";
import { InviteSupplierWithVariantPageContainer } from "../../components/Views/WorkingSiteDetails/InviteSupplierWIthVariantPageContainer";
import { Alert } from "../../screens/Common/Alert";
import { SupplierStatus } from "../../../domain/entities/supplierStatus.enum";

interface Props {
  invitePermissions: Permission[];
}

const WorkingSiteSubcontractsView: FC<Props> = ({ invitePermissions }) => {
  const { siteId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    activeSubcontractorsResult,
    activeContractorsHasNextPage,
    activeContractorsFetchNextPage,
    deleteSubcontractor,
    documents,
    setAvailableDocuments,
    filterDocuments,
    updateFilterDocuments,
    updateFilterAvailable,
    availableSubcontractors,
    availableSubcontractorsIsLoading,
    availableSubcontractorsHasNextPage,
    availableSubcontractorsFetchNextPage,
    setAvailableCompaniesEnabled,
    companyVariants,
    siteVariants,
    isUserEmailAvailable,
    filterAvailable,
    sortAvailable,
    setSortAvailable,
    sortDocuments,
    setSortDocuments,
    inviteSubcontractor,
    inviteSubcontractorIsLoading,
    tags,
    activeContractorsFetching,
    invitedContractors,
    filterActive,
    updateFilterActive,
    filterInvited,
    setSortInvited,
    sortInvited,
    updateFilterInvited,
    sortActive,
    setSortActive,
    invitedContractorsHasNextPage,
    invitedContractorsFetchNextPage,
    invitedIsFetching,
    messageInvitation,
    renewInvitation,
    setMessageInvitation,
    setSearchActive,
    setSearchInvited,
    setSearchToInvite,
  } = useWorkingSubcontractorViewModel(siteId);

  const navigateToDetails = (supplier: Supplier) => {
    if (supplier.status === SupplierStatus.PENDING) {
      setShowInvitedBlockAlert(true);
      return;
    }
    navigate(`/workingsites/${siteId}/subcontracts/${supplier?.company?.id}`);
  };

  const [supplierToDelete, setSupplierToDelete] = useState<Supplier>();

  const [showAskConfirmDelete, setShowAskConfirmDelete] =
    useState<boolean>(false);
  const [showCreateSupplierModal, setShowCreateSupplierModal] =
    useState<boolean>(false);
  const [showInvitedBlockAlert, setShowInvitedBlockAlert] =
    useState<boolean>(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState<boolean>(false);

  const askConfirmDelete = (supplier: Supplier) => {
    setSupplierToDelete(supplier);
    setShowAskConfirmDelete(true);
  };

  const cancelConfirmDelete = () => {
    setShowAskConfirmDelete(false);
    setSupplierToDelete(undefined);
  };

  const confirmDelete = async () => {
    setDeleteIsLoading(true);
    await deleteSubcontractor(
      supplierToDelete.site.id,
      supplierToDelete.company.id,
    );
    cancelConfirmDelete();
    setDeleteIsLoading(false);
  };

  const showCreateSupplier = () => {
    setAvailableDocuments(true);
    setAvailableCompaniesEnabled(true);
    setShowCreateSupplierModal(true);
  };

  return (
    <ContentLayout
      action={
        <ActionBar>
          <RenderIf permissions={invitePermissions}>
            <ActionBarItem
              icon={FiPlus}
              description={t("addSubcontractor", { ns: "supplier" })}
              onClick={() => {
                showCreateSupplier();
              }}
            />
          </RenderIf>
        </ActionBar>
      }
    >
      <Flex
        flex={1}
        h="100%"
        w="100%"
        bg="white"
        alignItems="start"
        flexDirection="column"
        padding={10}
      >
        <DeleteActionAlert
          isLoading={deleteIsLoading}
          onConfirm={confirmDelete}
          onCancel={cancelConfirmDelete}
          mainTitle={t("warning", { ns: "common" })}
          title={t("confirmDeleteSubcontractor", { ns: "supplier" })}
          leftButtonText={t("confirm", { ns: "common" })}
          rightButtonText={t("cancel", { ns: "common" })}
          isOpen={showAskConfirmDelete}
        />

        <WorkingSiteSubcontractTable
          filterableColumns={[1]}
          rows={activeSubcontractorsResult}
          onDelete={askConfirmDelete}
          activeContractorsFetching={activeContractorsFetching}
          activeContractorsHasNextPage={activeContractorsHasNextPage}
          activeContractorsFetchNextPage={activeContractorsFetchNextPage}
          invitedContractors={invitedContractors}
          filterInvited={filterInvited}
          updateFilterInvited={updateFilterInvited}
          sortInvited={sortInvited}
          setSortInvited={setSortInvited}
          filterActive={filterActive}
          updateFilterActive={updateFilterActive}
          sortActive={sortActive}
          setSortActive={setSortActive}
          invitedContractorsHasNextPage={invitedContractorsHasNextPage}
          invitedContractorsFetchNextPage={invitedContractorsFetchNextPage}
          invitedIsFetching={invitedIsFetching}
          onSelect={navigateToDetails}
          onSelectInvited={() => setShowInvitedBlockAlert(true)}
          messageInvitation={messageInvitation}
          renewInvitation={renewInvitation}
          setMessageInvitation={setMessageInvitation}
          setSearchActive={setSearchActive}
          setSearchInvited={setSearchInvited}
        />

        {showCreateSupplierModal && (
          <InviteSupplierWithVariantPageContainer
            documents={documents}
            filterDocuments={filterDocuments}
            updateFilterDocuments={updateFilterDocuments}
            sortDocuments={sortDocuments}
            setSortDocuments={setSortDocuments}
            availableSuppliers={availableSubcontractors}
            availableSuppliersHasNextPage={availableSubcontractorsHasNextPage}
            availableSuppliersFetchNextPage={
              availableSubcontractorsFetchNextPage
            }
            availableSuppliersLoading={availableSubcontractorsIsLoading}
            filterAvailable={filterAvailable}
            updateFilterAvailable={updateFilterAvailable}
            sortAvailable={sortAvailable}
            setSortAvailable={setSortAvailable}
            inviteSuppliers={inviteSubcontractor}
            isLoading={inviteSubcontractorIsLoading}
            onClose={() => {
              setAvailableCompaniesEnabled(false);
              setAvailableDocuments(false);
              setShowCreateSupplierModal(false);
            }}
            tags={tags}
            isUserEmailAvailable={isUserEmailAvailable}
            companyVariants={companyVariants}
            siteVariants={siteVariants}
            setSearch={setSearchToInvite}
          />
        )}

        {showInvitedBlockAlert && (
          <Alert
            title={t("warning", { ns: "common" })}
            message={t("navigationSubcontractorBlocked", { ns: "supplier" })}
            variant="info"
            onClose={() => {
              setShowInvitedBlockAlert(false);
            }}
          />
        )}
      </Flex>
    </ContentLayout>
  );
};

export default WorkingSiteSubcontractsView;
