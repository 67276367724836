import UseCase from '../../../core/app/usecase';
import Variant from '../../entities/variant';
import VariantRepository from '../../repositories/variantRepository';
import { DocumentTypeResourceType } from '../../../mock/models/document-type';

type ARGS = {
	companyId: string;
	type: DocumentTypeResourceType;
	search?: string;
};

class GetVariants implements UseCase<ARGS, Variant[]> {
	private variantRepository: VariantRepository;

	constructor(variantRepository: VariantRepository) {
		this.variantRepository = variantRepository;
	}

	async Call(args: ARGS): Promise<Variant[]> {
		return await this.variantRepository.getVariants(args.companyId, args.type, args.search);
	}
}

export default GetVariants;
