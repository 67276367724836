import { FC, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { FaClone } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { SearchIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../assets/theme/colors";
import ContentLayout from "../../layout/ContentLayout";
import ActionBar from "../../components/Common/ActionBar";
import RenderIf from "../../components/Permissions/RenderIf";
import { DeleteActionAlert } from "../Common/DeleteActionAlert";
import ActionBarItem from "../../components/Common/ActionBarItem";
import EditButton from "../../components/Common/table/EditButton";
import SimpleTable from "../../components/Common/table/SimpleTable";
import { Permission } from "../../components/Permissions/Permissions";
import ActionButton from "../../components/Common/table/ActionButton";
import DeleteButton from "../../components/Common/table/DeleteButton";
import RequirementsGroup from "../../../domain/entities/requirementsGroup";
import InputAnimatedLabel from "../../components/Common/InputAnimatedLabel";
import { EditRequirementGroupNameView } from "./EditRequirementGroupNameView";
import TableColumnHeader from "../../components/Common/table/TableColumnHeader";
import { useCompanyRequirementsGroupsViewModel } from "../../hooks/Company/useCompanyRequirementsGroupViewModel";
import {
  Button,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";

interface Props {
  addGroupPermissions: Permission[];
  manageGroupPermissions: Permission[];
}

const CompanyRequirementsGroupsView: FC<Props> = ({
  addGroupPermissions,
  manageGroupPermissions,
}) => {
  const { t } = useTranslation();
  const {
    error,
    requirementsGroups,
    requirementGroupIsFetching,
    createRequirementsGroups,
    updateRequirementsGroup,
    deleteRequirementsGroup,
    search,
    setSearch,
    sort,
    setSort,
    cloneRequirementGroup,
  } = useCompanyRequirementsGroupsViewModel();
  const [selectedRequirementGroup, setSelectedRequirementGroup] =
    useState<RequirementsGroup>();
  const [showAskConfirmDelete, setShowAskConfirmDelete] =
    useState<boolean>(false);
  const [showEditNameModal, setShowEditNameModal] = useState<boolean>(false);
  const [cloneModal, setCloneModal] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>(null);
  const [requirementGroupIsLoading, setRequirementGroupIsLoading] =
    useState<boolean>(false);

  /**
   * The edited RequirementsGroup object is declared as a state here because will be used just a temporary object
   */
  const [editedObject, setEditedObject] = useState<RequirementsGroup>();

  /**
   * This method is given as a callback to the editable screen and will be called with two parameter:
   * @param fieldName is the name of the field that we want to update;
   * @param value is the new value for this field.
   */
  const handleFieldChange = (fieldName, value) => {
    setEditedObject((prevObject) => ({
      ...prevObject,
      [fieldName]: value,
    }));
  };

  function createEditableRequirementGroup(
    requirementGroup: RequirementsGroup,
  ): RequirementsGroup {
    setEditedObject(requirementGroup);
    return requirementGroup;
  }

  const askConfirmDelete = (requirementGroup: RequirementsGroup) => {
    setSelectedRequirementGroup(requirementGroup);
    setShowAskConfirmDelete(true);
  };

  const cancelConfirmDelete = () => {
    setShowAskConfirmDelete(false);
    setSelectedRequirementGroup(undefined);
  };

  const confirmDelete = async () => {
    setRequirementGroupIsLoading(true);
    await deleteRequirementsGroup(selectedRequirementGroup.id);
    cancelConfirmDelete();
    setRequirementGroupIsLoading(false);
  };

  const showNameModal = (requirementsGroup: RequirementsGroup) => {
    if (requirementsGroup) {
      createEditableRequirementGroup(requirementsGroup);
    } else {
      const newRequirementGroup: RequirementsGroup = {
        id: "",
        name: "",
      };
      setEditedObject(newRequirementGroup);
    }
    setShowEditNameModal(true);
  };

  const closeEditName = () => {
    setShowEditNameModal(false);
  };

  const saveNewName = async () => {
    setRequirementGroupIsLoading(true);
    if (editedObject?.id === "") {
      await createRequirementsGroups(editedObject);
    } else {
      await updateRequirementsGroup(editedObject);
    }
    closeEditName();
    setRequirementGroupIsLoading(false);
  };
  const navigate = useNavigate();

  const clone = (
    e: React.MouseEvent<HTMLElement>,
    requirementGroup: RequirementsGroup,
  ) => {
    e.stopPropagation(),
      setCloneModal(true),
      setSelectedRequirementGroup(requirementGroup);
  };

  const handleNewInput = (id: string, newValue: string) => {
    setNewName(newValue);
  };

  return (
    <ContentLayout
      action={
        <ActionBar>
          <RenderIf permissions={addGroupPermissions}>
            <ActionBarItem
              icon={FiPlus}
              description={t("create-requirements-group", { ns: "companies" })}
              onClick={() => showNameModal(undefined)}
            />
          </RenderIf>
        </ActionBar>
      }
    >
      <Flex
        flex={1}
        h="100%"
        w="100%"
        paddingTop={10}
        paddingLeft={10}
        paddingBottom={10}
        paddingRight={3}
        textAlign="center"
        flexDirection="column"
        alignItems="start"
        justifyContent="start"
      >
        {error && <Heading>{error.message}</Heading>}

        <Flex justifyContent="space-between" width="100%" alignItems="center">
          <div style={{ textAlign: "left" }}>
            <Text
              textColor={COLORS.sikuroBlue}
              fontSize={20}
              fontWeight={"bold"}
            >
              {t("requirementGroup", { ns: "requirements" })}
            </Text>
            <Text fontSize={16} color="black" fontWeight={"normal"}>
              {t(`requirementGroupManagement`, { ns: "requirements" })}
            </Text>
          </div>
          {setSearch && (
            <InputGroup style={{ width: 300 }}>
              <Input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <InputRightElement>
                <SearchIcon />
              </InputRightElement>
            </InputGroup>
          )}
        </Flex>

        <Flex
          flexDirection={"column"}
          alignItems={"start"}
          border="1px solid"
          borderColor="gray.300"
          borderRadius="10px"
          width="100%"
          position="relative"
          overflow={"hidden"}
          marginTop={4}
        >
          <SimpleTable
            tableId="company-requirements-groups-table"
            isLoading={requirementGroupIsFetching}
            emptyText={t("noCompanyRequirements", { ns: "requirements" })}
            showEmptyText={requirementsGroups?.length === 0}
          >
            <Thead>
              <Tr>
                <Th>
                  <TableColumnHeader
                    text={t("requirements-group-name", { ns: "requirements" })}
                    sort={{
                      handler: (direction) =>
                        setSort({ field: "name", direction }),
                      direction:
                        sort?.field === "name" ? sort.direction : undefined,
                    }}
                  />
                </Th>
                <RenderIf permissions={[Permission.Company_EditReqGroup]}>
                  <Th width="150px" />
                </RenderIf>
              </Tr>
            </Thead>
            <Tbody>
              {requirementsGroups.map((requirementGroup) => (
                <Tr
                  key={requirementGroup.id}
                  onClick={() =>
                    navigate(
                      `/company/requirements/${requirementGroup.id}/company`,
                    )
                  }
                  sx={{ cursor: "pointer" }}
                >
                  <Td>{requirementGroup.name}</Td>
                  <RenderIf permissions={manageGroupPermissions}>
                    <Td width="150px">
                      <Flex width={"100%"} gap={3}>
                        <EditButton
                          onClick={() => showNameModal(requirementGroup)}
                        />
                        <DeleteButton
                          onClick={() => askConfirmDelete(requirementGroup)}
                        />
                        <Tooltip
                          label={t("clone", { ns: "common" })}
                          aria-label="Preview"
                        >
                          <span>
                            <ActionButton
                              aria-label="clone"
                              onClick={(e) => clone(e, requirementGroup)}
                              icon={<FaClone />}
                            />
                          </span>
                        </Tooltip>
                      </Flex>
                    </Td>
                  </RenderIf>
                </Tr>
              ))}
            </Tbody>
          </SimpleTable>
        </Flex>
      </Flex>

      <DeleteActionAlert
        isLoading={requirementGroupIsLoading}
        onConfirm={confirmDelete}
        onCancel={cancelConfirmDelete}
        mainTitle={t("warning", { ns: "common" })}
        title={t("confirmDeleteRequirement", { ns: "requirements" })}
        leftButtonText={t("confirm", { ns: "common" })}
        rightButtonText={t("cancel", { ns: "common" })}
        isOpen={showAskConfirmDelete}
      />

      <EditRequirementGroupNameView
        isLoading={requirementGroupIsLoading}
        isOpen={showEditNameModal}
        requirementGroup={editedObject}
        handleFieldChange={handleFieldChange}
        onCancel={closeEditName}
        onConfirm={saveNewName}
      />
      {cloneModal && (
        <Modal
          isOpen={cloneModal}
          onClose={() => setCloneModal(false)}
          size="xl"
        >
          <ModalOverlay />
          <ModalContent backgroundColor="white">
            <ModalHeader>
              <Flex
                flex={1}
                h="100%"
                w="100%"
                alignItems="center"
                textAlign="center"
                flexDirection="row"
              >
                <Text textColor={"black"} fontSize={14}>
                  {t("cloneRequirementGroup", { ns: "requirements" })}
                </Text>
                <Flex flex={1} />
                <IconButton
                  marginLeft={3}
                  fontSize="3xl"
                  size="2xl"
                  backgroundColor="transparent"
                  onClick={() => setCloneModal(false)}
                  aria-label="Edit"
                >
                  <MdClose />
                </IconButton>
              </Flex>
            </ModalHeader>
            <ModalBody>
              <Flex
                flex={1}
                h="100%"
                w="100%"
                justifyContent="space-between"
                flexDirection="row"
                alignItems="baseline"
                marginBottom={5}
              >
                <InputAnimatedLabel
                  id={selectedRequirementGroup?.name}
                  label={t("requirements-group-name", { ns: "requirements" })}
                  handleOnChange={handleNewInput}
                  width="70%"
                />
                <Button
                  isDisabled={newName === null}
                  isLoading={requirementGroupIsLoading}
                  colorScheme="blue"
                  onClick={async () => (
                    setRequirementGroupIsLoading(true),
                    await cloneRequirementGroup({
                      ...selectedRequirementGroup,
                      name: newName,
                    }),
                    setCloneModal(false),
                    setRequirementGroupIsLoading(false)
                  )}
                  marginBottom={0}
                >
                  {t("clone", { ns: "requirements" })}
                </Button>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </ContentLayout>
  );
};

export default CompanyRequirementsGroupsView;
