import { FC, ReactElement, cloneElement } from "react";
import { FieldValues, RegisterOptions, useFormContext } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
  Flex,
  Link,
} from "@chakra-ui/react";
import { FaMapMarked } from "react-icons/fa";
import { COLORS } from "../../../assets/theme/colors";
import { borderRadius } from "polished";

interface BaseFormFieldProps {
  // Core properties.
  name: string;
  label: string;
  readOnly?: boolean;
  isEditing?: boolean;

  // Optional properties.
  colSpan?: number;
  displayValue?: string;
  hideRequiredMark?: boolean;
  renderRawValue?: (value: any) => string;
  rules?: RegisterOptions<FieldValues, string>;

  // Handlers.
  onChange?: (e?: any) => void;
  applyConditionalStyles?: boolean;
  selectedBackgroundColor?: string;
  selectedTextColor?: string;
}

export interface EditableFormFieldProps extends BaseFormFieldProps {
  // Elements rendered by the component.
  edit?: ReactElement;
  link?: string;
}

const kRequiredFieldSymbol = "*";

const BaseFormField: FC<EditableFormFieldProps> = ({
  edit,
  name,
  label,
  displayValue,
  renderRawValue,
  rules,
  onChange,
  link,
  readOnly = false,
  isEditing = false,
  hideRequiredMark = false,
  applyConditionalStyles,
  selectedBackgroundColor,
  selectedTextColor,
}) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext();
  const errorMessage = errors[name]?.message?.toString();
  const inputProps = register(name, rules);

  const performOnChange = (e: { target: any; type?: any }) => {
    inputProps.onChange(e);
    onChange?.(e);
  };

  // When rendering the component in read-only mode (isEditing is false),
  // use renderRawValue to render (format) the raw value of the form property;
  // If renderRawValue is not defined use the first non-null value of,
  // in order of priority, displayValue, rawValue and literal string "-".
  const rawValue = watch(name);
  const requiredMark = hideRequiredMark ? "" : ` ${kRequiredFieldSymbol}`;
  const display = renderRawValue?.(rawValue) ?? displayValue ?? rawValue ?? "-";
  const decoratedLabel =
    rules && isEditing && !hideRequiredMark ? `${label}${requiredMark}` : label;

  const labelStyles = {
    backgroundColor: isEditing ? selectedBackgroundColor : "white",
    color: isEditing ? selectedTextColor : "black",
    borderRadius: 3,
  };

  // FIXME: for some reason, there's an hardcoded property at line ~58 (name==='address').
  //        This is a generic component and no hardcoded property should be used.
  //        Behaviour *not* fixed so that no regression is introduced in code.
  return (
    <FormControl isInvalid={errors[name] !== undefined} variant="floating">
      {isEditing && !readOnly && edit ? (
        cloneElement(edit, { ...inputProps, onChange: performOnChange })
      ) : (
        <Flex ml="17px" mt="8px" pt={2} gap={2} alignItems={"center"}>
          <Text>{display ?? "-"}</Text>
          {(name === "address" || name === "address.street") &&
            display &&
            link && (
              <Link href={link} isExternal alignSelf={"end"} p={1}>
                <FaMapMarked fontSize={20} />
              </Link>
            )}
        </Flex>
      )}
      <FormLabel zIndex={1} style={labelStyles}>
        {decoratedLabel}
      </FormLabel>
      {errorMessage && (
        <FormErrorMessage paddingLeft={4}>{errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default BaseFormField;
