import { Box, Button, Text } from "@chakra-ui/react";
import { FaRegSave } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import React, { FC, useEffect, useState } from "react";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import LoadingView from "../../Common/LoadingView";
import { COLORS } from "../../../assets/theme/colors";
import DataBox from "../../../components/Common/DataBox";
import ContentLayout from "../../../layout/ContentLayout";
import { useNavigate, useParams } from "react-router-dom";
import { MdCancel, MdClose, MdEdit } from "react-icons/md";
import Chemical from "../../../../domain/entities/chemical";
import ActionBar from "../../../components/Common/ActionBar";
import ResourceDocumentsView, {
  ResourceDocumentProps,
} from "../ResourceDocumentsView";
import RenderIf from "../../../components/Permissions/RenderIf";
import ActionBarItem from "../../../components/Common/ActionBarItem";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Permission } from "../../../components/Permissions/Permissions";
import FormTextField from "../../../components/Common/forms/FormTextField";
import useChemicalViewModel from "../../../hooks/Resources/Chemicals/useChemicalViewModel";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import { useDocumentFilesDownloader } from "../../../hooks/Document/useDocumentsFilesDownloader";
import DocumentCommunicationModal from "../../../components/Views/common/DocumentCommunicationModal";
import useChemicalDocumentsViewModel from "../../../hooks/Resources/Chemicals/useChemicalDocumentsViewModel";
import DOMPurify from "dompurify";
import { FaUserPlus } from "react-icons/fa6";
import BaseModal from "../../../components/Common/alerts/BaseModal";
import SelectSitesView from "../../../components/Views/common/SelectSitesView";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { AiTaskStatus } from "../../../../domain/entities/aiTask";
import { FiPlus } from "react-icons/fi";
import { PermissionCheck } from "../../../providers/Auth0JWTProvider";

interface Props {
  permissions: ResourceDocumentProps["permissions"] & {
    editRecord: Permission;
    manageDocumentWithAi: Permission;
  };
}

const ChemicalDetailView: FC<Props> = ({ permissions }) => {
  const { id } = useParams();
  const { t } = useTranslation("chemicals");
  const {
    chemical,
    isFetching,
    updateImage,
    updateChemical,
    resourceSites,
    filterResourceSites,
    updateFilterResourceSites,
    setSortResourceSites,
    sortResourceSites,
    isFetchingSites,
    hasNextPage,
    fetchNextPage,
    setEnableGetResourceSites,
    hasSites,
    siteCollection,
    sortSiteCollection,
    setSortSiteCollection,
    filterSiteCollection,
    updateFilterSiteCollection,
    siteCollectionHasNextPage,
    selectAll,
    setSelectAll,
    totalSiteCount,
    siteCollectionFetchNextPage,
    refetchSiteCollection,
    linkChemicalToSites,
    isLinking,
  } = useChemicalViewModel(id as string);
  const documentsHook = useChemicalDocumentsViewModel(id);
  const [showSiteCollection, setShowSiteCollection] = useState(false);
  const [selectedSiteIds, setSelectedSiteIds] = useState<string[]>();
  const [showAddDocumentType, setShowAddDocumentType] =
    useState<boolean>(false);
  const downloader = useDocumentFilesDownloader();
  const [init, setInit] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [showCommunicationModal, setShowCommunicationModal] = useState(false);
  const [showManageTaskWithAI, setShowManageTaskWithAI] =
    useState<boolean>(false);

  const [uploadedImageFile, setUploadedImageFile] = useState<File>();
  const sanitizedHtmlContent = DOMPurify.sanitize(
    t("addResourceToSites", {
      ns: "sites",
      resource: `${chemical?.name}`,
    })
  );

  const navigate = useNavigate();
  const goToChemicalsList = () => {
    navigate(`/resources/chemicals`);
  };

  const onSubmit: SubmitHandler<Chemical> = (data) => {
    if (uploadedImageFile) {
      updateImage({ ...chemical, name: data.name }, uploadedImageFile);
    } else {
      updateChemical({ ...chemical, name: data.name });
    }
    setIsEditing(false);
  };

  const refetchCommentsAndNotes = () => {
    documentsHook.communicationProps.refetchResourceDocumentComments(),
      documentsHook.communicationProps.refetchResourceDocumentNotes();
  };

  const methods = useForm<Chemical>({ mode: "all", defaultValues: chemical });
  const requiredRule = { required: t("requiredField", { ns: "common" }) };
  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = methods;

  useEffect(() => {
    if (!chemical || init) {
      return;
    }

    reset({ name: chemical.name });
    setInit(true);
  }, [chemical, init, isEditing, reset]);

  if (!chemical) {
    return <LoadingView />;
  }
  const handleLinkToSites = async () => {
    await linkChemicalToSites(selectedSiteIds);
    setShowSiteCollection(false);
    setSelectedSiteIds([]);
  };

  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            bgColor={COLORS.sikuroBlue}
            color="white"
            icon={MdClose}
            description={t("close", { ns: "common" })}
            onClick={goToChemicalsList}
          />

          <RenderIf permissions={permissions.editRecord}>
            <ActionBarItem
              icon={isEditing ? MdCancel : MdEdit}
              onClick={() => {
                setIsEditing(!isEditing);
                if (isEditing) {
                  setUploadedImageFile(undefined);
                }
              }}
              description={t(isEditing ? "cancel" : "edit", { ns: "common" })}
            />
          </RenderIf>
          {isEditing && isValid && (
            <ActionBarItem
              description={t("confirm", { ns: "common" })}
              icon={FaRegSave}
              onClick={handleSubmit(onSubmit)}
            />
          )}
          <RenderIf permissions={permissions.documents.chat}>
            <ActionBarItem
              bgColor={COLORS.yellow}
              color="black"
              icon={HiOutlineChatAlt2}
              description={t("chat", { ns: "communication" })}
              onClick={() => {
                refetchCommentsAndNotes(), setShowCommunicationModal(true);
              }}
            />
          </RenderIf>

          <RenderIf permissions={permissions.addDocument}>
            <ActionBarItem
              icon={FiPlus}
              description={t("addDocument", { ns: "documents" })}
              onClick={() => setShowAddDocumentType(true)}
            />
          </RenderIf>

          <RenderIf
            check={PermissionCheck.All}
            permissions={[
              permissions.documents.addFile,
              permissions.manageDocumentWithAi,
            ]}
          >
            <ActionBarItem
              icon={FaWandMagicSparkles}
              description={t("manageDocumentWithAi", { ns: "documents" })}
              onClick={() => {
                documentsHook.documentAIProps.aiTasksRefetch();
                setShowManageTaskWithAI(true);
              }}
              isAnimated={documentsHook.documentAIProps.aiTasks.some(
                (task) => task.status === AiTaskStatus.RUNNING
              )}
            />
          </RenderIf>
          <ActionBarItem
            bgColor={COLORS.yellow}
            color="black"
            icon={FaUserPlus}
            description={t("addResourceToSitesTooltip", {
              ns: "sites",
              resource: `${chemical?.name}`,
            })}
            onClick={() => {
              refetchSiteCollection(), setShowSiteCollection(true);
            }}
          />
        </ActionBar>
      }
    >
      {chemical && (
        <Box px={3} py={5} marginLeft={7}>
          <Box textStyle="h1" mb={2}>
            {chemical?.name}
          </Box>
          <Box
            pb={8}
            border="1px solid"
            borderColor="gray.300"
            borderRadius="10px"
          >
            <FormProvider {...methods}>
              <DataBox
                title={t("details", { ns: "common" })}
                isEditing={isEditing}
                isLoading={isFetching}
                image={{
                  url: uploadedImageFile
                    ? URL.createObjectURL(uploadedImageFile)
                    : chemical.photo,
                  onUpdate: (f) => setUploadedImageFile(f),
                }}
                fields={[
                  <FormTextField
                    key="name"
                    name="name"
                    label={t("name")}
                    rules={requiredRule}
                  />,
                ]}
              />
            </FormProvider>
          </Box>
          <ResourceDocumentsView
            category={DocumentTypeCategory.CHEMICAL}
            documentsHook={documentsHook}
            resourcePath="/resources/workers"
            showAddDocumentType={showAddDocumentType}
            setShowAddDocumentType={setShowAddDocumentType}
            downloader={downloader}
            resourceSites={resourceSites}
            hasSites={hasSites}
            isFetchingSites={isFetchingSites}
            hasNextPage={hasNextPage}
            fetchNextPage={() => fetchNextPage()}
            siteCount={totalSiteCount}
            selectAllSites={selectAll}
            setSelectAllSites={setSelectAll}
            filterResourceSites={filterResourceSites}
            updateFilterResourceSites={updateFilterResourceSites}
            setSortResourceSites={setSortResourceSites}
            sortResourceSites={sortResourceSites}
            setEnableGetResourceSites={setEnableGetResourceSites}
            permissions={permissions}
            showManageTaskWithAI={showManageTaskWithAI}
            setShowManageTaskWithAI={setShowManageTaskWithAI}
          />
        </Box>
      )}
      {showCommunicationModal && (
        <DocumentCommunicationModal
          onClose={() => setShowCommunicationModal(false)}
          hook={documentsHook.communicationProps}
          permissions={permissions.documents}
        />
      )}
      {showSiteCollection && (
        <BaseModal
          closeOnOverlayClick={false}
          onClose={() => setShowSiteCollection(false)}
          footer={[
            <Button
              key="confirm"
              isDisabled={!selectedSiteIds || selectedSiteIds?.length === 0}
              colorScheme="blue"
              onClick={() => handleLinkToSites()}
              isLoading={isLinking}
            >
              {t("confirm", { ns: "common" })}
            </Button>,
            <Button
              key="cancel"
              colorScheme="red"
              onClick={() => setShowSiteCollection(false)}
            >
              {t("cancel", { ns: "common" })}
            </Button>,
          ]}
        >
          <Text
            fontSize={16}
            marginTop={3}
            marginBottom={5}
            dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }}
          />
          <SelectSitesView
            autosize
            title={""}
            siteSelectedAction={setSelectedSiteIds}
            siteList={siteCollection}
            setSortResourceSites={setSortSiteCollection}
            sortResourceSites={sortSiteCollection}
            filterResourceSites={filterSiteCollection}
            updateFilterResourceSites={updateFilterSiteCollection}
            hasNextPage={siteCollectionHasNextPage}
            fetchNextPage={() => siteCollectionFetchNextPage()}
            includeFooterButton={false}
            showSelectAll={false}
            hideSubtitles={true}
          />
        </BaseModal>
      )}
    </ContentLayout>
  );
};

export default ChemicalDetailView;
