import { useApp } from "../../../app";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAuth } from "../../providers/Auth0JWTProvider";
import SiteOptions, {
  ScheduleEmail,
} from "../../../domain/entities/siteOptions";
import SiteOptionsViewModel from "../../viewmodels/sites/SiteOptionsViewModel";
import { useEffect, useState } from "react";

const useSiteOptionsViewModel = (siteId: string, isWorkingSite?: boolean) => {
  const { context } = useApp();
  const { companyId } = useAuth();
  const viewModel = new SiteOptionsViewModel(companyId, siteId);

  const [scheduleActive, setScheduleActive] = useState(false);
  const [selectedDays, setSelectedDays] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [emails, setEmails] = useState<string[]>([]);
  const [error, setError] = useState<string>(undefined);
  const [time, setTime] = useState<string>();

  const getOptionsQuery = useQuery<SiteOptions>(
    ["get-site-options", companyId, siteId],
    async () => {
      return await viewModel.getSiteOptions();
    },
    {
      enabled: !isWorkingSite,
      initialData: context.site?.options,
    }
  );

  const getScheduleEmailQuery = useQuery<ScheduleEmail[]>(
    ["get-schedule-email", companyId, siteId],
    async () => await viewModel.getScheduleEmail(),
    {
      enabled: !isWorkingSite,
    }
  );

  useEffect(() => {
    if (getScheduleEmailQuery.data) {
      setSelectedDays(getScheduleEmailQuery.data[0]?.days ?? {});
      setTime(getScheduleEmailQuery.data[0]?.dayHour ?? "");
      setEmails(getScheduleEmailQuery.data[0]?.emails ?? []);
      setScheduleActive(getScheduleEmailQuery.data[0]?.active ?? false);
    }
  }, [getScheduleEmailQuery.data]);

  const updateOptionsMutation = useMutation(
    (options: Partial<SiteOptions>) =>
      viewModel.updateSiteOptions({ ...getOptionsQuery.data, ...options }),
    {
      onError: (err: Error) => {
        console.error("cannot update site options", err), setError(err.message);
      },
      onSuccess: () => getOptionsQuery.refetch(),
    }
  );

  const updateScheduledEmailMutation = useMutation(
    () =>
      viewModel.updateScheduledEmail({
        type: "eligibles-resources",
        active: scheduleActive,
        emails: emails,
        dayHour: scheduleActive ? time : null,
        days: selectedDays,
      }),
    {
      onError: (err: Error) => {
        console.error("cannot update schedule email", err),
          setError(err.message);
      },
      onSuccess: () => {
        getScheduleEmailQuery.refetch();
      },
    }
  );

  return {
    options: getOptionsQuery.data,
    scheduledEmail: getScheduleEmailQuery.data
      ? getScheduleEmailQuery.data[0]
      : [],
    getScheduleEmailQueryRefetch: getScheduleEmailQuery.refetch,
    isLoading: getOptionsQuery.isFetching || updateOptionsMutation.isLoading,
    updateOption: updateOptionsMutation.mutate,
    reload: getOptionsQuery.refetch,
    updateScheduledEmail: updateScheduledEmailMutation.mutate,
    setEmails,
    emails,
    time,
    setTime,
    setSelectedDays,
    selectedDays,
    scheduleActive,
    setScheduleActive,
    error,
    setError,
  };
};

export default useSiteOptionsViewModel;
