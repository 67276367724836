import UseCase from '../../../core/app/usecase';
import File from '../../entities/file';
import DocumentRepository from '../../repositories/documentRepository';

type DocumentFileParameters = {
	companyId: string;
	documentId: string;
	fileId: string;
	siteId?: string;
	target?: string;
	resourceId?: string;
	siteIds?: string[];
	selectAll?: boolean;
};

/**
 * Delete file from document.
 */
class DeleteFileFromDocument implements UseCase<DocumentFileParameters, void> {
	constructor(private repository: DocumentRepository) {}

	async Call({ companyId, documentId, fileId, siteId, target, resourceId, siteIds, selectAll }: DocumentFileParameters): Promise<void> {
		return await this.repository.deleteFileFromDocument(companyId, documentId, fileId, siteId, target, resourceId, siteIds, selectAll);
	}
}

export default DeleteFileFromDocument;
