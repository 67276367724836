import { SiteToolParams } from "../../../domain/entities/siteTool";
import { SiteResourceDetailViewModel } from "../../hooks/Site/useSiteResourceDetailViewModel";
import SiteToolDetailViewModel from "../sites/SiteToolDetailViewModel";
import {
  makeAddRequirementToSiteTool,
  makeDeleteSiteTool,
} from "../../../core/app/factory/siteTools/usecases";
import { ResourceDocumentEvaluationState } from "../../../domain/entities/resourceDocumentEvaluationState.enum";
import { makeEvaluateSiteDocument } from "../../../core/app/factory/documents/usecases";

class WorkingSiteToolDetailViewModel
  extends SiteToolDetailViewModel
  implements SiteResourceDetailViewModel<SiteToolParams>
{
  evaluateSiteResource = undefined;
  async addRequirementToSiteResource(
    companyId: string,
    toolId: string,
    siteId: string,
    documentTypeId: string,
    isOptional: boolean,
    graceDays: number,
  ): Promise<void> {
    await makeAddRequirementToSiteTool().Call({
      companyId,
      toolId,
      siteId,
      documentTypeId,
      isOptional,
      graceDays,
    });
  }
  async evaluateDocument(
    companyId: string,
    siteId: string,
    documentId: string,
    result: ResourceDocumentEvaluationState,
    expiresAt?: Date,
    noEvaluationExpiration?: boolean,
    target?: string,
    resourceId?: string,
  ): Promise<void> {
    await makeEvaluateSiteDocument().Call({
      companyId,
      siteId,
      documentId,
      result,
      expiresAt,
      noEvaluationExpiration,
      target,
      resourceId,
    });
  }

  async deleteSiteResource(
    companyId: string,
    toolId: string,
    siteId: string,
  ): Promise<void> {
    await makeDeleteSiteTool().Call({ companyId, toolId, siteId });
  }
}

export default WorkingSiteToolDetailViewModel;
