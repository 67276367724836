import { FC } from "react";
import { FiPlus } from "react-icons/fi";
import { FaRegSave } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { MdCancel, MdEdit } from "react-icons/md";
import ActionBar from "../../components/Common/ActionBar";
import ActionBarItem from "../../components/Common/ActionBarItem";
import { Permission } from "../../components/Permissions/Permissions";
import RenderIf, {
  useHasPermissions,
} from "../../components/Permissions/RenderIf";

interface Props {
  onAdd?: () => void;
  onSave?: () => void;
  onToggleEdit?: (newFlag: boolean) => void;

  canSave?: boolean;
  isEditing?: boolean;
  editDetailsPermissions: Permission[];
  editStaffPermissions: Permission[];
}

const CompanyDetailActionBar: FC<Props> = ({
  editDetailsPermissions,
  editStaffPermissions,
  onAdd,
  onSave,
  onToggleEdit,
  canSave,
  isEditing,
}) => {
  const { t } = useTranslation("companies");
  const canEditDetails = useHasPermissions(editDetailsPermissions);
  const canEditStaff = useHasPermissions(editStaffPermissions);

  return (
    <ActionBar>
      <RenderIf permissions={editDetailsPermissions}>
        {canEditDetails && onToggleEdit && (
          <ActionBarItem
            icon={isEditing ? MdCancel : MdEdit}
            onClick={() => onToggleEdit?.(!isEditing)}
            description={t(isEditing ? "cancel" : "edit", { ns: "common" })}
          />
        )}
        {onSave && canSave && (
          <ActionBarItem
            description={t("confirm", { ns: "common" })}
            icon={FaRegSave}
            onClick={onSave}
          />
        )}
      </RenderIf>
      <RenderIf permissions={[Permission.Company_EditRoles]}>
        {canEditStaff && onAdd && (
          <ActionBarItem
            description={t("createStaff")}
            icon={FiPlus}
            onClick={onAdd}
          />
        )}
      </RenderIf>
    </ActionBar>
  );
};

export default CompanyDetailActionBar;
