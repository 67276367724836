import Site from "./domain/entities/site";
import { useLocation } from "react-router-dom";
import Supplier from "./domain/entities/supplier";
import AppRouter from "./presentation/router/AppRouter";
import SiteOptions from "./domain/entities/siteOptions";
import { useAuth } from "./presentation/providers/Auth0JWTProvider";
import useNotificationsList from "./presentation/hooks/Notifications/useNotificationsList";
import {
  createContext,
  ReactNode,
  useCallback,
  useState,
  useEffect,
  useReducer,
  useContext,
} from "react";

interface IAppContext {
  context: {
    topBarTitle?: ReactNode;
    site?: {
      details: Site;
      options?: SiteOptions;
    };
    supplier?: {
      details: Supplier;
    };
  };

  notificationContext: any;
  updateContext?: (attributes: Partial<IAppContext["context"]>) => void;
}

const AppContext = createContext<IAppContext | undefined>(undefined);

type Action = {
  type: "UPDATE_CONTEXT";
  payload: Partial<IAppContext["context"]>;
};

const reducer = (
  state: IAppContext["context"],
  action: Action
): IAppContext["context"] => {
  switch (action.type) {
    case "UPDATE_CONTEXT":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const App = () => {
  const { companyId, user } = useAuth();
  const [state, dispatch] = useReducer(reducer, {});
  const authenticated = !!companyId;
  const path = useLocation();
  const [recentRoutes, setRecentRoutes] = useState<string[]>([]);

  const updateContext = useCallback(
    (attributes: Partial<IAppContext["context"]>) => {
      dispatch({ type: "UPDATE_CONTEXT", payload: attributes });
    },
    []
  );

  const notificationContext = useNotificationsList();

  const providerValue = {
    context: state,
    notificationContext,
    updateContext,
  };

  useEffect(() => {
    if (authenticated) {
      if (path.pathname.includes("/unread")) {
        notificationContext.refetchNotifications();
      }
      notificationContext.refreshCount();
    }
  }, [path]);

  useEffect(() => {
    if (authenticated) {
      notificationContext.setAuthenticated(authenticated);
    }
  }, [authenticated]);

  useEffect(() => {
    const smartsuppScript = document.createElement("script");
    smartsuppScript.id = "smartsupp-script";
    const widgetContainer = document.getElementById(
      "smartsupp-widget-container"
    );

    smartsuppScript.innerHTML = `
      var _smartsupp = _smartsupp || {};
      _smartsupp.key = '5515c1ee6ac350948fff3f9af35bce124e35c269';
      window.smartsupp||(function(d) {
        var s,c,o=smartsupp=function(){ o._.push(arguments)};o._=[];
        s=d.getElementsByTagName('script')[0];c=d.createElement('script');
        c.type='text/javascript';c.charset='utf-8';c.async=true;
        c.src='//www.smartsuppchat.com/loader.js?';s.parentNode.insertBefore(c,s);
      })(document);
      window.smartsupp('email', '${user?.email}');
      window.smartsupp('name', '${user?.name}');
      window.smartsupp('language', '${user?.language}');
      window.smartsupp('variables', {
       referrer: "${location.origin}${location.pathname}",
       history: "${recentRoutes}"
      });
    `;
    smartsuppScript.async = true;
    if (process.env.REACT_APP_SMARTSUPP_ENABLE === "true" && authenticated) {
      document.body.appendChild(smartsuppScript);
      setRecentRoutes((prevState) => {
        const newRoute = location.origin + location.pathname;
        if (prevState[0] !== newRoute) {
          const updatedRoutes = [newRoute, ...prevState];
          return updatedRoutes.slice(0, 5);
        } else {
          return prevState;
        }
      });
      if (widgetContainer && document.body.contains(widgetContainer)) {
        widgetContainer.style.visibility = "visible";
      }
    }
    return () => {
      // Cleanup: remove the script from DOM using the ref
      if (
        document.body.contains(smartsuppScript) &&
        widgetContainer &&
        document.body.contains(widgetContainer)
      ) {
        document.body.removeChild(smartsuppScript);
        widgetContainer.style.visibility = "hidden";
      }
    };
  }, [authenticated, user, location.pathname]);

  return (
    <AppContext.Provider value={providerValue}>
      <AppRouter />
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);
