import { MdClose } from "react-icons/md";
import { useEffect, useState } from "react";
import { AiOutlineSave } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import LoadingView from "../../Common/LoadingView";
import { COLORS } from "../../../assets/theme/colors";
import ContentLayout from "../../../layout/ContentLayout";
import { CloseIcon, Search2Icon } from "@chakra-ui/icons";
import { useNavigate, useParams } from "react-router-dom";
import ActionBar from "../../../components/Common/ActionBar";
import { RoleContext } from "../../../../domain/entities/role";
import Permission from "../../../../domain/entities/permission";
import ActionBarItem from "../../../components/Common/ActionBarItem";
import useUpsertRoleViewModel, {
  useRoleDetailsViewModelProps,
} from "../../../hooks/Roles/useRoleDetailsViewModel";

import {
  Flex,
  Text,
  Box,
  Checkbox,
  HStack,
  Spacer,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Grid,
  GridItem,
  SimpleGrid,
} from "@chakra-ui/react";
import { useAuth } from "../../../providers/Auth0JWTProvider";
import { permissionSectionMap } from "../../../components/Permissions/Permissions";

type PermissionGroup = {
  title?: string;
  resource: string;
  permissions: Permission[];
};

const RoleDetailsView = () => {
  const { roleId } = useParams();
  const values: useRoleDetailsViewModelProps = useUpsertRoleViewModel(roleId);

  return values?.role?.permissions ? (
    <RoleDetails {...values}></RoleDetails>
  ) : (
    <LoadingView />
  );
};

const RoleDetails = ({
  role,
  updatePermissions,
}: useRoleDetailsViewModelProps) => {
  const { roleId } = useParams();
  const { companyId, company, updateContext } = useAuth();
  const { t } = useTranslation("settings");
  const navigate = useNavigate();
  const [filter, setFilter] = useState<string>("");
  const [showLeavePage, setShowLeavePage] = useState(false);
  const [enabledPermissions, setEnabledPermissions] = useState<string[]>(
    role.permissions.filter((p) => p.enabled).map((p) => p.id),
  );

  const save = async () => {
    await updatePermissions(
      { roleId, permissionIds: enabledPermissions },
      close,
    );
    await updateContext(companyId, company);
    setShowLeavePage(false);
  };

  const close = () => {
    navigate("/settings/roles");
  };

  // Grouping should be done server-side, but due to time constraints,
  // the client will handle it until an appropriate API exists.
  const groupPermissions = (source: Permission[]): PermissionGroup[] => {
    const groups = new Map<string, PermissionGroup>();

    for (const p of source) {
      const group = groups.get(p.resource) ?? {
        title: t(`permissionGroup.${p.resource}`, { ns: "permissions" }),
        resource: p.resource,
        permissions: [],
      };
      group.permissions.push({
        ...p,
        title: t(`permissions.${p.id.replaceAll(":", "_")}`, {
          ns: "permissions",
          context: "title",
        }),
        description: t(`permissions.${p.id.replaceAll(":", "_")}`, {
          ns: "permissions",
          context: "description",
        }),
      });

      groups.set(p.resource, group);
    }

    const keys = Array.from(groups.keys()).sort();
    let sorted: PermissionGroup[] = [];
    for (const k of keys) {
      sorted.push(groups.get(k));
    }
    const order = [
      "dashboard",
      "sites",
      "worksite",
      "company",
      "resources",
      "supplier",
      "calendar",
      "settings",
    ];
    switch (role.context) {
      case RoleContext.SITE:
        sorted = sorted.filter((s) => ["sites"].includes(s.resource));
        sorted.forEach((s) => {
          if (s.resource === "sites")
            s.permissions = s.permissions.filter(
              (p) => p.id !== "sites:manage",
            );
        });
        break;
      case RoleContext.WORKSITE:
        sorted = sorted.filter((s) => ["worksite"].includes(s.resource));
        sorted.forEach((s) => {
          if (s.resource === "worksite")
            s.permissions = s.permissions.filter(
              (p) => p.id !== "worksite:show",
            );
        });
        break;
      default:
        sorted.forEach((s) => {
          if (s.resource === "sites")
            s.permissions = s.permissions.filter(
              (p) => p.id === "sites:manage",
            );
          if (s.resource === "worksite")
            s.permissions = s.permissions.filter(
              (p) => p.id === "worksite:show",
            );
        });
        sorted.sort(
          (a, b) => order.indexOf(a.resource) - order.indexOf(b.resource),
        );
        break;
    }
    return sorted;
  };

  const groupResources = (permissions: Permission[]): PermissionGroup[] => {
    const groups = new Map<string, PermissionGroup>();
    for (const p of permissions) {
      const resource = permissionSectionMap[p.id];
      const group = groups.get(resource) ?? {
        title: t(`permissionGroup.${resource}`, { ns: "permissions" }),
        resource: resource,
        permissions: [],
      };

      group.permissions.push({
        ...p,
        title: t(`permissions.${p.id.replaceAll(":", "_")}`, {
          ns: "permissions",
          context: "title",
        }),
        description: t(`permissions.${p.id.replaceAll(":", "_")}`, {
          ns: "permissions",
          context: "description",
        }),
      });

      groups.set(resource, group);
    }

    const keys = Array.from(groups.keys()).sort();
    const sorted: PermissionGroup[] = [];
    for (const k of keys) {
      sorted.push(groups.get(k));
    }
    const order = [
      "records",
      "general-documents",
      "site-documents",
      "eval-owner",
      "overview",
      "document-presets",
      "document-types",
      "requirements-groups",
      "badge",
      "workers",
      "vehicles",
      "machines",
      "tools",
      "chemicals",
      "company-documents",
      "subcontractors",
      "suppliers",
      "requirements",
      "access",
      "review",
      "reports",
    ];
    sorted.sort(
      (a, b) => order.indexOf(a.resource) - order.indexOf(b.resource),
    );
    return sorted;
  };

  const permissions = role ? groupPermissions(role.permissions) : [];

  const toggleAllPermissions = (group: string, enabled: boolean) => {
    setShowLeavePage(true);
    const groupPermissions = (
      role.context == RoleContext.GENERAL
        ? permissions
        : groupResources(permissions[0].permissions)
    )
      .find((p) => p.resource === group)
      ?.permissions.map((p) => p.id);
    if (enabled) {
      const newEnabledPermissions = [
        ...enabledPermissions,
        ...groupPermissions,
      ].filter((value, index, self) => self.indexOf(value) === index);
      setEnabledPermissions(newEnabledPermissions);
    } else {
      setEnabledPermissions(
        enabledPermissions.filter((p) => !groupPermissions.includes(p)),
      );
    }
  };

  const handleTogglePermissions = (id: string) => {
    setShowLeavePage(true);
    if (enabledPermissions.includes(id)) {
      setEnabledPermissions(enabledPermissions.filter((p) => p !== id));
    } else {
      setEnabledPermissions([...enabledPermissions, id]);
    }
  };
  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            onClick={close}
            icon={MdClose}
            description={t("close", { ns: "common" })}
            color="white"
            bgColor={COLORS.sikuroBlue}
          />
          {showLeavePage && (
            <ActionBarItem
              onClick={save}
              icon={AiOutlineSave}
              description={t("save", { ns: "common" })}
            />
          )}
        </ActionBar>
      }
    >
      <Flex
        flex={1}
        h="100%"
        w="100%"
        bg="white"
        padding={10}
        justifyContent="start"
        flexDirection="column"
      >
        <Text
          fontSize="larger"
          fontWeight="semibold"
          color={COLORS.sikuroBlue}
          textTransform={"capitalize"}
        >
          {role.context == RoleContext.GENERAL &&
            t("roles.generalRoleTitle", { roleName: role.name })}
          {role.context == RoleContext.SITE &&
            t("roles.siteRoleTitle", { roleName: role.name })}
          {role.context == RoleContext.WORKSITE &&
            t("roles.workingSiteRoleTitle", { roleName: role.name })}
        </Text>

        <InputGroup marginTop={4}>
          {filter !== "" && (
            <InputLeftElement onClick={() => setFilter("")}>
              <CloseIcon />
            </InputLeftElement>
          )}
          <Input
            variant="outline"
            value={filter}
            onChange={(v) => setFilter(v.target.value)}
          />
          <InputRightElement>
            <Search2Icon />
          </InputRightElement>
        </InputGroup>

        {showLeavePage && (
          <Box
            my={4}
            py={5}
            borderRadius="10px"
            bg={COLORS.lightRed}
            position={"sticky"}
            top={4}
            zIndex={1}
          >
            <Text px={4}>{t("usavedChanges", { ns: "sites" })}</Text>
          </Box>
        )}

        <Accordion allowToggle mt={8}>
          {(role.context == RoleContext.GENERAL
            ? permissions
            : groupResources(permissions[0].permissions)
          ).map((g) => (
            <AccordionItem key={g.resource} flexGrow={1}>
              <HStack>
                <Checkbox
                  isChecked={g.permissions.every((p) =>
                    enabledPermissions.includes(p.id),
                  )}
                  isIndeterminate={
                    g.permissions.some((p) =>
                      enabledPermissions.includes(p.id),
                    ) &&
                    !g.permissions.every((p) =>
                      enabledPermissions.includes(p.id),
                    )
                  }
                  onChange={(e) =>
                    toggleAllPermissions(g.resource, e.target.checked)
                  }
                />
                <AccordionButton>
                  <Text fontWeight="semibold">
                    {g.title ?? g.resource}
                    {filter !== "" && `(${g.permissions.length})`}
                  </Text>

                  <Spacer />
                  <AccordionIcon />
                </AccordionButton>
              </HStack>
              <AccordionPanel>
                {role.context === RoleContext.GENERAL &&
                ["company", "resources", "supplier"].includes(g.resource) ? (
                  groupResources(g.permissions).map((rg, i) => (
                    <Box
                      key={rg.title}
                      sx={
                        i > 0 && {
                          borderTop: "1px solid rgb(226, 232, 240)",
                          mt: 5,
                        }
                      }
                    >
                      <Text my={2} fontWeight="semibold">
                        {t(`permissionGroup.${rg.resource}`, {
                          ns: "permissions",
                        })}
                      </Text>
                      <SimpleGrid columns={2} spacing={3}>
                        {rg.permissions.map((p, k) => (
                          <PermissionCheckbox
                            key={p.id}
                            p={p}
                            k={k}
                            enabledPermissions={enabledPermissions}
                            handleTogglePermissions={handleTogglePermissions}
                          />
                        ))}
                      </SimpleGrid>
                    </Box>
                  ))
                ) : (
                  <SimpleGrid columns={2} spacing={3}>
                    {g.permissions.map((p, k) => (
                      <PermissionCheckbox
                        key={p.id}
                        p={p}
                        k={k}
                        enabledPermissions={enabledPermissions}
                        handleTogglePermissions={handleTogglePermissions}
                      />
                    ))}
                  </SimpleGrid>
                )}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Flex>
    </ContentLayout>
  );
};

type PermissionCheckboxProps = {
  p: Permission;
  k: number;
  enabledPermissions: string[];
  handleTogglePermissions: (id: string) => void;
};
const PermissionCheckbox = ({
  p,
  k,
  enabledPermissions,
  handleTogglePermissions,
}: PermissionCheckboxProps) => (
  <Checkbox
    mt={2}
    isChecked={enabledPermissions.includes(p.id)}
    onChange={(e) => handleTogglePermissions(p.id)}
  >
    <Text fontWeight="semibold" flexGrow={1} align="left">
      {p.title}
    </Text>
    <Text fontSize="smaller" flexGrow={1}>
      {p.description}
    </Text>
  </Checkbox>
);

export default RoleDetailsView;
