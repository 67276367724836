import UseCase from '../../../core/app/usecase';
import SiteRepository from '../../repositories/siteRepository';
import { Evaluable } from '../../entities/evaluable.enum';

interface Args {
	companyId: string;
	siteId: string;
	resourceId: string;
	evaluationId: string;
	isApproved: boolean;
	type: Evaluable;
}

class ApprovePendingEvaluation implements UseCase<Args, void> {
	private siteRepository: SiteRepository;

	constructor(siteRepository: SiteRepository) {
		this.siteRepository = siteRepository;
	}

	async Call({ companyId, siteId, resourceId, evaluationId, isApproved, type }: Args): Promise<void> {
		return await this.siteRepository.approvePendingEvaluation(companyId, siteId, resourceId, evaluationId, type, isApproved);
	}
}

export default ApprovePendingEvaluation;
