import { useMemo, useState } from "react";
import User from "../../../domain/entities/user";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAuth } from "../../providers/Auth0JWTProvider";
import UserSettingsViewModel from "../../viewmodels/users/UserSettingsViewModel";
import {
  LoginResponse,
  UpdatePasswordResponse,
} from "../../../infrastructure/responses/auth";
import { UserNotification } from "../../../domain/entities/interfaces/userNotification";

export interface UpdatePasswordArgs {
  value: string;
  confirm: string;
}

export interface UpdatePasswordParams {
  oldPassword: string;
  value: string;
  confirm: string;
}

export interface UpdateAlertParams {
  expiringDocumentsDaysBeforeAlert?: number[];
  expiringOwnedSitesDaysBeforeAlert?: number[];
  expiringWorkingSitesDaysBeforeAlert?: number[];
}

const useUserSettingsViewModel = (onPasswordUpdated?: () => void) => {
  const { companyId, user } = useAuth();

  const [passwordUpdateError, setPasswordUpdateError] =
    useState<Error>(undefined);
  const [oldPasswordError, setOldPasswordError] = useState<string>(undefined);
  const [updatePasswordData, setUpdatePasswordData] =
    useState<UpdatePasswordResponse>();

  const [errorMessage, setErrorMessage] = useState<string>(undefined);

  const viewModel = useMemo(
    () => new UserSettingsViewModel(companyId, user.id),
    [user.id, companyId]
  );

  const {
    data: currentUser,
    isLoading,
    refetch,
    error,
  } = useQuery(["get-user", user.id], async () => await viewModel.get());

  const updateMutation = useMutation((user: User) => viewModel.update(user), {
    onSuccess: () => refetch(),
  });

  const updateImageMutation = useMutation(
    (image: File) => viewModel.updateUserImage(user.id, image),
    { onSuccess: () => refetch() }
  );

  const onPasswordUpdateSuccess = () => {
    onPasswordUpdated();
  };

  const onPasswordUpdateFail = (e: string | Error) => {
    setPasswordUpdateError(typeof e === "string" ? new Error(e) : e);
  };

  const updatePasswordMutation = useMutation(
    async (args: UpdatePasswordParams) => {
      setPasswordUpdateError(undefined);

      const updatePasswordData = await viewModel.updatePassword(
        args.oldPassword,
        args.value,
        args.confirm
      );
      setUpdatePasswordData(updatePasswordData);
    },
    {
      onSuccess: onPasswordUpdateSuccess,
      onError: onPasswordUpdateFail,
    }
  );

  const getUserNotifications = useQuery(
    ["badges", companyId, user.id],
    async () => {
      return await viewModel.getUserNotifications(companyId, user.id);
    }
  );
  const updateUserNotificationMutation = useMutation(
    async (notifications: UserNotification[]) => {
      await viewModel.updateUserNotification(companyId, user.id, notifications);
    },
    {
      onSuccess: () => getUserNotifications.refetch(),
      onError: (error) => console.error(error),
    }
  );

  const updateAlertNotificationMutation = useMutation(
    async (alert: UpdateAlertParams) => {
      await viewModel.updateAlertNotification(companyId, user.id, alert);
    },
    {
      onSuccess: () => getUserNotifications.refetch(),
      onError: (error) => {
        if (error instanceof Error) {
          setErrorMessage(error.message);
        }
      },
    }
  );

  const updateUserNotifications = (notifications: UserNotification[]) => {
    updateUserNotificationMutation.mutateAsync(notifications);
  };

  const updateAlertNotification = async (alert: UpdateAlertParams) => {
    try {
      return await updateAlertNotificationMutation.mutateAsync(alert);
    } catch (err) {
      console.error(err);
    }
  };

  const userNotifications = getUserNotifications.data;

  return {
    currentUser,
    error,
    errorMessage,
    setErrorMessage,
    updateAlertNotification,
    updateUser: updateMutation.mutateAsync,
    updateUserImage: updateImageMutation.mutateAsync,
    isLoading:
      isLoading || updateMutation.isLoading || updateImageMutation.isLoading,
    passwordUpdateError,
    oldPasswordError,
    updatePassword: updatePasswordMutation.mutateAsync,
    updatePasswordIsLoading: updatePasswordMutation.isLoading,
    updatePasswordData,
    userNotifications,
    updateUserNotifications,
    isLoadingUpdateUserNotifications: updateUserNotificationMutation.isLoading,
    notificationsIsFetching: getUserNotifications.isFetching,
  };
};

export default useUserSettingsViewModel;
