import Form from "../../Common/forms/Form";
import TagSelect from "../../Common/TagSelect";
import { useTranslation } from "react-i18next";
import { ReactElement, useEffect } from "react";
import BaseModal from "../../Common/alerts/BaseModal";
import { COLORS } from "../../../assets/theme/colors";
import { Button, Flex, Text } from "@chakra-ui/react";
import Typology from "../../../../domain/entities/typology";
import { EditableFormFieldProps } from "../../Common/forms/FormField";
import { CreateResourceArgs } from "../../../hooks/Resources/useResourceListViewModel";

type CreateResourceModalProps<T> = {
  namespace: string;
  onClose: () => void;
  createResource: (resource: CreateResourceArgs<T>) => void;
  typologies: Typology[];
  isValid: boolean;
  createFields?: Array<ReactElement<Omit<EditableFormFieldProps, "isEditing">>>;
  selectedTypology?: Typology;
  onSelectTypology: (t: Typology) => void;
  onConfirm: () => void;
  isLoading: boolean;
};

function CreateResourceModal<T>({
  namespace,
  typologies,
  onClose,
  isLoading,
  isValid,
  createFields,
  selectedTypology,
  onSelectTypology,
  onConfirm,
}: CreateResourceModalProps<T>) {
  const { t } = useTranslation(namespace);

  const defaultTypology = typologies?.find(
    (t) => t.name.toLowerCase() === "default",
  );

  useEffect(() => {
    onSelectTypology(selectedTypology ?? defaultTypology);
  }, []);

  return (
    <BaseModal
      bodyOverflow={"auto"}
      title={t("create")}
      onClose={onClose}
      isLoading={isLoading}
      footer={
        <Flex justifyContent="end" mt={10}>
          <Button
            onClick={onConfirm}
            colorScheme="blue"
            isLoading={isLoading}
            isDisabled={!isValid}
          >
            {t("create")}
          </Button>
        </Flex>
      }
    >
      <Text color={COLORS.sikuroBlue} fontWeight={500} variant="h2">
        {t("general")}
      </Text>
      <Text mb="20px">{t("generalSubtitle")}</Text>

      {createFields && (
        <Form
          isEditing
          templateColumns="repeat(2, 1fr)"
          fields={createFields}
        />
      )}

      <Text mt="20px" color={COLORS.sikuroBlue} fontWeight={500} variant="h2">
        {t("documents")}
      </Text>
      <Text mb="20px">{t("documentsSubtitle")}</Text>

      <TagSelect
        tags={typologies}
        defaultMenuIsOpen={false}
        usePortal={true}
        selectedTags={[(selectedTypology ?? defaultTypology)?.id]}
        setSelectedTags={(selected) =>
          onSelectTypology(
            typologies.find((t) => t.id === selected[selected.length - 1]),
          )
        }
      />
    </BaseModal>
  );
}

export default CreateResourceModal;
