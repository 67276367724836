import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Divider,
  Flex,
  IconButton,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";

interface OnboardingHeaderProps {
  title: string;
  onBack?: () => void;
}

const OnboardingHeader: FC<OnboardingHeaderProps> = ({ title, onBack }) => {
  const { t } = useTranslation();

  return (
    <Flex width="100%">
      {onBack && (
        <IconButton
          icon={<ArrowBackIcon width={10} />}
          aria-label={t("back")}
          onClick={onBack}
        />
      )}

      <Spacer marginLeft={onBack ? -10 : 0} />
      <VStack>
        <Text margin={2} fontSize="large">
          {title}
        </Text>
        <Divider width="100%" />
      </VStack>

      <Spacer />
    </Flex>
  );
};

export default OnboardingHeader;
