import {
  makeAddTypologyToTool,
  makeCreatePropagableToolDocument,
  makeCreateToolDocument,
  makeEvaluateToolDocument,
  makeGetTool,
  makeGetToolDocuments,
  makeRemoveTypologyFromTool,
} from "../../../core/app/factory/tools/usecases";
import { ResourceDocumentsViewModel } from "../resources/ResourceDocumentsViewModel";
import { makeGetTypologies } from "../../../core/app/factory/typologies/usecases";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import Document from "../../../domain/entities/document";
import { GetDocumentsFilter } from "../../../domain/repositories/documentRepository";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { ResourceDocumentEvaluationState } from "../../../domain/entities/resourceDocumentEvaluationState.enum";
import DocumentTypeWithPublic from "../../../domain/entities/documentTypeWithPublic";

class ToolDocumentsViewModel extends ResourceDocumentsViewModel {
  private getResourceUseCase = makeGetTool();
  private getTypologiesUseCase = makeGetTypologies();

  constructor(private toolId: string) {
    super(toolId, DocumentTypeCategory.TOOL);
  }

  //#region Extends class: ResourceDocumentsViewModel

  async getDocuments(
    companyId: string,
    filter?: GetDocumentsFilter,
    sort?: SortMeta,
    pageParam?: number,
  ): Promise<Document[]> {
    return await makeGetToolDocuments().Call({
      companyId,
      resourceId: this.toolId,
      sort,
      filter,
      pageParam,
    });
  }

  async createDocument(companyId: string, documents: string[]): Promise<void> {
    return await makeCreateToolDocument().Call({
      companyId,
      resourceId: this.toolId,
      documents,
    });
  }

  async createPropagableDocuments(
    companyId: string,
    documents: DocumentTypeWithPublic[],
  ): Promise<void> {
    return await makeCreatePropagableToolDocument().Call({
      companyId,
      resourceId: this.toolId,
      documents,
    });
  }

  async evaluateDocument(
    companyId: string,
    documentId: string,
    evaluationState?: ResourceDocumentEvaluationState,
    expirationDate?: Date,
    noEvaluationExpiration?: boolean,
    siteIds?: string[],
    selectAll?: boolean,
  ): Promise<void> {
    return await makeEvaluateToolDocument().Call({
      companyId,
      resourceId: this.toolId,
      documentId,
      evaluationState,
      expirationDate,
      noEvaluationExpiration,
      siteIds,
      selectAll,
    });
  }

  async addTypologyToResource(
    companyId: string,
    resourceId: string,
    typologyId: string,
  ) {
    return await makeAddTypologyToTool().Call({
      companyId,
      resourceId,
      typologyId,
    });
  }

  async removeTypologyFromResource(
    companyId: string,
    resourceId: string,
    typologyId: string,
  ) {
    return await makeRemoveTypologyFromTool().Call({
      companyId,
      resourceId,
      typologyId,
    });
  }

  //#endregion

  async getResource(companyId: string, resourceId: string) {
    return await this.getResourceUseCase.Call({
      companyId,
      toolId: resourceId,
    });
  }

  async getTypologies(companyId: string) {
    return await this.getTypologiesUseCase.Call({
      companyId,
      type: DocumentTypeResourceType.TOOL,
    });
  }
}

export { ToolDocumentsViewModel };
