import { injectable } from 'tsyringe';
import Site, { ResourceSites, SiteState } from '../../domain/entities/site';
import Typology from '../../domain/entities/typology';
import DocumentType, { RenameModel } from '../../domain/entities/documentType';
import Document, { SiteResourceDocument } from '../../domain/entities/document';
import Requirement from '../../domain/entities/requirement';
import SiteRepository, {
	DocumentTypeAdd,
	GetResourceSitesFilter,
	GetSitesFilter,
	LinkResourcesToSiteWarning,
} from '../../domain/repositories/siteRepository';
import RequirementsGroup from '../../domain/entities/requirementsGroup';
import { PaginatedResults, SortMeta } from '../../domain/entities/interfaces/paginatedResults';
import { GetDocumentsFilter } from '../../domain/repositories/documentRepository';
import { removeEmptyAttributes } from '../../utils';
import { RequirementSubject } from '../../domain/entities/requirementSubject.enum';
import SiteOptions, { ScheduleEmail } from '../../domain/entities/siteOptions';
import { Evaluable } from '../../domain/entities/evaluable.enum';
import PendingEvaluation from '../../domain/entities/pending-evaluation';
import { GetPendingEvaluationsResponse, mapPendingEvaluationsResponse } from '../responses/sites';
import { ApiService } from '../utilities/apiService';
import { dateIntervals } from '../utilities/filters';
import Variant from '../../domain/entities/variant';
import { PendingEvaluationsFilters } from '../../presentation/hooks/Site/useSiteApproveEvaluationsViewModel';
import { DocumentTypeCategory } from '../../domain/entities/documentTypeCategory.enum';
import { GetSiteChemicalsResponse } from '../responses/siteChemicals';
import { mapSiteChemical, SiteChemical } from '../../domain/entities/siteChemical';
import { GetSiteChemicalsFilters } from '../../domain/repositories/siteChemicalRepository';
import { GetSiteWorkersResponse } from '../responses/siteWorkers';
import { mapSiteWorker, SiteWorker } from '../../domain/entities/siteWorker';
import { GetSiteWorkersFilters } from '../../domain/repositories/siteWorkerRepository';
import { GetSiteMachinesResponse } from '../responses/siteMachines';
import { mapSiteMachine, SiteMachine } from '../../domain/entities/siteMachine';
import { GetSiteMachinesFilters } from '../../domain/repositories/siteMachineRepository';
import { GetSiteToolsFilters } from '../../domain/repositories/siteToolRepository';
import { mapSiteTool, SiteTool } from '../../domain/entities/siteTool';
import { GetSiteToolsResponse } from '../responses/siteTools';
import { GetSiteVehiclesResponse } from '../responses/siteVehicles';
import { mapSiteVehicle, SiteVehicle } from '../../domain/entities/siteVehicle';
import { GetSiteVehiclesFilters } from '../../domain/repositories/siteVehicleRepository';
import { ResourceType } from '../../presentation/screens/Site/ResourceSelectableTable';
import { mapApiResponseToDocument } from '../adapters/getResourceDocuments';

@injectable()
class ServerSiteRepository implements SiteRepository {
	constructor(private apiService: ApiService) {}

	private baseURL = (companyId: string): string => {
		return `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}`;
	};

	// Public.
	async getSites(companyId: string, filter?: GetSitesFilter, sort?: SortMeta, pageParam?: number, withPermissions?: boolean): Promise<Site[]> {
		const params = new URLSearchParams({
			withPermissions: withPermissions ? 'true' : 'false',
			...(pageParam ? { pageParam: pageParam.toString(), perPage: String(25) } : {}),
			...filter,
			...sort,
		});
		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/sites/owned?${params.toString()}`);
		const { results } = await response.json();
		return results;
	}

	async getSitesForRoleAssignment(companyId: string): Promise<Site[]> {
		// FIXME: for now, this call is made whenever a user wants to assign
		//        a site-role to a user it controls for a site it owns.
		//        Future iterations should take into consideration suppliers and sub-suppliers authorization workflows.
		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/sites/owned`);
		const { results } = await response.json();
		return results;
	}

	async getSite(companyId: string, siteId: string): Promise<Site> {
		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/sites/${siteId}`);
		return response.json();
	}

	async updateSite(companyId: string, site: Site, image?: File): Promise<Site> {
		const data = new FormData();
		const params = new Set();
		if (image) {
			data.append('photo', image);
			params.add('photo');
		}

		Object.keys(site).map((p) => {
			if (!params.has(p) && p !== 'photo') {
				data.append(p, site[p]);
				params.add(p);
			}
		});

		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/sites/${site.id}`, { method: 'POST', body: data });

		return response.json();
	}

	async updateState(companyId: string, siteId: string, state: SiteState): Promise<void> {
		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/sites/${siteId}?state=${state}`, { method: 'PUT' });

		return response.json();
	}

	async createSite(
		companyId: string,
		site: Site,
		typology?: Typology,
		requirementsGroup?: RequirementsGroup,
		companyVariant?: Variant,
		siteVariant?: Variant
	): Promise<Site> {
		const { state, ...siteWithoutState } = site;

		const payload = removeEmptyAttributes({
			...siteWithoutState,
			typologyId: typology?.id,
			requirementsGroupId: requirementsGroup?.id,
			companyVariantId: companyVariant.id,
			siteVariantId: siteVariant.id,
		});

		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/sites`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(payload),
		});

		return response.json();
	}

	async addRequirementToSite(
		companyId: string,
		siteId: string,
		documents: DocumentTypeAdd[],
		requirementSubject: RequirementSubject
	): Promise<Requirement> {
		const urlAddRequirement = `${this.baseURL(companyId)}/sites/${siteId}/${requirementSubject}-requirements/`;

		const mappedRequirements = documents.map((document) => ({
			documentTypeId: document.documentTypeId,
			isOptional: document.isOptional,
			graceDays: document.graceDays,
			variantsIds: document.variants.map((obj) => obj.id),
			specializationsIds: document.specializations.map((obj) => obj.id),
		}));

		const response = await this.apiService.fetchWithToken(urlAddRequirement, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ requirements: mappedRequirements }),
		});
		return response.json();
	}

	async addRequirementToWorkingSite(
		companyId: string,
		siteId: string,
		documentTypeId: string,
		isOptional: boolean,
		graceDays: number,
		requirementSubject: RequirementSubject
	): Promise<Requirement> {
		const urlAddRequirement =
			requirementSubject !== 'company' && requirementSubject !== 'site'
				? `${this.baseURL(companyId)}/sites/${siteId}/requirements`
				: `${this.baseURL(companyId)}/sites/${siteId}/suppliers/${companyId}/${requirementSubject}-requirements`;

		const response = await this.apiService.fetchWithToken(urlAddRequirement, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ resourceType: requirementSubject, documentTypeId: documentTypeId, isOptional: isOptional, graceDays: graceDays }),
		});
		return response.json();
	}

	async removeRequirementFromSite(companyId: string, siteId: string, requirementId: string, subject: RequirementSubject): Promise<void> {
		await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/sites/${siteId}/${subject}-requirements/${requirementId}`, {
			method: 'DELETE',
		});
	}

	async getDocuments(companyId: string, siteId: string, filter?: GetDocumentsFilter, sort?: SortMeta, pageParam?: number): Promise<Document[]> {
		const { tags, expiresAt, ...restFilter } = filter || {};
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...restFilter,
			...dateIntervals(expiresAt),
			...sort,
		});
		tags?.length > 0 && tags.forEach((tag) => params.append('tagIds[]', tag));

		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/sites/${siteId}/documents?${params.toString()}`);
		const documents = await response.json();

		const mappedDocuments: Document[] = documents.results ? documents.results.map(mapApiResponseToDocument) : [];
		return mappedDocuments;
	}

	async createDocument(companyId: string, siteId: string, documents: string[]): Promise<void> {
		try {
			await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/sites/${siteId}/documents`, {
				method: 'POST',
				body: JSON.stringify({ documentTypesIds: documents }),
				headers: { 'Content-Type': 'application/json' },
			});
		} catch (e) {
			return Promise.reject(e);
		}
	}

	async getWorkerSites(
		companyId: string,
		workerId: string,
		filter: GetResourceSitesFilter,
		sort?: SortMeta,
		pageParam?: number,
		includeWorkingSites?: boolean
	): Promise<ResourceSites> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...filter,
			...sort,
		});
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/workers/${workerId}/sites?${params.toString()}&includeWorkingSites=${
				includeWorkingSites ? 1 : 0
			}`
		);
		if (!response.ok) throw new Error('Error fetching worker sites');
		const { results, count } = await response.json();
		return { results, count };
	}

	async getMachineSites(
		companyId: string,
		machineId: string,
		filter?: GetResourceSitesFilter,
		sort?: SortMeta,
		pageParam?: number,
		includeWorkingSites?: boolean,
		ignoreUserSiteRoleRestrictions?: boolean
	): Promise<ResourceSites> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...filter,
			...sort,
		});

		if (ignoreUserSiteRoleRestrictions) {
			params.append('ignoreUserSiteRoleRestrictions', 'true');
		}

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/machines/${machineId}/sites?${params.toString()}&includeWorkingSites=${
				includeWorkingSites ? 1 : 0
			}`
		);
		if (!response.ok) throw new Error('Error fetching machine sites');
		const { results, count } = await response.json();
		return { results, count };
	}

	async getVehicleSites(
		companyId: string,
		vehicleId: string,
		filter?: GetResourceSitesFilter,
		sort?: SortMeta,
		pageParam?: number,
		includeWorkingSites?: boolean,
		ignoreUserSiteRoleRestrictions?: boolean
	): Promise<ResourceSites> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...filter,
			...sort,
		});

		if (ignoreUserSiteRoleRestrictions) {
			params.append('ignoreUserSiteRoleRestrictions', 'true');
		}

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/vehicles/${vehicleId}/sites?${params.toString()}&includeWorkingSites=${
				includeWorkingSites ? 1 : 0
			}`
		);
		if (!response.ok) throw new Error('Error fetching vehicle sites');
		const { results, count } = await response.json();
		return { results, count };
	}

	async getToolSites(
		companyId: string,
		toolId: string,
		filter?: GetResourceSitesFilter,
		sort?: SortMeta,
		pageParam?: number,
		includeWorkingSites?: boolean
	): Promise<ResourceSites> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...filter,
			...sort,
		});
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/tools/${toolId}/sites?${params.toString()}&includeWorkingSites=${
				includeWorkingSites ? 1 : 0
			}`
		);
		if (!response.ok) throw new Error('Error fetching tool sites');
		const { results, count } = await response.json();
		return { results, count };
	}

	async getChemicalSites(
		companyId: string,
		chemicalId: string,
		filter?: GetResourceSitesFilter,
		sort?: SortMeta,
		pageParam?: number,
		includeWorkingSites?: boolean
	): Promise<ResourceSites> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...filter,
			...sort,
		});
		const response = await this.apiService.fetchWithToken(
			`${
				process.env.REACT_APP_SERVER_API_ENDPOINT
			}/companies/${companyId}/chemicals/${chemicalId}/sites?${params.toString()}&includeWorkingSites=${includeWorkingSites ? 1 : 0}`
		);
		if (!response.ok) throw new Error('Error fetching chemical sites');
		const { results, count } = await response.json();
		return { results, count };
	}

	async getSiteOptions(companyId: string, siteId: string): Promise<SiteOptions> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/options`
		);
		return await response.json();
	}

	async getScheduleEmail(companyId: string, siteId: string): Promise<ScheduleEmail[]> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/scheduled-notifications`
		);
		const { results } = await response.json();
		return results;
	}

	async updateSiteOptions(companyId: string, siteId: string, options: SiteOptions): Promise<void> {
		try {
			const response = await this.apiService.fetchWithToken(
				`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/options`,
				{
					method: 'PUT',
					body: JSON.stringify(options),
					headers: { 'Content-Type': 'application/json' },
				}
			);

			if (!response.ok) {
				if (response.status === 422) {
					const error = 'selectABadge';
					return Promise.reject(new Error(error));
				}
				const { message } = await response.json();
				return Promise.reject(new Error(message));
			}
			return await response.json();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	async updateScheduleEmail(companyId: string, siteId: string, options: ScheduleEmail): Promise<void> {
		try {
			const response = await this.apiService.fetchWithToken(
				`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/scheduled-notifications`,
				{
					method: 'PUT',
					body: JSON.stringify(options),
					headers: { 'Content-Type': 'application/json' },
				}
			);

			if (!response.ok) {
				if (response.status === 422) {
					const error = 'emailIsRequired';
					return Promise.reject(new Error(error));
				}
				const { message } = await response.json();
				return Promise.reject(new Error(message));
			}
		} catch (e) {
			return Promise.reject(e);
		}
	}

	async approvePendingEvaluation(
		companyId: string,
		siteId: string,
		resourceId: string,
		evaluationId: string,
		type: Evaluable,
		isApproved: boolean
	): Promise<void> {
		await this.apiService.fetchWithToken(
			`${this.baseURL(companyId)}/sites/${siteId}/${evaluablePathMap[type]}/${resourceId}/pending-evaluations/${evaluationId}`,
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ isApproved }),
			}
		);
	}

	async getPendingEvaluations(
		companyId: string,
		siteId: string,
		type: Evaluable,
		filters: PendingEvaluationsFilters,
		sort: SortMeta,
		pageParam: number
	): Promise<PendingEvaluation[]> {
		const filtersWithResource: PendingEvaluationsFilters = { ...filters };

		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...filtersWithResource,
			...sort,
		});

		const response = await this.apiService.fetchWithToken(
			`${this.baseURL(companyId)}/sites/${siteId}/${evaluablePathMap[type]}/pending-evaluations?${params}`
		);
		const { results }: GetPendingEvaluationsResponse = await response.json();
		return mapPendingEvaluationsResponse(results, type);
	}

	async getSiteSupplierDocuments(
		companyId: string,
		siteId: string,
		resourceType: DocumentTypeCategory,
		supplierId: string,
		pageParam: number
	): Promise<PaginatedResults<SiteResourceDocument>> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: '25',
		});

		const response = await this.apiService.fetchWithToken(
			`${this.baseURL(companyId)}/sites/${siteId}/suppliers/${supplierId}/${resourceType}-requirements?${params}`
		);

		const requirements = await response.json();
		return requirements;
	}

	async getSiteSupplierResourcesDocuments(
		companyId: string,
		siteId: string,
		resourceType: DocumentTypeCategory,
		supplierId: string,
		pageParam: number
	): Promise<PaginatedResults<DocumentType>> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: '25',
		});

		const response = await this.apiService.fetchWithToken(
			`${this.baseURL(companyId)}/sites/${siteId}/suppliers/${supplierId}/requirements/${resourceType}/document-types?${params}`
		);

		const requirements = await response.json();
		return requirements;
	}

	async getSiteSupplierChemicals(
		companyId: string,
		siteId: string,
		pageParam: number,
		filterSiteResources: GetSiteChemicalsFilters,
		siteResourcesSort: SortMeta,
		supplierId: string
	): Promise<PaginatedResults<SiteChemical>> {
		const { variantIds, specializationIds, ...restFilter } = filterSiteResources || {};
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: (25).toString(),
			...(supplierId !== undefined && supplierId !== null ? { companyId: supplierId } : {}),
			...restFilter,
			...(variantIds ? { variantIds: variantIds.join(',') } : {}),
			...(specializationIds ? { specializationIds: specializationIds.join(',') } : {}),
			...siteResourcesSort,
		});

		// if (archived === true) {
		//   params.append("archived", "true");
		// }

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/chemicals?${params.toString()}`
		);
		const data: GetSiteChemicalsResponse = await response.json();
		return {
			...data,
			results: data.results.map(mapSiteChemical(siteId)),
		};
	}

	async getSiteSupplierWorkers(
		companyId: string,
		siteId: string,
		pageParam: number,
		filterSiteResources: GetSiteWorkersFilters,
		siteResourcesSort: SortMeta,
		supplierId: string
	): Promise<PaginatedResults<SiteWorker>> {
		const { badgeAvailable, variantIds, specializationIds, ...restFilter } = filterSiteResources || {};
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: (25).toString(),
			...(supplierId ? { companyId: supplierId } : {}),
			...restFilter,
			...(variantIds ? { variantIds: variantIds.join(',') } : {}),
			...(specializationIds ? { specializationIds: specializationIds.join(',') } : {}),
			...(badgeAvailable ? { badgeAvailable: badgeAvailable ? '1' : '0' } : {}),
			...siteResourcesSort,
		});

		// if (archived === true) {
		//   params.append("archived", "true");
		// }

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/workers?${params.toString()}`
		);
		const data: GetSiteWorkersResponse = await response.json();
		return {
			...data,
			results: data.results.map(mapSiteWorker(siteId)),
		};
	}

	async getSiteSupplierMachines(
		companyId: string,
		siteId: string,
		pageParam: number,
		filterSiteResources: GetSiteMachinesFilters,
		siteResourcesSort: SortMeta,
		supplierId: string
	): Promise<PaginatedResults<SiteMachine>> {
		const { badgeAvailable, variantIds, specializationIds, ...restFilter } = filterSiteResources || {};
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: (25).toString(),
			...(supplierId !== undefined && supplierId !== null ? { companyId: supplierId } : {}),
			...restFilter,
			...(variantIds ? { variantIds: variantIds.join(',') } : {}),
			...(specializationIds ? { specializationIds: specializationIds.join(',') } : {}),
			...(badgeAvailable ? { badgeAvailable: badgeAvailable ? '1' : '0' } : {}),
			...siteResourcesSort,
		});

		// if (archived === true) {
		//   params.append("archived", "true");
		// }

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/machines?${params.toString()}`
		);
		const data: GetSiteMachinesResponse = await response.json();
		return {
			...data,
			results: data.results.map(mapSiteMachine(siteId)),
		};
	}

	async getSiteSupplierTools(
		companyId: string,
		siteId: string,
		pageParam: number,
		filterSiteResources: GetSiteToolsFilters,
		siteResourcesSort: SortMeta,
		supplierId: string
	): Promise<PaginatedResults<SiteTool>> {
		const { variantIds, specializationIds, ...restFilter } = filterSiteResources || {};
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: (25).toString(),
			...(supplierId !== undefined && supplierId !== null ? { companyId: supplierId } : {}),
			...restFilter,
			...(variantIds ? { variantIds: variantIds.join(',') } : {}),
			...(specializationIds ? { specializationIds: specializationIds.join(',') } : {}),
			...siteResourcesSort,
		});

		// if (archived === true) {
		//   params.append("archived", "true");
		// }

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/tools?${params.toString()}`
		);
		const data: GetSiteToolsResponse = await response.json();
		return {
			...data,
			results: data.results.map(mapSiteTool(siteId)),
		};
	}

	async getSiteSupplierVehicles(
		companyId: string,
		siteId: string,
		pageParam: number,
		filterSiteResources: GetSiteVehiclesFilters,
		siteResourcesSort: SortMeta,
		supplierId: string
	): Promise<PaginatedResults<SiteVehicle>> {
		const { badgeAvailable, variantIds, specializationIds, ...restFilter } = filterSiteResources || {};
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: (25).toString(),
			...(supplierId !== undefined && supplierId !== null ? { companyId: supplierId } : {}),
			...restFilter,
			...(variantIds ? { variantIds: variantIds.join(',') } : {}),
			...(specializationIds ? { specializationIds: specializationIds.join(',') } : {}),
			...(badgeAvailable ? { badgeAvailable: badgeAvailable ? '1' : '0' } : {}),
			...siteResourcesSort,
		});

		// if (archived === true) {
		//   params.append("archived", "true");
		// }

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/vehicles?${params.toString()}`
		);
		const data: GetSiteVehiclesResponse = await response.json();
		return {
			...data,
			results: data.results.map(mapSiteVehicle(siteId)),
		};
	}

	async getResourceLinkableSites(
		companyId: string,
		resourceType?: string,
		resourceId?: string,
		filters?: GetSitesFilter,
		sort?: SortMeta,
		pageParam?: number
	): Promise<Site[]> {
		const { ...restFilter } = filters || {};
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: (25).toString(),
			...restFilter,
			...sort,
		});
		const url = resourceId ? `${resourceType}s/${resourceId}/assignableSites` : `${resourceType}s/assignableSites`;

		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/${url}?${params.toString()}`);
		const { results } = await response.json();
		return results;
	}

	async addResourceToSites(companyId: string, resourceType: string, resourceId: string, siteIds: string[]): Promise<void> {
		await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/${resourceType}s/${resourceId}/addSites`, {
			method: 'POST',
			body: JSON.stringify({ siteIds }),
			headers: { 'Content-Type': 'application/json' },
		});
	}
	async addResourcesToSites(
		companyId: string,
		resourceType: ResourceType,
		resourceIds: string[],
		siteIds: string[],
		selectAllSites?: boolean,
		selectAllResources?: boolean
	): Promise<LinkResourcesToSiteWarning> {
		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/${resourceType}s/addSites/bulk`, {
			method: 'POST',
			body: JSON.stringify({ selectAllSites, selectAllResources, resourceIds, siteIds }),
			headers: { 'Content-Type': 'application/json' },
		});
		return response.json();
	}
}

const evaluablePathMap = {
	[Evaluable.COMPANY]: 'suppliers',
	[Evaluable.WORKER]: 'workers',
	[Evaluable.VEHICLE]: 'vehicles',
	[Evaluable.MACHINE]: 'machines',
	[Evaluable.TOOL]: 'tools',
	[Evaluable.CHEMICAL]: 'chemicals',
};

export default ServerSiteRepository;
