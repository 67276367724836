import { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { EditableFormFieldProps } from "./FormField";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import { format, isValid, parse, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import { getDateFormat } from "../../../../utils";
import CustomDateInput from "../../Views/common/CustomDateInput/CustomDateInput";

interface FormDateFieldProps extends EditableFormFieldProps {
  minDate?: Date;
  maxDate?: Date;
  selectedBackgroundColor?: string;
  selectedTextColor?: string;
}
const kRequiredFieldSymbol = "*";

const FormDateField: FC<FormDateFieldProps> = ({
  name, label, isEditing = false, rules, onChange, minDate, maxDate, hideRequiredMark,
  selectedBackgroundColor, selectedTextColor
}) => {
  const { t } = useTranslation("common");
  const {
    register,
    setValue,
    formState: { errors },
    setError,
    watch,
  } = useFormContext();
  const errorMessage = errors[name]?.message?.toString();
  const inputProps = register(name, rules);
  const currentValue = watch(name);
  const requiredMark = hideRequiredMark ? "" : ` ${kRequiredFieldSymbol}`;
  const decoratedLabel =
    rules && isEditing && !hideRequiredMark ? `${label}${requiredMark}` : label;
  const [dateError, setDateError] = useState<boolean>();
  const [inputValue, setInputValue] = useState<string>();

  const formatDate = (rawDate: string | Date): string | undefined => {
    if (typeof rawDate === "string") {
      const date = parseISO(rawDate);
      if (isValid(date)) {
        return format(date, getDateFormat());
      }
    } else if (rawDate instanceof Date && isValid(rawDate)) {
      return format(rawDate, getDateFormat());
    }
    return undefined;
  };
  const handleChange = (date: string | null) => {
    if (date) {
      const parsedDate = parse(date, getDateFormat(), new Date());
      if (isValid(parsedDate)) {
        const formattedDate = format(parsedDate, "yyyy-MM-dd");
        setValue(name, formattedDate, { shouldValidate: true });
        onChange?.();
      }
    } else {
      setValue(name, null, { shouldValidate: true });
      onChange?.();
    }
  };
  const labelStyles = { backgroundColor: isEditing ? selectedBackgroundColor : "white", color: isEditing ? selectedTextColor : "black", borderRadius: 3 };
  useEffect(() => {
    if (dateError) {
      setError(name, {
        type: "error",
        message: t("invalidDateError", { ns: "common", date: inputValue }),
      });
    }
  }, [dateError]);

  return (
    <FormControl isInvalid={errors[name] !== undefined} variant="floating">
      {isEditing ? (
        <CustomDateInput
          {...inputProps}
          value={formatDate(currentValue)}
          onChange={handleChange}
          minDate={minDate}
          maxDate={maxDate}
          isClearable={!rules}
          name={name}
          hasError={setDateError}
          setValue={setInputValue}
          textColorSelected={selectedTextColor}
          backgroundColorSelected={selectedBackgroundColor}
        />
      ) : (
        <Text ml="17px" mt="8px" pt={2}>
          {formatDate(currentValue) || "-"}
        </Text>
      )}
      <FormLabel zIndex={1} style={labelStyles}>{decoratedLabel}</FormLabel>
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
};

export default FormDateField;
