import UseCase from '../../../core/app/usecase';
import Supplier from '../../entities/supplier';
import SupplierRepository from '../../repositories/supplierRepository';
import SubcontractorsParameters from './subcontractorParameters';

class DeleteSubcontractor implements UseCase<SubcontractorsParameters, void> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ clientId, siteId, subcontractorId }: SubcontractorsParameters): Promise<void> {
		return await this.supplierRepository.deleteSubcontractor(clientId, siteId, subcontractorId);
	}
}

export default DeleteSubcontractor;
