import ContentLayout from "../../../layout/ContentLayout";
import { Evaluable } from "../../../../domain/entities/evaluable.enum";
import useSiteApproveEvaluationsViewModel, {
  PendingEvaluationsFilters,
} from "../../../hooks/Site/useSiteApproveEvaluationsViewModel";
import { useParams } from "react-router-dom";
import {
  Flex,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { EvaluationButton } from "../../Common/ResourceEvaluationToggle";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ActionButton from "../../Common/table/ActionButton";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import PendingEvaluation from "../../../../domain/entities/pending-evaluation";
import { COLORS } from "../../../assets/theme/colors";
import { ConfirmAlert } from "../../../screens/Common/ConfirmAlert";
import InfiniteTable from "../../Common/table/InfiniteTable";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import ColumnFilterComponent from "../../Common/table/ColumnFilterComponent";
import { ResourceDocumentEvaluationState } from "../../../../domain/entities/resourceDocumentEvaluationState.enum";
import RenderIf from "../../Permissions/RenderIf";
import { Permission } from "../../Permissions/Permissions";
import SearchInput from "../../../screens/Common/SearchInput";

type Props = {
  type: Evaluable;
};
const SiteApproveEvaluationsResourceView = ({ type }: Props) => {
  const { siteId } = useParams();
  const {
    evaluations,
    evaluationsIsLoading,
    pendingEvaluationsHasNextPage,
    pendingEvaluationsFetchNextPage,
    filterEvaluations,
    setFilterEvaluations,
    updateFilterEvaluations,
    sort,
    setSort,
    approve,
    approveIsLoading,
    setSearch,
  } = useSiteApproveEvaluationsViewModel(siteId, type);
  const { t } = useTranslation("sites");

  const [showConfirmAlert, setShowConfirmAlert] = useState<boolean>(false);
  const [selectedEvaluation, setSelectedEvaluation] = useState<{
    item: PendingEvaluation;
    value: boolean;
  }>(undefined);

  const validateEvaluation = async (
    item: PendingEvaluation,
    isApproved: boolean
  ) => {
    await approve({
      resourceId:
        type === Evaluable.COMPANY ? item.company.id : item.resource.id,
      evaluationId: item.id,
      isApproved,
    });
  };

  const fieldEvaluations = filterEvaluations ?? {
    company: "",
    requirementId: "",
    result: "",
  };

  const columns: {
    field: keyof PendingEvaluationsFilters;
    type: "text" | "select" | "date-range";
    options?: Record<string, string>;
  }[] = [{ field: "company", type: "text" }];

  if (type !== Evaluable.COMPANY) {
    if (type !== Evaluable.WORKER) {
      columns.push({ field: "resource", type: "text" });
    } else {
      columns.push({ field: "lastName", type: "text" });
      columns.push({ field: "firstName", type: "text" });
    }
  }

  columns.push(
    { field: "requirement", type: "text" },
    {
      field: "result",
      type: "select",
      options: ResourceDocumentEvaluationState,
    }
  );

  useEffect(() => {
    setFilterEvaluations({});
  }, [type]);

  return (
    <ContentLayout>
      <Flex
        justifyContent={"space-between"}
        alignItems="center"
        w={"100%"}
        marginTop={10}
        width={"calc(100vw - 220px)"}
        marginLeft={10}
      >
        <Text>{t("subtitle", { ns: "sites" })}</Text>
        <SearchInput onSearch={setSearch} />
      </Flex>
      <Flex
        flexDirection={"column"}
        alignItems={"start"}
        border="1px solid"
        borderColor="gray.300"
        borderRadius="10px"
        width={"calc(100vw - 220px)"}
        marginLeft={10}
        marginRight={10}
        position="relative"
        overflow={"hidden"}
        mt={4}
      >
        <InfiniteTable
          autosize
          infiniteScroll={{
            dataLength: evaluations.length,
            hasNextPage: pendingEvaluationsHasNextPage,
            fetchNextPage: pendingEvaluationsFetchNextPage,
          }}
          isLoading={evaluationsIsLoading}
          tableId="site-approved-evaluations"
          showEmptyText={evaluations?.length === 0}
          emptyText={t("noEvaluations", { ns: "sites" })}
        >
          <Thead>
            <Tr>
              {columns.map((c) => (
                <Th key={c.field}>
                  <TableColumnHeader
                    text={t(`${c.field}`, { ns: "sites" })}
                    filter={
                      c.field != "result" && {
                        isActive:
                          !!fieldEvaluations[c.field] &&
                          (!Array.isArray(fieldEvaluations[c.field]) ||
                            !!fieldEvaluations[c.field][0]),
                        component: (
                          <ColumnFilterComponent
                            value={fieldEvaluations[c.field]}
                            type={c.type}
                            updateFilter={(value) =>
                              updateFilterEvaluations(c.field, value)
                            }
                            namespace="enum"
                            selectOptions={c.options}
                          />
                        ),
                      }
                    }
                    sort={
                      c.field != "result" && {
                        handler: (direction) =>
                          setSort({ field: c.field, direction }),
                        direction:
                          sort?.field === c.field ? sort.direction : null,
                      }
                    }
                  />
                </Th>
              ))}
              <Th width={"150px"} />
            </Tr>
          </Thead>
          <Tbody borderRadius="xl">
            {evaluations &&
              evaluations.map((item) => (
                <Tr key={item.id}>
                  <Td>{item.company.name}</Td>
                  {type !== Evaluable.COMPANY && type !== Evaluable.WORKER && (
                    <Td>{item.resource.name}</Td>
                  )}
                  {type === Evaluable.WORKER && (
                    <Td>{item.resource.lastName}</Td>
                  )}
                  {type === Evaluable.WORKER && (
                    <Td>{item.resource.firstName}</Td>
                  )}
                  <Tooltip
                    label={item.evaluation.resource.type.description}
                    placement="bottom-start"
                    openDelay={500}
                  >
                    <Td>
                      {item.evaluation.resource.type.name}{" "}
                      {!item.isOptional ? (
                        <span style={{ color: COLORS.error }}>*</span>
                      ) : (
                        ""
                      )}
                    </Td>
                  </Tooltip>
                  <Td justifyContent="center">
                    <EvaluationButton
                      value={item.evaluation.result}
                      evaluationType="document"
                    />
                  </Td>
                  <RenderIf
                    permissions={Permission.Sites_ConfirmPendingEvals}
                    fallback={
                      <Td
                        sx={{ justifyContent: "center", gap: "10px" }}
                        width="150px"
                      />
                    }
                  >
                    <Td
                      sx={{ justifyContent: "center", gap: "10px" }}
                      width="150px"
                    >
                      <Tooltip
                        label={t("approveEvaluationTooltip", { ns: "sites" })}
                      >
                        <span>
                          <ActionButton
                            aria-label="approve"
                            onClick={() => {
                              setSelectedEvaluation({ item, value: true });
                              setShowConfirmAlert(true);
                            }}
                            icon={
                              <AiFillCheckCircle
                                color={COLORS.sikuroBlue}
                                size="30px"
                              />
                            }
                          />
                        </span>
                      </Tooltip>
                      <Tooltip
                        label={t("rejectEvaluationTooltip", { ns: "sites" })}
                      >
                        <span>
                          <ActionButton
                            aria-label="abort"
                            onClick={() => {
                              setSelectedEvaluation({ item, value: false });
                              setShowConfirmAlert(true);
                            }}
                            icon={
                              <AiFillCloseCircle
                                color={COLORS.error}
                                size="30px"
                              />
                            }
                          />
                        </span>
                      </Tooltip>
                    </Td>
                  </RenderIf>
                </Tr>
              ))}
          </Tbody>
        </InfiniteTable>
        {showConfirmAlert && (
          <ConfirmAlert
            title={t("attention", { ns: "common" })}
            message={
              selectedEvaluation.value
                ? t("confirmEvaluation")
                : t("rejectEvaluation")
            }
            variant="warning"
            closeClickingOutside={false}
            onCancel={() => {
              setSelectedEvaluation(undefined);
              setShowConfirmAlert(false);
            }}
            onConfirm={async () => {
              await validateEvaluation(
                selectedEvaluation.item,
                selectedEvaluation.value
              );
              setSelectedEvaluation(undefined);
              setShowConfirmAlert(false);
            }}
            isLoading={approveIsLoading}
            isCloseDisabled={approveIsLoading}
          />
        )}
      </Flex>
    </ContentLayout>
  );
};

export default SiteApproveEvaluationsResourceView;
