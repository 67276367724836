import { useAuth } from "../../../providers/Auth0JWTProvider";
import Chemical from "../../../../domain/entities/chemical";
import { ChemicalViewModel } from "../../../viewmodels/chemicals/ChemicalViewModel";
import { useMemo, useState } from "react";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { GetSitesFilter } from "../../../../domain/repositories/siteRepository";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import { updateFilterWithDelete } from "../../../../utils";
import { ResourceType } from "../../../screens/Site/ResourceSelectableTable";

const useChemicalViewModel = (id: string) => {
  const { companyId } = useAuth();
  const [sortResourceSites, setSortResourceSites] = useState<SortMeta>();
  const [filterResourceSites, setFilterResourceSites] =
    useState<GetSitesFilter>();
  const [sortSiteCollection, setSortSiteCollection] = useState<SortMeta>();
  const [filterSiteCollection, setFilterSiteCollection] =
    useState<GetSitesFilter>();
  const [selectAll, setSelectAll] = useState(false);
  const [enableGetResourceSites, setEnableGetResourceSites] = useState<{
    getSites: boolean;
    action: string;
  }>({ getSites: false, action: "" });
  const viewModel = useMemo(() => new ChemicalViewModel(), []);
  const [act, setAct] = useState<string>();
  const queryClient = useQueryClient();

  const {
    data: chemical,
    isFetching,
    refetch: getChemical,
  } = useQuery<Chemical, Error>(
    ["chemical", companyId, id],
    async () => await viewModel.get(companyId, id),
    {
      retry: false,
      initialData: undefined,
    },
  );

  const updateMutation = useMutation(
    ({ chemical, imageFile }: { chemical: Chemical; imageFile?: File }) =>
      viewModel.update(companyId, chemical, imageFile),
    {
      onError: (err) => {
        console.error(err);
      },
      onSuccess: () => getChemical(),
    },
  );

  const updateChemical = (chemical: Chemical) => {
    return updateMutation.mutateAsync({ chemical });
  };

  const updateImage = (chemical: Chemical, imageFile?: File) => {
    return updateMutation.mutateAsync({
      chemical,
      imageFile,
    });
  };

  const getChemicalSites = useInfiniteQuery(
    ["resource-sites", companyId, id, sortResourceSites, filterResourceSites],
    async ({ pageParam = 1 }) => {
      const includeWorkingSites = act === "add";
      return await viewModel.getChemicalSites(
        companyId,
        id,
        sortResourceSites,
        filterResourceSites,
        pageParam,
        includeWorkingSites,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.results.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: !!sortResourceSites || !!filterResourceSites,
    },
  );
  const hasSites = async (action) => {
    setAct(action);
    await queryClient.invalidateQueries([
      "resource-sites",
      companyId,
      id,
      sortResourceSites,
      filterResourceSites,
      action,
    ]);
    const data = await getChemicalSites.refetch();
    const sites = data.data?.pages.flatMap((page) => page.results) ?? [];
    return sites.length > 0;
  };

  const getResourceLinkableSites = useInfiniteQuery(
    [
      "site-collection",
      companyId,
      id,
      sortSiteCollection,
      filterSiteCollection,
    ],
    async ({ pageParam = 1 }) => {
      return await viewModel.getResourceLinkableSites(
        companyId,
        "chemical" as ResourceType,
        id,
        sortSiteCollection,
        filterSiteCollection,
        pageParam,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    },
  );
  const linkChemicalToSites = (siteIds: string[]) => {
    linkChemicalToSitesMutation.mutateAsync(siteIds);
  };
  const linkChemicalToSitesMutation = useMutation(
    async (siteIds: string[]) => {
      return await viewModel.linkResourceToSites(companyId, id, siteIds);
    },
    {
      onError: (e) => console.error(e),
      onSuccess: () => getChemical(),
    },
  );

  const resourceSites =
    getChemicalSites.data?.pages?.flatMap((page) => page.results) ?? [];
  const totalSiteCount = getChemicalSites.data?.pages?.[0]?.count ?? 0;

  return {
    chemical,
    getChemical,
    isFetching,
    updateImage,
    updateChemical,
    resourceSites,
    isFetchingSites: getChemicalSites.isFetching,
    hasNextPage: getChemicalSites.hasNextPage,
    fetchNextPage: getChemicalSites.fetchNextPage,
    setEnableGetResourceSites,
    updateFilterResourceSites: (field, value) => {
      updateFilterWithDelete(setFilterResourceSites, field, value);
    },
    filterResourceSites,
    setSortResourceSites,
    sortResourceSites,
    hasSites,
    siteCollection: getResourceLinkableSites.data?.pages?.flat() ?? [],
    sortSiteCollection,
    setSortSiteCollection,
    filterSiteCollection,
    updateFilterSiteCollection: (field, value) => {
      updateFilterWithDelete(setFilterSiteCollection, field, value);
    },
    siteCollectionHasNextPage: getResourceLinkableSites.hasNextPage,
    siteCollectionFetchNextPage: getResourceLinkableSites.fetchNextPage,
    refetchSiteCollection: getResourceLinkableSites.refetch,
    linkChemicalToSites,
    isLinking: linkChemicalToSitesMutation.isLoading,
    totalSiteCount,
    selectAll,
    setSelectAll,
  };
};

export default useChemicalViewModel;
