import { useParams } from "react-router-dom";
import { Permission } from "../../components/Permissions/Permissions";
import { SiteWorkerParams } from "../../../domain/entities/siteWorker";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import SiteResourcesListView from "../../components/Views/Sites/SiteResourcesListView";
import useWorkingSiteWorkersViewModel from "../../hooks/WorkingSite/useWorkingSiteWorkersViewModel";

const SiteWorkersView = () => {
  const { siteId } = useParams();
  const resourceColumns = ["lastName", "firstName", "fiscalCode", "jobTitle"];
  return (
    <SiteResourcesListView<SiteWorkerParams>
      type={DocumentTypeResourceType.WORKER}
      useViewModel={useWorkingSiteWorkersViewModel(
        siteId,
        resourceColumns,
        DocumentTypeResourceType.WORKER,
      )}
      resourceColumns={resourceColumns}
      addToSitePermissions={[Permission.Worksite_AttachWorker]}
      removeFromSitePermissions={[Permission.Worksite_DetachWorker]}
    />
  );
};

export default SiteWorkersView;
