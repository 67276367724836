import UseCase from '../../../core/app/usecase';
import Requirement from '../../entities/requirement';
import { RequirementSubject } from '../../entities/requirementSubject.enum';
import RequirementRepository from '../../repositories/requirementRepository';

type DeleteSiteRequirementParameter = {
	companyId: string;
	siteId?: string;
	requirementId: string;
	subject: RequirementSubject;
};
class DeleteSiteRequirement implements UseCase<DeleteSiteRequirementParameter, void> {
	private requirementRepository: RequirementRepository;

	constructor(requirementRepository: RequirementRepository) {
		this.requirementRepository = requirementRepository;
	}

	async Call({ companyId, siteId, requirementId, subject }: DeleteSiteRequirementParameter): Promise<void> {
		return await this.requirementRepository.deleteSiteRequirement(companyId, siteId, requirementId, subject);
	}
}

export default DeleteSiteRequirement;
