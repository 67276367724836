import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";

import { useTranslation } from "react-i18next";
import ContentLayout from "../../layout/ContentLayout";
import { COLORS } from "../../assets/theme/colors";
import useSiteRequirementsDocumentViewModel from "../../hooks/Document/useSiteRequirementsDocument";
import TableColumnHeader from "../../components/Common/table/TableColumnHeader";
import ColumnFilterComponent from "../../components/Common/table/ColumnFilterComponent";
import React, { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import StateTag from "../../components/Common/StateTag";
import InfiniteTable from "../../components/Common/table/InfiniteTable";
import { useAuth } from "../../providers/Auth0JWTProvider";
import { InfoBanner } from "../../components/Common/alerts/InfoBanner";
import { format } from "date-fns";
import { SortDirection } from "../../../domain/entities/interfaces/paginatedResults";
import SearchInput from "../Common/SearchInput";

const SiteRequirementsDocument = () => {
  const { t } = useTranslation("documents");
  const [searchParams, _] = useSearchParams();
  const daysSelected = Number(searchParams.get("daysSelected")) || 0;

  const currentDate = new Date();
  const futureDate = new Date(currentDate);
  futureDate.setDate(currentDate.getDate() + 10);

  const location = useLocation();
  const { siteId } = useParams();
  const { companyId } = useAuth();
  const [isTablet] = useMediaQuery("(max-width: 1300px)");
  const [showInfoBanner, setShowInfoBanner] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [indexTab, setIndexTab] = useState<number>(
    location.pathname.includes("supplier") ? 1 : 0,
  );
  const {
    siteReqResource,
    siteReqSupplier,
    siteReqResourceLoading,
    siteReqResourceHasNextPage,
    siteReqResourceFetchNextPage,
    siteReqResourceSort,
    setSiteReqResourceSort,
    siteReqResourceFilter,
    updateFilterSiteReqResource,
    siteReqSupplierLoading,
    siteReqSupplierHasNextPage,
    siteReqSupplierFetchNextPage,
    siteReqSupplierSort,
    setSiteReqSupplierSort,
    siteReqSupplierFilter,
    updateFilterSiteReqSupplier,
    setSearchResources,
    setSearchSuppliers,
  } = useSiteRequirementsDocumentViewModel();

  useEffect(() => {
    if (location.state) {
      if (location.state?.fromPrevUrl === "ASC") {
        handleTabChange(1);
        setSiteReqResourceSort({
          field: "expiresAt",
          direction: SortDirection.Ascending,
        });
        setSiteReqSupplierSort({
          field: "expiresAt",
          direction: SortDirection.Ascending,
        });
        setShowInfoBanner(true);
      }
      if (location.state?.fromPrevUrl === "unevaluated") {
        handleTabChange(0);
        updateFilterSiteReqSupplier("state", "unevaluated");
        updateFilterSiteReqResource("state", "unevaluated");
        setShowInfoBanner(true);
      }
    }
  }, []);

  const navigate = useNavigate();

  const resourceColumns = [
    {
      field: "name",
      type: "text" as const,
      label: t("siteRequirementsDocument.name"),
    },
    {
      field: "resourceName",
      type: "text" as const,
      label: t("siteRequirementsDocument.resourceName"),
    },
    {
      field: "resourceType",
      type: "select" as const,
      label: t("siteRequirementsDocument.resourceType"),
    },
    {
      field: "resourceOwner",
      type: "text" as const,
      label: t("siteRequirementsDocument.supplier"),
    },
    {
      field: "expiresAt",
      type: "date-range" as const,
      label: t("siteRequirementsDocument.expiresAt"),
    },
    {
      field: "state",
      type: "select" as const,
      label: t("siteRequirementsDocument.state"),
    },
  ];

  const supplierColumns = [
    {
      field: "name",
      type: "text" as const,
      label: t("siteRequirementsDocument.name"),
    },
    {
      field: "resourceOwner",
      type: "text" as const,
      label: t("siteRequirementsDocument.supplier"),
    },
    {
      field: "expiresAt",
      type: "date-range" as const,
      label: t("siteRequirementsDocument.expiresAt"),
    },
    {
      field: "state",
      type: "select" as const,
      label: t("siteRequirementsDocument.state"),
    },
  ];

  const stateOptions = [
    {
      option: "available",
      label: t("siteRequirementsDocument.available"),
    },
    {
      option: "not_available",
      label: t("siteRequirementsDocument.not_available"),
    },
    {
      option: "unevaluated",
      label: t("siteRequirementsDocument.unevaluated"),
    },
    {
      option: "not_applicable",
      label: t("siteRequirementsDocument.not_applicable"),
    },
  ];

  const resourceOptions = [
    {
      option: "chemical",
      label: t("chemical", { ns: "common" }),
    },
    {
      option: "machine",
      label: t("machine", { ns: "common" }),
    },
    {
      option: "tool",
      label: t("tool", { ns: "common" }),
    },
    {
      option: "vehicle",
      label: t("vehicle", { ns: "common" }),
    },
    {
      option: "worker",
      label: t("worker", { ns: "common" }),
    },
  ];

  const filterReqResource = siteReqResourceFilter ?? {
    name: "",
    resourceName: "",
    resourceType: "",
    resourceOwner: "",
    expiresAt: null,
    state: "",
  };

  useEffect(() => {
    if (daysSelected > 0) {
      const currentDate = new Date();
      const futureDate = new Date(currentDate);
      futureDate.setDate(currentDate.getDate() + daysSelected);
      updateFilterSiteReqSupplier("expiresAt", [
        new Date(currentDate.setHours(0, 0, 0, 0)),
        new Date(futureDate.setHours(23, 59, 59, 0)),
      ]);
      updateFilterSiteReqResource("expiresAt", [currentDate, futureDate]);
    }
  }, [daysSelected]);

  const filterReqSupplier = siteReqSupplierFilter ?? {
    name: "",
    resourceOwner: "",
    expiresAt: "",
    state: "",
  };

  const handleTabChange = (index: number) => {
    setIndexTab(index);
    if (index === 1) {
      navigate(`/sites/${siteId}/documents/siteRequirements/supplier`);
    } else if (index === 0) {
      navigate(`/sites/${siteId}/documents/siteRequirements/resource`);
    }
  };

  const [isMobile] = useMediaQuery("(max-width: 767px)");

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  return (
    <ContentLayout>
      <Box pt={6} px={10}>
        <Text
          fontSize="xl"
          textColor={COLORS.sikuroBlue}
          fontWeight="bold"
          pt={4}
        >
          {t("evaluationOverview")}
        </Text>
        <Text mb={5}>{t("evaluationOverviewSubtitle")}</Text>
        {showInfoBanner && (
          <InfoBanner
            text={t("evaluationOverviewInfo")}
            onClose={() => setShowInfoBanner(false)}
          />
        )}
        <Tabs
          defaultIndex={indexTab}
          onChange={(e) => handleTabChange(e)}
          marginTop={4}
        >
          <TabList>
            <Tab width={isMobile ? "700px" : "100%"} textAlign={"center"}>
              {t("siteRequirementsDocument.resourcesTab")}
            </Tab>
            <Tab width={isMobile ? "700px" : "100%"} textAlign={"center"}>
              {t("siteRequirementsDocument.suppliersTab")}
            </Tab>
          </TabList>

          <TabPanels marginTop={6}>
            <TabPanel p={0}>
              <Flex justifyContent={"end"} alignItems="center" w={"100%"}>
                <SearchInput onSearch={setSearchResources} />
              </Flex>
              <Flex
                flexDirection={"column"}
                alignItems={"start"}
                border="1px solid"
                borderColor="gray.300"
                borderRadius="10px"
                width={"calc(100vw - 210px)"}
                position="relative"
                marginTop={3}
                overflowX={"auto"}
                overflowY={"hidden"}
              >
                <InfiniteTable
                  autosize={true}
                  tableId="site-requirements-resource"
                  infiniteScroll={{
                    dataLength: siteReqResource.length,
                    hasNextPage: siteReqResourceHasNextPage,
                    fetchNextPage: siteReqResourceFetchNextPage,
                  }}
                  isLoading={siteReqResourceLoading}
                  emptyText={t("noItems", { ns: "common" })}
                >
                  <Thead>
                    <Tr>
                      {resourceColumns.map((column) => {
                        return (
                          <Th
                            key={uuidv4()}
                            width={isTablet ? "200px" : undefined}
                          >
                            <TableColumnHeader
                              text={column.label}
                              filter={{
                                isActive:
                                  !!filterReqResource[column.field] &&
                                  (!Array.isArray(
                                    filterReqResource[column.field],
                                  ) ||
                                    !!filterReqResource[column.field][0]),
                                component: (
                                  <ColumnFilterComponent
                                    value={filterReqResource[column.field]}
                                    type={column.type}
                                    updateFilter={(value) => {
                                      updateFilterSiteReqResource(
                                        column.field,
                                        value,
                                      );
                                    }}
                                    selectOptions={
                                      column.field === "state"
                                        ? stateOptions
                                        : column.field === "resourceType"
                                          ? resourceOptions
                                          : null
                                    }
                                  />
                                ),
                              }}
                              sort={{
                                handler: (direction) =>
                                  setSiteReqResourceSort({
                                    field: column.field,
                                    direction,
                                  }),
                                direction:
                                  siteReqResourceSort &&
                                  siteReqResourceSort.field === column.field
                                    ? siteReqResourceSort.direction
                                    : null,
                              }}
                            />
                          </Th>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody borderRadius="xl">
                    {siteReqResource.map((document) => {
                      return (
                        <Tr
                          cursor={"pointer"}
                          key={uuidv4()}
                          onClick={() =>
                            navigate(
                              `/sites/${siteId}/resources/${document.resourceType}s/${document.resourceId}`,
                            )
                          }
                        >
                          <Tooltip
                            label={document?.description}
                            placement="bottom-start"
                            openDelay={500}
                          >
                            <Td width={isTablet ? "200px" : undefined}>
                              {document.name}
                            </Td>
                          </Tooltip>
                          <Td width={isTablet ? "200px" : undefined}>
                            {document.resourceName}
                          </Td>
                          <Td width={isTablet ? "200px" : undefined}>
                            {t(document.resourceType, { ns: "common" })}
                          </Td>
                          <Td width={isTablet ? "200px" : undefined}>
                            {document.resourceOwner}
                          </Td>
                          <Td width={isTablet ? "200px" : undefined}>
                            {document.expiresAt
                              ? format(
                                  new Date(document.expiresAt),
                                  "dd/MM/yyyy",
                                )
                              : "--/--/----"}
                          </Td>
                          <Td width={isTablet ? "200px" : undefined}>
                            <StateTag
                              value={document.state}
                              type={"combinedEvaluation"}
                            />
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </InfiniteTable>
              </Flex>
            </TabPanel>
            <TabPanel p={0}>
              <Flex justifyContent={"end"} alignItems="center" w={"100%"}>
                <SearchInput onSearch={setSearchSuppliers} />
              </Flex>
              <Flex
                flexDirection={"column"}
                alignItems={"start"}
                border="1px solid"
                borderColor="gray.300"
                borderRadius="10px"
                width={"calc(100vw - 210px)"}
                position="relative"
                marginTop={3}
              >
                <InfiniteTable
                  autosize={true}
                  tableId="reqSupplier"
                  infiniteScroll={{
                    dataLength: siteReqSupplier.length,
                    hasNextPage: siteReqSupplierHasNextPage,
                    fetchNextPage: siteReqSupplierFetchNextPage,
                  }}
                  isLoading={siteReqSupplierLoading}
                  emptyText={t("noItems", { ns: "common" })}
                >
                  <Thead>
                    <Tr>
                      {supplierColumns.map((column) => (
                        <Th
                          key={uuidv4()}
                          width={isTablet ? "200px" : undefined}
                        >
                          <TableColumnHeader
                            text={column.label}
                            filter={{
                              isActive:
                                !!filterReqSupplier[column.field] &&
                                (!Array.isArray(
                                  filterReqSupplier[column.field],
                                ) ||
                                  !!filterReqSupplier[column.field][0]),
                              component: (
                                <ColumnFilterComponent
                                  value={filterReqSupplier[column.field]}
                                  type={column.type}
                                  updateFilter={(value) => {
                                    return updateFilterSiteReqSupplier(
                                      column.field,
                                      value,
                                    );
                                  }}
                                  selectOptions={
                                    column.field === "state"
                                      ? stateOptions
                                      : null
                                  }
                                />
                              ),
                            }}
                            sort={{
                              handler: (direction) =>
                                setSiteReqSupplierSort({
                                  field: column.field,
                                  direction,
                                }),
                              direction:
                                siteReqSupplierSort &&
                                siteReqSupplierSort.field === column.field
                                  ? siteReqSupplierSort.direction
                                  : null,
                            }}
                          />
                        </Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody borderRadius="xl">
                    {siteReqSupplier.map((document) => (
                      <Tr
                        cursor={"pointer"}
                        key={uuidv4()}
                        onClick={() =>
                          companyId === document.resourceId
                            ? navigate(
                                `/sites/${siteId}/documents/supplier/${document.resourceId}`,
                              )
                            : navigate(
                                `/sites/${siteId}/suppliers/${document.resourceId}`,
                              )
                        }
                      >
                        <Tooltip
                          label={document?.description}
                          placement="bottom-start"
                          openDelay={500}
                        >
                          <Td width={isTablet ? "200px" : undefined}>
                            {document.name}
                          </Td>
                        </Tooltip>
                        <Td width={isTablet ? "200px" : undefined}>
                          {document.resourceName}
                        </Td>
                        <Td width={isTablet ? "200px" : undefined}>
                          {document.expiresAt
                            ? format(new Date(document.expiresAt), "dd/MM/yyyy")
                            : "--/--/----"}
                        </Td>
                        <Td width={isTablet ? "200px" : undefined}>
                          <StateTag
                            value={document.state}
                            type={"combinedEvaluation"}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </InfiniteTable>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </ContentLayout>
  );
};

export default SiteRequirementsDocument;
