import {
  Box,
  Button,
  Flex,
  IconButton,
  IconProps,
  Image,
  SimpleGrid,
  Skeleton,
} from "@chakra-ui/react";
import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsTrash } from "react-icons/bs";
import { HiPencil } from "react-icons/hi";
import { Identifiable } from "../../../domain/entities/interfaces/identifiable";
import { Named } from "../../../domain/entities/interfaces/named";
import { formatDateBasedOnLanguage, formatStringToDate } from "../../../utils";
import { COLORS } from "../../assets/theme/colors";
import { UploadImageView } from "./UploadImageView";
import DetailField from "./table/DetailField";
import { useAuth } from "../../providers/Auth0JWTProvider";
import { useMediaQuery } from "@chakra-ui/react";

export type FieldAttributes = {
  label: string;
  value: string | ReactNode;
  edit?: {
    value: string;
    onChange: (value: string) => void;
    validate?: boolean;
    validationRegex?: RegExp;
    validationErrorMessage?: string;
    type:
      | "text"
      | "multiline"
      | "date"
      | "select"
      | "number"
      | "customTimezoneSelect";
    numericSizeConstraint?: number;
    options?: Array<Identifiable & Named>;
    noSelection?: string;
    submitIsDisabled?: boolean;
  };
  isLoaded?: boolean;
  height?: string;
  isTag?: boolean;
};

type DetailsHeaderProps = {
  image?: {
    url?: string;
    Icon?: React.FC<IconProps>;
    QRCode?: ReactNode;
    disableFlag?: boolean;
    onChange?: (value: File) => void;
  } & ({ url: string } | { Icon: React.FC<IconProps> });
  title?: string;
  attributes: FieldAttributes[];
  unlinkBadgeResource?: () => void;
  isLoaded?: boolean;
  shouldShowImage?: boolean;
};

const DetailsHeader = ({
  image,
  title,
  attributes,
  unlinkBadgeResource,
  isLoaded,
  shouldShowImage,
}: DetailsHeaderProps) => {
  const Icon = image?.Icon ?? undefined;
  const QRCode = image?.QRCode ?? undefined;
  const { t } = useTranslation();
  const [showImageEditing, setShowImageEditing] = useState<boolean>(false);
  const { token } = useAuth();
  const [imageUrl, setImageUrl] = useState<string>();

  useEffect(() => {
    const fetchImage = async (imageUrl: string) => {
      const response = await fetch(imageUrl, {
        headers: { Authorization: `Bearer ${token.access_token}` },
      });

      const blob = await response.blob();
      setImageUrl(URL.createObjectURL(blob));
    };
    if (image?.url && token.access_token) {
      fetchImage(image?.url);
    }
  }, [image?.url, token.access_token]);

  const [isMobile] = useMediaQuery("(max-width: 767px)");

  return (
    <Flex
      gap={10}
      flexDirection={image ? "row" : "column"}
      alignItems={image ? "center" : "start"}
      border="1px solid"
      borderColor="gray.300"
      borderRadius="10px"
      p={image ? 0 : "10px 20px"}
      width={isMobile ? "700px" : "100%"}
      position="relative"
      overflow={"hidden"}
      flex="1 1 1000px"
    >
      <Box
        textStyle="h2"
        sx={{ position: "absolute", top: "20px", left: "33px", zIndex: 1 }}
      >
        {title}
      </Box>

      {shouldShowImage && (
        <Flex
          w={image?.disableFlag ? "200px" : "280px"}
          pl="2rem"
          pt="3.5rem"
          minHeight="265px"
          minWidth="208px"
          height="100%"
          pos="relative"
          display="flex"
          flexDirection="column"
          bg={image?.disableFlag ? "transparent" : COLORS.yellow}
          sx={{
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
          }}
          _after={
            image?.disableFlag
              ? undefined
              : {
                  content: "''",
                  pos: "absolute",
                  right: "0",
                  top: "0",
                  w: "0",
                  h: "0",
                  borderRight: `80px solid white`,
                  borderBottom: `160px solid transparent`,
                  borderTop: `120px solid transparent`,
                }
          }
        >
          <Skeleton
            height="143px"
            width="143px"
            isLoaded={isLoaded}
            bg={COLORS.lightYellow}
            color="white"
            fadeDuration={1}
            borderRadius={"10px"}
            startColor="gray.200"
            endColor="gray.300"
          >
            {!Icon && imageUrl && (
              <Image
                borderRadius="10px"
                height="143px"
                width="143px"
                src={imageUrl}
              />
            )}
            {Icon && <Icon height="143px" width="143px" />}
            {QRCode && QRCode}
          </Skeleton>
          {image?.onChange && (
            <IconButton
              aria-label="edit"
              icon={<HiPencil />}
              onClick={() => setShowImageEditing(true)}
              size="5"
              variant="ghost"
              cursor="pointer"
              _hover={{ backgroundColor: undefined }}
              sx={{ position: "absolute", left: "170px", top: "30px" }}
            />
          )}
        </Flex>
      )}

      <SimpleGrid
        marginTop={shouldShowImage ? "30px" : "45px"}
        marginBottom={"30px"}
        columns={image?.disableFlag ? 3 : 4}
        sx={{ width: image ? "calc(100% - 280px)" : "100%" }}
        gap={6}
      >
        {attributes.map(({ label, value, edit }, index) => (
          <DetailField
            key={label}
            label={label}
            value={
              edit?.type === "date"
                ? formatDateBasedOnLanguage(value as string)
                : value
            }
            edit={edit}
            isLoaded={isLoaded}
          />
        ))}

        {unlinkBadgeResource && (
          <Button
            marginTop={5}
            borderRadius={4}
            height={8}
            style={{
              gridColumnStart: 1,
              gridColumnEnd: image?.disableFlag ? 4 : 5,
            }}
            width="100%"
            fontSize="l"
            size="2xl"
            onClick={() => unlinkBadgeResource()}
            rightIcon={<BsTrash />}
            aria-label="Edit"
          >
            {t("unlinkResource", { ns: "badges" })}
          </Button>
        )}
      </SimpleGrid>

      <UploadImageView
        isOpen={showImageEditing}
        title={t("uploadBoxTitle", { ns: "common" })}
        onSave={image?.onChange}
        onCancel={() => setShowImageEditing(false)}
      />
    </Flex>
  );
};

export default DetailsHeader;
