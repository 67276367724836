import { useTranslation } from "react-i18next";
import { BaseDateRangePicker } from "../DateRangePicker";
import { Box, Select } from "@chakra-ui/react";
import TextInput from "../TextInput";
import TagSelect from "../TagSelect";
import Tag from "../../../../domain/entities/tag";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import TimeRangePicker from "../TimeRangePicker";
import { useEffect, useState } from "react";

export type ColumnFilterType =
  | "date-range"
  | "dateTime-range"
  | "time-range"
  | "select"
  | "text"
  | "tags"
  | "boolean";

export type FilterComponentProps<Enum> = {
  type?: ColumnFilterType;
  selectOptions?: Enum | { option: string; label: string }[];
  tags?: Tag[];
  value: string | string[] | [Date, Date];
  updateFilter: (value: string | string[] | [Date, Date]) => void;
  namespace?: string;
  optionsTranslationContext?: string;
};

const ColumnFilterComponent = <Enum extends Record<string, string> | null>({
  type,
  value,
  updateFilter,
  selectOptions,
  tags,
  namespace,
  optionsTranslationContext,
}: FilterComponentProps<Enum>) => {
  const { t } = useTranslation();

  const [filterValue, setFilterValue] = useState(value);
  const [clearFilterValue, setClearFilterValue] = useState<boolean>(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (filterValue) {
        updateFilter(filterValue);
      } else if (!filterValue && clearFilterValue) {
        updateFilter(filterValue);
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [filterValue, clearFilterValue]);

  switch (type) {
    case "date-range":
      return (
        <div>
          <BaseDateRangePicker
            startDate={(Array.isArray(value) ? value[0] : value) as Date}
            endDate={(Array.isArray(value) ? value[1] : value) as Date}
            setDates={(dates) => updateFilter(dates)}
          />
        </div>
      );
    case "dateTime-range":
      return (
        <Box
          flexDirection={"column"}
          display="flex"
          justifyContent="center"
          alignItems="center"
          backgroundColor={"gray.300"}
          border="1px solid"
          borderColor="gray.300"
          borderRadius="10px"
        >
          <BaseDateRangePicker
            startDate={(Array.isArray(value) ? value[0] : value) as Date}
            endDate={(Array.isArray(value) ? value[1] : value) as Date}
            setDates={(dates) => updateFilter(dates)}
          />
          <TimeRangePicker
            setTimeRange={updateFilter}
            timeRange={value as [Date, Date]}
          />
        </Box>
      );
    case "time-range":
      return (
        <TimeRangePicker
          setTimeRange={updateFilter}
          timeRange={value as [Date, Date]}
        />
      );
    case "select":
      return (
        <Select
          value={(value as string) ?? ""}
          onChange={(e) => updateFilter(e.target.value)}
        >
          <option value="">{t("all")}</option>
          {getSelectOptions(
            selectOptions,
            optionsTranslationContext,
            (value: string) => t(value, { ns: namespace }),
          )?.map((opt) => {
            return (
              <option key={opt.option} value={opt.option}>
                {opt.label}
              </option>
            );
          })}
        </Select>
      );
    case "tags":
      return (
        <TagSelect
          tags={tags}
          selectedTags={(value as string[]) ?? []}
          setSelectedTags={updateFilter}
          style={{ width: "300px" }}
          marginOnTop={"2px"}
          marginBottom={"2px"}
        />
      );
    default:
      return (
        <TextInput
          value={filterValue as string}
          onChange={(value, clear) => {
            setClearFilterValue(clear);
            setFilterValue(value);
          }}
          placeholder={t("search")}
        />
      );
  }
};

function getSelectOptions(
  selectOptions: any,
  optionsTranslationContext?: string,
  t?: (value: string) => string,
) {
  return typeof selectOptions === "object" && !Array.isArray(selectOptions)
    ? Object.keys(selectOptions).map((key) => ({
        option: selectOptions[key],
        label: optionsTranslationContext
          ? t(`${optionsTranslationContext}.${selectOptions[key]}`)
          : t(selectOptions[key]), // Optional: Use translation function if needed
      }))
    : selectOptions;
}

export default ColumnFilterComponent;
