import {
  makeAddFileToDocument,
  makeConfirmAiTask,
  makeCreateAiTask,
  makeDeleteAiTask,
  makeDeleteFileFromDocument,
  makeGetAiTaskDetail,
  makeGetAiTasks,
  makeGetAiTaskSites,
  makeGetDocumentEvaluations,
  makeGetDocumentFiles,
  makeGetDocumentFilesLog,
  makeGetDocumentLog,
  makeGetSiteSupplierDocuments,
  makeUpdateFile,
} from "../../../core/app/factory/documents/usecases";
import { makeGetDocumentTypesForAi } from "../../../core/app/factory/documentTypes/usecases";
import {
  makeDeleteSiteRequirement,
  makeGetDocumentTypes,
} from "../../../core/app/factory/requirements/useCases";
import { makeAddRequirementToWorkingSite } from "../../../core/app/factory/sites/usecases";
import { makeGetTags } from "../../../core/app/factory/tags/usecases";
import AiTask from "../../../domain/entities/aiTask";
import AiTaskDocument from "../../../domain/entities/aiTaskDocument";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";
import Document, {
  SiteResourceDocument,
} from "../../../domain/entities/document";
import {
  DocumentActionLogEntry,
  DocumentFileLogEntry,
} from "../../../domain/entities/documentLogEntry";
import DocumentType from "../../../domain/entities/documentType";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import DocumentTypeWithPublic from "../../../domain/entities/documentTypeWithPublic";
import Evaluation from "../../../domain/entities/evaluation";
import {
  default as File,
  default as FileEntity,
} from "../../../domain/entities/file";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import Requirement from "../../../domain/entities/requirement";
import { RequirementSubject } from "../../../domain/entities/requirementSubject.enum";
import { ResourceDocumentEvaluationState } from "../../../domain/entities/resourceDocumentEvaluationState.enum";
import { DocumentSites } from "../../../domain/entities/site";
import Tag from "../../../domain/entities/tag";
import {
  GetDocumentEvaluationFilter,
  GetDocumentFilesFilter,
  GetDocumentLogFilter,
  GetDocumentsFilter,
} from "../../../domain/repositories/documentRepository";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";
import { DocumentTypeResourceType } from "../../../mock/models/document-type";
import { AddDocumentTypeViewModel } from "../../hooks/Document/useAddDocumentType";
import { DocumentInfoViewModel } from "../../hooks/Document/useDocumentInfo";
import { DocumentsViewModel } from "../../hooks/Document/useDocuments";

class SupplierDocumentsViewModel
  implements
    DocumentInfoViewModel,
    AddDocumentTypeViewModel,
    DocumentsViewModel
{
  constructor(
    private companyId: string,
    private siteId: string,
    private supplierId: string,
    private target: string
  ) {}

  getDocuments(
    companyId: string,
    filter?: GetDocumentsFilter,
    sort?: SortMeta
  ): Promise<Document[]> {
    throw new Error("Method not implemented.");
  }
  createDocument(companyId: string, documents: string[]): Promise<void> {
    throw new Error("Method not implemented.");
  }
  async createPropagableDocuments(
    companyId: string,
    documents: DocumentTypeWithPublic[]
  ): Promise<void> {
    throw new Error("Method not implemented.");
  }
  updateDocument(companyId: string, document: Document): Promise<void> {
    throw new Error("Method not implemented.");
  }
  deleteDocument(companyId: string, documentId: string): Promise<Document[]> {
    throw new Error("Method not implemented.");
  }
  evaluateDocument(
    companyId: string,
    documentId: string,
    evaluationState?: ResourceDocumentEvaluationState,
    expirationDate?: Date
  ) {
    throw new Error("Method not implemented.");
  }

  async getDocumentTypes(
    companyId: string,
    search?: string,
    tags?: string[],
    sort?: SortMeta,
    pageParam?: number,
    resourceId?: string,
    systemOnly?: boolean
  ): Promise<DocumentType[]> {
    return await makeGetDocumentTypes().Call({
      companyId,
      filter:
        this.target === "company"
          ? DocumentTypeCategory.COMPANY
          : DocumentTypeCategory.SITE,
      //NM:I assigned the opposite of systemOnly because if the latter is true, then includePublic must be false and vice versa.
      includePublic: !systemOnly,
      search,
      tags,
      sort,
      pageParam,
      withFileCount: true,
      resourceId,
      systemOnly,
    });
  }

  async get(
    target: "company" | "site",
    pageParam: number,
    filter,
    sort
  ): Promise<SiteResourceDocument[]> {
    return makeGetSiteSupplierDocuments().Call({
      siteId: this.siteId,
      companyId: this.companyId,
      supplierId: this.companyId,
      target,
      filter,
      sort,
      pageParam,
    });
  }

  async addRequirementToTarget(
    documentTypeId: string,
    isOptional: boolean,
    graceDays: number,
    requirementSubject: RequirementSubject
  ): Promise<Requirement> {
    return await makeAddRequirementToWorkingSite().Call({
      companyId: this.companyId,
      siteId: this.siteId,
      documentTypeId,
      requirementSubject,
      isOptional,
      graceDays,
    });
  }

  async getDocumentEvaluations(
    companyId: string,
    siteId: string,
    resourceType: string,
    resourceId: string,
    documentId: string,
    pageParam: number,
    filter: GetDocumentEvaluationFilter,
    sort: SortMeta
  ): Promise<Evaluation<ResourceDocumentEvaluationState>[]> {
    return await makeGetDocumentEvaluations().Call({
      companyId,
      siteId,
      resourceType,
      resourceId,
      documentId,
      pageParam,
      filter,
      sort,
    });
  }

  async getDocumentFiles(
    companyId: string,
    documentId: string,
    filter?: GetDocumentFilesFilter,
    sort?: SortMeta
  ): Promise<File[]> {
    return await makeGetDocumentFiles().Call({
      companyId,
      documentId,
      filter,
      sort,
      siteId: this.siteId,
      target: this.target,
    });
  }

  async getDocumentFilesLogs(
    companyId: string,
    documentId: string,
    filter?: GetDocumentLogFilter,
    sort?: SortMeta,
    pageParam?: number
  ): Promise<DocumentActionLogEntry[]> {
    return await makeGetDocumentFilesLog().Call({
      companyId,
      documentId,
      filter,
      sort,
      pageParam,
    });
  }

  async deleteDocumentFile(
    companyId: string,
    documentId: string,
    fileId: string,
    siteId?: string,
    target?: string
  ): Promise<void> {
    await makeDeleteFileFromDocument().Call({
      companyId,
      documentId,
      fileId,
      siteId,
      target,
    });
  }

  async deleteSiteRequirement(
    companyId: string,
    siteId: string,
    requirementId: string,
    subject: RequirementSubject
  ): Promise<void> {
    await makeDeleteSiteRequirement().Call({
      companyId,
      siteId,
      requirementId,
      subject,
    });
  }

  async addFileToDocument(
    companyId: string,
    documentId: string,
    file: File,
    siteId?: string,
    target?: string
  ): Promise<void> {
    await makeAddFileToDocument().Call({
      companyId,
      documentId,
      file,
      siteId,
      target,
    });
  }

  async getDocumentLogs(
    companyId: string,
    documentId: string,
    filter?: GetDocumentLogFilter,
    sort?: SortMeta,
    resourceId?: string,
    siteId?: string,
    target?: string,
    pageParam?: number
  ): Promise<DocumentFileLogEntry[]> {
    return await makeGetDocumentLog().Call({
      companyId,
      documentId,
      filter,
      sort,
      siteId,
      target,
      pageParam,
    });
  }

  async getTags(companyId: string, search?: string): Promise<Tag[]> {
    return await makeGetTags().Call({ companyId, search });
  }

  async updateFile(
    companyId: string,
    documentId: string,
    fileId: string,
    updatedFiles: Partial<FileEntity>[],
    siteIds?: string[],
    target?: string,
    resourceId?: string,
    selectAll?: boolean
  ): Promise<void> {
    return await makeUpdateFile().Call({
      companyId,
      documentId,
      updatedFiles,
      fileId,
      siteIds,
      target,
      resourceId,
      selectAll,
    });
  }
  getDocumentSites(
    companyId: string,
    documentId: string
  ): Promise<DocumentSites> {
    throw new Error("Method not implemented.");
  }

  // AI section fuctions

  async createAiTask(
    companyId: string,
    files: FileEntity,
    sectionCode: AiTaskSectionCode,
    resourceId: string,
    type: DocumentTypeCategory
  ): Promise<string> {
    return await makeCreateAiTask().Call({
      companyId,
      files,
      sectionCode,
      resourceId,
      resourceType: type as unknown as DocumentTypeResourceType,
    });
  }

  async getAiTasks(
    companyId: string,
    sectionCode: AiTaskSectionCode,
    resourceType?: DocumentTypeCategory,
    resourceId?: string,
    siteId?: string
  ): Promise<AiTask[]> {
    return await makeGetAiTasks().Call({
      companyId,
      sectionCode,
      resourceType,
      resourceId,
      siteId,
    });
  }

  async getAiTaskDetail(companyId: string, taskId: string): Promise<AiTask> {
    return await makeGetAiTaskDetail().Call({ companyId, taskId });
  }

  async deleteAiTask(companyId: string, taskId: string): Promise<void> {
    return await makeDeleteAiTask().Call({ companyId, taskId });
  }

  async sendTaskConfirmation(
    companyId: string,
    taskId: string,
    aiTaskDocuments: AiTaskDocument[],
    siteIds: string[]
  ): Promise<void> {
    return await makeConfirmAiTask().Call({
      companyId,
      taskId,
      aiTaskDocuments,
      siteIds,
    });
  }

  async getAiTaskSites(
    companyId: string,
    sectionCode: AiTaskSectionCode,
    taskId: string,
    sortSites: SortMeta,
    filterSites: GetSitesFilter,
    pageParam?: number
  ) {
    return await makeGetAiTaskSites().Call({
      companyId,
      sectionCode,
      taskId,
      sortSites,
      filterSites,
      pageParam,
    });
  }

  async getDocumentTypesForAi(
    companyId: string,
    aiTaskId: string,
    resourceType: DocumentTypeCategory
  ): Promise<DocumentType[]> {
    return await makeGetDocumentTypesForAi().Call({
      companyId,
      aiTaskId,
      resourceType,
    });
  }
}

export default SupplierDocumentsViewModel;
