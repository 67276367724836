import {
  Button,
  ButtonGroup,
  Flex,
  GridItem,
  ModalOverlay,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FiAlertTriangle, FiInfo } from "react-icons/fi";
import { HiOutlineLockClosed, HiOutlineLockOpen } from "react-icons/hi";
import { IoMdDownload } from "react-icons/io";
import Document from "../../../../domain/entities/document";
import FileEntity from "../../../../domain/entities/file";
import { ResourceDocumentEvaluationState } from "../../../../domain/entities/resourceDocumentEvaluationState.enum";
import { formatDateForInputHTML } from "../../../../utils";
import { COLORS } from "../../../assets/theme/colors";
import { DocumentCommunicationHook } from "../../../hooks/Document/useDocumentCommunicationVIewModel";
import { UseDownloader } from "../../../hooks/Document/useDocumentsFilesDownloader";
import CommentButton from "../../Common/CommentButton";
import RenderIf from "../../Permissions/RenderIf";
import { ResourceDocumentsPermissions } from "./SiteResourceDocuments";
import { DocumentEvaluationComponent } from "./DocumentEvaluationComponent";
import { DocumentExpirationComponent } from "./DocumentExpirationComponent";

interface Props {
  action?: "add" | "delete" | "evaluate" | "edit" | "editFile" | string | null;
  communicationProps: DocumentCommunicationHook;
  disabledFunctions: boolean;
  permissions: ResourceDocumentsPermissions;
  document: Document;
  documentsFetching?: boolean;
  downloader: UseDownloader;
  editDocument: (
    expiresAt?: Date,
    isPublic?: boolean,
    noExpiration?: boolean,
    showEdit?: boolean
  ) => void;
  evaluateDocument: (
    documentId: string,
    evaluationState?: ResourceDocumentEvaluationState,
    expirationDate?: Date,
    siteIds?: string[]
  ) => void;
  hasSites?: (action: string) => Promise<boolean>;
  isReadOnly: boolean;
  onNext: () => void;
  onPrev: () => void;
  renameModels: string[];
  setAction: (action: "add" | "delete" | "evaluate" | "edit") => void;
  setActiveFile: (file: FileEntity | null) => void;
  setFileToAdd: (value: null) => void;
  selectedEvaluationState: ResourceDocumentEvaluationState;
  setSelectedEvaluationState: (state: ResourceDocumentEvaluationState) => void;
  setShowEdit: (showEdit: boolean) => void;
  setShowSites: (showSites: boolean) => void;
  setShowUpdateEvaluationDate?: (showEvaluationDate: boolean) => void;
  showDownloadAll?: boolean;
  siteGeneralDocument?: boolean;
  showOverlay?: boolean;
  setShowErrorEvalution?: (value: boolean) => void;
  setUpdateEvaluationDate?: Dispatch<SetStateAction<string>>;
}

export const DocumentDetailHeader = ({
  document,
  renameModels,
  downloader,
  siteGeneralDocument,
  hasSites,
  documentsFetching,
  disabledFunctions,
  setFileToAdd,
  action,
  permissions,
  showOverlay,
  setShowErrorEvalution,
  setUpdateEvaluationDate,
  communicationProps,
  editDocument,
  evaluateDocument,
  setAction,
  selectedEvaluationState,
  setSelectedEvaluationState,
  setActiveFile,
  onPrev,
  onNext,
  setShowEdit,
  setShowUpdateEvaluationDate,
  setShowSites,
}: Props) => {
  const toast = useToast();
  const ref = useRef(null);
  const { t } = useTranslation("documents");

  const [isPublic, setIsPublic] = useState<boolean>(document.isPublic);
  const [popoverOpen, setPopOverOpen] = useState<boolean>();
  const [newValidity, setNewValidity] = useState<string>();
  const [evaluationValidity, setEvaluationValidity] = useState<string>();
  const [noExpiration, setNoExpiration] = useState<boolean>(
    document?.noExpiration
  );

  const handleEvaluation = async (option?: ResourceDocumentEvaluationState) => {
    const hasSiteItems = hasSites && (await hasSites("evaluate"));
    if (hasSiteItems) {
      setAction("evaluate");
      setSelectedEvaluationState(option);
      setShowUpdateEvaluationDate(
        option === ResourceDocumentEvaluationState.AVAILABLE ? true : false
      );
      evaluateDocument(document.id, option, new Date(evaluationValidity), []);
    } else {
      setShowErrorEvalution(true);
      setSelectedEvaluationState(ResourceDocumentEvaluationState.UNDEFINED);
    }
  };

  const handleEditDocument = (data: Date) => {
    return editDocument(data, document.isPublic, noExpiration);
  };

  useEffect(() => {
    if (document) {
      const formattedDocValidity = formatDateForInputHTML(document?.expiresAt);
      const formattedEvaluationValidity = document?.evaluationExpiresAt
        ? formatDateForInputHTML(document?.evaluationExpiresAt)
        : null;
      setNewValidity(formattedDocValidity);
      setEvaluationValidity(formattedEvaluationValidity);
    }
  }, [document]);

  return (
    <>
      <GridItem
        colSpan={1}
        rowSpan={1}
        w={"sm"}
        bg={COLORS.yellow}
        borderRadius={"5px 0 0 0 "}
        zIndex={action ? 2 : 1}
        borderBottom={"1px solid #efefef"}
      >
        {(action === "evaluate" ||
          action === "add" ||
          action === "edit" ||
          action === "editFile" ||
          action === "delete") && <ModalOverlay></ModalOverlay>}
        <Flex
          py={8}
          px={6}
          gap={2}
          justifyContent={"space-between"}
          flexDirection={"column"}
        >
          <Flex flexDirection={"column"}>
            <Text
              width={"100%"}
              fontWeight={600}
              overflow={"hidden"}
              textOverflow={"ellipsis"}
              whiteSpace={"nowrap"}
            >
              {document?.type.name}
            </Text>
            <Text fontSize={"small"}>{document?.filesCount} file</Text>
          </Flex>
          <Flex alignItems={"center"} gap={4}>
            {!siteGeneralDocument && (
              <RenderIf permissions={permissions.edit}>
                <ButtonGroup isAttached isDisabled={disabledFunctions}>
                  <Button
                    variant={!isPublic ? "outline" : "solid"}
                    sx={{
                      borderRadius: 10,
                      color: !isPublic ? "white" : COLORS.sikuroBlue,
                      backgroundColor: !isPublic ? "white" : COLORS.sikuroBlue,
                    }}
                    onClick={() => (
                      setIsPublic(true),
                      editDocument(
                        document?.expiresAt,
                        true,
                        noExpiration,
                        true
                      )
                    )}
                    h={"2rem"}
                  >
                    <HiOutlineLockOpen color={!isPublic ? "black" : "white"} />
                  </Button>
                  <Button
                    variant={isPublic ? "outline" : "solid"}
                    sx={{
                      borderRadius: 10,
                      color: isPublic ? "white" : COLORS.sikuroBlue,
                      backgroundColor: isPublic ? "white" : COLORS.sikuroBlue,
                    }}
                    onClick={() => (
                      setIsPublic(false),
                      editDocument(document?.expiresAt, false, noExpiration)
                    )}
                    h={"2rem"}
                  >
                    <HiOutlineLockClosed
                      color={!isPublic ? "white" : "black"}
                    />
                  </Button>
                </ButtonGroup>
              </RenderIf>
            )}
            {communicationProps && (
              <RenderIf permissions={permissions.chat}>
                <CommentButton
                  isDisabled={disabledFunctions}
                  onClick={(comment) => {
                    if (comment) {
                      communicationProps.createResourceDocumentComment(
                        `#[${document?.type.name}](${document?.id}) ${comment}`
                      );
                    }
                  }}
                  comments={(
                    communicationProps.resourceDocumentComments || []
                  ).filter((c) => c.comment.includes(`(${document?.id})`))}
                  isLoading={communicationProps.isFetching}
                  color="black"
                  fontSize="23px"
                  taggableUsers={communicationProps?.taggableUsers}
                />
              </RenderIf>
            )}
            <RenderIf permissions={permissions.downloadAll}>
              <Menu placement="auto">
                <Tooltip label={t("downloadAllFiles", { ns: "documents" })}>
                  <MenuButton
                    minWidth="22px"
                    paddingLeft={2}
                    paddingRight={2}
                    paddingTop={2}
                    paddingBottom={2}
                    borderRadius={25}
                    _hover={{
                      paddingLeft: "2",
                      paddingRight: "2",
                      borderRadius: "25",
                      boxShadow: "0 0 12px 4px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <IoMdDownload fontSize="26px" color={"gray"} />
                  </MenuButton>
                </Tooltip>
                <MenuList>
                  <MenuGroup title={t("downloadAllFiles", { ns: "documents" })}>
                    {renameModels?.map((name) => (
                      <MenuItem
                        isDisabled={disabledFunctions}
                        key={name}
                        minH="48px"
                        onClick={() =>
                          downloader?.initiateZipDownload(
                            document.id,
                            name,
                            (ok) => {
                              toast({
                                duration: 5000,
                                isClosable: true,
                                colorScheme: ok ? "green" : "red",
                                icon: ok ? <FiInfo /> : <FiAlertTriangle />,
                                description: ok
                                  ? t("downloadAllFilesOk")
                                  : t("downloadAllFilesError"),
                              });
                            }
                          )
                        }
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </MenuGroup>
                </MenuList>
              </Menu>
            </RenderIf>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem
        colSpan={2}
        rowSpan={1}
        w={"full"}
        borderBottom={"1px solid #efefef"}
      >
        <Flex
          gap={4}
          zIndex={10}
          ref={ref}
          h={"full"}
          justifyContent={siteGeneralDocument ? "flex-end" : undefined}
        >
          <RenderIf
            permissions={permissions.setExpiration}
            fallback={
              <RenderIf permissions={permissions.viewEvaluations}>
                <DocumentExpirationComponent
                  readOnly
                  action={action}
                  disabledFunctions={disabledFunctions}
                  document={document}
                  documentsFetching={documentsFetching}
                  handleEditDocument={handleEditDocument}
                  newValidity={newValidity}
                  popoverOpen={popoverOpen}
                  ref={ref}
                  setAction={setAction}
                  setPopOverOpen={setPopOverOpen}
                  noExpiration={noExpiration}
                  setNoExpiration={setNoExpiration}
                  currentNoExpiration={document?.noExpiration}
                />
              </RenderIf>
            }
          >
            <DocumentExpirationComponent
              readOnly={false}
              action={action}
              disabledFunctions={disabledFunctions}
              document={document}
              documentsFetching={documentsFetching}
              handleEditDocument={handleEditDocument}
              newValidity={newValidity}
              popoverOpen={popoverOpen}
              ref={ref}
              setAction={setAction}
              setPopOverOpen={setPopOverOpen}
              noExpiration={noExpiration}
              setNoExpiration={setNoExpiration}
              currentNoExpiration={document?.noExpiration}
            />
          </RenderIf>

          <Flex gap={4} flexGrow={1} py={4} alignItems={"start"}>
            <RenderIf
              permissions={permissions.evaluate}
              fallback={
                <RenderIf permissions={permissions.viewEvaluations}>
                  <DocumentEvaluationComponent
                    action={action}
                    disabledFunctions={disabledFunctions}
                    documentsFetching={documentsFetching}
                    readOnly
                    handleEvaluation={handleEvaluation}
                    isPublic={isPublic}
                    selectedState={selectedEvaluationState}
                    setSelectedState={setSelectedEvaluationState}
                    hasSites={hasSites}
                  />
                </RenderIf>
              }
            >
              <DocumentEvaluationComponent
                action={action}
                disabledFunctions={disabledFunctions}
                documentsFetching={documentsFetching}
                handleEvaluation={handleEvaluation}
                isPublic={isPublic}
                readOnly={false}
                selectedState={selectedEvaluationState}
                setSelectedState={setSelectedEvaluationState}
                hasSites={hasSites}
              />
            </RenderIf>
          </Flex>
          <Flex
            gap={4}
            fontSize={"24px"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Tooltip label={t("previousDocument")} shouldWrapChildren>
              <FaChevronLeft
                onClick={() => (
                  setActiveFile(null),
                  setFileToAdd(null),
                  setAction(null),
                  setShowEdit(false),
                  onPrev()
                )}
                cursor={"pointer"}
              />
            </Tooltip>
            <Tooltip label={t("nextDocument")} shouldWrapChildren>
              <FaChevronRight
                onClick={() => (
                  setActiveFile(null),
                  setFileToAdd(null),
                  setAction(null),
                  setShowEdit(false),
                  onNext()
                )}
                cursor={"pointer"}
              />
            </Tooltip>
          </Flex>
        </Flex>
      </GridItem>
    </>
  );
};
