import { useTranslation } from "react-i18next";
import Document from "../../../../domain/entities/document";
import {
  Flex,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import TableViewLabelFiltersHeader from "../../../components/Common/table/TableViewLabelFiltersHeader";
import { DropResult } from "react-beautiful-dnd";
import Tag from "../../../../domain/entities/tag";
import DnDTable from "../../../components/Common/table/DnDTable";
import { DocumentsListViewModelProps } from "../../../hooks/Document/useDocumentsViewModel";
import LoadingView from "../../../screens/Common/LoadingView";
import ExpirationIcon from "./ExpirationIcon";
import { formatDateBasedOnLanguage } from "../../../../utils";
import React from "react";
import { HiOutlineLockClosed, HiOutlineLockOpen } from "react-icons/hi2";
import TagList from "../../../screens/Common/TagList";
import RenderIf from "../../Permissions/RenderIf";
import CommentButton from "../../Common/CommentButton";
import HistoryButton from "../../Common/table/HistoryButton";
import AddFileButton from "../../Common/table/AddFileButton";
import DeleteButton from "../../Common/table/DeleteButton";
import { ResourceDocumentsPermissions } from "../common/SiteResourceDocuments";
import DocumentPreset from "../../../../domain/entities/documentPreset";
import { LuFileSearch2 } from "react-icons/lu";
import { COLORS } from "../../../assets/theme/colors";

type Props = {
  communicationProps: DocumentsListViewModelProps["communicationProps"];
  permissions: ResourceDocumentsPermissions;
  tags?: Array<Tag>;
  setAskConfirmDelete?: (value: boolean) => void;
  autosize?: boolean;
  documents: Array<Document>;
  documentsLoading: boolean;
  onReorder: (doc: Document[]) => void;
  handleDragIn: (docId: string) => void;
  handleDrop: (doc: Document, e: React.DragEvent<HTMLTableRowElement>) => void;
  onDelete?: (documentPreset: DocumentPreset) => void;
  openDocumentDetailModal?: (document: Document) => void;
  openHistory?: (document: Document) => void;
  setShowConfirmDeleteModal?: (documentId: string) => void;
};

const GeneralDocumentsDnDView = ({
  documents,
  permissions,
  communicationProps,
  documentsLoading,
  onReorder,
  openDocumentDetailModal,
  openHistory,
  setShowConfirmDeleteModal,
  autosize = true,
  handleDragIn,
  handleDrop,
}: Props) => {
  const [isTablet] = useMediaQuery("(max-width: 1300px)");
  const { t } = useTranslation("documents");
  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const element = documents.find((el) => el.id === result.draggableId);
    if (element.order === result.destination.index + 1) {
      return;
    }
    const newArray = [...documents];
    const index = documents.findIndex((obj) => obj.id === result.draggableId);
    const movedObject = newArray.splice(index, 1)[0];
    newArray.splice(result.destination.index, 0, movedObject);
    await onReorder(newArray);
  };

  return (
    <Flex
      flexDirection={"column"}
      alignItems={"start"}
      width={"calc(100vw - 200px)"}
      overflow={"auto"}
      position="relative"
      marginTop={4}
      height={documentsLoading ? "20rem" : undefined}
    >
      {documentsLoading ? (
        <LoadingView />
      ) : (
        <DnDTable
          autosize={autosize}
          dnd={{
            onDragEnd,
            droppableId: "documents",
          }}
          showEmptyText={!documents || documents.length === 0}
          emptyText={t("noPreset")}
          isLoading={documentsLoading}
        >
          <Thead>
            <TableViewLabelFiltersHeader
              columns={[
                { id: "drag", title: "", width: "50px" },
                {
                  id: "name",
                  title: t("name"),
                  width: "300px",
                },
                {
                  id: "expiresAt",
                  title: t("expiresAt"),
                  width: `${isTablet ? "170px" : undefined}`,
                },
                {
                  id: "isPublic",
                  title: t("isPublic"),
                  width: `${isTablet ? "120px" : undefined}`,
                },
                {
                  id: "tags",
                  title: t("tags"),
                  width: `${isTablet ? "170px" : undefined}`,
                },
                {
                  id: "actions",
                  title: t(""),
                  width: `${isTablet ? "180px" : undefined}`,
                },
              ]}
            />
          </Thead>
          <Tbody>
            {documents?.map((row) => {
              let showLastUploadedFile;
              if (
                row?.lastUploadedFileUri?.includes(".xlsx") ||
                row?.lastUploadedFileUri?.includes(".xls") ||
                row?.lastUploadedFileUri?.includes(".zip") ||
                row?.lastUploadedFileUri?.includes(".doc") ||
                row?.lastUploadedFileUri?.includes(".docx")
              ) {
                showLastUploadedFile = false;
              } else {
                showLastUploadedFile = true;
              }
              return (
                <Tr
                  key={row.id}
                  id={row.id}
                  onDragEnter={() => handleDragIn(row.id)}
                  onDrop={(e) => handleDrop(row, e)}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <Td width={"300px"} textColor={"gray.700"}>
                    <Flex gap={4}>
                      <Tooltip
                        label={row.type.description}
                        placement="bottom-start"
                        openDelay={300}
                      >
                        {row.type.name}
                      </Tooltip>
                      {row.lastUploadedFileUri && showLastUploadedFile && (
                        <Tooltip
                          label={t("lastUpdatedFile")}
                          placement="bottom-start"
                        >
                          <span style={{ marginLeft: 10 }}>
                            <LuFileSearch2
                              color={COLORS.sikuroBlue}
                              cursor="pointer"
                              onClick={() =>
                                window.open(row.lastUploadedFileUri, "_blank")
                              }
                            />
                          </span>
                        </Tooltip>
                      )}
                    </Flex>
                  </Td>
                  <Td width={isTablet ? "170px" : undefined}>
                    {!row.noExpiration && (
                      <Flex alignItems={"center"} gap={4}>
                        <ExpirationIcon days={row.expiresIn} />
                        {row.expiresAt
                          ? formatDateBasedOnLanguage(row.expiresAt)
                          : "--/--/----"}
                      </Flex>
                    )}
                    {row.noExpiration && (
                      <Flex alignItems={"center"} gap={4}>
                        <ExpirationIcon days={100} noExpiration={true} />
                        {t("noExpiration")}
                      </Flex>
                    )}
                  </Td>
                  <Td width={isTablet ? "120px" : undefined}>
                    <Flex justifyContent="start">
                      {row.isPublic ? (
                        <HiOutlineLockOpen size={26} />
                      ) : (
                        <HiOutlineLockClosed size={26} />
                      )}
                    </Flex>
                  </Td>
                  <Td width={isTablet ? "170px" : undefined}>
                    <Flex justifyContent="center">
                      <TagList tags={row.type.tags ?? []} />
                    </Flex>
                  </Td>
                  <Td width={isTablet ? "170px" : undefined}>
                    <Flex gap={4} justifyContent="center">
                      {communicationProps && (
                        <RenderIf permissions={permissions.chat}>
                          <CommentButton
                            onClick={(comment) => {
                              if (comment)
                                communicationProps.createResourceDocumentComment(
                                  `#[${row.type.name}](${row.id}) ${comment}`,
                                );
                            }}
                            comments={
                              communicationProps.resourceDocumentComments?.filter(
                                (c) => c.comment.includes(`(${row.id})`),
                              ) ?? []
                            }
                            isLoading={communicationProps.isFetching}
                            taggableUsers={communicationProps?.taggableUsers}
                          />
                        </RenderIf>
                      )}

                      <RenderIf permissions={permissions.viewEvaluations}>
                        <HistoryButton onClick={() => openHistory(row)} />
                      </RenderIf>

                      <RenderIf permissions={permissions.addFile}>
                        <AddFileButton
                          justFile={false}
                          onClick={() => openDocumentDetailModal(row)}
                          filesCount={row.filesCount}
                        />
                      </RenderIf>
                      <RenderIf permissions={permissions.delete}>
                        <DeleteButton
                          onClick={() => setShowConfirmDeleteModal(row.id)}
                        />
                      </RenderIf>
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </DnDTable>
      )}
    </Flex>
  );
};

export default GeneralDocumentsDnDView;
