import { SiteResource } from './siteResource';
import Badge from './badge';
import { SupplierResource } from './supplierResource';
import { WorkerStatus } from './worker';

export type WorkerParams = {
	fullName: string;
	firstName: string;
	lastName: string;
	fiscalCode: string;
	jobTitle: string;
	photo: string;
	dateOfBirth: string;
	dateOfEmployment: string;
	email: string;
	phone: string;
	placeOfBirth: string;
	status: WorkerStatus;
};

export type SiteWorkerParams = WorkerParams & {
	hasSafetyInduction?: boolean;
};

export type SiteWorker = SiteResource<SiteWorkerParams & { badge?: Badge }>;

export type SupplierWorker = SupplierResource<WorkerParams>;

export const mapSiteWorker =
	(siteId: string = null) =>
	(siteWorker): SiteWorker => ({
		fullName: `${siteWorker.firstName} ${siteWorker.lastName}`,
		firstName: siteWorker.firstName,
		lastName: siteWorker.lastName,
		dateOfBirth: siteWorker.dateOfBirth,
		dateOfEmployment: siteWorker.dateOfEmployment,
		email: siteWorker.email,
		phone: siteWorker.phone,
		photo: siteWorker.photo,
		placeOfBirth: siteWorker.placeOfBirth,
		fiscalCode: siteWorker.fiscalCode,
		variants: siteWorker.variants,
		specializations: siteWorker.specializations,
		company: siteWorker.companyName,
		id: siteWorker.id,
		siteId: siteId,
		companyId: siteWorker.companyId,
		state: siteWorker.state,
		isGlobal: siteWorker.isGlobal,
		isForcedEvaluation: siteWorker.isForcedEvaluation,
		jobTitle: siteWorker.jobTitle,
		status: siteWorker.status,
	});
