import { SiteResourceDocument } from '../../entities/document';
import UseCase from '../../../core/app/usecase';
import DocumentRepository from '../../repositories/documentRepository';
import { GetWorkingSiteDocumentsFilter } from '../../repositories/filters';
import { SortMeta } from '../../entities/interfaces/paginatedResults';

interface Args {
	siteId: string;
	companyId: string;
	supplierId?: string;
	target?: 'company' | 'site';
	filter?: GetWorkingSiteDocumentsFilter;
	sort?: SortMeta;
	pageParam?: number;
}

class GetSiteSupplierDocuments implements UseCase<Args, SiteResourceDocument[]> {
	constructor(private repository: DocumentRepository) {}

	async Call({ siteId, companyId, supplierId, target, filter, sort, pageParam }: Args): Promise<SiteResourceDocument[]> {
		return await this.repository.getSiteSupplierDocuments(companyId, siteId, supplierId, target, filter, sort, pageParam);
	}
}

export default GetSiteSupplierDocuments;
