import { GiCrane } from "react-icons/gi";
import { useParams } from "react-router-dom";
import SubNavBar from "../../Common/SubNavBar";
import { useTranslation } from "react-i18next";
import ToolIcon from "../../Common/icons/ToolIcon";
import { TbBuildingFactory } from "react-icons/tb";
import { NavItemProps } from "../../Common/NavItem";
import WorkerIcon from "../../Common/icons/WorkerIcon";
import VehicleIcon from "../../Common/icons/VehicleIcon";
import MachineIcon from "../../Common/icons/MachineIcon";
import ChemicalIcon from "../../Common/icons/ChemicalIcon";

const CompanyReqGroupDetailSubNav = () => {
  const { id } = useParams();
  const { t } = useTranslation("navbar");

  const items: NavItemProps[] = [
    {
      icon: TbBuildingFactory,
      path: `/company/requirements/${id}/company`,
      name: t("sub.company"),
    },
    {
      Icon: WorkerIcon,
      path: `/company/requirements/${id}/worker`,
      name: t("sub.workers"),
    },
    {
      Icon: VehicleIcon,
      path: `/company/requirements/${id}/vehicle`,
      name: t("sub.vehicles"),
    },
    {
      Icon: MachineIcon,
      path: `/company/requirements/${id}/machine`,
      name: t("sub.machines"),
    },
    {
      Icon: ToolIcon,
      path: `/company/requirements/${id}/tool`,
      name: t("sub.tools"),
    },
    {
      Icon: ChemicalIcon,
      path: `/company/requirements/${id}/chemical`,
      name: t("sub.chemicals"),
    },
    {
      icon: GiCrane,
      path: `/company/requirements/${id}/site`,
      name: t("sub.site"),
    },
  ];

  return <SubNavBar items={items} />;
};

export default CompanyReqGroupDetailSubNav;
